import React, {Component} from 'react';
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Space from "../../components/core/panel/Space";
import MUIDataTable from "mui-datatables";
import Button from "../../components/core/button/Button";
import {GET_USERS} from "../../redux/actions/auth";

const useStyles = makeStyles(theme => ({
    action: {
        display: 'flex'
    }
}));

const Body = props => {
    const classes = useStyles()

    const options = {
        filter: true,
        filterType: "multiselect",
        selectableRows: 'none',
        tableBodyHeight: 600,
    };

    const columns = ["Username", "Lastname", "Firstname",
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                searchable: false,
            }
        },
    ];

    const data = [
        // ["1883", "Lazo", "Creselle John", "Member", "March 12, 2020",
        //     <div className={classes.action}>
        //         <Button size={'small'} color={'warning'}>Edit Privileges</Button>
        //         <Space space={1.5}/>
        //         <Button size={'small'} color={'error'}>Remove User</Button>
        //     </div>
        // ],
    ];
    for (let user of props.class.props.users) {
        data.push([
            user.username,
            user.lastname,
            user.firstname,
            <div className={classes.action}>
                <Button size={'small'} color={'warning'}>Edit Privileges</Button>
                <Space space={1.5}/>
                <Button size={'small'} color={'error'}>Remove User</Button>
            </div>
        ])
    }

    return (
        <div>

            {/*HEADINF*/}
            {/*<Typography*/}
            {/*    variant={'h4'}*/}
            {/*>*/}
            {/*    Users*/}
            {/*</Typography>*/}
            {/*<Typography*/}
            {/*    variant={'body1'}*/}
            {/*>*/}
            {/*    Manage users and their privileges*/}
            {/*</Typography>*/}
            {/*<Space vertical space={3}/>*/}
            {/*<Line horizontal/>*/}
            {/*<Space vertical space={3}/>*/}

            <MUIDataTable
                title={
                    <>
                        <Typography
                            variant={'h4'}
                        >
                            Users
                        </Typography>
                        <Typography
                            variant={'body1'}
                        >
                            Manage users in this office and their privileges
                        </Typography>
                        <Space vertical space={3}/>
                    </>
                }
                data={data}
                columns={columns}
                options={options}
            />

        </div>
    )
}

class Users extends Component {
    componentDidMount() {
        GET_USERS()
    }

    render() {
        return <Body class={this}/>
    }
}

const mapStateToProps = state => ({
    users: state.ADMIN.users
})

export default connect(mapStateToProps, {})(Users)