// import { store } from "../../redux";
import { SEND_MESSAGE_DIALOG } from "../../redux/actions/dialogs";
import { env } from "../../env";
import { MESSAGE } from "../../redux/actions/message";

// const { dispatch, getState } = store;

export default function Main(response) {
  if (response) {
    switch (response.data.error) {
      case "Invalid Credentials":
        SEND_MESSAGE_DIALOG(
          "error",
          "danger",
          "Incorrect Password",
          "Your entered password is not correct for your PMAPS Account Number. Please try again!",
          () => { }
        );
        break;
      case "Not Registered":
        SEND_MESSAGE_DIALOG(
          "error",
          "danger",
          "Not Registered",
          "You have not registered yet. Please register at " +
          env.URL_HRIS +
          " and " +
          "login again here using the same credentials.",
          () => { }
        );
        break;
      case "Unable to Connect":
        SEND_MESSAGE_DIALOG(
          "error",
          "danger",
          "Unable to Authenticate",
          "We can't connect to the server of HRIS at the moment. Please try again later!" +
          " If the problem persists, please contact administrator.",
          () => { }
        );
        break;
      case "Already Exists!":
        SEND_MESSAGE_DIALOG(
          "error",
          "danger",
          "Already Exists",
          "Cannot create a link of the document because this directory already contains the " +
          "document or a linked copy of the document.",
          () => { }
        );
        MESSAGE(`Linking document`, "dequeue");
        return false;
      // case 'Directory Not Found':
      //     REDIRECT('../page-not-found', RELOAD)
      //     return false

      default:
        break;
    }
  }
  // else
  // SEND_MESSAGE_DIALOG('error', 'danger',
  //     'Unable to Connect',
  //     'We\'re unable to connect to the server at the moment. ' +
  //     'Please try again later! If the problem persists, please contact administrator.',
  // )

  return true;
}
