import { store } from "../redux";
/* import IS_ROOT_DIRECTORY from "./directory/IS_ROOT_DIRECTORY";
import IS_PUBLIC_REQUEST from "./context/IS_PUBLIC_REQUEST";
import IS_DIRECTORY from "./directory/IS_DIRECTORY"; */

const { getState } = store

export const IS_DOCUMENT_CONTEXT_OPEN = () => {
   const CONTEXT = getState().CONTEXT;
   return (
      CONTEXT.show_document_context_menu &&
      CONTEXT.documentX === CONTEXT.directory_view_X &&
      CONTEXT.documentY === CONTEXT.directory_view_Y
   );
};
export const IS_DIRECTORY_CONTEXT_OPEN = () => {
   const CONTEXT = getState().CONTEXT;
   return (
      CONTEXT.show_directory_context_menu &&
      CONTEXT.directoryX === CONTEXT.directory_view_X &&
      CONTEXT.directoryY === CONTEXT.directory_view_Y
   );
   // IS_DIRECTORY(CONTEXT.clipboard_destination) &&
   // !IS_ROOT_DIRECTORY(CONTEXT.clipboard_destination)
};

export const IS_DOCUMENT_TRASH_CONTEXT_OPEN = () => {
   const CONTEXT = getState().CONTEXT;
   return (
      CONTEXT.show_document_context_menu_trash &&
      CONTEXT.documentX &&
      CONTEXT.documentY
   );
};

// export const IS_DIRECTORY_TRASH_CONTEXT_OPEN = () => {
//    const CONTEXT = getState().CONTEXT;
//    return (
//       CONTEXT.show_directory_context_menu_trash &&
//       CONTEXT.directoryX &&
//       CONTEXT.directoryY
//    );
//    // IS_DIRECTORY(CONTEXT.clipboard_destination) &&
//    // !IS_ROOT_DIRECTORY(CONTEXT.clipboard_destination)
// };

export const IS_DIRECTORY_VIEW_CONTEXT_OPEN = () => {
   const CONTEXT = getState().CONTEXT;
   return (
      CONTEXT.show_directory_view_context_menu &&
      CONTEXT.directoryX !== CONTEXT.directory_view_X &&
      CONTEXT.directoryY !== CONTEXT.directory_view_Y &&
      CONTEXT.documentX !== CONTEXT.directory_view_X &&
      CONTEXT.documentY !== CONTEXT.directory_view_Y &&
      !CONTEXT.show_directory_context_menu_trash &&
      !CONTEXT.show_document_context_menu_trash
   );
};

export const IS_DIRECTORY_TRASH_CONTEXT_OPEN = () => {
   const CONTEXT = getState().CONTEXT;
   return (
      CONTEXT.show_directory_view_context_menu &&
      CONTEXT.directoryX !== CONTEXT.directory_view_X &&
      CONTEXT.directoryY !== CONTEXT.directory_view_Y &&
      CONTEXT.documentX !== CONTEXT.directory_view_X &&
      CONTEXT.documentY !== CONTEXT.directory_view_Y &&
      !CONTEXT.show_directory_context_menu_trash &&
      !CONTEXT.show_document_context_menu_trash
   );
   // IS_DIRECTORY(CONTEXT.clipboard_destination) &&
   // !IS_ROOT_DIRECTORY(CONTEXT.clipboard_destination)
};
