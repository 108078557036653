// Inside /utils/history.js
const createHistory = require("history").createBrowserHistory;

const history = createHistory();

export const REDIRECT = (link, action = () => {}) => {
     if (window.location.pathname !== link) {
          history.push(link);
          action();
     }
};

export const REPLACE = (link, action = () => {}) => {
     if (window.location.pathname !== link) {
          history.replace(link);
          action();
     }
};

export const GO_BACK = (action = () => {}) => {
     history.goBack();
     action();
};

export const GET_ACTION = () => {
     return history.action;
};

export const RELOAD = (action = () => {}) => {
     history.go();
     action();
};

export default createHistory();
