import { AUTH_LOGOUT } from "../types";
import { store } from "../../index";
import { SEND_MESSAGE_DIALOG } from "../dialogs";
import ERROR_HANDLING from "../../../utils/requests/ERROR_HANDLING";
import CHANGE_URL from "../../../utils/context/CHANGE_URL";

const { dispatch } = store;

export const REQUEST_FUNCTIONS = (response) => {
     if (!IS_AUTHORIZED(response)) return false;
     if (!ERROR_HANDLING(response)) return false;

     return true;
};

const IS_AUTHORIZED = (response) => {
     if (response.data.detail) {
          dispatch({ type: AUTH_LOGOUT });
          SEND_MESSAGE_DIALOG(
               "error",
               "danger",
               "Session Terminated",
               "Your session has been terminated. It's either your session has ended or your account has been " +
                    "signed-in from the other device or browser. " +
                    "If it's not you, please login and change your password immediately.",
               () => {}
          );
          CHANGE_URL("/landing-page");
          return false;
     }
     return true;
};

export const imgToBase64 = (url,callback) => {
     let xhr = new XMLHttpRequest();
     xhr.onload = function() {
          let reader = new FileReader();
          reader.onloadend = function() {
               callback(reader.result);
          }
          reader.readAsDataURL(xhr.response);
     };
     xhr.open('GET', url);
     xhr.responseType = 'blob';
     xhr.send();
}