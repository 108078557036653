import React from 'react'
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import LightToolTip from "../tooltip/LightToolTip";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.grey.S3,
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
        }
    },
    default: {
        color: theme.palette.grey.S3,
        '&:hover': {
            color: theme.palette.grey.S3,
        },
    },
    success: {
        color: theme.palette.success.main,
        '&:hover': {
            color: theme.palette.success.main,
        },
    },
    warning: {
        color: theme.palette.warning.main,
        '&:hover': {
            color: theme.palette.warning.main,
        },
    },
    error: {
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.main,
        },
    },
    hoverDefault: {
        '&:hover': {
            color: theme.palette.grey.S4,
        },
    },
    hoverSuccess: {
        '&:hover': {
            color: theme.palette.success.main,
        },
    },
    hoverWarning: {
        '&:hover': {
            color: theme.palette.warning.main,
        },
    },
    hoverError: {
        '&:hover': {
            color: theme.palette.error.main,
        },
    },
}))

export default function Main(props) {
    const classes = useStyles()
    return (
        <LightToolTip title={props.tooltipTitle || ''} placement={props.tooltipPlacement || 'bottom'}>
            <div className={clsx(
                classes.root,
                props.color === 'success' ? classes.success : null,
                props.color === 'warning' ? classes.warning : null,
                props.color === 'error' ? classes.error : null,
                props.hoverColor === 'default' ? classes.hoverDefault : null,
                props.hoverColor === 'success' ? classes.hoverSuccess : null,
                props.hoverColor === 'warning' ? classes.hoverWarning : null,
                props.hoverColor === 'error' ? classes.hoverError : null,
            )} onClick={props.onClick}>
                {props.icon}
            </div>
        </LightToolTip>
    )
}