// import {store} from '../../redux'
import { SEND_MESSAGE_DIALOG } from "../../redux/actions/dialogs";

// const {dispatch, getState} = store

export default function Main(employee_id, password) {

    if (employee_id === '') {
        SEND_MESSAGE_DIALOG('error', 'danger',
            'ID Number is Empty',
            'PMAPS Account ID or ID Number is a required field. Please fill it up to continue.',
        )
        return false
    }
    if (password === '') {
        SEND_MESSAGE_DIALOG('error', 'danger',
            'Password is Empty',
            'Password is a required field. Please fill it up to continue.',
        )
        return false
    }

    return true
}