/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
}));

export default function ComboBox(props) {
    const classes = useStyles();
    const PROPS = props;
    const label = PROPS.label;
    const value = PROPS.value;

    return (
        <Autocomplete
            id="combo-box-demo"
            options={value}
            getOptionDisabled={(option) => true}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
        />
    );
}