import React from 'react'
import { makeStyles } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import Space from "../panel/Space";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {
        border: '1px solid ' + theme.palette.grey.S3,
        borderRadius: theme.shape.borderRadius,
        background: 'white',
        outline: 0,
        color: theme.palette.grey.S4,
        width: '100%'
    },
    control: {
        display: 'flex',
        alignItems: 'center'
    },
    active: {
        border: '1px solid ' + theme.palette.primary.main,
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    small: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    error: {
        border: '1px solid ' + theme.palette.error.main,
    },
    errorMessage: {
        color: theme.palette.error.main
    }

}))

const BootstrapInput = withStyles(theme => ({
    root: {
        width: '100%',
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        // border: '1px solid ' + theme.palette.grey.S3,
        borderRadius: theme.shape.borderRadius,
        background: 'white',
        padding: theme.spacing(1.5),
        outline: 0,
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.grey.S4,
        width: '100%',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        '&:focus': {
            border: '1px solid ' + theme.palette.grey.S3,
            borderRadius: theme.shape.borderRadius,
            background: 'white'
        },
    },
}))(InputBase);


export default function Main(props) {
    const classes = useStyles()
    return (
        <div style={{ width: '100%' }}>
            <div className={clsx(classes.root,
                props.error ? classes.error : null
            )}>
                <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    name={props.name}
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    input={<BootstrapInput />}
                    classes={{
                        icon: classes.icon
                    }}
                >
                    {props.nullable ?
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem> : null
                    }
                    {props.menuItem && props.menuItem.length > 0 ?
                        props.menuItem.map((item, index) => (
                            <MenuItem value={item} key={index}>{item}</MenuItem>
                        ))
                        : null
                    }
                </Select>
            </div>
            {props.error ?
                <div>
                    <Space vertical space={1} />
                    <Typography variant={'caption'} className={classes.errorMessage}>
                        {props.error}
                    </Typography>
                </div> : null
            }
        </div>

    )
}