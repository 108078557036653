import storage from "redux-persist/lib/storage";
// import storageSession from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

export const persistConfig = {
  key: "root",
  storage,
  whitelist: ["AUTH", "TRASH"],
  stateReconciler: autoMergeLevel2,
};

export const stateSyncConfig = {
  channel: "UDAS",
  whitelist: [],
};
