import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";

import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { env } from "../env";
import { persistConfig, stateSyncConfig } from "./config";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
  withReduxStateSync,
} from "redux-state-sync/dist/syncState";

const middleware = [thunk, createStateSyncMiddleware(stateSyncConfig)];

const reducers = persistReducer(persistConfig, withReduxStateSync(rootReducer));

export const store = createStore(
  reducers,
  {},
  env.DEBUG
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware)
);

initStateWithPrevTab(store);

export const persistor = persistStore(store);
export const { dispatch, getState } = store;
