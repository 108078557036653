import React, { Component } from 'react';
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import STATES from "../../redux/states";
import { GET_DIRECTORY_TREE } from "../../redux/actions/directory";
import { GET_ACTIONS, MONITORING_PRINT_PDF, VIEW_MONITORING } from "../../redux/actions/document";
import ViewReport from "../../pages/ViewReport";
import MaterialUIPickers from "../core/input/ReactDatePicker";
import Button from "../core/button/Button";
import { setToggle, setForm } from "../../redux/actions/context";
import { Assessment, Print } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SEND_MESSAGE_DIALOG } from "../../redux/actions/dialogs";
import IS_SUPPORT from "../../utils/permissions/IS_SUPPORT";
import NativeSelects from "../core/input/Checkmarks";
// import {
//     GET_ACTIVE_USERS_BY_USER_DEPARTMENTS,
// } from "../../redux/actions/auth";
import { POPULATE_HRIS_USER_DATA } from "../../redux/actions/admin";

const useStyles = makeStyles(theme => ({
    container: {
        height: "30px",
        width: "100%",
        display: "grid",
    },
    filterContainer: {
        width: "100%",
        // padding: "10px",
        display: "grid",
        // justifyItems: "center",
        alignItems: "center",
        gap: "1%",
        gridTemplateColumns: "25% 15% 15% 25% 15%",
    },
    fullWidth: {
        width: "100% !important",
    },
    dateRangeContainer: {
        minWidth: "300px",
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gap: "20px",
        marginBottom: "23px",
    },
    btnShowReport: {
        justifySelf: "end",
    },
    tableContainer: {
        width: "100%",
        display: "grid",
        gridAutoFlow: "row",
    },
    circularProgress: {
        marginTop: "1px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
    },
    circularProgressIcon: {
        color: "primary",
        size: "200"
    },
    btnPrintPdfContainer: {
        marginTop: "30px",
        justifyContent: "end",
    },
}));

const Body = (PROPS) => {
    const CLASSES = useStyles();
    // const CLASS = PROPS.class;
    const CONTEXT = PROPS.class.props.CONTEXT;
    const DIRECTORY = PROPS.class.props.DIRECTORY;
    const DOCUMENT = PROPS.class.props.DOCUMENT;
    const AUTH = PROPS.class.props.AUTH;
    const ADMIN = PROPS.class.props.ADMIN;

    const getOfficeNameByRootDirectoryId = (id) => {
        const officesDept = DIRECTORY.tree.departments;
        const officesPublic = DIRECTORY.tree.public;
        const officesObj = officesDept.concat(officesPublic);
        if (officesObj.length > 0) {
            for (let i = 0; i < officesObj.length; i++) {
                if (officesObj[i].directory_id.toString() === id.toString()) {
                    return officesObj[i].name;
                }
            }
        }
        return "";
    }

    const getActionDescriptionById = (id) => {
        const actions = DOCUMENT.document_actions.actions;
        const allOfficesTxt = "All Action";
        if (id.toString() === "all") return allOfficesTxt;
        if (CONTEXT.toggles.action_loader === false) {
            for (let i = 0; i < actions.length; i++) {
                if (actions[i].id.toString() === id.toString()) {
                    return actions[i].action.toUpperCase();
                }
            }
        }
        return "";
    }

    const getUserNameById = (id) => {
        const users = ADMIN.users_user_departments;
        const allUserTxt = "All User";
        if (id.toString() === "all") return allUserTxt;
        if (CONTEXT.toggles.active_users_by_users_department_loader === false) {
            for (let i = 0; i < users.length; i++) {
                if (users[i].id.toString() === id.toString()) {
                    return (users[i].firstname + " " + users[i].lastname).toUpperCase();
                }
            }
        }
        return "";
    }

    const onClick = type => {
        switch (type) {
            case 'show_report':
                if (isValid()) {
                    setToggle("report_loader", true);
                    setToggle("view_report", true);
                    VIEW_MONITORING()
                }
                break;
            case 'print_pdf':
                let office = getOfficeNameByRootDirectoryId(CONTEXT.forms.monitoring.offices);
                let action = getActionDescriptionById(CONTEXT.forms.monitoring.actions);
                let user = getUserNameById(CONTEXT.forms.monitoring.users);
                MONITORING_PRINT_PDF(office, action, user);
                break;
            default:
                break;
        }
    }

    const isValid = () => {
        let valid_flag = true;
        let dialogMessage = {
            variant: "error",
            icon: "danger",
            title: "Empty Required Fields",
            caption: "Some required fields were left unfilled. Please make sure to fill in them all to continue.",
        }
        if ((!CONTEXT.forms.monitoring.offices && typeof CONTEXT.forms.monitoring.offices === 'undefined') || CONTEXT.forms.monitoring.offices === "") {
            setForm("monitoring", "error_title", "This field is required.");
            valid_flag = false;
        } else setForm("monitoring", "error_title", false);

        if (typeof CONTEXT.forms.monitoring.actions === 'undefined' || CONTEXT.forms.monitoring.actions === "") {
            setForm("monitoring", "error_title", "This field is required.");
            valid_flag = false;
        } else setForm("monitoring", "error_title", false);

        if (typeof CONTEXT.forms.monitoring.users === 'undefined' || CONTEXT.forms.monitoring.users === "") {
            setForm("monitoring", "error_title", "This field is required.");
            valid_flag = false;
        } else setForm("monitoring", "error_title", false);

        if (typeof CONTEXT.forms.monitoring.date_from === 'undefined' || CONTEXT.forms.monitoring.date_from === "") {
            setForm("monitoring", "error_title", "This field is required.");
            valid_flag = false;
        } else setForm("monitoring", "error_title", false);

        if (typeof CONTEXT.forms.monitoring.date_to === 'undefined' || CONTEXT.forms.monitoring.date_to === "") {
            setForm("monitoring", "error_title", "This field is required.");
            valid_flag = false;
        } else setForm("monitoring", "error_title", false);

        if (CONTEXT.forms.monitoring.date_from > CONTEXT.forms.monitoring.date_to && valid_flag) {
            setForm("monitoring", "error_title", "Invalid Date");
            valid_flag = false;
            dialogMessage = {
                variant: "error",
                icon: "danger",
                title: "Invalid Date",
                caption: "Start Date should not be greater than End Date.",
            }
        } else setForm("monitoring", "error_title", false);

        if (!valid_flag) {
            SEND_MESSAGE_DIALOG(
                dialogMessage.variant,
                dialogMessage.icon,
                dialogMessage.title,
                dialogMessage.caption
            );
            setToggle("report_loader", false);
            setToggle("view_report", false);
        }
        return valid_flag;
    };

    let OFFICES = [];
    if (CONTEXT.toggles.first_directory_tree_loader === false) {
        if (IS_SUPPORT()) {
            const officesDept = DIRECTORY.tree.departments;
            const officesPublic = DIRECTORY.tree.public;
            const officesObj = officesDept.concat(officesPublic);
            if (officesObj) {
                officesObj.map((obj) => {
                    if (!obj.is_support) {
                        OFFICES.push({ id: obj.directory_id, value: obj.name });
                    }
                })
            }
        } else {
            if (typeof AUTH.department !== 'undefined' && AUTH.department.length > 0) {
                const userDepartment = AUTH.department;
                userDepartment.map((obj) => {
                    if (obj.is_head) {
                        OFFICES.push({ id: obj.id, value: obj.name });
                    }
                });
            }
        }
    }

    let ACTIONS = [];
    if (CONTEXT.toggles.action_loader === false) {
        ACTIONS = [{ id: "all", value: "ALL" }];
        DOCUMENT.document_actions.actions.map((obj, index) => (
            ACTIONS[index + 1] = {
                id: obj.id,
                value: obj.action.toUpperCase(),
            }
        ));
    }
    let USERS = [];

    if (CONTEXT.toggles.active_users_by_users_department_loader === false) {
        if (ADMIN.users_user_departments.length > 0) USERS = [{ id: "all", value: "ALL" }];
        ADMIN.users_user_departments.map((obj, index) => (
            USERS[index + 1] = {
                id: obj.id,
                value: (obj.lastname + ", " + obj.firstname).toUpperCase(),
            }
        ));
    }

    return (
        <div className="container">
            <section className={CLASSES.filterContainer}>
                <div>
                    <NativeSelects
                        title={"OFFICES"}
                        type={"monitoring"}
                        name={"offices"}
                        value={OFFICES.sort()}
                        smallfont
                        small
                    />
                </div>
                <div>
                    <NativeSelects
                        title={"ACTIONS"}
                        type={"monitoring"}
                        name={"actions"}
                        value={ACTIONS}
                        smallfont
                        small
                    />
                </div>
                <div>
                    <NativeSelects
                        title={"USERS"}
                        type={"monitoring"}
                        name={"users"}
                        value={USERS}
                        smallfont
                        small
                    />
                </div>
                <div>
                    <div className={CLASSES.dateRangeContainer}>
                        <MaterialUIPickers
                            currentMaxDate
                            title={'Start Date'}
                            type={"monitoring"}
                            name={"date_from"}
                        />
                        <MaterialUIPickers
                            currentMaxDate
                            title={'End Date'}
                            type={"monitoring"}
                            name={"date_to"}
                        />
                    </div>
                </div>
                <div className={CLASSES.btnShowReport}>
                    <Button
                        label={"GENERATE"}
                        variant={"contained"}
                        loading={
                            CONTEXT.toggles
                                .report_loader
                        }
                        fullwidth
                        size="small"
                        class={PROPS.class}
                        onClick={() => onClick("show_report")}
                        icon={<Assessment />}
                    />
                </div>
            </section>

            <section className={CLASSES.tableContainer}>
                {CONTEXT && (CONTEXT.toggles.report_loader) ? (
                    <div className={CLASSES.circularProgress} >
                        <CircularProgress className={CLASSES.circularProgressIcon} />
                    </div>
                ) : CONTEXT && (CONTEXT.toggles.view_report && !CONTEXT.toggles.report_loader) ? (
                    <div>
                        <div className={CLASSES.btnPrintPdfContainer}>
                            <Button
                                label={"PRINT"}
                                variant={"contained"}
                                class={PROPS.class}
                                onClick={() => onClick("print_pdf")}
                                icon={<Print />}
                                margin10
                            />
                        </div>
                        <div>
                            <ViewReport />
                        </div>
                    </div>
                ) : null}
            </section>
        </div>
    )
}


class Monitoring extends Component {

    componentDidMount = async () => {
        GET_DIRECTORY_TREE()
        GET_ACTIONS()
        POPULATE_HRIS_USER_DATA()
    }
    render() {
        return <Body class={this} />
    }
}

export default connect(STATES, null)(Monitoring)
