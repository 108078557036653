import React, { Fragment } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Space from "../core/panel/Space";
import { MoreHorizOutlined, SendOutlined } from "@material-ui/icons";
import clsx from "clsx";
import IconLink from "../core/button/IconLink";
import Line from "../core/decoration/Line";
import {
  RiClipboardLine,
  RiDeleteBin7Line,
  RiEditLine,
  RiFileCopyLine,
  RiInformationLine,
  RiLinksLine,
  RiScissorsCutLine,
} from "react-icons/all";
import HAS_CRUD from "../../utils/permissions/HAS_CRUD";
import Popover from "@material-ui/core/Popover";
import LightToolTip from "../core/tooltip/LightToolTip";
import { MESSAGE } from "../../redux/actions/message";
import { setContext, setForm, setToggle } from "../../redux/actions/context";
import {
  COPY_DOCUMENT,
  CUT_DOCUMENT,
  LINK_DOCUMENT,
  REQUEST_ALL_TO_PUBLICIZE,
} from "../../redux/actions/document";
import IS_ROOT_DIRECTORY from "../../utils/directory/IS_ROOT_DIRECTORY";
import {
  COPY_DIRECTORY,
  CUT_DIRECTORY,
  DELETE_DIRECTORY,
} from "../../redux/actions/directory";
import {
  SEND_CONFIRMATION_DIALOG,
  SEND_MESSAGE_DIALOG,
} from "../../redux/actions/dialogs";
import { getDepartment } from "../../redux/actions/department";
import IS_SUPPORT from "../../utils/permissions/IS_SUPPORT";
import IS_HEAD from "../../utils/permissions/IS_HEAD";
import IS_ENV_DEP from "../../utils/context/IS_ENV_DEP";
import IN_ANCESTRY from "../../utils/directory/IN_ANCESTRY";
import IS_MODERATOR_IN_DIRECTORY from "../../utils/permissions/IS_MODERATOR_IN_DIRECTORY";
import HAS_DEPARTMENT from "../../utils/permissions/HAS_DEPARTMENT";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(5),
  },
  iconAction: {
    borderRadius: 100,
    padding: 4,
    margin: "-4px",
    color: theme.palette.grey.S4,
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
      background: theme.palette.grey.RGB0,
    },
  },
  iconActionActive: {
    color: theme.palette.primary.main,
    background: theme.palette.grey.RGB0,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  popoverMoreDirectoryRoot: {
    marginTop: 12,
  },
  popoverMoreDirectoryPaper: {
    boxShadow: theme.shadows[3],
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  moreDirectoryItemRoot: {
    display: "flex",
    alignItems: "center",
    maxWidth: 250,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.grey.S4,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
      background: theme.palette.grey.RGB0,
    },
  },
  checkIcon: {
    width: theme.spacing(2),
    height: 20,
  },
  icon: {
    width: theme.spacing(3),
  },
}));

const MoreItem = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.moreDirectoryItemRoot} onClick={props.onClick}>
      <div className={classes.icon}>{props.icon}</div>

      <Space space={1.5} />
      <Typography
        noWrap={props.level !== 1}
        variant={"body2"}
        className={clsx(classes.typography)}
      >
        {props.label}
      </Typography>
    </div>
  );
};

export default function Main(props) {
  const classes = useStyles();

  const { CONTEXT, DIRECTORY_DETAILS } = useSelector((state) => ({
    CONTEXT: state.CONTEXT,
    DIRECTORY_DETAILS: state.DIRECTORY.details,
  }));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const onClick = (action) => {
    switch (action) {
      case "copy":
      case "cut":
        setContext("clipboard", {
          id: CONTEXT.active_directory,
          type: "DIRECTORY",
          action,
        });
        if (action === "copy") {
          MESSAGE(`Directory cut to clipboard`, "dequeue");
          MESSAGE(`Directory copied to clipboard`, "enqueue", "success");
        } else {
          // GET_DESCENDANCE(CONTEXT.active_directory, (descendance) => {
          //     SET_CONTEXT('cut_directory_descendance', descendance)
          MESSAGE(`Directory copied to clipboard`, "dequeue");
          MESSAGE(`Directory cut to clipboard`, "enqueue", "success");
          // }, () => {
          //     MESSAGE(`Directory copied to clipboard`, 'dequeue')
          //     MESSAGE(`Failed to cut directory to clipboard`, 'enqueue', 'error')
          // })
        }
        break;
      case "paste-copy":
        if (CONTEXT.clipboard.type === "DOCUMENT") {
          COPY_DOCUMENT(CONTEXT.active_directory, CONTEXT.clipboard.id);
        }
        break;
      case "paste-link":
        if (CONTEXT.clipboard.type === "DOCUMENT") {
          LINK_DOCUMENT(CONTEXT.active_directory, CONTEXT.clipboard.id);
        }
        break;
      case "paste-cut":
        if (CONTEXT.clipboard.type === "DOCUMENT") {
          CUT_DOCUMENT(CONTEXT.active_directory, CONTEXT.clipboard.id);
        }
        break;
      case "paste-directory":
        if (CONTEXT.clipboard.action === "copy") {
          COPY_DIRECTORY(CONTEXT.active_directory, CONTEXT.clipboard.id);
        } else if (CONTEXT.clipboard.action === "cut") {
          CUT_DIRECTORY(CONTEXT.active_directory, CONTEXT.clipboard.id);
        }
        break;
      case "edit-department":
        getDepartment(null, () => {
          setContext("formRequestMode", "UPDATE");
          setContext("formType", "Department");
          setToggle("create_new", true);
        });
        handleClose();
        break;
      case "edit-directory":
        setForm("directory", "name", DIRECTORY_DETAILS.name);
        setForm("directory", "description", DIRECTORY_DETAILS.description);
        setForm("directory", "group", DIRECTORY_DETAILS.group.name);
        setForm("directory", "moderators", DIRECTORY_DETAILS.moderators);
        setForm("directory", "is_public", DIRECTORY_DETAILS.is_public);
        setContext("formRequestMode", "UPDATE");
        setContext("formType", "Directory");
        setToggle("create_new", true);
        handleClose();
        break;
      case "edit-directory-link":
        setToggle("view_directory_link", true);
        handleClose();
        break;
      case "trash-directory":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "warning",
          "Trash Confirmation",
          "This directory will be moved to the Office Trash Bin. You can restore it later if necessary.",
          () => {
            DELETE_DIRECTORY();
          }
        );
        handleClose();
        break;
      case "request-all-to-publicize":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Request Confirmation",
          "Upon confirmation, all unpublicized documents in this directory will be requested for " +
          "public posting and will be subject for approval.",
          () => {
            REQUEST_ALL_TO_PUBLICIZE();
          }
        );
        handleClose();
        break;
      case "info":
        SEND_MESSAGE_DIALOG(
          "info",
          "",
          "Coming Soon",
          "This feature is under development and will be available in the next updates.",
          () => { }
        );
        handleClose();
        break;
      default:
        break;
    }
  };

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div className={clsx(classes.root, classes.flex)}>
      {!props.root ? (
        <div className={classes.flex}>
          {HAS_DEPARTMENT() ? (
            <IconLink
              tooltipTitle={"Copy Directory"}
              icon={<RiFileCopyLine size={22} />}
              onClick={() => {
                onClick("copy");
              }}
            />
          ) : null}

          {HAS_CRUD() ? (
            <div className={classes.flex}>
              {IS_ROOT_DIRECTORY() ? null : IS_MODERATOR_IN_DIRECTORY(
                CONTEXT.active_directory
              ) ? null : (
                <div className={classes.flex}>
                  <Space space={2} />
                  <IconLink
                    tooltipTitle={"Cut Directory"}
                    icon={<RiScissorsCutLine size={22} />}
                    onClick={() => {
                      onClick("cut");
                    }}
                  />
                </div>
              )}

              {CONTEXT.clipboard.type === "DOCUMENT" ? (
                CONTEXT.clipboard.action === "copy" ? (
                  <div className={classes.flex}>
                    <Space space={2} />
                    <IconLink
                      tooltipTitle={"Paste as Copy"}
                      icon={<RiClipboardLine size={22} />}
                      onClick={() => {
                        onClick("paste-copy");
                      }}
                    />
                    <Space space={2} />
                    <IconLink
                      tooltipTitle={"Paste as Link"}
                      icon={<RiLinksLine size={22} />}
                      onClick={() => {
                        onClick("paste-link");
                      }}
                    />
                  </div>
                ) : CONTEXT.clipboard.action === "cut" ? (
                  <div className={classes.flex}>
                    <Space space={2} />
                    <IconLink
                      tooltipTitle={"Paste"}
                      icon={<RiClipboardLine size={22} />}
                      onClick={() => {
                        onClick("paste-cut");
                      }}
                    />
                  </div>
                ) : null
              ) : CONTEXT.clipboard.type === "DIRECTORY" ? (
                CONTEXT.clipboard.action === "cut" &&
                  IN_ANCESTRY(CONTEXT.clipboard.id) ? null : (
                  <div className={classes.flex}>
                    <Space space={2} />
                    <IconLink
                      tooltipTitle={"Paste"}
                      icon={<RiClipboardLine size={22} />}
                      onClick={() => {
                        onClick("paste-directory");
                      }}
                    />
                  </div>
                )
              ) : null}
            </div>
          ) : null}

          <Space space={3} />
          <Line height={24} />
          <Space space={3} />

          {/*{ IS_ROOT_DIRECTORY() || IS_MODERATOR() ? null :*/}
          {/*    <div className={classes.flex}>*/}
          {/*        <Space space={3}/>*/}
          {/*        <Line height={24}/>*/}
          {/*        <Space space={3}/>*/}
          {/*        <IconLink*/}
          {/*            tooltipTitle={'Edit Directory'}*/}
          {/*            icon={<RiEditLine size={24}/>}*/}
          {/*            onClick={props.editOnClick}*/}
          {/*        />*/}
          {/*        <Space space={2}/>*/}
          {/*        <IconLink*/}
          {/*            tooltipTitle={'Delete Directory'}*/}
          {/*            icon={<RiDeleteBin7Line size={24}/>}*/}
          {/*            onClick={props.deleteOnClick}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*}*/}
          {/*{IS_HEAD() && ARE_DOCUMENTS_IN_DIRECTORY_ALL_PUBLIC() === false ?*/}
          {/*    <div className={classes.flex}>*/}
          {/*        <Space space={3}/>*/}
          {/*        <Line height={24}/>*/}
          {/*        <Space space={3}/>*/}
          {/*        <IconLink*/}
          {/*            tooltipTitle={'Request All Documents to Publicize'}*/}
          {/*            icon={<SendOutlined fontSize={'small'}/>}*/}
          {/*            onClick={props.publicizeAllRequestOnClick}*/}
          {/*        />*/}
          {/*    </div> : null*/}
          {/*}*/}

          {/*<IconLink*/}
          {/*    tooltipTitle={'More Actions'}*/}
          {/*    icon={<MdMoreHoriz size={24}/>}*/}
          {/*    onClick={handleClick}*/}
          {/*/>*/}

          <LightToolTip title="More Actions" placement="bottom">
            <MoreHorizOutlined
              className={clsx(
                classes.iconAction,
                open ? classes.iconActionActive : null
              )}
              onClick={handleClick}
            />
          </LightToolTip>
        </div>
      ) : null}

      <Popover
        id={"popover-more-directory"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          root: classes.popoverMoreDirectoryRoot,
          paper: classes.popoverMoreDirectoryPaper,
        }}
      >
        {/*<MoreItem*/}
        {/*    icon={<RiScissorsCutLine size={22}/>}*/}
        {/*    label={'Cut Directory'}*/}
        {/*    onClick={() => {*/}

        {/*    }}*/}
        {/*/>*/}
        {/*<MoreItem*/}
        {/*    icon={<RiClipboardLine size={22}/>}*/}
        {/*    label={'Paste as Copy'}*/}
        {/*    onClick={() => {*/}

        {/*    }}*/}
        {/*/>*/}
        {/*<MoreItem*/}
        {/*    icon={<RiLinksLine size={22}/>}*/}
        {/*    label={'Paste as Link'}*/}
        {/*    onClick={() => {*/}

        {/*    }}*/}
        {/*/>*/}

        {/*<Space vertical space={0.5} />*/}
        {/*<Line horizontal />*/}
        {/*<Space vertical space={0.5} />*/}

        {IS_SUPPORT() && IS_HEAD() && IS_ROOT_DIRECTORY() ? (
          <div>
            <MoreItem
              icon={<RiEditLine size={22} />}
              label={"Edit Department"}
              onClick={() => {
                onClick("edit-department");
              }}
            />
            <Space vertical space={0.5} />
            <Line horizontal />
            <Space vertical space={0.5} />
          </div>
        ) : null}

        {HAS_CRUD() && !IS_ROOT_DIRECTORY() ? (
          IS_MODERATOR_IN_DIRECTORY(CONTEXT.active_directory) ? null : (
            <div>
              <MoreItem
                icon={<RiEditLine size={22} />}
                label={"Edit Directory"}
                onClick={() => {
                  onClick("edit-directory");
                }}
              />
              <MoreItem
                icon={<RiDeleteBin7Line size={22} />}
                label={"Move to Trash"}
                onClick={() => {
                  onClick("trash-directory");
                }}
              />
              <Space vertical space={0.5} />
              <Line horizontal />
              <Space vertical space={0.5} />
            </div>
          )
        ) : null}

        {/*<MoreItem*/}
        {/*    icon={<RiShareLine size={22}/>}*/}
        {/*    label={'Share'}*/}
        {/*    onClick={() => {*/}

        {/*    }}*/}
        {/*/>*/}

        {/*<MoreItem*/}
        {/*    icon={<RiUser3Line size={22}/>}*/}
        {/*    label={'Moderators'}*/}
        {/*    onClick={() => {*/}

        {/*    }}*/}
        {/*/>*/}
                {/*  hide for global task  */}
        {/* {IS_HEAD() && IS_ENV_DEP() && !IS_SUPPORT() ? (
          <Fragment>
            <MoreItem
              icon={<SendOutlined style={{ fontSize: 22 }} />}
              label={"Request Documents to Publicize"}
              onClick={() => {
                onClick("request-all-to-publicize");
              }}
            />
            <Space vertical space={0.5} />
            <Line horizontal />
            <Space vertical space={0.5} />
          </Fragment>
        ) : null} */}
        {HAS_CRUD() && !IS_ROOT_DIRECTORY() ? (
          IS_MODERATOR_IN_DIRECTORY(CONTEXT.active_directory) ? null : (
            <div>
              <MoreItem
                  icon={<RiEditLine size={22} />}
                  label={"Edit Directory Link"}
                  onClick={() => {
                    onClick("edit-directory-link");
                  }}
              />
              <Line horizontal />
            </div>
          )
        ) : null}

        <MoreItem
          icon={<RiInformationLine size={22} />}
          label={"Information"}
          onClick={() => {
            onClick("info");
          }}
        />
      </Popover>
    </div>
  );
}
