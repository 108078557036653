import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { ClearOutlined } from "@material-ui/icons";
import Line from "../decoration/Line";
import Space from "../panel/Space";
import LightToolTip from "../tooltip/LightToolTip";
import Popover from "@material-ui/core/Popover";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { RiSearchLine, RiFocus2Line } from "react-icons/all";

import IS_PUBLIC_REQUEST from "../../../utils/context/IS_PUBLIC_REQUEST";
import clsx from "clsx";
import { setContext } from "../../../redux/actions/context";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // background: theme.palette.grey.S1,
    borderRadius: theme.shape.borderRadius,
    border: "1px solid " + theme.palette.grey.S2,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  input: {
    outline: 0,
    border: "none",
    background: "transparent",
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey.S4,
    width: 200,
    "&::placeholder": {
      color: theme.palette.grey.S3,
    },
  },
  icon: {
    // marginLeft: theme.spacing(2),
    color: theme.palette.grey.S3,
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  popoverRoot: {
    marginTop: 20,
  },
  popoverPaper: {
    boxShadow: theme.shadows[3],
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
  popoverContentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  captionContainer: {
    // paddingLeft: theme.spacing(2),
    width: 226,
  },
}));

export default function Main(props) {
  const classes = useStyles();
  const CONTEXT = props.class.props.CONTEXT;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = async (event) => {
    event.persist();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.container}>
        <input
          placeholder={"Search"}
          className={classes.input}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          value={props.value}
        />
        {props.value ? (
          <div className={classes.flex}>
            <Space space={2} />
            <LightToolTip title="Clear" placement="bottom">
              <ClearOutlined
                className={classes.icon}
                onClick={props.onClear}
                fontSize={"small"}
              />
            </LightToolTip>
          </div>
        ) : null}
        <Space space={2} />
        <LightToolTip title="Search" placement="bottom">
          <RiSearchLine
            size={22}
            className={classes.icon}
            onClick={props.onClick}
          />
        </LightToolTip>

        {IS_PUBLIC_REQUEST() ? null : (
          <div className={classes.flex}>
            <Space space={2} />
            <Line height={24} />
            <Space space={1.5} />
            <LightToolTip title="Target Location" placement="bottom">
              <RiFocus2Line
                size={22}
                className={clsx(classes.icon, open ? classes.activeIcon : null)}
                onClick={handleClick}
              />
            </LightToolTip>
          </div>
        )}
      </div>

      <Popover
        id={"popover-more-directory"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          root: classes.popoverRoot,
          paper: classes.popoverPaper,
        }}
      >
        <div className={classes.popoverContentContainer}>
          <Typography variant={"body2"}>Target Location</Typography>
          <Space vertical space={1} />
          <FormControlLabel
            control={
              <Checkbox
                name={"search_scope"}
                checked={CONTEXT.search_scope === "directory"}
                onClick={async () => {
                  await setContext("search_scope", "directory");
                  await props.onClick();
                }}
                value={CONTEXT.search_scope === "directory"}
                color="primary"
              />
            }
            label={"Active directory"}
            labelPlacement="end"
          />
          <div className={classes.captionContainer}>
            <Typography variant={"caption"}>
              All documents and directories within the active directory and its
              subdirectories.
            </Typography>
          </div>
          <Space vertical space={1} />
          <FormControlLabel
            control={
              <Checkbox
                name={"search_scope"}
                checked={CONTEXT.search_scope === "anywhere"}
                onClick={async () => {
                  await setContext("search_scope", "anywhere");
                  await props.onClick();
                }}
                value={CONTEXT.search_scope === "anywhere"}
                color="primary"
              />
            }
            label={"Anywhere"}
            labelPlacement="end"
          />
          <div className={classes.captionContainer}>
            <Typography variant={"caption"}>
              All documents and directories from your offices and all public
              documents in all directories from the other offices.
            </Typography>
          </div>
        </div>
      </Popover>
    </div>
  );
}
