import React from "react";
import { Grow, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import SelectionTable from "../table/SelectionTable";
import { useSelector } from "react-redux";
import { FolderOutlined } from "@material-ui/icons";
import LightToolTip from "../tooltip/LightToolTip";
import { getDirectoryPath } from "../../../utils/directory";
import { DateTime } from "luxon";
import { setContext } from "../../../redux/actions/context";

export default function Main({ trash }) {
  const classes = useStyles();
  const { listView } = useSelector((state) => ({
    listView: state.CONTEXT.toggles.directory_list_view || false,
  }));

  const columns = [
    {
      id: "id",
      numeric: true,
      disablePadding: true,
      label: "id",
      hidden: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      style: { width: "15%" },
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
      style: { width: "25%" },
    },
    {
      id: "group",
      numeric: false,
      disablePadding: false,
      label: "Group",
      style: { width: "10%" },
    },
    {
      id: "path",
      numeric: false,
      disablePadding: false,
      label: "Path",
      style: { width: "30%" },
    },
    {
      id: "deleted_at",
      numeric: false,
      disablePadding: false,
      label: "Date Deleted",
      wrap: (value) => {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED);
      },
      style: { width: "10%" },
    },
    {
      id: "deleted_by",
      numeric: false,
      disablePadding: false,
      label: "Deleted By",
      style: { width: "10%" },
    },
  ];

  const rows = [];
  if (trash)
    trash.forEach((item) => {
      rows.push([
        item.id,
        item.directory.name,
        item.directory.description,
        item.directory.group,
        getDirectoryPath(item.directory.ancestry),
        item.created_at,
        item.user.first_name + " " + item.user.last_name,
      ]);
    });

  return (
    <div className={classes.root}>
      <div className={classes.folderGroupContainer}>
        {listView && <FolderOutlined className={classes.folderIcon} />}
        <Typography
          noWrap
          variant={"body2"}
          className={clsx(classes.typography)}
        >
          DIRECTORIES
        </Typography>
      </div>
      {listView ? (
        <>
          <SelectionTable
            columns={columns}
            rows={rows}
            noHead
            disableSelect
            disableEmptyRow
            disableDenseToggle
            paginationRowsPerPage={10}
            onContextMenuRow={(e, item) => {
              e.preventDefault();
              e.persist();
              setContext("directoryX", e.pageX);
              setContext("directoryY", e.pageY);
              setContext("show_directory_context_menu_trash", true);
              setContext("pre_clipboard", {
                id: item[0],
                type: "TRASH_DIRECTORY",
              });
            }}
          // onClickRow={async row => {
          //    await SET_CONTEXT("search_key", "");
          //    await SET_CONTEXT("search_mode", false);
          //    await props.childOnClick(props.prefix + row[0]);
          // }}
          />
        </>
      ) : (
        <div className={classes.folderContainer}>
          {/* DIRECTORIES */}
          {trash.map((item, index) => (
            <div key={index}>
              <Grow in>
                <LightToolTip
                  title={item.directory.name}
                  placement={"bottom-start"}
                >
                  <div
                    className={clsx(classes.folderBoxContainer)}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      e.persist();
                      setContext("directoryX", e.pageX);
                      setContext("directoryY", e.pageY);
                      setContext("show_directory_context_menu_trash", true);
                      setContext("pre_clipboard", {
                        id: item.id,
                        type: "TRASH_DIRECTORY",
                      });
                    }}
                  >
                    <FolderOutlined className={classes.folderIcon} />
                    <Typography
                      noWrap
                      variant={"body1"}
                      className={clsx(classes.typography)}
                    >
                      {item.directory.name}
                    </Typography>
                  </div>
                </LightToolTip>
              </Grow>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  folderGroupContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  folderGroupActionContainer: {
    display: "flex",
    alignItems: "center",
  },
  folderContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  folderBoxContainer: {
    width: 200,
    border: "solid 1px " + theme.palette.grey.S2,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    "&:hover": {
      cursor: "pointer",
    },
  },
  folderIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.grey.S3,
  },
  gridIcon: {
    color: theme.palette.grey.S3,
  },
  typography: {
    lineHeight: "normal",
    color: theme.palette.grey.S4,
  },
  iconAction: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
}));
