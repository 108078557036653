import React from "react";
import { Link, makeStyles, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Space from "../panel/Space";
import Typography from "@material-ui/core/Typography";
import Button from "../button/Button";
import { TransitionSlideUp } from "../transition/TransitionSlideLeft";
import DataPrivacy from "../../../static/media/img/DataPrivacy.jpg";

const useStyles = makeStyles((theme) => ({
     root: {
          borderRadius: theme.shape.borderRadius,
     },
     body: {
          padding: theme.spacing(6),
          width: 500,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
     },
     iconContainer: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
     },
     icon: {
          fontSize: 100,
     },
     typography: {
          color: theme.palette.grey.S4,
     },
     link: {
          color: theme.palette.grey.S3,
          "&:hover": {
               cursor: "pointer",
               color: theme.palette.grey.S4,
          },
     },
     dataPrivacy: {
          width: "100%",
     },
}));

export default function Main(props) {
     const classes = useStyles();
     const theme = useTheme();

     return (
          <Dialog
               open={props.open}
               className={classes.root}
               TransitionComponent={TransitionSlideUp}
          >
               <div className={classes.body}>
                    {/*<div className={classes.iconContainer} style={{*/}
                    {/*    color: props.variant === 'success' ? theme.palette.success.main :*/}
                    {/*        props.variant === 'warning' ? theme.palette.warning.main :*/}
                    {/*            theme.palette.error.main*/}
                    {/*}}>*/}
                    {/*    {props.variant === 'success' ?*/}
                    {/*        <CheckCircleOutlined className={classes.icon}/> :*/}
                    {/*        <ReportProblemOutlined className={classes.icon}/>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <Link
                         href={
                              "http://www.usep.edu.ph/usep-data-privacy-statement/"
                         }
                    >
                         <img
                              src={DataPrivacy}
                              alt={"Data Privacy"}
                              className={classes.dataPrivacy}
                         />
                    </Link>
                    <Space vertical space={4} />
                    <Typography variant={"h5"} className={classes.typography}>
                         {props.title}
                    </Typography>
                    <Space vertical space={2} />
                    <Typography
                         variant={"body1"}
                         align={"center"}
                         className={classes.typography}
                         style={{ color: theme.palette.grey.S3 }}
                    >
                         By continuing to browse this website, you agree to the
                         University of Southeastern Philippines’ Data Privacy
                         Statement. The full text of The Statement can be
                         accessed by clicking the image above.
                    </Typography>
                    <Space vertical space={6} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                         <Button
                              label={"CONTINUE"}
                              variant={"outlined"}
                              color={props.variant}
                              onClick={props.onClose}
                         />
                    </div>
               </div>
               <div
                    style={{
                         height: 15,
                         background: theme.palette.primary.gradient,
                    }}
               ></div>
          </Dialog>
     );
}
