import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height:"800px",
        fontSize: "5px"
    },

}));




export default function DataTable(PROPS) {
    const classes = useStyles();

    return (
        <div className={classes.container} >
            <DataGrid
                rows={PROPS.ROWS}
                columns={PROPS.COLUMNS}
                pageSize={PROPS.pages}
            />
        </div>
    );
}
