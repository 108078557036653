import React from 'react'
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    line: {
        background: theme.palette.grey.S2
    }
}))

export default function Main(props) {
    const classes = useStyles()
    return (
        <div>
            {
                props.horizontal ?
                    <div className={classes.line}
                        style={{
                            width: props.width ? props.width : '100%',
                            height: 1
                        }}>

                    </div> :
                    <div className={classes.line}
                        style={{
                            height: props.height ? props.height : '100%',
                            width: 1
                        }}>

                    </div>
            }
        </div>
    )
}