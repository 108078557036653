export const DIRECTORY_TREE_GET = 'DIRECTORY_TREE_GET'
export const DIRECTORY_DETAILS_GET = 'DIRECTORY_DETAILS_GET'
export const DIRECTORY_TOGGLE_SET = 'DIRECTORY_TOGGLE_SET'
export const DIRECTORY_TREE_LIST = 'DIRECTORY_TREE_LIST'
export const DIRECTORY_LINK_DESCRIPTION = 'DIRECTORY_LINK_DESCRIPTION'

export const DOCUMENT_ACTIONS_GET = 'DOCUMENT_ACTIONS_GET'
export const DOCUMENT_MONITORING_GET = 'DOCUMENT_MONITORING_GET'
export const DOCUMENT_DETAILS_GET = 'DOCUMENT_DETAILS_GET'
export const DOCUMENT_DIRECTORY_GET = 'DOCUMENT_DIRECTORY_GET'
export const DOCUMENT_REQUESTED_TO_PUBLICIZE_GET = 'DOCUMENT_REQUESTED_TO_PUBLICIZE_GET'
export const DOCUMENT_CLEAR = 'DOCUMENT_CLEAR'

export const DEPARTMENT_GET_PUBLIC_ALL = 'DEPARTMENT_GET_PUBLIC_ALL'

export const CONTEXT_DIRECTORY_EXPANDED_ADD = 'CONTEXT_DIRECTORY_EXPANDED_ADD'
export const CONTEXT_DIRECTORY_EXPANDED_REMOVE = 'CONTEXT_DIRECTORY_EXPANDED_REMOVE'
export const CONTEXT_SET = 'CONTEXT_SET'
export const CONTEXT_RESET = 'CONTEXT_RESET'
export const CONTEXT_DIRECTORY_ACTIVE = 'CONTEXT_DIRECTORY_ACTIVE'
export const CONTEXT_FORM_SET_DIRECTORY = 'CONTEXT_FORM_SET_DIRECTORY'
export const CONTEXT_FORM_SET_DIRECTORY_GROUP = 'CONTEXT_FORM_SET_DIRECTORY_GROUP'
export const CONTEXT_FORM_SET_DOCUMENT = 'CONTEXT_FORM_SET_DOCUMENT'
export const CONTEXT_FORM_SET_FILTER = 'CONTEXT_FORM_SET_FILTER'
export const CONTEXT_FORM_SET_DEPARTMENT = 'CONTEXT_FORM_SET_DEPARTMENT'
export const CONTEXT_FORM_SET_REPORTS = 'CONTEXT_FORM_SET_REPORTS'
export const CONTEXT_FORM_SET_MONITORING_PARAMETERS = 'CONTEXT_FORM_SET_MONITORING_PARAMETERS'
export const CONTEXT_FORM_SET_CHANGE_PASSWORD = 'CONTEXT_FORM_SET_CHANGE_PASSWORD'
export const CONTEXT_FORM_RESET_DIRECTORY = 'CONTEXT_FORM_RESET_DIRECTORY'
export const CONTEXT_FORM_RESET_DIRECTORY_GROUP = 'CONTEXT_FORM_RESET_DIRECTORY_GROUP'
export const CONTEXT_FORM_RESET_DOCUMENT = 'CONTEXT_FORM_RESET_DOCUMENT'
export const CONTEXT_FORM_RESET_FILTER = 'CONTEXT_FORM_RESET_FILTER'
export const CONTEXT_FORM_RESET_DEPARTMENT = 'CONTEXT_FORM_RESET_DEPARTMENT'
export const CONTEXT_FORM_RESET_CHANGE_PASSWORD = 'CONTEXT_FORM_RESET_CHANGE_PASSWORD'

export const SUGGESTION_ATTR_KEY = 'SUGGESTION_ATTR_KEY'
export const SUGGESTION_ATTR_VALUE = 'SUGGESTION_ATTR_VALUE'
export const SUGGESTION_KEYWORD = 'SUGGESTION_KEYWORD'
export const SUGGESTION_TYPE = 'SUGGESTION_TYPE'
export const SUGGESTION_DIRECTORY_GROUP = 'SUGGESTION_DIRECTORY_GROUP'
export const SUGGESTION_USERS = 'SUGGESTION_USERS'
export const SUGGESTION_DEPARTMENT_USERS = 'SUGGESTION_DEPARTMENT_USERS'
export const SUGGESTION_CLEAR = 'SUGGESTION_CLEAR'

export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const MESSAGE_ADD = 'MESSAGE_ADD'
export const MESSAGE_REMOVE = 'MESSAGE_REMOVE'
export const MESSAGE_CLEAR = 'MESSAGE_CLEAR'

export const ADMIN_STAGING_AUTHENTICATION_SET = 'ADMIN_STAGING_AUTHENTICATION_SET'
export const ADMIN_USERS_SET = 'ADMIN_USERS_SET'
export const USERS_USER_DEPARTMENTS_SET = 'USERS_USER_DEPARTMENTS_SET'
export const ADMIN_DEPARTMENTS_SET = 'ADMIN_DEPARTMENTS_SET'
export const ADMIN_RESET = 'ADMIN_RESET'
export const TRASH_SET = 'TRASH_SET'
export const TRASH_RESET = 'TRASH_RESET'


export const DEPARTMENT_GET_PUBLIC_GUEST = 'DEPARTMENT_GET_PUBLIC_GUEST'

export const USER_DEPARTMENTS = 'USER_DEPARTMENTS'

export const DIRECTORY_LOADING = 'DIRECTORY_LOADING'
