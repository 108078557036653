import React from 'react'
import Slide from "@material-ui/core/Slide";

const TransitionSlideLeft = React.forwardRef(
    function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    }
);

export const TransitionSlideUp = React.forwardRef(
    function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    }
);

export default TransitionSlideLeft