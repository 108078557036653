/* import {store} from "../../redux";
import {MESSAGE} from "../../redux/actions/message";
import axios from "axios";
import { env } from "../../env";
import { setToggle } from "../../redux/actions/context";

const { dispatch, getState } = store;
const URL = env.URL_API; */

/**
 *  CHECKS THE STATUS OF THE API SERVER EVERY 10 SECONDS
 */
export default function Main(ifOnline) {
  // let timer = setInterval(() => {}, 10000)
  // clearInterval(timer)
  // HTTP_NET_TEST(ifOnline)
  // setInterval(() => HTTP_NET_TEST(ifOnline), 10000)
}

// function HTTP_NET_TEST(ifOnline) {
//   let message_added = false;
//   for (let item of getState().MESSAGE) {
//     if (item.message === "Server is offline") message_added = true;
//   }
//   axios
//     .get(URL + "app/net-test")
//     .then(() => {
//       if (!getState().CONTEXT.toggles.online) {
//         setToggle("online", true);
//         // if (message_added)
//         //     MESSAGE('Server is offline')

//         // SET LOADER TO TRUE TO REFETCH
//         setToggle("first_directory_details_loader", true);
//         setToggle("first_directory_tree_loader", true);
//         if (typeof ifOnline === "function") ifOnline();
//       }
//     })
//     .catch(() => {
//       if (getState().CONTEXT.toggles.online && window.navigator.onLine) {
//         setToggle("online", false);
//         // if (!message_added)
//         //     MESSAGE('Server is offline', 'enqueue', 'info', true)
//       }
//     });
// }

// function SET_TO_ONLINE() {}
