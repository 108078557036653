import { TRASH_SET, TRASH_RESET } from "../actions/types";

const init = {
   directory: [],
   document: [],
};

export default function Main(state = init, action) {
   switch (action.type) {
      case TRASH_SET:
         return action.payload;
      case TRASH_RESET:
         return init;
      default:
         return state;
   }
}
