import React, { useEffect, useCallback, useMemo, useRef } from "react";
// import { connect } from "react-redux";
import Header from "../components/layout/Header";
// import DirectoryTree from "../components/layout/DirectoryTree";
import { makeStyles } from "@material-ui/core";
// import LargeCreateNew from "../components/core/button/LargeCreateNew";
import clsx from "clsx";
/* import Breadcrumb from "../components/core/breadcrumb/Breadcrumb";
import DirectoryGroupBox from "../components/core/directory/DirectoryGroupBox";
import DocumentBox from "../components/core/document/DocumentBox"; */
import { setContext, setForm, setToggle } from "../redux/actions/context";
// import ActionDirectory from "../components/actions/ActionDirectory";
import CreateNew from "./Forms";
// import Space from "../components/core/panel/Space";
import ViewDocument from "./ViewDocument";
import Footer from "../components/layout/Footer";
/* import EmptyFolder from '../static/media/img/EmptyFolder.svg'
import Typography from "@material-ui/core/Typography";
import SearchResult from "../components/core/breadcrumb/SearchResult"; */
import FilterPanel from "../components/layout/FilterPanel";
/* import ActionDocuments from "../components/actions/ActionDocuments";
import RequestedToPublicizeDocumentBox from "../components/core/document/RequestedToPublicizeDocumentBox"; */
import HAS_CRUD from "../utils/permissions/HAS_CRUD";
// import STATES from "../redux/states";
import { GET_DIRECTORY_DETAILS } from "../redux/actions/directory";
// import IS_ROOT_DIRECTORY from "../utils/directory/IS_ROOT_DIRECTORY";
import IS_PUBLIC_REQUEST from "../utils/context/IS_PUBLIC_REQUEST";
import {
  GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS
} from "../redux/actions/document";
import { GET_SUGGESTION } from "../redux/actions/suggestion";
/* import IS_ONLINE from "../utils/context/IS_ONLINE";
import OfflineBackdrop from "../components/core/panel/OfflineBackdrop"; */
import IS_AUTHENTICATED from "../utils/permissions/IS_AUTHENTICATED";
import { REDIRECT, RELOAD } from "../utils/history";
import { LOAD_DIRECTORY } from "../utils/directory";
import ContextMenuDirectory from "../components/core/context_menu/ContextMenuDirectory";
import ContextMenuDocument from "../components/core/context_menu/ContextMenuDocument";
import {
  IS_DIRECTORY_CONTEXT_OPEN,
  IS_DIRECTORY_VIEW_CONTEXT_OPEN,
  IS_DOCUMENT_CONTEXT_OPEN
} from "../utils/context_menu";
import ContextMenuDirectoryView from "../components/core/context_menu/ContextMenuDirectoryView";
import IS_ADMIN from "../utils/permissions/IS_ADMIN";
import OfficeTrashBin from "./OfficeTrashBin";
import { useSelector } from "react-redux";
import { getDepartment } from "../redux/actions/department";
import HomeSidebar from "../components/HomeSidebar";
import { useParams } from "react-router-dom";
import HomeContentHeader from "../components/HomeContentHeader";
import HomeContentBody from "../components/HomeContentBody";
import Line from "../components/core/decoration/Line";
import { MESSAGE } from "../redux/actions/message";
import { useDropzone } from "react-dropzone";
import DirectoryLinks from "./DirectoryLinks";

export default function Home() {
  const styles = style();
  const params = useParams();

  const { active_directory, toggles, context, auth, forms } = useSelector(state => ({
    active_directory: state.CONTEXT.active_directory,
    forms: state.CONTEXT.forms,
    toggles: {
      create_new: state.CONTEXT.toggles.create_new,
      view_document: state.CONTEXT.toggles.view_document,
      filter_panel: state.CONTEXT.toggles.filter_panel,
      view_directory_link: state.CONTEXT.toggles.view_directory_link,
    },
    context: {
      search_mode: state.CONTEXT.search_mode,
      search_key: state.CONTEXT.search_key,
      documentX: state.CONTEXT.documentX,
      documentY: state.CONTEXT.documentY,
      directoryX: state.CONTEXT.directoryX,
      directoryY: state.CONTEXT.directoryY,
      directory_view_X: state.CONTEXT.directory_view_X,
      directory_view_Y: state.CONTEXT.directory_view_Y
    },
    auth: {
      upload_size_limit: state.AUTH.upload_filesize_limit,
    },
  }));

  useEffect(() => {
    if (IS_AUTHENTICATED()) {
      if (IS_ADMIN() && window.location.pathname !== "/admin") {
        REDIRECT("/admin");
        RELOAD();
      }
      if (
        (window.location.pathname === "/login" || window.location.path_name === "/landing-page" ||
          window.location.pathname === "/") &&
        !IS_ADMIN()
      ) {
        REDIRECT("/dir/" + active_directory);
        LOAD_DIRECTORY(params.directory_id);
      } else {
        setToggle("first_directory_details_loader", true);
        setToggle("first_directory_tree_loader", true);

        GET_SUGGESTION("type", "");
        if (active_directory === "public-request")
          GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS();
        else {
          LOAD_DIRECTORY(params.directory_id);
        }
      }
    } else {

      if (
        window.location.pathname.startsWith("/dir/") ||
        window.location.pathname.startsWith("/doc/")
      ) {
        setContext("entered_path", window.location.pathname);
        if (window.location.pathname === "/dir/" + params.directory_id) {
          LOAD_DIRECTORY(params.directory_id);
        }
      }

      if ((window.location.pathname !== "/login" || window.location.pathname !== "/landing-page") && (window.location.pathname !== "/dir/" + params.directory_id)) {
        REDIRECT("/landing-page");
        RELOAD();
      }
    }
  }, []);

  const onClick = async (action, ...args) => {
    switch (action) {
      case "create-new":
        setContext("formRequestMode", "CREATE");
        setContext("formType", "Document");
        setToggle("create_new", true);
        setToggle("from_dropzone", false);
        break;
      case "create-new-dropzone":
        setContext("formRequestMode", "CREATE");
        setContext("formType", "Document");
        setToggle("create_new", true);
        break;
      case "department-edit":
        getDepartment(active_directory);
        setContext("formRequestMode", "UPDATE");
        setContext("formType", "Department");
        setToggle("createNew", true);
        break;
      case "sort-change":
        await setForm("filter", args[0], args[1]);
        if (!IS_PUBLIC_REQUEST()) GET_DIRECTORY_DETAILS();
        else GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS();
        break;
      case "filter-panel":
        setToggle("filter_panel", !toggles.filter_panel);
        break;
      case "":
        break;
      default:
        break;
    }
  };

  // Dropzone
  const activeDirectoryState = useRef()
  activeDirectoryState.current = active_directory
  const upload_filesize_limit = auth.upload_size_limit;

  const baseStyle = {
    paddingLeft: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#fff',
    borderStyle: 'solid',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const acceptStyle = {
    borderColor: HAS_CRUD() && active_directory.startsWith("dep") ? '#973939' : '#fff',
    backgroundColor: HAS_CRUD() && active_directory.startsWith("dep") ? '#f2ebeb' : null
  };

  const hasCreateAccess = files => {
    if (!(HAS_CRUD() && active_directory.startsWith("dep"))) {
      console.log('no acces')
      return {
        code: 'no-access-rights',
        message: 'You have no access rights to create document'
      };
    }

    return null;
  };

  const onDropzoneAdd = files => {
    setToggle("from_dropzone", true);
    setForm("document", "attachments", [
      ...forms.document.attachments,
      ...files
    ]);
  };

  const onDropAccepted = useCallback(files => {
    onDropzoneAdd(files);
    onClick("create-new-dropzone")
  }, []);

  const onDropRejected = useCallback(file => {
    if (HAS_CRUD() && activeDirectoryState.current.startsWith("dep")) {
      const isExceeded = (typeof file[0].size !== "undefined" && file[0].size / 1000000 > upload_filesize_limit) || (typeof file[0].file.size !== "undefined" && file[0].file.size / 1000000 > upload_filesize_limit);
      if (isExceeded) {
        MESSAGE("Filesize limit exceeded", "enqueue", "error");
      }

      if ((typeof file[0].name !== "undefined" && !file[0].name.endsWith(".pdf")) || (typeof file[0].file.name !== "undefined" && !file[0].file.name.endsWith(".pdf"))) {
        MESSAGE("Unsupported file format", "enqueue", "error");
      }
    }
  }, []);


  const {
    getRootProps,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    validator: hasCreateAccess,
    accept: "application/pdf",
    maxSize: upload_filesize_limit * 1000000,
    multiple: true,
  });

  const dropzoneStyle = useMemo(() => ({
    ...baseStyle,
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? {} : {})
  }), [
    isDragReject,
    isDragAccept
  ]);


  return (
    <div
      className={styles.root}
      onContextMenu={e => {
        e.preventDefault();
      }}
    >
      <Header />

      <div className={styles.bodyContainer}>
        <div className={clsx(styles.sidebarLeft)}>
          <HomeSidebar />
        </div>
        <div className={styles.content}>
          <div className={styles.contentHeaderContainer}>
            <HomeContentHeader />
          </div>
          {/* DROPZONE */}
          <div {...getRootProps({ style: dropzoneStyle })}>
            <div className={styles.flex}>
              <div
                className={styles.contentBodyContainer}
                onContextMenu={e => {
                  e.preventDefault();
                  e.persist();
                  setContext("directory_view_X", e.pageX);
                  setContext("directory_view_Y", e.pageY);
                  setContext("show_directory_view_context_menu", true);
                  if (!IS_DIRECTORY_CONTEXT_OPEN())
                    setContext("clipboard_destination", active_directory);
                }}
              >
                <HomeContentBody />
              </div>
              {toggles.filter_panel && (
                <>
                  <Line />
                  <FilterPanel onClose={() => onClick("filter-panel")} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {toggles.create_new && <CreateNew />}
      {toggles.view_document && <ViewDocument />}
      {toggles.view_directory_link && <DirectoryLinks />}

      <OfficeTrashBin />

      <ContextMenuDocument
        id={"popover-context-menu-document"}
        open={IS_DOCUMENT_CONTEXT_OPEN()}
        onClose={() => setContext("show_document_context_menu", false)}
        X={context.documentX}
        Y={context.documentY}
      />

      <ContextMenuDirectory
        id={"popover-context-menu-directory"}
        open={IS_DIRECTORY_CONTEXT_OPEN()}
        onClose={() => setContext("show_directory_context_menu", false)}
        X={context.directoryX}
        Y={context.directoryY}
      />

      <ContextMenuDirectoryView
        id={"popover-context-menu-directory"}
        open={IS_DIRECTORY_VIEW_CONTEXT_OPEN()}
        onClose={() => setContext("show_directory_view_context_menu", false)}
        X={context.directory_view_X}
        Y={context.directory_view_Y}
      />
    </div>
  );
}

const style = makeStyles(theme => ({
  root: {},
  bodyContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  sidebarLeft: {
    width: 300,
    minWidth: 300
  },
  sidebarLeftDirectoryTree: {
    // width: 300,
    height: "calc(100vh - 83px - 100px)",
    overflow: "hidden",
    overflowX: "none"
  },
  content: {
    width: "100%",
    height: "calc(100vh - 83px - 8px)",
    marginLeft: theme.spacing(4)
  },
  contentHeaderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1px " + theme.palette.grey.S2,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  contentBodyContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  contentBody: {
    width: "100%",
    minWidth: 300,
    height: "calc(100vh - 57px - 81px - 25px - 3px)",
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: 3,
    marginRight: 3
  },
  sidebarRight: {
    width: 300,
    minWidth: 300,
    height: "calc(100vh - 57px - 81px - 25px - 3px)",
    // display: 'flex',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    borderLeft: "solid 1px " + theme.palette.grey.S2,
    marginTop: 3,
    marginRight: 3
  },
  emptyDirectoryContainer: {
    height: "calc(100% - 80px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
    // paddingRight: theme.spacing(10),
  },
  emptyDirectory: {
    height: 200,
    width: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(7),
    paddingBottom: theme.spacing(8),
    background: "#FAFAFA",
    borderRadius: 1000
  },
  scrollbar: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      marginRight: "-8px",
      width: 8,
      height: 8,
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      opacity: 1,
      backgroundColor: theme.palette.grey.S2
      // '&:hover': {
      //     backgroundColor: theme.palette.grey.S2,
      // }
    }
  },
  scrollbarHidden: {
    "&:hover": {
      overflow: "scroll",
      "&::-webkit-scrollbar": {
        marginRight: "-8px",
        width: 8,
        height: 8,
        backgroundColor: "transparent"
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 10,
        opacity: 1,
        backgroundColor: theme.palette.grey.S2
      }
    }
  },
  emptyFolder: {
    height: 100,
    color: theme.palette.grey.S2
  },
  flex: {
    display: "flex"
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center"
  },
  rootDirectoryContainer: {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
    marginBottom: 36
  },
  trashContainer: {
    display: "flex",
    alignItems: "center",
    padding: "12px 12px",
    border: `solid .5px ${theme.palette.grey.S2}`,
    borderRadius: 4,
    "&:hover": {
      cursor: "pointer"
    }
  },
  icon: {
    color: theme.palette.grey.S3
  },
  iconDirTitle: {
    fontSize: 36,
    color: theme.palette.primary.main,
    marginRight: 8
  }
}));
