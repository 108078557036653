import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import { CancelScheduleSendOutlined, SendOutlined } from "@material-ui/icons";
import Space from "../panel/Space";

import {
  RiDeleteBin7Line,
  RiEarthLine,
  RiFileCopyLine,
  RiScissorsCutLine
} from "react-icons/all";
import Line from "../decoration/Line";
import { setContext, setForm, setToggle } from "../../../redux/actions/context";
import { MESSAGE } from "../../../redux/actions/message";
import HAS_CRUD from "../../../utils/permissions/HAS_CRUD";
import IS_ENV_PUB from "../../../utils/context/IS_ENV_PUB";
import IS_HEAD from "../../../utils/permissions/IS_HEAD";
import {
  CANCEL_REQUEST_TO_PUBLICIZE,
  DELETE_DOCUMENT,
  GET_DOCUMENT,
  REQUEST_TO_PUBLICIZE,
  UNPUBLICIZE
} from "../../../redux/actions/document";
import { env } from "../../../env";
import {
  SEND_CONFIRMATION_DIALOG,
  SEND_MESSAGE_DIALOG,
} from "../../../redux/actions/dialogs";
import { GET_DIRECTORY_DETAILS } from "../../../redux/actions/directory";
import { IS_DOCUMENT_CONTEXT_OPEN } from "../../../utils/context_menu";
import IS_SUPPORT from "../../../utils/permissions/IS_SUPPORT";
import IS_AUTHENTICATED from "../../../utils/permissions/IS_AUTHENTICATED";

const useStyles = makeStyles((theme) => ({
  root: {},
  popoverRoot: {
    marginTop: 12,
  },
  popoverPaper: {
    boxShadow: theme.shadows[3],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minWidth: 150,
    maxWidth: 300,
  },
  popoverItem: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.grey.S4,

    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main + " !important",
      background: theme.palette.grey.RGB0,
    },
  },
  flex: {
    display: "flex",
  },
}));

export default function Main(props) {
  const classes = useStyles();
  const { context, document_details } = useSelector((state) => ({
    context: {
      pre_clipboard: state.CONTEXT.pre_clipboard,
    },
    document_details: state.DOCUMENT.details,
  }));


  const onClick = async (action) => {
    switch (action) {
      case "copy":
      case "cut":
        setContext("clipboard", {
          id: context.pre_clipboard.id,
          type: context.pre_clipboard.type,
          action,
        });
        if (action === "copy") {
          MESSAGE(`Document cut to clipboard`, "dequeue");
          MESSAGE(`Document copied to clipboard`, "enqueue");
        } else {
          MESSAGE(`Document copied to clipboard`, "dequeue");
          MESSAGE(`Document cut to clipboard`, "enqueue");
        }
        break;
      case "download":
        GET_DOCUMENT(context.pre_clipboard.id, () => {
          let attachments = document_details.attachments;
          if (attachments.length > 0) {
            window.location =
              env.URL_REPOSITORY + attachments[0].dir + attachments[0].name;
          } else {
            MESSAGE("Document has no attachment", "enqueue", "error");
          }
        });

        break;
      case "print":
        GET_DOCUMENT(context.pre_clipboard.id, () => {
          let attachments = document_details.attachments;
          if (attachments.length > 0) {
            window.open(
              env.URL_REPOSITORY + attachments[0].dir + attachments[0].name,
              "PRINT",
              "height=720,width=1280"
            );
          } else {
            MESSAGE("Document has no attachment", "enqueue", "error");
          }
        });

        break;
      case "edit":
        GET_DOCUMENT(context.pre_clipboard.id, () => {
          setForm("document", "id", document_details.id);
          setForm("document", "title", document_details.title);
          setForm("document", "description", document_details.description);
          setForm("document", "is_public", document_details.is_public);
          setForm("document", "type", document_details.type);
          setForm("document", "attributes", document_details.attributes);
          setForm("document", "keywords", document_details.keywords);
          setForm("document", "old_attachments", document_details.attachments);
          setContext("formRequestMode", "UPDATE");
          setContext("formType", "Document");
          setToggle("view_document", false);
          setToggle("create_new", true);
        });

        break;
      case "delete":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "warning",
          "Trash Confirmation",
          "This document will be moved to the Office Trash Bin. You can restore it later if necessary.",
          () => {
            DELETE_DOCUMENT(context.pre_clipboard.id);
          }
        );

        break;
      case "publicize-request":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Request Confirmation",
          "Upon confirmation, this document will be requested for " +
          "public posting and will be subject for approval.",
          () => {
            REQUEST_TO_PUBLICIZE(context.pre_clipboard.id, () => {
              GET_DIRECTORY_DETAILS();
            });
          }
        );
        break;
      case "publicize-request-cancel":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Cancel Request Confirmation",
          "Upon confirmation, this request to post this document on public will be cancelled.",
          () => {
            CANCEL_REQUEST_TO_PUBLICIZE(context.pre_clipboard.id, () => {
              GET_DIRECTORY_DETAILS();
            });
          }
        );
        break;
      case "unpublicize":
        SEND_CONFIRMATION_DIALOG(
          "error",
          "danger",
          "Unpublicize Confirmation",
          "Upon confirmation, this document will only be available to your office and not to the public",
          () => {
            MESSAGE(`Unpublicizing document`, "enqueue", "info");
            UNPUBLICIZE(context.pre_clipboard.id, async () => {
              await GET_DIRECTORY_DETAILS();
              await onClick("close-view-document");
              MESSAGE(`Unpublicizing document`, "dequeue");
              MESSAGE(
                `Document unpublicized successfully`,
                "enqueue",
                "success"
              );
            });
          }
        );
        break;
      case "info":
        SEND_MESSAGE_DIALOG(
          "info",
          "",
          "Coming Soon",
          "This feature is under development and will be available in the next updates.",
          () => { }
        );
        break;
      default:
        break;
    }

    props.onClose();
  };
  // { IS_AUTHENTICATED() ? (
  return (
    <div className={classes.root}>
      {IS_ENV_PUB() && !context.pre_clipboard.is_public ? (
        IS_DOCUMENT_CONTEXT_OPEN() ? (
          props.onClose()
        ) : null
      ) : IS_AUTHENTICATED() ?  (
        <Popover
          id={props.id}
          open={props.open}
          onClose={props.onClose}
          anchorPosition={{ left: props.X, top: props.Y - 10 }}
          anchorReference={"anchorPosition"}
          classes={{
            root: classes.popoverRoot,
            paper: classes.popoverPaper,
          }}
        >
          <Fragment>
            
               <div
               className={classes.popoverItem}
               onClick={() => onClick("copy")}
             >
               <div className={classes.flex}>
                 <RiFileCopyLine size={24} />
                 <Space space={2} />
                 <Typography variant={"body1"}>Copy</Typography>
               </div>
             </div>

            {HAS_CRUD() ? (
              <Fragment>
                <div
                  className={classes.popoverItem}
                  onClick={() => onClick("cut")}
                >
                  <div className={classes.flex}>
                    <RiScissorsCutLine size={24} />
                    <Space space={2} />
                    <Typography variant={"body1"}>Cut</Typography>
                  </div>
                </div>
              </Fragment>
            ) : null}

            <Space vertical space={1} />
            <Line horizontal width={"100%"} />
            <Space vertical space={1} />

            {/*<div*/}
            {/*   className={classes.popoverItem}*/}
            {/*   onClick={() => onClick("download")}*/}
            {/*>*/}
            {/*   <div className={classes.flex}>*/}
            {/*      <RiDownloadCloud2Line size={24} />*/}
            {/*      <Space space={2} />*/}
            {/*      <Typography variant={"body1"}>Download</Typography>*/}
            {/*   </div>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*   className={classes.popoverItem}*/}
            {/*   onClick={() => onClick("print")}*/}
            {/*>*/}
            {/*   <div className={classes.flex}>*/}
            {/*      <RiPrinterLine size={24} />*/}
            {/*      <Space space={2} />*/}
            {/*      <Typography variant={"body1"}>Print</Typography>*/}
            {/*   </div>*/}
            {/*</div>*/}
            {HAS_CRUD() ? (
              <Fragment>
                {/*{DOCUMENT.is_link ? null :*/}
                {/*    <>*/}
                {/*        <div*/}
                {/*            className={classes.popoverItem}*/}
                {/*            onClick={() => onClick('edit')}*/}
                {/*        >*/}
                {/*            <div className={classes.flex}>*/}
                {/*                <RiEdit2Line size={24}/>*/}
                {/*                <Space space={2}/>*/}
                {/*                <Typography variant={'body1'}>Edit Document</Typography>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*}*/}
                <div
                  className={classes.popoverItem}
                  onClick={() => onClick("delete")}
                >
                  <div className={classes.flex}>
                    <RiDeleteBin7Line size={24} />
                    <Space space={2} />
                    <Typography variant={"body1"}>Move to Trash</Typography>
                  </div>
                </div>

                {IS_HEAD() && !IS_SUPPORT() ? (
                  <Fragment>
                    <Space vertical space={1} />
                    <Line horizontal width={"100%"} />
                    <Space vertical space={1} />
                    {context.pre_clipboard.is_public === false ? (
                      <div
                        className={classes.popoverItem}
                        onClick={() => onClick("publicize-request")}
                      >
                        <div className={classes.flex}>
                          <SendOutlined />
                          <Space space={2} />
                          <Typography variant={"body1"}>
                            Request to Publicize (Uniwide)
                          </Typography>
                        </div>
                      </div>
                    ) : context.pre_clipboard.is_public === null ?  context.pre_clipboard.is_guest === false ? (
                      <div
                        className={classes.popoverItem}
                        onClick={() => onClick("publicize-request-cancel")}
                      >
                        <div className={classes.flex}>
                          <CancelScheduleSendOutlined />
                          <Space space={2} />
                          <Typography variant={"body1"}>
                            Cancel Request to Publicize (Uniwide)
                          </Typography>
                        </div>
                      </div>
                    ) : "" 
                    :context.pre_clipboard.is_guest === false ? (
                      <div
                        className={classes.popoverItem}
                        onClick={() => onClick("unpublicize")}
                      >
                        <div className={classes.flex}>
                          <RiEarthLine size={24} />
                          <Space space={2} />
                          <Typography variant={"body1"}>Unpublicize (Uniwide)</Typography>
                        </div>
                      </div>
                    ):""}
                  </Fragment>
                ) : null}
              </Fragment>
            ) : null}
          </Fragment>
        </Popover>
      ):""}
    </div>
  );
}
