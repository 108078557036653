import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { CheckCircleOutlined, ReportProblemOutlined } from "@material-ui/icons";
import Space from "../panel/Space";
import Typography from "@material-ui/core/Typography";
import Button from "../button/Button";
import clsx from "clsx";
import { TransitionSlideUp } from "../transition/TransitionSlideLeft";
import InputText from "../input/InputText";

const useStyles = makeStyles((theme) => ({
   root: {
      borderRadius: theme.shape.borderRadius,
   },
   body: {
      padding: theme.spacing(6),
      width: 350,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
   },
   iconContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
   },
   icon: {
      fontSize: 100,
   },
   typography: {
      color: theme.palette.grey.S4,
   },
   link: {
      color: theme.palette.grey.S3,
      "&:hover": {
         cursor: "pointer",
         color: theme.palette.grey.S4,
      },
   },
}));

export default function Main(props) {
   const classes = useStyles();
   const theme = useTheme();

   const [key_textInput, setKeyInput] = useState(null);

   return (
      <Dialog
         onClose={props.onClose}
         open={props.open}
         className={classes.root}
         TransitionComponent={TransitionSlideUp}
      >
         <div className={classes.body}>
            <div
               className={classes.iconContainer}
               style={{
                  color:
                     props.variant === "success"
                        ? theme.palette.success.main
                        : props.variant === "warning"
                           ? theme.palette.warning.main
                           : theme.palette.error.main,
               }}
            >
               {props.variant === "success" ? (
                  <CheckCircleOutlined className={classes.icon} />
               ) : (
                  <ReportProblemOutlined className={classes.icon} />
               )}
            </div>
            <Space vertical space={4} />
            <Typography variant={"h5"} className={classes.typography}>
               {props.title}
            </Typography>
            <Space vertical space={2} />
            <Typography
               variant={"body1"}
               align={"center"}
               className={classes.typography}
               style={{ color: theme.palette.grey.S3 }}
            >
               {props.caption}
            </Typography>
            {props.key_text && (
               <>
                  <Space vertical space={2} />
                  <Typography
                     variant={"body1"}
                     align={"center"}
                     className={classes.typography}
                     style={{ color: theme.palette.grey.S3 }}
                  >
                     Enter{" "}
                     <span
                        style={{
                           color: theme.palette[props.variant].main,
                           fontWeight: "bold",
                        }}
                     >
                        {props.key_text}
                     </span>{" "}
                     below to proceed.
                  </Typography>
                  <Space vertical space={1} />
                  <InputText
                     value={key_textInput}
                     onChange={(e) => setKeyInput(e.target.value)}
                  />
               </>
            )}
            <Space vertical space={6} />
            {props.variant === "success" ? (
               <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                     label={"OKAY"}
                     variant={"outlined"}
                     color={"success"}
                     onClick={props.onClose}
                  />
               </div>
            ) : null}
            {props.variant === "warning" ? (
               <div
                  style={{
                     display: "flex",
                     justifyContent: "space-between",
                     width: "100%",
                     alignItems: "center",
                  }}
               >
                  <Typography
                     variant={"body1"}
                     className={clsx(classes.typography, classes.link)}
                     onClick={props.onClose}
                  >
                     CANCEL
                  </Typography>
                  <Button
                     disabled={
                        props.key_text && props.key_text !== key_textInput
                     }
                     label={"CONFIRM"}
                     variant={"outlined"}
                     color={"warning"}
                     onClick={props.onConfirm}
                  />
               </div>
            ) : null}
            {props.variant === "error" ? (
               <div
                  style={{
                     display: "flex",
                     justifyContent: "space-between",
                     width: "100%",
                     alignItems: "center",
                  }}
               >
                  <Typography
                     variant={"body1"}
                     className={clsx(classes.typography, classes.link)}
                     onClick={props.onClose}
                  >
                     CANCEL
                  </Typography>
                  <Button
                     disabled={
                        props.key_text && props.key_text !== key_textInput
                     }
                     label={"CONFIRM"}
                     variant={"outlined"}
                     color={"error"}
                     onClick={props.onConfirm}
                  />
               </div>
            ) : null}
         </div>
         <div
            style={{
               height: 15,
               backgroundColor:
                  props.variant === "success"
                     ? theme.palette.success.main
                     : props.variant === "warning"
                        ? theme.palette.warning.main
                        : theme.palette.error.main,
            }}
         />
      </Dialog>
   );
}
