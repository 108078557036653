import { store } from "../../redux";

const { getState } = store

export const checkIsAccreditorByActiveDirectory = () => {
    const active_dir = getState().CONTEXT.active_directory.substring(3)
    const AUTH = getState().AUTH
    if (AUTH.privileges && AUTH.privileges.accreditor &&
        AUTH.privileges.accreditor.includes(parseInt(active_dir))) return true
    return false
}

export const isUserAccreditor = () => {
    return getState().AUTH.is_accreditor
}

export default function Main() {

    const DIRECTORY_DETAILS = getState().DIRECTORY.details
    if (DIRECTORY_DETAILS.ancestry && DIRECTORY_DETAILS.ancestry.length > 0) {
        const AUTH = getState().AUTH
        for (let item of DIRECTORY_DETAILS.ancestry) {
            if (AUTH.privileges && AUTH.privileges.accreditor &&
                AUTH.privileges.accreditor.includes(item.id)) return true
        }
    }
    return false

}
