import React, { useEffect } from "react";
import "./app.scss";
import { useSelector } from "react-redux";
import Dialogs from "./components/layout/Dialogs";
// import STATES from "./redux/states";
import OfflineBackdrop from "./components/core/panel/OfflineBackdrop";
import {
  DequeueSnackBar,
  EnqueueSnackBar,
} from "./components/core/snackbar/SnackBarProvider";
import IS_ONLINE from "./utils/context/IS_ONLINE";
import IS_AUTHENTICATED from "./utils/permissions/IS_AUTHENTICATED";
import Routes from "./routes";
import { setContext } from "./redux/actions/context";
import GLOBAL_EVENTS from "./utils/GLOBAL_EVENTS";
import SystemUpgrade from "./components/system_modes/SystemUpgrade";
import { env } from "./env";
import SystemMaintenance from "./components/system_modes/SystemMaintenance";
import { SEND_MESSAGE_DIALOG } from "./redux/actions/dialogs";
import StagingLogin from "./pages/StagingLogin";
import { REDIRECT, RELOAD } from "./utils/history";
import IS_ADMIN from "./utils/permissions/IS_ADMIN";

export default function App() {
  const { staging_authenticated, messages, active_directory } = useSelector(
    (state) => ({
      staging_authenticated: state.ADMIN.staging_authenticated,
      messages: state.MESSAGE,
      active_directory: state.CONTEXT.active_directory,
    })
  );

  useEffect(() => {
    const path = window.location.pathname;

    if (IS_AUTHENTICATED()) {
      if (path === "/login") {
        if (!IS_ADMIN()) {
          REDIRECT("/dir/" + active_directory);
          RELOAD();
        } else REDIRECT("/admin");
      }
    } else {

      if (path !== "/landing-page" && path !== "/login" && !path.startsWith("/dir/")) {
        REDIRECT("/landing-page");
        RELOAD();
      }



    }

    if (path.startsWith("/dir/") || path.startsWith("/doc/"))
      setContext("entered_path", path);

    if (env.SYSTEM_MAINTENANCE_NOTICE) {
      SEND_MESSAGE_DIALOG(
        "warning",
        "info",
        "System Maintenance Notice",
        "We will conduct system maintenance on " +
        env.SYSTEM_UPGRADE_NOTICE_DATE +
        ", " +
        env.SYSTEM_UPGRADE_NOTICE_TIME +
        ". " +
        "Please be informed that the system is not usable from that moment and it might take some " +
        "time before the maintenance is completely done."
      );
    }
    if (env.SYSTEM_UPGRADE_NOTICE) {
      SEND_MESSAGE_DIALOG(
        "warning",
        "info",
        "System Upgrade Notice",
        "We will conduct system upgrade on " +
        env.SYSTEM_UPGRADE_NOTICE_DATE +
        ", " +
        env.SYSTEM_UPGRADE_NOTICE_TIME +
        ". " +
        "Please be informed that the system is not usable from that moment and it might take some " +
        "time before the upgrade is completely done."
      );
    }

    GLOBAL_EVENTS();
  }, [active_directory]);

  if (env.STAGING && !staging_authenticated) return <StagingLogin />;
  if (env.SYSTEM_UPGRADE_MODE) return <SystemUpgrade />;
  if (env.SYSTEM_MAINTENANCE_MODE) return <SystemMaintenance />;

  return (
    <>
      <Routes />
      <Dialogs />

      {!IS_ONLINE() && <OfflineBackdrop in />}

      {messages?.map((message) => {
        if (message.action === "enqueue")
          return (
            <EnqueueSnackBar
              message={message.message}
              variant={message.variant}
              persist={message.persist}
            />
          );
        else return <DequeueSnackBar message={message.message} />;
      })}
    </>
  );
}
