import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Line from "../decoration/Line";
import getFileIcon from "../../../utils/GET_FILE_ICON";
import Space from "../panel/Space";
import LightToolTip from "../tooltip/LightToolTip";
import Grow from "@material-ui/core/Grow";

import {
  RiEarthLine,
  RiFileCopyLine,
  RiLinksLine,
  RiLockLine,
} from "react-icons/all";
import IS_ENV_PUB from "../../../utils/context/IS_ENV_PUB";
import IS_ACCREDITOR from "../../../utils/permissions/IS_ACCREDITOR";
import SelectionTable from "../table/SelectionTable";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { getDirectoryPath } from "../../../utils/directory";
import { setContext } from "../../../redux/actions/context";

export default function Main({ trash }) {
  const classes = useStyles();
  const { listView } = useSelector((state) => ({
    listView: state.CONTEXT.toggles.directory_list_view || false,
  }));

  const columns = [
    {
      id: "id",
      numeric: true,
      disablePadding: true,
      label: "id",
      hidden: true,
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
      style: { width: "15%" },
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
      style: { width: "25%" },
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
      style: { width: "10%" },
    },
    {
      id: "path",
      numeric: false,
      disablePadding: false,
      label: "Path",
      style: { width: "30%" },
    },
    {
      id: "deleted_at",
      numeric: false,
      disablePadding: false,
      label: "Date Deleted",
      wrap: (value) => {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED);
      },
      style: { width: "10%" },
    },
    {
      id: "deleted_by",
      numeric: false,
      disablePadding: false,
      label: "Deleted By",
      style: { width: "10%" },
    },
  ];

  const rows = [];
  if (trash)
    trash.forEach((item) => {
      rows.push([
        item.id,
        item.document.title,
        item.document.description,
        item.document.type,
        getDirectoryPath(item.directory.ancestry),
        item.created_at,
        item.user.first_name + " " + item.user.last_name,
      ]);
    });

  return (
    <div className={classes.root}>
      <div className={classes.folderGroupContainer}>
        {listView && (
          <img
            alt={"File Icon"}
            src={getFileIcon(".pdf")}
            style={{
              width: 24,
              marginRight: 16,
            }}
          />
        )}
        <Typography
          noWrap
          variant={"body2"}
          className={clsx(classes.typography)}
        >
          DOCUMENTS
        </Typography>
      </div>
      {listView ? (
        <>
          <SelectionTable
            columns={columns}
            rows={rows}
            noHead
            disableSelect
            disableEmptyRow
            disableDenseToggle
            paginationRowsPerPage={10}
            onContextMenuRow={(e, item) => {
              e.preventDefault();
              e.persist();
              setContext("documentX", e.pageX);
              setContext("documentY", e.pageY);
              setContext("show_document_context_menu_trash", true);
              setContext("pre_clipboard", {
                id: item[0],
                type: "TRASH_DOCUMENT",
              });
            }}
            onClickRow={async (row) => {
              // if (isUserAccreditor() && !IS_ACCREDITOR()) {
              //    accred_public_document_on_click();
              // } else {
              //    if (
              //       !row[1] &&
              //       props.prefix === "pub" &&
              //       !IS_ACCREDITOR()
              //    ) {
              //       private_document_on_click();
              //    } else {
              //       props.onClick(row[0]);
              //    }
              // }
            }}
          />
        </>
      ) : (
        <Grid container spacing={0}>
          {trash?.map((doc, index) => (
            <Grid item key={index}>
              <Grow in={true}>
                <LightToolTip
                  title={doc.document.title}
                  placement={"bottom-start"}
                >
                  <div
                    className={classes.documentBoxContainer}
                    // onClick={() => {
                    //    if (isUserAccreditor() && !IS_ACCREDITOR()) {
                    //       accred_public_document_on_click();
                    //    } else {
                    //       if (
                    //          !doc.is_public &&
                    //          props.prefix === "pub" &&
                    //          !IS_ACCREDITOR()
                    //       ) {
                    //          private_document_on_click();
                    //       } else {
                    //          props.onClick(doc.docdir_id);
                    //       }
                    //    }
                    // }}
                    // onContextMenu={(e) => {
                    //    e.preventDefault();
                    //    e.persist();
                    //    SET_CONTEXT("documentX", e.pageX);
                    //    SET_CONTEXT("documentY", e.pageY);
                    //    SET_CONTEXT("show_context_menu", true);
                    //    SET_CONTEXT("pre_clipboard", {
                    //       id: doc.docdir_id,
                    //       type: "DOCUMENT",
                    //       is_public: doc.is_public,
                    //    });
                    // }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      e.persist();
                      setContext("documentX", e.pageX);
                      setContext("documentY", e.pageY);
                      setContext("show_document_context_menu_trash", true);
                      setContext("pre_clipboard", {
                        id: doc.id,
                        type: "TRASH_DOCUMENT",
                      });
                    }}
                  >
                    {IS_ACCREDITOR() ? (
                      <Space vertical space={4} />
                    ) : (
                      <div className={classes.badgeContainer}>
                        {doc.is_link ? (
                          <div>
                            <LightToolTip
                              title="Link Document"
                              placement="bottom-end"
                            >
                              <RiLinksLine size={22} className={classes.icon} />
                            </LightToolTip>
                          </div>
                        ) : doc.is_copy ? (
                          <div>
                            <LightToolTip
                              title="Copy Document"
                              placement="bottom-end"
                            >
                              <RiFileCopyLine
                                size={22}
                                className={classes.icon}
                              />
                            </LightToolTip>
                          </div>
                        ) : (
                          <Space vertical space={3} />
                        )}
                        {doc.is_public ? (
                          <div className={classes.flexAlignCenter}>
                            <Space space={1} />
                            <LightToolTip
                              title="Publicized Document"
                              placement="bottom-end"
                            >
                              <RiEarthLine size={22} className={classes.icon} />
                            </LightToolTip>
                          </div>
                        ) : IS_ENV_PUB() ? (
                          <div className={classes.flexAlignCenter}>
                            <Space space={1} />
                            <LightToolTip
                              title="Private Document"
                              placement="bottom-end"
                            >
                              <RiLockLine size={22} className={classes.icon} />
                            </LightToolTip>
                          </div>
                        ) : null}
                      </div>
                    )}
                    <Space vertical space={3} />
                    <div className={classes.filetypeIconContainer}>
                      <img
                        src={getFileIcon(".pdf")}
                        alt={"Filetype icon"}
                        className={classes.filetypeIcon}
                      />
                    </div>
                    <Line horizontal width={"100%"} />
                    <Typography
                      noWrap
                      variant={"body1"}
                      className={clsx(classes.typography, classes.title)}
                    >
                      {doc.document.title}
                    </Typography>
                  </div>
                </LightToolTip>
              </Grow>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  documentContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  documentBoxContainer: {
    width: 232,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: "solid 1px " + theme.palette.grey.S2,
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      cursor: "pointer",
    },
  },
  filetypeIconContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(6),
    paddingTop: 0,
  },
  typography: {
    lineHeight: "normal",
    color: theme.palette.grey.S4,
  },
  filetypeIcon: {
    height: 70,
  },
  title: {
    padding: theme.spacing(2),
  },
  icon: {
    color: theme.palette.grey.S3,
  },
  iconLink: {
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  badgeContainer: {
    display: "flex",
    justifyContent: "flex-end",
    // alignItems: 'center',
    padding: theme.spacing(1),
    height: theme.spacing(3),
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },

  folderGroupContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  gridIcon: {
    color: theme.palette.grey.S3,
  },
}));
