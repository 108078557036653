import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { setForm, setToggle } from "../../../redux/actions/context";
import {GET_ACTIVE_USERS_BY_USER_DEPARTMENTS} from "../../../redux/actions/auth";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
        minWidth: "175px",
    },
    smallfont: {
        fontSize: "13px",
    },
    small: {
        height: "35px",
    },
    root: {
        margin: theme.spacing(1),
    }
}));

export default function NativeSelects(PROPS) {
    const classes = useStyles();
    const DATA = PROPS.value;
    const [state, setState] = React.useState({});

    useEffect(() => {
        const value = state.value;
        setForm(PROPS.type, PROPS.name, value);
    }, [state]);

    const handleChange = (event) => {
        setToggle("view_report",false);
        const value = event.target.value;
        const officesTxt = "offices";

        setState({
            value
        });
        if (PROPS.name === officesTxt) {
            setForm(PROPS.type, "users", "");
            GET_ACTIVE_USERS_BY_USER_DEPARTMENTS(value);
        }
    };

    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple" className={classes.smallfont}>{PROPS.title}</InputLabel>
                <Select
                    className={clsx(
                        classes.root,
                        PROPS.smallfont ? classes.smallfont : null,
                        PROPS.small ? classes.small : null,
                    )}

                    native
                    value={state.age}
                    onChange={handleChange}
                    label="Age"
                    inputProps={{
                        name: 'value',
                        id: 'outlined-age-native-simple',
                    }}
                >
                    <option aria-label="None" value=""/>
                    {DATA.map((data,index) => (
                        <option value={data.id}>{data.value}</option>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
