import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { setForm, setToggle } from "../redux/actions/context";
import SearchResult from "./core/breadcrumb/SearchResult";
import Typography from "@material-ui/core/Typography";
import Breadcrumb from "./core/breadcrumb/Breadcrumb";
import IS_PUBLIC_REQUEST from "../utils/context/IS_PUBLIC_REQUEST";
import ActionDirectory from "./actions/ActionDirectory";
import ActionDocuments from "./actions/ActionDocuments";
import { GET_DIRECTORY_DETAILS } from "../redux/actions/directory";
import { GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS } from "../redux/actions/document";

export default function HomeContentHeader() {
  const styles = style();
  const { active_directory, directory_details, filter, toggles, context } =
    useSelector((state) => ({
      active_directory: state.CONTEXT.active_directory,
      directory_details: state.DIRECTORY.details,
      filter: state.CONTEXT.forms.filter,
      toggles: {
        filter_panel: state.CONTEXT.toggles.filter_panel,
      },
      context: {
        search_mode: state.CONTEXT.search_mode,
        search_key: state.CONTEXT.search_key,
      },
    }));

  const onClickFilterPanel = () => {
    setToggle("filter_panel", !toggles.filter_panel);
  };

  const onChangeSort = (name, value) => {
    setForm("filter", name, value);
    if (!IS_PUBLIC_REQUEST()) GET_DIRECTORY_DETAILS();
    else GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS();
  };

  return (
    <>
      <div className={styles.flexAlignCenter}>
        {context.search_mode ? (
          <SearchResult search_key={context.search_key} />
        ) : active_directory === "public-request" ? (
          <Typography noWrap variant={"h6"}>
            Public Requests
          </Typography>
        ) : (
          <Breadcrumb
            ancestry={directory_details.ancestry}
            active_directory={active_directory}
          />
        )}
      </div>

      {/* ACTIONS */}
      <div className={styles.flex}>
        {!IS_PUBLIC_REQUEST() && <ActionDirectory />}

        {/* DOCUMENT ACTIONS */}
        <ActionDocuments
          sortChangeOnClick={onChangeSort}
          sortBy={filter.sort_by}
          ascending={filter.ascending}
          filterOnClick={onClickFilterPanel}
        />
      </div>
    </>
  );
}

const style = makeStyles((theme) => ({
  flex: {
    display: "flex",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
}));
