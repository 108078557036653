import { MESSAGE_ADD, MESSAGE_CLEAR, MESSAGE_REMOVE } from "../actions/types";

const init = []

export default function Main(state = init, action) {
    switch (action.type) {
        case MESSAGE_ADD:
            return state.concat([action.payload])
        case MESSAGE_REMOVE:
            return state.filter(
                item => item.message !== action.message
            )
        case MESSAGE_CLEAR:
            return init
        default:
            return state
    }
}