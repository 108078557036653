import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import { SendOutlined } from "@material-ui/icons";
import Space from "../panel/Space";
import Line from "../decoration/Line";
import {
  RiClipboardLine,
  RiDeleteBin7Line,
  RiEdit2Line,
  RiFileCopyLine,
  RiInformationLine,
  RiLinksLine,
  RiScissorsCutLine,
} from "react-icons/all";
import { setContext, setForm, setToggle } from "../../../redux/actions/context";
import HAS_CRUD from "../../../utils/permissions/HAS_CRUD";
// import IS_ROOT_DIRECTORY from "../../../utils/directory/IS_ROOT_DIRECTORY";
import { MESSAGE } from "../../../redux/actions/message";
import {
  COPY_DOCUMENT,
  CUT_DOCUMENT,
  LINK_DOCUMENT,
} from "../../../redux/actions/document";
import {
  COPY_DIRECTORY,
  CUT_DIRECTORY,
  DELETE_DIRECTORY,
  GET_DIRECTORY_DETAILS,
  LINK_DIRECTORY,
  REMOVE_LINK_DIRECTORY,
} from "../../../redux/actions/directory";
import {
  SEND_CONFIRMATION_DIALOG,
  SEND_CONFIRMATION_DIALOG_WITH_KEY,
  SEND_MESSAGE_DIALOG,
} from "../../../redux/actions/dialogs";
import IS_PUBLIC_REQUEST from "../../../utils/context/IS_PUBLIC_REQUEST";
import IS_HEAD from "../../../utils/permissions/IS_HEAD";
import IS_SUPPORT from "../../../utils/permissions/IS_SUPPORT";
import IN_ANCESTRY from "../../../utils/directory/IN_ANCESTRY";
import IS_MODERATOR_IN_DIRECTORY from "../../../utils/permissions/IS_MODERATOR_IN_DIRECTORY";
import { useSelector } from "react-redux";
import {useEffect} from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
  popoverRoot: {
    marginTop: 12,
  },
  popoverPaper: {
    boxShadow: theme.shadows[3],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minWidth: 150,
    maxWidth: 300,
  },
  popoverItem: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.grey.S4,

    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
      background: theme.palette.grey.RGB0,
    },
  },
  flex: {
    display: "flex",
  },
}));

export default function ContextMenuDirectory(props) {
  const classes = useStyles();
  const { context, directory_details } = useSelector((state) => ({
    context: {
      active_directory: state.CONTEXT.active_directory,
      pre_clipboard: state.CONTEXT.pre_clipboard,
      clipboard: state.CONTEXT.clipboard,
      clipboard_destination: state.CONTEXT.clipboard_destination,
      clipboard_directory_is_link: state.CONTEXT.clipboard_directory_is_link,
    },
    directory_details: state.DIRECTORY.details,
  }));

  useEffect(() => {

    setForm(
        "directory",
        "name",
        directory_details.name
    );
    setForm(
        "directory",
        "description",
        directory_details
            .description
    );
    setForm(
        "directory",
        "group",
        directory_details.group ? directory_details.group.name : []
    );
    setForm(
        "directory",
        "moderators",
        directory_details
            .moderators
    );
    setForm(
        "directory",
        "is_public",
        directory_details.is_public
    );
  }, [directory_details]);

  // export default function (props) {
  //     const classes = useStyles()
  //     const class_props = props.class.props

  const onClick = async (action) => {
    switch (action) {
      case "copy":
      case "cut":
        setContext("clipboard", {
          id: context.pre_clipboard.id,
          type: context.pre_clipboard.type,
          action,
        });
        if (action === "copy") {
          MESSAGE(`Directory cut to clipboard`);
          MESSAGE(`Directory copied to clipboard`, "enqueue", "success");
        } else {
          // GET_DESCENDANCE(props.class.props.CONTEXT.clipboard.id, (descendance) => {
          //     SET_CONTEXT('cut_directory_descendance', descendance)
          MESSAGE(`Directory copied to clipboard`, "dequeue");
          MESSAGE(`Directory cut to clipboard`, "enqueue", "success");
          // }, () => {
          //     MESSAGE(`Directory copied to clipboard`, 'dequeue')
          //     MESSAGE(`Failed to cut directory to clipboard`, 'enqueue', 'error')
          // })
        }
        break;
      case "paste-copy":
        if (context.clipboard.type === "DOCUMENT") {
          COPY_DOCUMENT(context.clipboard_destination, context.clipboard.id);
        }
        break;
      case "paste-link":
        if (context.clipboard.type === "DOCUMENT") {
          LINK_DOCUMENT(context.clipboard_destination, context.clipboard.id);
        }
        break;
      case "paste-cut":
        if (context.clipboard.type === "DOCUMENT") {
          CUT_DOCUMENT(context.clipboard_destination, context.clipboard.id);
        }
        break;
      case "paste-directory":
        if (context.clipboard.action === "copy") {
          COPY_DIRECTORY(context.clipboard_destination, context.clipboard.id);
        } else if (context.clipboard.action === "cut") {
          CUT_DIRECTORY(context.clipboard_destination, context.clipboard.id);
        }
        break;
      case "paste-directory-link":
        if (context.clipboard.action === "copy") {
          LINK_DIRECTORY(context.clipboard_destination, context.clipboard.id);
        }
        break;
      case "edit":
        MESSAGE("Preparing", "enqueue", "info", "true");
        await GET_DIRECTORY_DETAILS(
          context.clipboard_destination,
          () => {

            setContext("formRequestMode", "UPDATE");
            setContext("formType", "Directory");
            setToggle("create_new", true);
            MESSAGE("Preparing", "dequeue");
          },
          () => {
            MESSAGE("Preparing", "dequeue");
            MESSAGE(
                "Failed to fetch directory",
                "enqueue",
                "error"
            );
          }
        );
        break;
      case "delete":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "warning",
          "Trash Confirmation",
          "This directory will be moved to the Office Trash Bin. You can restore it later if necessary.",
          () => {
            DELETE_DIRECTORY(context.clipboard_destination, false);
          }
        );
        break;
      case "delete-link":
        SEND_CONFIRMATION_DIALOG_WITH_KEY(
          "error",
          "error",
          "Remove Confirmation",
          "This directory link will be removed from this directory. This cannot be undone.",
          "REMOVE LINK",
          () => {
            REMOVE_LINK_DIRECTORY(
              context.active_directory,
              context.pre_clipboard.id
            );
          }
        );
        break;
      case "publicize-request-all":
        // SEND_CONFIRMATION_DIALOG('warning','danger','Request Confirmation',
        //     'Upon confirmation, all unpublicized documents in this directory will be requested for ' +
        //     'public posting and will be subject for approval.', () => {
        //         REQUEST_ALL_TO_PUBLICIZE()
        //     })
        break;
      case "info":
        SEND_MESSAGE_DIALOG(
          "info",
          "",
          "Coming Soon",
          "This feature is under development and will be available in the next updates.",
          () => { }
        );
        break;
      default:
        break;
    }

    props.onClose();
  };

  return (
    <div className={classes.root}>
      {IS_PUBLIC_REQUEST() ? null : (
        <Popover
          id={props.id}
          open={props.open}
          onClose={props.onClose}
          anchorPosition={{ left: props.X, top: props.Y - 10 }}
          anchorReference={"anchorPosition"}
          classes={{
            root: classes.popoverRoot,
            paper: classes.popoverPaper,
          }}
        >
          {HAS_CRUD() ||
            IS_MODERATOR_IN_DIRECTORY(context.clipboard_destination) ? (
            <Fragment>
              <div
                className={classes.popoverItem}
                onClick={() => onClick("copy")}
              >
                <div className={classes.flex}>
                  <RiFileCopyLine size={24} />
                  <Space space={2} />
                  <Typography variant={"body1"}>Copy</Typography>
                </div>
              </div>
              {HAS_CRUD() && !context.clipboard_directory_is_link ? (
                <div
                  className={classes.popoverItem}
                  onClick={() => onClick("cut")}
                >
                  <div className={classes.flex}>
                    <RiScissorsCutLine size={24} />
                    <Space space={2} />
                    <Typography variant={"body1"}>Cut</Typography>
                  </div>
                </div>
              ) : null}

              <Space vertical space={1} />
              <Line horizontal width={"100%"} />
              <Space vertical space={1} />

              {context.clipboard.type === "DOCUMENT" ? (
                <Fragment>
                  {context.clipboard.action === "copy" ? (
                    <Fragment>
                      <div
                        className={classes.popoverItem}
                        onClick={() => onClick("paste-copy")}
                      >
                        <div className={classes.flex}>
                          <RiClipboardLine size={24} />
                          <Space space={2} />
                          <Typography variant={"body1"}>
                            Paste as Copy
                          </Typography>
                        </div>
                      </div>
                      <div
                        className={classes.popoverItem}
                        onClick={() => onClick("paste-link")}
                      >
                        <div className={classes.flex}>
                          <RiLinksLine size={24} />
                          <Space space={2} />
                          <Typography variant={"body1"}>
                            Paste as Link
                          </Typography>
                        </div>
                      </div>
                    </Fragment>
                  ) : context.clipboard.action === "cut" ? (
                    <Fragment>
                      <div
                        className={classes.popoverItem}
                        onClick={() => onClick("paste-cut")}
                      >
                        <div className={classes.flex}>
                          <RiClipboardLine size={24} />
                          <Space space={2} />
                          <Typography variant={"body1"}>Paste</Typography>
                        </div>
                      </div>
                    </Fragment>
                  ) : null}

                  <Space vertical space={1} />
                  <Line horizontal width={"100%"} />
                  <Space vertical space={1} />
                </Fragment>
              ) : context.clipboard.type === "DIRECTORY" ? (
                context.clipboard.id === context.clipboard_destination ||
                  IN_ANCESTRY(context.clipboard.id) ||
                  context.clipboard_directory_is_link ? null : (
                  <Fragment>
                    <div
                      className={classes.popoverItem}
                      onClick={() => onClick("paste-directory")}
                    >
                      <div className={classes.flex}>
                        <RiClipboardLine size={24} />
                        <Space space={2} />
                        <Typography variant={"body1"}>Paste</Typography>
                      </div>
                    </div>
                    {context.clipboard.action === "copy" && (
                      <div
                        className={classes.popoverItem}
                        onClick={() => onClick("paste-directory-link")}
                      >
                        <div className={classes.flex}>
                          <RiLinksLine size={24} />
                          <Space space={2} />
                          <Typography variant={"body1"}>
                            Paste as Link
                          </Typography>
                        </div>
                      </div>
                    )}
                    <Space vertical space={1} />
                    <Line horizontal width={"100%"} />
                    <Space vertical space={1} />
                  </Fragment>
                )
              ) : null}

              {!IS_MODERATOR_IN_DIRECTORY(context.clipboard_destination) ? (
                <Fragment>
                  {!context.clipboard_directory_is_link && (
                    <div
                      className={classes.popoverItem}
                      onClick={() => onClick("edit")}
                    >
                      <div className={classes.flex}>
                        <RiEdit2Line size={24} />
                        <Space space={2} />
                        <Typography variant={"body1"}>
                          Edit Directory
                        </Typography>
                      </div>
                    </div>
                  )}
                  {context.clipboard_directory_is_link ? (
                    <div
                      className={classes.popoverItem}
                      onClick={() => onClick("delete-link")}
                    >
                      <div className={classes.flex}>
                        <RiDeleteBin7Line size={24} />
                        <Space space={2} />
                        <Typography variant={"body1"}>Remove Link</Typography>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={classes.popoverItem}
                      onClick={() => onClick("delete")}
                    >
                      <div className={classes.flex}>
                        <RiDeleteBin7Line size={24} />
                        <Space space={2} />
                        <Typography variant={"body1"}>Move to Trash</Typography>
                      </div>
                    </div>
                  )}

                  <Space vertical space={1} />
                  <Line horizontal width={"100%"} />
                  <Space vertical space={1} />
                </Fragment>
              ) : null}

              {IS_HEAD() && !IS_SUPPORT() ? (
                <Fragment>
                  <div
                    className={classes.popoverItem}
                    onClick={() => onClick("publicize-request-all")}
                  >
                    <div className={classes.flex}>
                      <SendOutlined />
                      <Space space={2} />
                      <Typography variant={"body1"}>
                        Request All to Publicize
                      </Typography>
                    </div>
                  </div>

                  <Space vertical space={1} />
                  <Line horizontal width={"100%"} />
                  <Space vertical space={1} />
                </Fragment>
              ) : null}
            </Fragment>
          ) : (
            <Fragment>
              <div
                className={classes.popoverItem}
                onClick={() => onClick("copy")}
              >
                <div className={classes.flex}>
                  <RiFileCopyLine size={24} />
                  <Space space={2} />
                  <Typography variant={"body1"}>Copy</Typography>
                </div>
              </div>
              <Space vertical space={1} />
              <Line horizontal width={"100%"} />
              <Space vertical space={1} />
            </Fragment>
          )}

          <div className={classes.popoverItem} onClick={() => onClick("info")}>
            <div className={classes.flex}>
              <RiInformationLine size={24} />
              <Space space={2} />
              <Typography variant={"body1"}>Information</Typography>
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
}
