import { store } from "../../redux";

const { getState } = store

export default function Main(directory_id) {
    directory_id = directory_id ? directory_id.substring(3) : 0
    return getState().AUTH.CRUD_dirs && getState().AUTH.CRUD_dirs.includes(
        parseInt(directory_id)
    )
}
