import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { ClearOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
     root: {},
     chip: {
          marginTop: theme.spacing(2),
          marginRight: theme.spacing(2),
          minWidth: 50,
          maxWidth: 324,
     },
     chipRoot: {
          borderRadius: theme.shape.borderRadius,
     },
}));

export default function Main(props) {
     const classes = useStyles();

     return (
          <div className={classes.root}>
               {props.list
                    ? props.list.map((item, index) => (
                         <Chip
                              key={index}
                              variant="outlined"
                              // avatar={
                              //     <Avatar alt="" src={Picture}/>
                              // }
                              label={item.name}
                              deleteIcon={
                                   <ClearOutlined fontSize={"small"} />
                              }
                              onDelete={() => props.handleDelete(item)}
                              className={classes.chip}
                              classes={{
                                   root: classes.chipRoot,
                              }}
                         />
                    ))
                    : null}
          </div>
     );
}
