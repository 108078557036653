import { store } from "../../redux";

const { getState } = store;

export default function Main() {
     let active_directory = getState().CONTEXT.active_directory;
     if (active_directory && active_directory.substring(0, 3) === "pub")
          return true;
     return false;
}
