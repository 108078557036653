import {
     CONTEXT_DIRECTORY_ACTIVE,
     CONTEXT_DIRECTORY_EXPANDED_ADD,
     CONTEXT_DIRECTORY_EXPANDED_REMOVE,
     CONTEXT_FORM_RESET_DEPARTMENT,
     CONTEXT_FORM_RESET_DIRECTORY,
     CONTEXT_FORM_RESET_DIRECTORY_GROUP,
     CONTEXT_FORM_RESET_DOCUMENT,
     CONTEXT_FORM_RESET_FILTER,
     CONTEXT_FORM_SET_DEPARTMENT,
     CONTEXT_FORM_SET_MONITORING_PARAMETERS,
     CONTEXT_FORM_SET_REPORTS,
     CONTEXT_FORM_SET_DIRECTORY,
     CONTEXT_FORM_SET_DIRECTORY_GROUP,
     CONTEXT_FORM_SET_DOCUMENT,
     CONTEXT_FORM_SET_FILTER,
     CONTEXT_RESET,
     CONTEXT_SET,
     DIRECTORY_TOGGLE_SET,
} from "./types";
import { store } from "../index";

const { dispatch, getState } = store;

export const clearForm = (type) => {
  switch (type) {
    case "directory":
      dispatch({ type: CONTEXT_FORM_RESET_DIRECTORY });
      break;
    case "directory_group":
      dispatch({ type: CONTEXT_FORM_RESET_DIRECTORY_GROUP });
      break;
    case "document":
      dispatch({ type: CONTEXT_FORM_RESET_DOCUMENT });
      break;
    case "filter":
      dispatch({ type: CONTEXT_FORM_RESET_FILTER });
      break;
    case "department":
      dispatch({ type: CONTEXT_FORM_RESET_DEPARTMENT });
      break;
    default:
      break;
  }
};

export const REDIRECT = (link) => {
     setContext("redirect", link);
};

export const resetContext = () => {
  dispatch({ type: CONTEXT_RESET });
};

export const setActiveDirectory = (directory_id, callback) => {
  dispatch({
    type: CONTEXT_DIRECTORY_ACTIVE,
    dir_id: directory_id,
  });
  if (typeof callback === "function") callback();
};

export const setContext = (name, value) => {
  dispatch({ type: CONTEXT_SET, payload: { name, value } });
};

export const setForm = (type, name, value) => {
  switch (type) {
    case "directory":
      dispatch({
        type: CONTEXT_FORM_SET_DIRECTORY,
        payload: { name, value },
      });
      break;
    case "directory_group":
      dispatch({
        type: CONTEXT_FORM_SET_DIRECTORY_GROUP,
        payload: { name, value },
      });
      break;
    case "document":
      dispatch({
        type: CONTEXT_FORM_SET_DOCUMENT,
        payload: { name, value },
      });
      break;
    case "filter":
      dispatch({
        type: CONTEXT_FORM_SET_FILTER,
        payload: { name, value },
      });
      break;
    case "department":
      dispatch({
        type: CONTEXT_FORM_SET_DEPARTMENT,
        payload: { name, value },
      });
      break;
    case "monitoring":
      dispatch({
          type: CONTEXT_FORM_SET_MONITORING_PARAMETERS,
          payload: { name, value },
      });
      break;
    case "reports":
        dispatch({
            type: CONTEXT_FORM_SET_REPORTS,
            payload: { name, value },
        });
          break;
    default:
      break;
  }
};

export const setToggle = (name, value) => {
  dispatch({
    type: DIRECTORY_TOGGLE_SET,
    payload: { name, value },
  });
};

/*
    EXPANDED DIRECTORY
 */

export const addToExpandedDirectory = (directory_id) => {
  dispatch({
    type: CONTEXT_DIRECTORY_EXPANDED_ADD,
    dir_id: directory_id,
  });
};

export const toggleExpandDirectory = (dir_id) => {
  dispatch({
    type: getState().CONTEXT.expanded_directories.includes(dir_id)
      ? CONTEXT_DIRECTORY_EXPANDED_REMOVE
      : CONTEXT_DIRECTORY_EXPANDED_ADD,
    dir_id,
  });
};

export const removeFromExpandedDirectory = (dir_id) => {
  dispatch({ type: CONTEXT_DIRECTORY_EXPANDED_REMOVE, dir_id });
};
