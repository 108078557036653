import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, makeStyles, Typography, useTheme } from "@material-ui/core";
import Brand from "../../static/media/img/Brand.png";
import HeaderSearchbar from "../core/input/HeaderSearchbar";
import Badge from "@material-ui/core/Badge";
import Space from "../core/panel/Space";
import Line from "../core/decoration/Line";
import Avatar from "@material-ui/core/Avatar";
import LightToolTip from "../core/tooltip/LightToolTip";
import { LOG_OUT } from "../../redux/actions/auth";
import Popover from "@material-ui/core/Popover";
import Button from "../../components/core/button/Button";


import IS_HEAD from "../../utils/permissions/IS_HEAD";
import IS_PUBLIC_REQUEST from "../../utils/context/IS_PUBLIC_REQUEST";
import IS_MODERATOR from "../../utils/permissions/IS_MODERATOR";
import IS_SUPPORT from "../../utils/permissions/IS_SUPPORT";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  RiLogoutCircleLine,
  RiMailLine,
  RiNotificationLine,
  RiSettingsLine,
} from "react-icons/all";
import STATES from "../../redux/states";
import { setContext } from "../../redux/actions/context";
import { GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS } from "../../redux/actions/document";
import { GET_DIRECTORY_DETAILS } from "../../redux/actions/directory";
import IS_ADMIN from "../../utils/permissions/IS_ADMIN";
import { useHistory } from "react-router";
import { SEND_MESSAGE_DIALOG } from "../../redux/actions/dialogs";
import { env } from "../../env";
import { store } from "../../../src/redux/index";
const { getState } = store;
const useStyles = makeStyles((theme) => ({
  container: {
    borderBottom: "solid 1px " + theme.palette.grey.S2,
    width: "100%",
    background: "white",
    display: "flex",
    flexDirection: "column",
  },
  topHeader: {
    width: "100%",
    height: 15,
    background: theme.palette.primary.main,
  },
  header: {
    height: 50,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  brand: {
    height: 45,
  },
  navigation: {
    paddingLeft: theme.spacing(5),
    display: "flex",
    alignItems: "center",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  profilePanel: {
    display: "flex",
    alignItems: "center",
    maxWidth: 500,
    "&:hover": {
      cursor: "pointer",
    },
  },
  typography: {
    lineHeight: "normal",
  },
  iconLink: {
    color: theme.palette.grey.S2,
  },
  popoverUserProfileRoot: {
    marginTop: 12,
  },
  popoverUserProfilePaper: {
    boxShadow: theme.shadows[3],
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    minWidth: 150,
    maxWidth: 250,
  },
  popoverUserProfileItem: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),

    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
      background: theme.palette.grey.RGB0,
    },
  },
  popoverProfileGreetings: {
    padding: theme.spacing(5),
  },
  badge: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    borderRadius: theme.shape.borderRadius,
  },
  link: {
    color: theme.palette.grey.S5,
    "&:hover": {
      textDecoration: "unset",
    },
  },
}));

const Body = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const THEME = useTheme();
  const CONTEXT = props.class.props.CONTEXT;
  const AUTH = getState().AUTH;
  const mailto =
    "mailto:" +
    env.EMAIL_UDAS.toLowerCase() +
    "?" +
    "cc=" +
    env.EMAIL_KMD.toLowerCase();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const onClick = async (action, event) => {
    switch (action) {
      case "search-change":
        await setContext("header_search", event.target.value);
        if (!props.class.props.CONTEXT.header_search)
          await onClick("search-clear");
        break;
      case "search-clear":
        await setContext("search_mode", false);
        await setContext("search_key", "");
        await setContext("header_search", "");
        if (IS_PUBLIC_REQUEST()) GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS();
        else GET_DIRECTORY_DETAILS();
        break;
      case "search-submit":
        if (CONTEXT.header_search) {
          await setContext("search_mode", true);
          await setContext("search_key", CONTEXT.header_search);
          if (IS_PUBLIC_REQUEST()) GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS();
          else GET_DIRECTORY_DETAILS();
        }
        break;
      case "search-enter-key":
        if (event.key === "Enter") await onClick("search-submit", event);
        break;
      case "profile-popover-open":
        setAnchorEl(event.currentTarget);
        setOpen(true);
        break;
      case "profile-popover-close":
        setAnchorEl(null);
        setOpen(false);
        break;
      case "notification-open":
        SEND_MESSAGE_DIALOG(
          "info",
          "",
          "Coming Soon",
          "This feature is under development and will be available in the next updates.",
          () => {}
        );
        break;
      case "settings-open":
        SEND_MESSAGE_DIALOG(
          "info",
          "",
          "Coming Soon",
          "This feature is under development and will be available in the next updates.",
          () => {}
        );
        break;
      default:
        break;
    }
  };

  const login = async () => {
    history.push("/login")
  }

  const btnLogo = async () => {
    if(!AUTH.token)
      history.push("/landing-page")
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img src={Brand} alt={"Brand"} className={classes.brand} onClick={btnLogo} />
        <div className={classes.navigation}>
          {IS_ADMIN() && AUTH.token ? null : (
            <div className={classes.flexAlignCenter}>
              {CONTEXT.toggles.directory_details_loader ||
              CONTEXT.toggles.directory_tree_loader ? (
                <CircularProgress size={THEME.spacing(3)} />
              ) : null}
              <Space space={3} />
              {
                AUTH.token ? (
                   <HeaderSearchbar
                value={CONTEXT.header_search}
                onChange={(event) => onClick("search-change", event)}
                onKeyDown={(event) => onClick("search-enter-key", event)}
                onClick={() => onClick("search-submit")}
                onClear={() => onClick("search-clear")}
                class={props.class}
              />
              ): null
              }
               <Space space={6} />
             {
                AUTH.token ? (
                    <LightToolTip title="Notifications" placement="bottom">
                    <Badge
                      color="primary"
                      variant={"dot"}
                      onClick={() => onClick("notification-open")}
                    >
                      <RiNotificationLine size={24} className={classes.iconLink} />
                    </Badge>
                  </LightToolTip>
                ) : null
             }
              <Space space={3} />
              <Line height={30} />
              <Space space={3} />
              {
                  AUTH.token ? (
                    <LightToolTip title="Settings" placement="bottom">
                    <RiSettingsLine
                      size={24}
                      className={classes.iconLink}
                      onClick={() => onClick("settings-open")}
                    />
                  </LightToolTip>
                  ) : null
              }
              <Space space={6} />
            </div>
          )}
          {AUTH.token ? (
            <div className={classes.profilePanel}>
            <Avatar
              alt={
                props.class.props.AUTH.first_name +
                " " +
                props.class.props.AUTH.last_name
              }
              src={props.class.props.AUTH.avatar}
              onClick={(event) => onClick("profile-popover-open", event)}
            />
          </div>
          
          ) : 
           <Button
          label={"LOGIN"}
          variant={"outlined"}
          active
         //  loading={props.class.props.CONTEXT.toggles.login_loader}
         //  class={props.class}
          onClick={login}
       />

          }
          
        </div>
      </div>

      <Popover
        id={"profile-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={() => onClick("profile-popover-close")}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{
          root: classes.popoverUserProfileRoot,
          paper: classes.popoverUserProfilePaper,
        }}
      >
        <div className={classes.popoverProfileGreetings}>
          <Typography variant={"body1"} align={"center"}>
            {" "}
            Hello there,
          </Typography>
          <Space vertical space={1} />
          <Typography variant={"h6"} align={"center"}>
            {props.class.props.AUTH.first_name}{" "}
            {props.class.props.AUTH.last_name}
          </Typography>
          {IS_HEAD() || IS_SUPPORT() ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Space vertical space={1} />
              <div style={{ display: "flex" }}>
                {IS_SUPPORT() ? (
                  <div
                    className={classes.badge}
                    style={{
                      background: THEME.palette.success.main,
                    }}
                  >
                    <Typography
                      variant={"caption"}
                      style={{
                        color: "white",
                      }}
                    >
                      Support
                    </Typography>
                  </div>
                ) : null}
                {IS_SUPPORT() && IS_HEAD() ? <Space space={1} /> : null}
                {IS_SUPPORT() && IS_MODERATOR() && !IS_HEAD() ? (
                  <Space space={1} />
                ) : null}
                {IS_HEAD() ? (
                  <div
                    className={classes.badge}
                    style={{
                      background: THEME.palette.warning.main,
                    }}
                  >
                    <Typography
                      variant={"caption"}
                      style={{
                        color: "white",
                      }}
                    >
                      Head
                    </Typography>
                  </div>
                ) : null}
                {/*{ IS_HEAD() && IS_MODERATOR() ?*/}
                {/*    <Space space={1} /> : null*/}
                {/*}*/}
                {/*{ IS_MODERATOR() ?*/}
                {/*    <div className={classes.badge} style={{*/}
                {/*        background: theme.palette.success.main,*/}
                {/*    }}>*/}
                {/*        <Typography variant={'caption'} style={{color: 'white'}}>Moderator</Typography>*/}
                {/*    </div> : null*/}
                {/*}*/}
              </div>
            </div>
          ) : null}
        </div>
        <Line horizontal width={"100%"} />
        <Space vertical space={1} />
        {/*<div className={classes.popoverUserProfileItem} onClick={onClickChangePassword}>*/}
        {/*    <VpnKeyOutlined />*/}
        {/*    <Space space={2}/>*/}
        {/*    <Typography variant={'body2'}> Change Password </Typography>*/}
        {/*</div>*/}
        <Link
          href={mailto}
          target={"_blank"}
          rel={"noopener noreferrer"}
          className={classes.link}
        >
          <div className={classes.popoverUserProfileItem}>
            <RiMailLine size={22} />
            <Space space={2} />
            <Typography variant={"body2"}> Send Us a Message </Typography>
          </div>
        </Link>
        <div className={classes.popoverUserProfileItem} onClick={LOG_OUT}>
          <RiLogoutCircleLine size={22} />
          <Space space={2} />
          <Typography variant={"body2"}> Logout </Typography>
        </div>
        <Space vertical space={0.5} />
      </Popover>
    </div>
  );
};

class Header extends Component {
  render() {
    return <Body class={this} />;
  }
}

export default connect(STATES, null)(Header);
