import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, makeStyles } from "@material-ui/core";
/* import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer"; */
import Typography from "@material-ui/core/Typography";
import Space from "../components/core/panel/Space";
import { env } from "../env";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: 1280,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bodyContainer: {
    maxWidth: 450,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Body = (props) => {
  const classes = useStyles();
  const mailto =
    "mailto:" +
    env.EMAIL_UDAS.toLowerCase() +
    "?" +
    "cc=" +
    env.EMAIL_KMD.toLowerCase() +
    "&subject=[Help]";
  return (
    <div className={classes.root}>
      <div className={classes.bodyContainer}>
        <Typography variant={"h3"} color={"primary"}>
          404
        </Typography>
        <Space vertical space={2} />
        <Typography variant={"h5"}>PAGE NOT FOUND</Typography>
        <Space vertical space={5} />
        <Typography variant={"body1"} align={"center"}>
          Sorry, we can't find this page. Please go back to the previous page or
          enter a valid link.
        </Typography>

        <Space vertical space={5} />
        <Typography variant={"body1"} align={"center"}>
          For information, you may reach us through
        </Typography>
        <Link href={mailto}>
          <Typography variant={"h6"} align={"center"} color={"primary"}>
            {env.EMAIL_UDAS.toLowerCase()}
          </Typography>
        </Link>
      </div>
    </div>
  );
};

class Admin extends Component {
  render() {
    return <Body class={this} />;
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Admin);
