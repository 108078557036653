import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Space from "../panel/Space";
import {
  ChevronRight,
  FolderOutlined,
  MoreHorizOutlined,
} from "@material-ui/icons";
import LightToolTip from "../tooltip/LightToolTip";
import Popover from "@material-ui/core/Popover";
import clsx from "clsx";
import useTheme from "@material-ui/core/styles/useTheme";
import HAS_CRUD from "../../../utils/permissions/HAS_CRUD";
import IS_ENV_PUB from "../../../utils/context/IS_ENV_PUB";
import { GET_DIRECTORY_DETAILS } from "../../../redux/actions/directory";
import IS_ACCREDITOR from "../../../utils/permissions/IS_ACCREDITOR";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  textOverflow: {
    maxWidth: 180,
    minWidth: 50,
    overflow: "hidden",
    textOverflow: "ellipsis",

    "&:hover": {
      cursor: "default",
    },
  },
  iconAction: {
    borderRadius: 100,
    padding: 4,
    margin: "-4px",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
      background: theme.palette.grey.RGB0,
    },
  },
  iconActionActive: {
    color: theme.palette.primary.main,
    background: theme.palette.grey.RGB0,
  },
  popoverMoreDirectoryRoot: {
    marginTop: 12,
  },
  popoverMoreDirectoryPaper: {
    boxShadow: theme.shadows[3],
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  moreDirectoryItemRoot: {
    display: "flex",
    alignItems: "center",
    maxWidth: 250,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.grey.S4,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
      background: theme.palette.grey.RGB0,
    },
  },
  icon: {
    // color: theme.palette.grey.S4,
  },
  typography: {
    lineHeight: "normal",
    // color: theme.palette.grey.S4
  },
  hoverLink: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  badge: {
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
    paddingTop: theme.spacing(0.1),
    paddingBottom: theme.spacing(0.1),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      cursor: "pointer",
    },
  },
  tooltip: {
    padding: theme.spacing(3),
    maxWidth: 220,
    textAlign: "justify",
  },
  arrow: {
    color: theme.palette.warning.main,
  },
}));

const MoreDirectoryItem = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.moreDirectoryItemRoot} onClick={props.onClick}>
      <FolderOutlined className={clsx(classes.icon)} />
      <Space space={1.5} />
      <Typography
        noWrap={props.level !== 1}
        variant={"body2"}
        className={clsx(classes.typography)}
      >
        {props.label}
      </Typography>
    </div>
  );
};

export default function Main({ ancestry, active_directory }) {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = async (event) => {
    event.persist();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  let prefix =
    active_directory.length > 2 ? active_directory.substring(0, 3) : null;

  return (
    <div className={classes.flexContainer}>
      {ancestry ? (
        <div className={classes.flexContainer}>
          {ancestry.length > 1 ? (
            <div className={classes.flexContainer}>
              <LightToolTip title={ancestry[0].name} placement={"bottom-start"}>
                <Typography
                  noWrap
                  variant={"h6"}
                  className={clsx(classes.textOverflow, classes.hoverLink)}
                  onClick={() => {
                    GET_DIRECTORY_DETAILS(prefix + ancestry[0].id);
                  }}
                >
                  {ancestry[0].name}
                </Typography>
              </LightToolTip>
              <Space space={2} />
              <ChevronRight />
              <Space space={2} />
              {ancestry.length > 2 ? (
                <div className={classes.flexContainer}>
                  <LightToolTip title="More directories" placement="bottom">
                    <MoreHorizOutlined
                      id={"breadcrumb-more-toggle"}
                      className={clsx(
                        classes.iconAction,
                        open ? classes.iconActionActive : null
                      )}
                      onClick={handleClick}
                    />
                  </LightToolTip>
                  <Space space={2} />
                  <ChevronRight />
                  <Space space={2} />

                  <Popover
                    id={"popover-more-directory"}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    classes={{
                      root: classes.popoverMoreDirectoryRoot,
                      paper: classes.popoverMoreDirectoryPaper,
                    }}
                  >
                    {ancestry.map((dir, index) => (
                      <div key={index}>
                        {index > 0 && index !== ancestry.length - 1 ? (
                          <MoreDirectoryItem
                            label={dir.name}
                            onClick={() => {
                              handleClose();
                              GET_DIRECTORY_DETAILS(prefix + dir.id);
                            }}
                          />
                        ) : null}
                      </div>
                    ))}
                  </Popover>
                </div>
              ) : null}
            </div>
          ) : null}

          <LightToolTip
            title={ancestry[ancestry.length - 1].name}
            placement={"bottom-start"}
          >
            <Typography
              noWrap
              variant={"h6"}
              className={clsx(classes.textOverflow)}
            >
              {ancestry[ancestry.length - 1].name}
            </Typography>
          </LightToolTip>
        </div>
      ) : null}
      {HAS_CRUD() && !IS_ENV_PUB() ? (
        <div className={classes.flexContainer}>
          <Space space={2} />
          <LightToolTip
            title="You can create, update, delete, copy, and move directories and documents inside this directory."
            placement="bottom"
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <div
              className={classes.badge}
              style={{
                background: theme.palette.warning.main,
              }}
            >
              <Typography variant={"caption"} style={{ color: "white" }}>
                EDITOR
              </Typography>
            </div>
          </LightToolTip>
        </div>
      ) : null}
      {IS_ACCREDITOR() ? (
        <div className={classes.flexContainer}>
          <Space space={2} />
          <LightToolTip
            title="You can open the documents inside this directory."
            placement="bottom"
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <div
              className={classes.badge}
              style={{
                background: theme.palette.success.main,
              }}
            >
              <Typography variant={"caption"} style={{ color: "white" }}>
                ACCREDITOR
              </Typography>
            </div>
          </LightToolTip>
        </div>
      ) : null}
    </div>
  );
}
