import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  FolderOutlined,
  FolderSpecialOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";
import { setContext } from "../../../redux/actions/context";
import LightToolTip from "../tooltip/LightToolTip";
import SelectionTable from "../table/SelectionTable";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  folderGroupContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  folderGroupActionContainer: {
    display: "flex",
    alignItems: "center",
  },
  folderContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  folderBoxContainer: {
    width: 200,
    border: "solid 1px " + theme.palette.grey.S2,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    "&:hover": {
      cursor: "pointer",
    },
  },
  folderIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.grey.S3,
  },
  gridIcon: {
    color: theme.palette.grey.S3,
  },
  typography: {
    lineHeight: "normal",
    color: theme.palette.grey.S4,
  },
  iconAction: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
}));

export default function Main(props) {
  const classes = useStyles();
  const { listView } = useSelector((state) => ({
    listView: state.CONTEXT.toggles.directory_list_view || false,
  }));

  const columns = [
    {
      id: "id",
      numeric: true,
      disablePadding: true,
      label: "id",
      hidden: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      style: {
        width: "50%",
      },
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "is_link",
      numeric: false,
      disablePadding: false,
      label: "is_link",
      hidden: true,
    },
  ];

  const { children, groupName } = props;

  const rows = [];
  children.forEach((item) => {
    if (groupName === item.group)
      rows.push([item.id, item.name, item.description, item.is_link]);
  });

  // const toggleListView = () => {
  //   setToggle("directory_list_view", !listView);
  // };

  return (
    <div className={classes.root}>
      {/* GROUP NAME */}
      <div className={classes.folderGroupContainer}>
        <Typography
          noWrap
          variant={"body2"}
          className={clsx(classes.typography)}
        >
          {props.groupName}
        </Typography>
        {/*<div onClick={toggleListView}>*/}
        {/*   {listView ? (*/}
        {/*      <FormatListBulletedOutlined className={classes.gridIcon} />*/}
        {/*   ) : (*/}
        {/*      <GridOnOutlined className={classes.gridIcon} />*/}
        {/*   )}*/}
        {/*</div>*/}
      </div>
      {listView ? (
        <>
          <SelectionTable
            columns={columns}
            rows={rows}
            noHead
            disableSelect
            disablePagination
            disableEmptyRow
            disableDenseToggle
            onClickRow={async (row) => {
              await setContext("search_key", "");
              await setContext("search_mode", false);
              await props.childOnClick(props.prefix + row[0]);
            }}
            onContextMenuRow={(e, row) => {
              e.preventDefault();
              e.persist();
              setContext("directoryX", e.pageX);
              setContext("directoryY", e.pageY);
              setContext("show_directory_context_menu", true);
              setContext("clipboard_destination", props.prefix + row[[0]]);
              setContext("pre_clipboard", {
                id: props.prefix + row[[0]],
                type: "DIRECTORY",
              });
              setContext("clipboard_directory_is_link", row[3] || false);
            }}
          />
        </>
      ) : (
        <div className={classes.folderContainer}>
          {/* DIRECTORIES */}
          {props.children.map((child, index) => (
            <div key={index}>
              {child.group === props.groupName ? (
                <Grow in>
                  <LightToolTip title={child.name} placement={"bottom-start"}>
                    <div
                      className={clsx(classes.folderBoxContainer)}
                      onClick={async () => {
                        await setContext("search_key", "");
                        await setContext("search_mode", false);
                        await props.childOnClick(props.prefix + child.id);
                      }}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        e.persist();
                        setContext("directoryX", e.pageX);
                        setContext("directoryY", e.pageY);
                        setContext("show_directory_context_menu", true);
                        setContext(
                          "clipboard_destination",
                          props.prefix + child.id
                        );
                        setContext("pre_clipboard", {
                          id: props.prefix + child.id,
                          type: "DIRECTORY",
                        });
                        setContext(
                          "clipboard_directory_is_link",
                          child.is_link || false
                        );
                      }}
                    >
                      {child.is_link ? (
                        <FolderSpecialOutlined className={classes.folderIcon} />
                      ) : (
                        <FolderOutlined className={classes.folderIcon} />
                      )}
                      <Typography
                        noWrap
                        variant={"body1"}
                        className={clsx(classes.typography)}
                      >
                        {child.name}
                      </Typography>
                    </div>
                  </LightToolTip>
                </Grow>
              ) : null}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
