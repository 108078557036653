import React from 'react';
// import {connect} from "react-redux";
// import {makeStyles} from "@material-ui/core";
import MessageDialog from "../core/dialog/MessageDialog";
import ConfirmationDialog from "../core/dialog/ConfirmationDialog";
import RequestDialog from "../core/dialog/RequestDialog";
import { setContext } from "../../redux/actions/context";
import { MESSAGE } from "../../redux/actions/message";
import { useSelector } from "react-redux";

export default function Dialogs() {
  const {
    message_dialog,
    confirmation_dialog,
    confirmation_dialog_with_key,
    request_dialog,
  } = useSelector((state) => ({
    message_dialog: state.CONTEXT.message_dialog,
    confirmation_dialog: state.CONTEXT.confirmation_dialog,
    confirmation_dialog_with_key: state.CONTEXT.confirmation_dialog_with_key,
    request_dialog: state.CONTEXT.request_dialog,
  }));

  return (
    <>
      {message_dialog.open && (
        <MessageDialog
          open={true}
          variant={message_dialog.variant}
          icon={message_dialog.icon}
          title={message_dialog.message.title}
          body={message_dialog.message.caption}
          onClose={() => {
            if (typeof message_dialog.on_close === "function") {
              message_dialog.on_close();
            }
            setContext("message_dialog", { open: false });
          }}
        />
      )}

      {confirmation_dialog.open && (
        <ConfirmationDialog
          open={true}
          variant={confirmation_dialog.variant}
          title={confirmation_dialog.message.title}
          caption={confirmation_dialog.message.caption}
          onClose={() =>
            setContext("confirmation_dialog", {
              open: false,
            })
          }
          onConfirm={() => {
            if (typeof confirmation_dialog.on_confirm === "function") {
              confirmation_dialog.on_confirm();
            } else {
              MESSAGE("Failed! Please try again.", "enqueue", "error");
            }
            setContext("confirmation_dialog", {
              open: false,
            });
          }}
        />
      )}

      {confirmation_dialog_with_key?.open && (
        <ConfirmationDialog
          open={true}
          variant={confirmation_dialog_with_key.variant}
          title={confirmation_dialog_with_key.message.title}
          caption={confirmation_dialog_with_key.message.caption}
          onClose={() =>
            setContext("confirmation_dialog_with_key", {
              open: false,
            })
          }
          key_text={confirmation_dialog_with_key.key}
          onConfirm={() => {
            if (typeof confirmation_dialog_with_key.on_confirm === "function") {
              confirmation_dialog_with_key.on_confirm();
            } else {
              MESSAGE("Failed! Please try again.", "enqueue", "error");
            }
            setContext("confirmation_dialog_with_key", {
              open: false,
            });
          }}
        />
      )}

      {request_dialog.open && (
        <RequestDialog
          open={true}
          variant={request_dialog.variant}
          title={request_dialog.message.title}
          caption={request_dialog.message.caption}
          onClose={() => setContext("request_dialog", { open: false })}
          onConfirm={() => {
            if (typeof request_dialog.on_confirm === "function") {
              request_dialog.on_confirm();
            } else {
              MESSAGE("Failed! Please try again.", "enqueue", "error");
            }
          }}
        />
      )}
    </>
  );
}
