import React from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Space from "./panel/Space";
import {store} from "../../redux";

export default function Main() {
  const { getState } = store
  const links = getState().DIRECTORY.linklist

  return (
    <>
      {links && Object.keys(links).length > 0 ? (
        <>
          {links.map((item, index) => (
            <div key={index}>
              <Typography style={{whiteSpace: 'pre-line'}} variant={"caption"}>{item.title}</Typography>
                <Space vertical space={1} />
              {item.details && Object.keys(item.details).length > 0 ? (
                  item.details.map((detailItem) => (
                      <div>
                        <Typography style={{whiteSpace: 'pre-line'}} variant={"h6"}>{detailItem.description}</Typography>
                        <Link href={item.link} target={"_blank"}>
                            <Typography onClick={() => window.open(detailItem.link)} variant={"body1"}>{detailItem.link}</Typography>
                        </Link>
                        <Space vertical space={3} />
                      </div>
                  ))
              ) : null}
            </div>
          ))}
          <Space vertical space={4} />
        </>
      ) : null}
    </>
  );
}
