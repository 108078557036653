import { store } from "../../redux";

const { getState } = store;

export default function Main() {
     const department = getState().AUTH.department;
     if (department) {
          for (let item of department) {
               if (item.is_head) return true;
          }
     }
     return false;
}
