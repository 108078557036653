import React, { useState } from "react";
import { Link, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
/* import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Vector from '../static/media/img/Vector.png' */
import InputText from "../components/core/input/InputText";
import Space from "../components/core/panel/Space";
import QASASBrand from "../static/media/img/Brand.png";
import KMD from "../static/media/img/SDMD.png";
import { RiTerminalBoxLine } from "react-icons/all";
import { env } from "../env";
import Box from "@material-ui/core/Box";
import { STAGING_LOG_IN } from "../redux/actions/admin";

export default function StagingLogin() {
  const classes = useStyles();
  const [passKey, setPassKey] = useState("");

  const mailto =
    "mailto:" +
    env.EMAIL_UDAS.toLowerCase() +
    "?" +
    "cc=" +
    env.EMAIL_KMD.toLowerCase();

  const login = async () => {
    await STAGING_LOG_IN(
      passKey,
      () => { },
      () => { }
    );
  };
  const onKeyDown = (event) => {
    if (event.key === "Enter") login();
  };

  return (
    <div className={classes.root}>
      <div className={classes.topHeader} />
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <img src={QASASBrand} alt={"QASAS Brand"} className={classes.brand} />
          <img src={KMD} alt={"KMD"} className={classes.brand} />
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.dialog}>
          <div className={classes.container}>
            <RiTerminalBoxLine size={70} className={classes.icon} />
            <Space vertical space={5} />
            <Typography variant={"h5"}>STAGING SITE</Typography>
            <Space vertical space={5} />
            <InputText
              name={"pass_key"}
              value={passKey}
              onChange={(e) => setPassKey(e.target.value)}
              onKeyDown={onKeyDown}
              type={"password"}
              placeholder={"Enter PASS KEY then press ENTER"}
              className={classes.inputText}
              style={{
                textAlign: "center",
              }}
            />
            <Space vertical space={5} />
            <Typography variant={"body1"} align={"center"}>
              This site is now intended for the staging of this application.
              From now on, please visit the main site through
            </Typography>
            <Space vertical space={3} />
            <Link href={env.URL_UDAS}>
              <Typography variant={"h5"} align={"center"}>
                <Box>{env.URL_UDAS}</Box>
              </Typography>
            </Link>
            <Space vertical space={5} />
            <Typography variant={"body1"} align={"center"}>
              If you have concerns, you may reach us through
            </Typography>
            <Link href={mailto} target={"_blank"} rel={"noopener noreferrer"}>
              <Typography variant={"h6"} align={"center"} color={"primary"}>
                {env.EMAIL_UDAS.toLowerCase()}
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    minHeight: "calc(718px + 100px)",
    width: "100%",
    background: theme.palette.grey.RGB0,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    background: "white",
  },
  headerContent: {
    width: 1160,
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topHeader: {
    background: theme.palette.primary.gradient,
    height: 30,
    width: "100%",
  },
  content: {
    width: "100%",
    minWidth: 1280,
    height: "calc(100% - 150px)",
    background: "white",
    zIndex: 200,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dialog: {
    width: 525,
  },
  container: {
    borderRadius:
      theme.shape.borderRadius + "px " + theme.shape.borderRadius + "px 0 0",
    background: "white",
    padding: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  vectorContainer: {
    display: "flex",
    alignItems: "flex-end",
    height: "100%",
  },
  vector: {
    width: "100%",
  },
  brand: {
    height: 48,
  },
  inputText: {
    width: 350,
    padding: theme.spacing(2),
    border: "1px solid " + theme.palette.grey.S3,
    borderRadius: theme.shape.borderRadius,
    background: "white",
    outline: 0,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey.S4,
  },
  flex: {
    display: "flex",
  },
  flexAlignItemsCenter: {
    alignItems: "center",
  },
  flexSpaceBetween: {
    justifyContent: "space-between",
  },
}));
