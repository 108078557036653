import React from "react";
import { makeStyles } from "@material-ui/core";
import {
     FolderOutlined,
     FolderSharedOutlined,
     FolderSpecialOutlined,
     ShowChart,
} from "@material-ui/icons";
import clsx from "clsx";
import Space from "../panel/Space";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import { setContext } from "../../../redux/actions/context";
import { GET_DIRECTORY_DETAILS } from "../../../redux/actions/directory";

const useStyles = makeStyles((theme) => ({
     root: {
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          paddingRight: theme.spacing(3),
          paddingLeft: theme.spacing(1),
          display: "flex",
          transition: "0.5 ease-in-out",
          // width: '100vw',

          "&:hover": {
               background: theme.palette.grey.RGB0,
          },
     },
     directory: {
          display: "flex",
          "&:hover": {
               cursor: "pointer",
               color: theme.palette.primary.main,
               fontWeight: "bold",
          },
     },
     active: {
          borderLeft: "solid 5px " + theme.palette.primary.main,
          paddingLeft: 3,
          paddingRight: theme.spacing(3),
          background: theme.palette.grey.RGB0,
          // borderRadius: '0 ' + theme.shape.borderRadius + 'px ' + theme.shape.borderRadius + 'px 0'
     },
     activeIconText: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
     },
     activeText: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
     },
     activeIcon: {
          color: theme.palette.secondary.main,
     },
     expandToggle: {
          width: 24,
          height: 24,
          "&:hover": {
               cursor: "pointer",
               color: theme.palette.primary.main,
               fontWeight: "bold",
          },
          transition: "0.25s ease-in-out",
     },
     expandToggleExpanded: {
          transform: "rotate(90deg)",
     },
     text: {
          marginTop: 3,
          fontWeight: "bold",
          overflow: "hidden",
          textOverflow: "ellipsis",
          paddingRight: theme.spacing(3),
     },
     icon: {
          marginTop: 0,
     },
}));

export default function Main(props) {
     const classes = useStyles();
     const dir = `${props.class.props.CONTEXT.active_directory.substring(
          0,
          3
     )}${props.class.props.DIRECTORY.details.ancestry &&
               props.class.props.DIRECTORY.details.ancestry.length > 0
               ? props.class.props.DIRECTORY.details.ancestry[0].id
               : 0
          }`;
     const activeFlag = props.id === dir ? true : props.id === props.class.props.CONTEXT.active_directory;

     let LevelSpace = [];
     for (let i = 0; i < props.level; i++) {
          LevelSpace.push(<Space space={3 - 1} />);
     }

     return (
          <Collapse in>
               <div
                    className={clsx(
                         classes.root,
                         activeFlag ? classes.active : null
                    )}
               >
                    {/* INDENTION */}
                    {LevelSpace.map((space, index) => (
                         <div key={index}>{space}</div>
                    ))}

                    {/* EXPAND TOGGLE */}
                    {/*{props.hasChildren ?*/}
                    {/*    <ChevronRightOutlined*/}
                    {/*        className={clsx(*/}
                    {/*            classes.expandToggle,*/}
                    {/*            activeFlag ? classes.activeText : null,*/}
                    {/*            props.expanded.includes(props.id) ?*/}
                    {/*                classes.expandToggleExpanded : null*/}
                    {/*        )}*/}
                    {/*        onClick={() => {*/}
                    {/*            TOGGLE_EXPAND_DIRECTORY(props.id)*/}

                    {/*        }}*/}
                    {/*    /> : <Space space={3}/>*/}
                    {/*}*/}

                    {/*<Space space={1}/>*/}

                    {/* LABEL */}
                    <div
                         className={clsx(
                              classes.directory,
                              activeFlag ? classes.activeIconText : null
                         )}
                         onClick={async () => {
                              await setContext("search_key", "");
                              await setContext("search_mode", false);
                              if (props.onClick) props.onClick();
                              else GET_DIRECTORY_DETAILS(props.id);
                         }}
                    // onContextMenu={(e) => {
                    //     e.preventDefault()
                    //     e.persist()
                    //     SET_CONTEXT('directoryX', e.pageX)
                    //     SET_CONTEXT('directoryY', e.pageY)
                    //     SET_CONTEXT('directory_view_X', e.pageX)
                    //     SET_CONTEXT('directory_view_Y', e.pageY)
                    //     SET_CONTEXT('show_context_menu',true)
                    //     SET_CONTEXT(
                    //         'clipboard_destination',
                    //         props.id
                    //     )
                    //     SET_CONTEXT('pre_clipboard',{
                    //         id: props.id,
                    //         type: 'DIRECTORY'
                    //     })
                    // }}
                    >
                         {/* ICON */}
                         {props.icon === "special" ? (
                              <FolderSpecialOutlined
                                   className={clsx(classes.icon)}
                              />
                         ) : props.icon === "shared" ? (
                              <FolderSharedOutlined
                                   className={clsx(classes.icon)}
                              />
                         ) : props.icon === "reports" ? (
                              <ShowChart
                                   className={clsx(classes.icon)}
                              />
                         ) : (
                              <FolderOutlined className={clsx(classes.icon)} />
                         )}

                         <Space space={1.5} />

                         {/* LABEL */}
                         <Typography
                              noWrap={props.level !== 1}
                              variant={"body2"}
                              className={clsx(classes.text)}
                         >
                              {" "}
                              {props.label}{" "}
                         </Typography>
                    </div>
               </div>
          </Collapse>
     );
}
