const API = 'https://udas-admin.usep.edu.ph/'

// const API_LOCAL = "http://172.16.210.15:8000/";   
// const API_IP = "http://172.16.210.89:8000/";
// const API = "http://172.16.210.10:8000/";

export const env = {
   URL_API: API + 'api/',
   // URL_API: API_LOCAL + "api/",
   URL_REPOSITORY: API + "repository/",
   URL_UDAS: "http://udas.usep.edu.ph/",
   URL_HRIS: "http://hris.usep.edu.ph/",

   // IMPORTANT for CONTACTS
   EMAIL_UDAS: "udas@usep.edu.ph",
   EMAIL_KMD: "sdmd@usep.edu.ph",

   // DEV MODE
   DEBUG: true,
   STAGING: false,

   // SYSTEM NOTICE
   SYSTEM_UPGRADE_NOTICE: false,
   SYSTEM_UPGRADE_NOTICE_DATE: "April 20, 2020",
   SYSTEM_UPGRADE_NOTICE_TIME: "12:00 AM",
   SYSTEM_MAINTENANCE_NOTICE: false,
   SYSTEM_MAINTENANCE_NOTICE_DATE: "April 20, 2020",
   SYSTEM_MAINTENANCE_NOTICE_TIME: "12:00 AM",

   // SYSTEM MODES
   SYSTEM_UPGRADE_MODE: false,
   SYSTEM_MAINTENANCE_MODE: false,

   DIRECTORY_DETAILS: {
      // CARS
      16110: {
         AACCUP_link: "",
         PQA_link: ""
      },

      // CAEc
      6276: {
         AACCUP_link: "",
         PQA_link: ""
      },

      // CAS
      6279: {
         AACCUP_link: "",
         PQA_link: "http://bit.ly/PQA-DRYRUN"
      },

      // CBA
      6274: {
         AACCUP_link: "",
         PQA_link: ""
      },

      // CDM
      6287: {
         AACCUP_link: "",
         PQA_link: ""
      },

      // CEd
      6303: {
         AACCUP_link: "",
         PQA_link: "http://bit.ly/CEDPQA"
      },

      // CoE
      634: {
         AACCUP_link: "",
         PQA_link: ""
      },

      // CIC
      7: {
         AACCUP_link: "",
         PQA_link: ""
      },

      // CTET
      6241: {
         // 16793
         AACCUP_link: "",
         PQA_link: ""
      },

      // CT
      6235: {
         AACCUP_link: "",
         PQA_link: "http://bit.ly/ctPqa"
      }
   },

   DIRECTORY_LINKS: [
      {
         directory_ids: [645, 236785],
         caption: "USeP-CIC Laboratories and Facilities Virtual Tour",
         link:
            "https://drive.google.com/file/d/1TlUy59c7z5MK61jezbVBY6KL6mzr22th/view?usp=sharing"
      },
      {
         directory_ids: [645, 236785],
         caption: "University Clinic Virtual Tour",
         link:
            "https://drive.google.com/file/d/13Kz-GDhTwNd-Dc4KDCqpJOG6c8-yGzQj/view?usp=sharing"
      },
      {
         directory_ids: [645, 236785],
         caption: "USeP Infrastructure Projects",
         link: "https://www.youtube.com/watch?v=GDyWK_F_17E"
      },
      {
         directory_ids: [270805],
         caption: "Google Drive for other pertinent documents.",
         link:
            "https://drive.google.com/drive/folders/19Ggs-c2oje-lqc15_12MV5DLdX_V0_yf?usp=sharing"
      }
   ]

};
export default env;