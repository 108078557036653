import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Line from "../decoration/Line";
import getFileIcon from "../../../utils/GET_FILE_ICON";
import { GET_DOCUMENT } from "../../../redux/actions/document";
import { setToggle } from "../../../redux/actions/context";

const useStyles = makeStyles((theme) => ({
   root: {
      width: "100%",
      marginBottom: theme.spacing(4),
   },
   documentContainer: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
   },
   documentBoxContainer: {
      width: 232,
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      border: "solid 1px " + theme.palette.grey.S2,
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
         cursor: "pointer",
      },
   },
   filetypeIconContainer: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(6),
   },
   typography: {
      lineHeight: "normal",
      color: theme.palette.grey.S4,
      textAlign: "center",
   },
   filetypeIcon: {
      height: 70,
   },
   title: {
      padding: theme.spacing(2),
   },
}));

export default function Main(props) {
   const classes = useStyles();

   return (
      <div className={classes.root}>
         <Typography
            noWrap
            variant={"caption"}
            className={clsx(classes.typography)}
         >
            {" "}
            {props.group}{" "}
         </Typography>
         <Grid container spacing={0}>
            {props.documents.map((doc, index) => (
               <div>
                  {props.group === doc.department ? (
                     <Grid item key={index}>
                        <div
                           className={classes.documentBoxContainer}
                           onClick={async () => {
                              await GET_DOCUMENT(doc.docdir_id);
                              setToggle("view_document", true);
                           }}
                        >
                           <div className={classes.filetypeIconContainer}>
                              <img
                                 src={getFileIcon(
                                    doc.attachments.length > 0
                                       ? doc.attachments[0]
                                       : ""
                                 )}
                                 alt={"Filetype icon"}
                                 className={classes.filetypeIcon}
                              />
                           </div>
                           <Line horizontal width={"100%"} />
                           <Typography
                              noWrap
                              variant={"body1"}
                              className={clsx(
                                 classes.typography,
                                 classes.title
                              )}
                           >
                              {doc.title}
                           </Typography>
                        </div>
                     </Grid>
                  ) : null}
               </div>
            ))}
         </Grid>
      </div>
   );
}
