import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import InputText from "../core/input/InputText";
import InputTextSuggestion from "../core/input/InputTextSuggestion";
import InputTextArea from "../core/input/InputTextArea";
import Space from "../core/panel/Space";
import { AddCircleOutline } from "@material-ui/icons";
import Dropzone from "../core/input/Dropzone";
import clsx from "clsx";
import ChipList from "../core/chip/ChipList";
import InputSelect from "../core/input/InputSelect";
import Grid from "@material-ui/core/Grid";
import { setForm } from "../../redux/actions/context";
import { GET_SUGGESTION } from "../../redux/actions/suggestion";
import LightToolTip from "../core/tooltip/LightToolTip";
import getFileIcon from "../../utils/GET_FILE_ICON";
import { useSelector } from "react-redux";

export default function Main(props) {
  const classes = useStyles();
  const theme = useTheme();
  const form = props.class.props.CONTEXT.forms.document;
  const upload_filesize_limit = props.class.props.AUTH.upload_filesize_limit;
  const suggestion = props.class.props.SUGGESTION;

  const deletedAttachments = useSelector(
    (state) => state.CONTEXT.forms.document.deletedAttachments
  );

  const onDropzoneAdd = (files) => {
    setForm("document", "attachments", [
      ...props.class.props.CONTEXT.forms.document.attachments,
      ...files,
    ]);
  };

  const onRemoveFile = (file) => {
    if (file.id)
      setForm("document", "deletedAttachments", [
        ...deletedAttachments,
        file.id,
      ]);
    setForm(
      "document",
      "attachments",
      props.class.props.CONTEXT.forms.document.attachments.filter(
        (i) => i !== file
      )
    );
  };

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <div className={classes.flexColumn} style={{ paddingRight: 24 }}>
            <div className={classes.groupControl}>
              <div className={classes.flexAlignItemsCenter}>
                <Typography variant={"body1"}>What is the title?</Typography>
                <Space space={1} />
                <LightToolTip title={"Required field"} placement={"right"}>
                  <Typography
                    variant={"body1"}
                    color={"primary"}
                    className={classes.badge}
                  >
                    *
                  </Typography>
                </LightToolTip>
              </div>
              <Space vertical space={2} />
              <InputText
                name={"title"}
                value={form.title}
                placeholder={`Ex. USeP's Vision, Mission, and Goals`}
                // maxlength={100}
                error={form.error_title}
                onChange={(event) =>
                  setForm("document", event.target.name, event.target.value)
                }
              />
            </div>
            <div className={classes.groupControl}>
              <div className={classes.flexAlignItemsCenter}>
                <Typography variant={"body1"}>What is the type?</Typography>
                <Space space={1} />
                <LightToolTip title={"Required field"} placement={"right"}>
                  <Typography
                    variant={"body1"}
                    color={"primary"}
                    className={classes.badge}
                  >
                    *
                  </Typography>
                </LightToolTip>
              </div>
              <Space vertical space={2} />
              <InputSelect
                menuItem={suggestion.types}
                value={form.type}
                error={form.error_type}
                onChange={(e) => {
                  setForm("document", "type", e.target.value);
                }}
              />
            </div>
            <div className={clsx(classes.groupControl)}>
              <Typography variant={"body1"}>
                What is the description?
              </Typography>
              <Space vertical space={2} />
              <InputTextArea
                name={"description"}
                rows={5}
                value={form.description}
                placeholder={""}
                maxlength={200}
                onChange={(event) =>
                  setForm("document", event.target.name, event.target.value)
                }
              />
            </div>
            <div className={classes.groupControl}>
              <div className={classes.flexAlignItemsCenterSpaceBetween}>
                <Typography variant={"body1"}>
                  What are the keywords?
                </Typography>
                <Space space={2} />
                <AddCircleOutline
                  className={classes.icon}
                  onClick={props.class.onKeywordsAdd}
                />
              </div>
              <Space vertical space={2} />
              <div className={classes.flexAlignItemsCenter}>
                <InputTextSuggestion
                  name={"keyword"}
                  items={suggestion.keywords}
                  value={form.keyword}
                  maxlength={20}
                  type={"document"}
                  placeholder={"Keyword i.e. Vision, Mission, Goals, ..."}
                  onChange={setForm}
                  onSelect={setForm}
                  onKeyDown={props.class.onKeywordsAdd}
                  callback={() => {
                    GET_SUGGESTION(
                      "keyword",
                      props.class.props.CONTEXT.forms.document.keyword
                    );
                  }}
                />
              </div>
              <ChipList
                list={form.keywords}
                handleDelete={(item) => {
                  setForm(
                    "document",
                    "keywords",
                    form.keywords.filter((i) => i !== item)
                  );
                }}
              />
            </div>
            <div className={classes.groupControl}>
              <div className={classes.flexAlignItemsCenterSpaceBetween}>
                <Typography variant={"body1"}>
                  What are the additional attributes?
                </Typography>
                <Space space={2} />
                <AddCircleOutline
                  className={classes.icon}
                  onClick={props.class.onAttributesAdd}
                />
              </div>
              <Space vertical space={2} />
              <div className={classes.flexAlignItemsCenter}>
                <InputTextSuggestion
                  name={"attribute_key"}
                  items={suggestion.attribute_keys}
                  value={form.attribute_key}
                  maxlength={50}
                  type={"document"}
                  placeholder={"Identifier i.e. Year"}
                  onChange={setForm}
                  onSelect={setForm}
                  onKeyDown={props.class.onAttributesAdd}
                  callback={() => {
                    GET_SUGGESTION(
                      "attribute_key",
                      props.class.props.CONTEXT.forms.document.attribute_key
                    );
                  }}
                />
                <Space space={2} />
                <InputTextSuggestion
                  name={"attribute_value"}
                  items={suggestion.attribute_values}
                  value={form.attribute_value}
                  maxlength={50}
                  type={"document"}
                  placeholder={"Value i.e. " + new Date().getFullYear()}
                  onChange={setForm}
                  onSelect={setForm}
                  onKeyDown={props.class.onAttributesAdd}
                  callback={() => {
                    GET_SUGGESTION(
                      "attribute_value",
                      props.class.props.CONTEXT.forms.document.attribute_value
                    );
                  }}
                />
              </div>
              <ChipList
                object
                list={form.attributes}
                handleDelete={(item) => {
                  setForm(
                    "document",
                    "attributes",
                    form.attributes.filter((i) => i !== item)
                  );
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={7}>
          <div className={classes.flexColumn} style={{ paddingLeft: 24 }}>
            <div className={clsx(classes.groupControl)}>
              <div className={classes.flexAlignItemsCenter}>
                <Typography variant={"body1"}>
                  What are the file attachments?
                </Typography>
                <Space space={1} />
                <LightToolTip title={"Required field"} placement={"right"}>
                  <Typography
                    variant={"body1"}
                    color={"primary"}
                    className={classes.badge}
                  >
                    *
                  </Typography>
                </LightToolTip>
                {/*<Space space={3} />*/}
                {/*{props.mode === "UPDATE" ? (*/}
                {/*   <Typography*/}
                {/*      variant={"body1"}*/}
                {/*      style={{*/}
                {/*         color: theme.palette.primary.main*/}
                {/*      }}*/}
                {/*   >*/}
                {/*      <em>*/}
                {/*         Add new file to replace current attachment*/}
                {/*      </em>*/}
                {/*   </Typography>*/}
                {/*) : null}*/}
              </div>
              <Space vertical space={2} />
              <Dropzone
                onDropzoneAdd={onDropzoneAdd}
                fileSizeLimit={upload_filesize_limit}
                error={form.error_attachment}
                onRemoveFile={onRemoveFile}
                attachments={form.attachments}
              />
              <Grid
                container
                spacing={5}
                style={{
                  paddingTop: 36,
                  paddingRight: theme.spacing(8),
                }}
              >
                {form.attachments.map((file, index) => (
                  <Grid item key={index} md={6} lg={4}>
                    <div
                      style={{
                        textAlign: "center",
                        maxWidth: 300,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={getFileIcon(file.path || file.name)}
                        alt={"File icon"}
                        style={{
                          height: 100,
                        }}
                      />
                      <Space vertical space={3} />
                      <Typography
                        // noWrap
                        variant={"body1"}
                      >
                        {file.path || file.name}
                      </Typography>
                      <Space vertical space={2} />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {file.path
                          ? `${(file.size / 1000000).toFixed(2)} MB`
                          : file.size}

                        <div
                          style={{
                            margin: "0 14px",
                          }}
                        >
                          |
                        </div>
                        <div
                          onClick={() => onRemoveFile(file)}
                          style={{
                            color: theme.palette.error.main,
                            cursor: "pointer",
                          }}
                        >
                          Remove
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexAlignItemsCenter: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  flexAlignItemsCenterSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // width: '100%'
  },
  groupControl: {
    // marginRight: theme.spacing(8),
    marginBottom: theme.spacing(6),
    minWidth: 300,
    width: "100%",
  },
  icon: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  fixedWidth300: {
    width: 300,
  },
  badge: {
    // paddingLeft: theme.spacing(0.75),
    // paddingRight: theme.spacing(0.75),
    // paddingTop: theme.spacing(0.1),
    // paddingBottom: theme.spacing(0.1),
    // borderRadius: theme.shape.borderRadius,
    // background: theme.palette.warning.main,
    // color: 'white',

    color: theme.palette.error.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
