import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import Autocomplete from "react-autocomplete";
import Space from "../panel/Space";
import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { isFunction } from "../../../utils/function";

const useStyles = makeStyles((theme) => ({
   root: {
      border: "1px solid " + theme.palette.grey.S3,
      borderRadius: theme.shape.borderRadius,
      background: "white",
      width: "100%",
      display: "flex",
      alignItems: "center",
   },
   active: {
      border: "1px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
      fontWeight: "bold",
   },
   item: {
      padding: 14,
   },
   icon: {
      marginTop: "-5px",
      marginBottom: "-5px",
   },
   input: {
      outline: 0,
      border: "none",
      background: "transparent",
      fontSize: 16,
      width: "calc(100% - 24px)",
      padding: 0,
      margin: theme.spacing(1.5),
      color: theme.palette.grey.S4,
      "&::placeholder": {
         color: theme.palette.grey.S3,
      },
   },
   error: {
      border: "1px solid " + theme.palette.error.main,
   },
   errorMessage: {
      color: theme.palette.error.main,
   },
}));

export default function Main(props) {
   const classes = useStyles();
   const theme = useTheme();

   let { items } = props;

   const menuStyle = {
      borderRadius: "3px",
      boxShadow: theme.shadows[3],
      background: "white",
      fontSize: theme.typography.body1.fontSize,
      position: "absolute",
      overflow: "auto",
      maxHeight: 300,
      maxWidth: "none",
      marginLeft: "-24px",
      marginTop: 12,
      paddingTop: items.length > 0 ? 5 : 0,
      paddingBottom: items.length > 0 ? 5 : 0,
      zIndex: 1001,
   };

   const onKeyDown = (event) => {
      if (event.key === "Enter" && props.onKeyDown) props.onKeyDown();
   };

   items = items.filter((i) => i.name !== "");
   if (props.value) {
      items = items.filter((i) =>
         i.name.toLowerCase().includes(props.value.toLowerCase())
      );
   }
   console.log({ items: items });

   return (
      <div>
         <div
            className={clsx(classes.root, props.error ? classes.error : null)}
         >
            <div style={{ width: "100%" }}>
               <Autocomplete
                  getItemValue={(item) => item.name}
                  items={items}
                  renderItem={(item, isHighlighted) => (
                     <div
                        key={item}
                        className={classes.item}
                        style={{
                           paddingTop: 8,
                           paddingBottom: 8,
                           overflow: "hidden",
                           textOverflow: "ellipsis",
                           color: theme.palette.grey.S4,
                           background: isHighlighted
                              ? theme.palette.grey.S1
                              : "white",
                        }}
                     >
                        {item.name}
                     </div>
                  )}
                  inputProps={{
                     placeholder: props.placeholder,
                     type: props.type,
                     className: classes.input,
                     maxLength: props.maxlength,
                     onKeyDown: onKeyDown,
                  }}
                  value={props.value}
                  onChange={async (event) => {
                     let name = event.target.value;
                     let id = 0;
                     await props.onChange(props.type, props.name, { id, name });
                     if (isFunction(props.callback)) await props.callback();
                  }}
                  onSelect={async (name) => {
                     let id = 0;

                     for (let user of items)
                        if (user.name === name) id = user.id;

                     if (props.multiple) await props.onSelect(id, name);
                     else
                        await props.onSelect(props.type, props.name, {
                           id,
                           name,
                        });
                     if (isFunction(props.callback)) await props.callback();
                  }}
                  menuStyle={menuStyle}
                  wrapperStyle={{
                     display: "block",
                  }}
               />
            </div>
            {props.variant === "dropdown" ? (
               <div>
                  <Space space={1} />
                  <KeyboardArrowDownOutlined className={classes.icon} />
               </div>
            ) : null}
         </div>
         {props.error ? (
            <div>
               <Space vertical space={1} />
               <Typography variant={"caption"} className={classes.errorMessage}>
                  {props.error}
               </Typography>
            </div>
         ) : null}
      </div>
   );
}
