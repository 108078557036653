import React, { Component } from "react";
import { connect } from "react-redux";
// import { makeStyles } from "@material-ui/core";
import STATES from "../redux/states";
import DataTable from "../components/core/table/DataTable";

/* const useStyles = makeStyles(theme => ({
    root: {
        height: "auto",
        width: "100%",
        display: "flex"
    },
})); */


const Body = (PROPS) => {

    // const CLASSES = useStyles();
    const CLASS = PROPS.class;

    const COLUMNS = [
        { field: 'id', headerName: 'ID', width: 100 },
        {
            field: 'action',
            headerName: 'ACTIONS',
            width: 200,
            editable: false,
        },
        {
            field: 'department',
            headerName: 'DEPARTMENT',
            width: 200,
            editable: false,
        },
        {
            field: 'document',
            headerName: 'DOCUMENT',
            width: 175,
            editable: false,
        },
        {
            field: 'description',
            headerName: 'DESCRIPTION',
            width: 275,
            editable: false,
        },
        {
            field: 'user',
            headerName: 'User',
            width: 270,
            editable: false,
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 350,
            editable: false,
        },

    ];

    let ROWS = [];
    if (CLASS.props.CONTEXT.toggles.report_loader === false && typeof CLASS.props.DOCUMENT.document_monitoring.log !== 'undefined') {
        ROWS = CLASS.props.DOCUMENT.document_monitoring.log.map(function (data, index) {
            const formattedDate = new Date(data.date).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'Asia/Manila' });
            return { id: index + 1, action: data.action, description: data.description, department: data.department, date: formattedDate, document: data.document, user: data.user }
        });
    }
    return (
        <DataTable
            COLUMNS={COLUMNS}
            ROWS={ROWS}
            pages={20}
        />
    );
};

class CreateNew extends Component {

    render() {
        return <Body class={this} />;
    }
}

export default connect(STATES, null)(CreateNew);
