import { CircularProgress, Dialog } from "@material-ui/core";
import { useSelector } from "react-redux";
import { TransitionSlideUp } from "../components/core/transition/TransitionSlideLeft";
import React from "react";
import clsx from "clsx";
import LightToolTip from "../components/core/tooltip/LightToolTip";
import {
   ClearOutlined,
   FormatListBulletedOutlined,
   GridOnOutlined,
} from "@material-ui/icons";
import Space from "../components/core/panel/Space";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { setToggle } from "../redux/actions/context";
import DirectoryTrashBox from "../components/core/directory/DirectoryTrashBox";
import DocumentTrashBox from "../components/core/document/DocumentTrashBox";
import {
   apiDeleteAllTrash,
   // apiDeleteDocumentPermanently,
   apiGetOfficeTrash,
   apiRestoreAllTrash,
} from "../api/trash";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import RefreshIcon from "@material-ui/icons/Refresh";
import Line from "../components/core/decoration/Line";
import ContextMenuDirectoryTrash from "../components/core/context_menu/ContextMenuDirectoryTrash";
import ContextMenuDocumentTrash from "../components/core/context_menu/ContextMenuDocumentTrash";
import { SEND_CONFIRMATION_DIALOG_WITH_KEY } from "../redux/actions/dialogs";
import { MESSAGE } from "../redux/actions/message";

export default function OfficeTrashBin() {
   const styles = style();
   const { openTrashBin, trash, loading_office_trash, listView, directory_details } = useSelector(
      (state) => ({
         openTrashBin: state.CONTEXT.toggles.openTrashBin,
         trash: state.TRASH,
         listView: state.CONTEXT.toggles.directory_list_view || false,
         loading_office_trash: state.CONTEXT.toggles.loading_office_trash,
         directory_details: state.DIRECTORY.details,
      })
   );

   const toggleListView = () => {
      setToggle("directory_list_view", !listView);
   };

   const restoreAll = () => {
      SEND_CONFIRMATION_DIALOG_WITH_KEY(
         "warning",
         "warning",
         "Restore Confirmation",
         "Upon confirmation, all directories and documents in your trash will be restored.",
         "RESTORE ALL",
         async () => {
            MESSAGE("Restoring all...", "enqueue", "info");
            const response = await apiRestoreAllTrash();
            MESSAGE("Restoring all...");
            if (response.ok)
               MESSAGE("All trash restored", "enqueue", "success");
            else MESSAGE("Failed to restore", "enqueue", "error");
         }
      );
   };

   const deleteAll = () => {
      SEND_CONFIRMATION_DIALOG_WITH_KEY(
         "error",
         "error",
         "Delete Confirmation",
         "Upon confirmation, all directories and documents in your trash will be removed PERMANENTLY. Once done, there is no way to undo it.",
         "DELETE ALL PERMANENTLY",
         async () => {
            MESSAGE("Deleting all...", "enqueue", "info");
            const response = await apiDeleteAllTrash();
            MESSAGE("Deleting all...");
            if (response.ok)
               MESSAGE("All trash deleted permanently", "enqueue", "success");
            else MESSAGE("Failed to delete", "enqueue", "error");
         }
      );
   };

   const isTrashEmpty =
      trash.directory.length === 0 && trash.document.length === 0;

   return (
      <Dialog
         fullScreen
         open={openTrashBin}
         TransitionComponent={TransitionSlideUp}
      >
         <div className={clsx(styles.container)}>
            <div className={clsx(styles.innerContainer, styles.scrollbar)}>
               <div className={styles.heading}>
                  <LightToolTip title="Close" placement="bottom">
                     <ClearOutlined
                        className={styles.icon}
                        onClick={() => setToggle("openTrashBin", false)}
                     />
                  </LightToolTip>
                  <Space space={5} />
                  <div className={styles.header}>
                     <div>
                        <Typography>OFFICE TRASH BIN</Typography>
                        <Typography variant={"h5"}>
                           {directory_details.name}
                        </Typography>
                     </div>
                     <div className={styles.headerRight}>
                        {loading_office_trash && (
                           <>
                              <CircularProgress color={"primary"} size={24} />
                              <Space space={5} />
                           </>
                        )}
                        <LightToolTip title={"Refresh"}>
                           <RefreshIcon onClick={() => apiGetOfficeTrash()} />
                        </LightToolTip>
                        <Space space={2} />
                           <LightToolTip title={`Change view`} placement="bottom">
                              {listView ? (
                                 <FormatListBulletedOutlined
                                    onClick={toggleListView}
                                 />
                              ) : (
                                 <GridOnOutlined onClick={toggleListView} />
                              )}
                           </LightToolTip>
                        <Space space={3} />
                        <Line height={24} />
                        <Space space={3} />
                        <LightToolTip title={"Restore All"}>
                           <RestoreFromTrashIcon
                              onClick={isTrashEmpty ? null : restoreAll}
                           />
                        </LightToolTip>
                        <Space space={2} />
                        <LightToolTip title={"Empty Trash Bin"}>
                           <DeleteForeverIcon
                              color={"error"}
                              onClick={isTrashEmpty ? null : deleteAll}
                           />
                        </LightToolTip>
                     </div>
                  </div>
               </div>
               <Space vertical space={8} />
               <div className={styles.body}>
                  {trash.directory.length === 0 &&
                     trash.document.length === 0 ? (
                     <>
                        <Typography>Empty Trash Bin</Typography>
                     </>
                  ) : (
                     <>
                        {trash.directory?.length > 0 && (
                           <div className={styles.section}>
                              <DirectoryTrashBox trash={trash.directory} />
                           </div>
                        )}
                        {trash.document?.length > 0 && (
                           <div className={styles.section}>
                              <DocumentTrashBox trash={trash.document} />
                           </div>
                        )}
                     </>
                  )}
               </div>
            </div>
         </div>

         <ContextMenuDirectoryTrash />
         <ContextMenuDocumentTrash />
      </Dialog>
   );
}

const style = makeStyles((theme) => ({
   container: {
      height: "calc(100vh - 18px)",
      minWidth: 1280,
   },
   innerContainer: {
      paddingLeft: theme.spacing(6),
      paddingTop: theme.spacing(6),
      marginRight: 3,
      height: "calc(100vh - 48px)",
   },
   icon: {
      "&:hover": {
         color: theme.palette.primary.main,
         cursor: "pointer",
      },
   },
   heading: {
      display: "flex",
      color: theme.palette.grey.S4,
   },
   header: {
      display: "flex",
      justifyContent: "space-between",
      flex: 1,
   },
   headerRight: {
      display: "flex",
      paddingRight: theme.spacing(3),
   },
   body: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
   },
   scrollbar: {
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
         width: 8,
         height: 8,
         backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
         borderRadius: 10,
         opacity: 1,
         backgroundColor: theme.palette.grey.S2,
      },
   },
   section: {},
}));
