import React from 'react'
import { useTheme } from '@material-ui/core/styles';

export default function Main(props) {
    const theme = useTheme();
    return (
        <div>
            {
                props.vertical ?
                    <div style={{ height: theme.spacing(props.space) }}></div> :
                    <div style={{ width: theme.spacing(props.space) }}></div>
            }
        </div>
    )
}