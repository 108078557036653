import { dispatch } from "../index";
import { TRASH_RESET, TRASH_SET } from "./types";

export const setOfficeTrash = (payload) => {
   dispatch({
      type: TRASH_SET,
      payload,
   });
};

export const resetOfficeTrash = () => {
   dispatch({
      type: TRASH_RESET,
   });
};
