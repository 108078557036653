import { combineReducers } from "redux";

import AUTH from "./auth";
import DIRECTORY from "./directory";
import DOCUMENT from "./document";
import DEPARTMENT from "./department";
import CONTEXT from "./context";
import MESSAGE from "./message";
import SUGGESTION from "./suggestion";
import ADMIN from "./admin";
import TRASH from "./trash";

export default combineReducers({
   AUTH,
   DOCUMENT,
   DIRECTORY,
   DEPARTMENT,
   CONTEXT,
   MESSAGE,
   SUGGESTION,
   ADMIN,
   TRASH,
});
