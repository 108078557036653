import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import Admin from "./pages/admin/Admin";
import LandingPage from "./pages/LandingPage";

export const routes = () => {
  return (
    <Switch>
      <Route path={"/dir/:directory_id"} component={Home} />
      <Route path={"/doc/:directory_id"} component={Home} />
      <Route path={"/dir/create"} component={Home} />
      <Route path={"/doc/create"} component={Home} />
      <Route path={"/dir/edit"} component={Home} />
      <Route path={"/doc/edit"} component={Home} />
      <Route path={"/login"} component={Login} />
      <Route path={"/admin"} component={Admin} />
      <Route path={"/"} component={LandingPage} />
      {/* <Route exact path={"/"} component={Home} /> */}
      <Route path={"/"} component={PageNotFound} />
    </Switch>
  );
};

export default routes;
