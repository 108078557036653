import React, { Component } from 'react';
import { connect } from "react-redux";
// import {createMuiTheme, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Space from "../../../components/core/panel/Space";
// import Line from "../../../components/core/decoration/Line";
import MUIDataTable from "mui-datatables";
import { setContext, setToggle } from "../../../redux/actions/context";
import OfficeUsers from "./OfficeUsers";
// import { THEME } from "../../../static/styles/theme";
import { GET_DEPARTMENTS } from "../../../redux/actions/department";
import { GET_DEPARTMENT_USERS } from "../../../redux/actions/auth";

// const useStyles = makeStyles((theme) => ({}));

/* const getMuiTheme = () =>
  createMuiTheme({
    ...THEME,
    overrides: {
      MuiPaper: {
        root: {
          boxShadow: "none !important",
        },
      },
      MuiToolbar: {
        root: {
          padding: "0 !important",
        },
      },
      MUIDataTablePagination: {
        tableCellContainer: {
          padding: "0 !important",
        },
      },
    },
  }); */

const Body = (props) => {
  // const classes = useStyles();

  // const [responsive, setResponsive] = useState("standard");
  // const [tableBodyHeight, setTableBodyHeight] = useState("500px");
  // const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");

  const onRowClick = (data) => {
    GET_DEPARTMENT_USERS(data[0]).then(() => {
      setContext("adminViewOfficeData", data);
      setToggle("adminViewOffice", true);
    });
  };

  const options = {
    filter: true,
    filterType: "multiselect",
    selectableRows: "none",
    // responsive,
    tableBodyHeight: 600,
    // tableBodyMaxHeight,
    onRowClick,
    downloadOptions: {
      filename: "Offices",
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
  };

  const columns = [
    {
      name: "ID",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
      },
    },
    "Name",
    "Support ?",
    "Number of Users",
    "Number of Directories",
    "Number of Documents",
    "Storage Usage",
  ];

  const data = [
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
    // [12, "Knowledge Management Division", "No", "12", 435, 5325, "234 GB"],
  ];
  for (let department of props.class.props.departments) {
    data.push([
      department.id,
      department.name,
      department.is_support ? "Yes" : "No",
      department.no_of_users,
      department.no_of_dirs,
      department.no_of_docs,
      department.storage_usage + " GB",
    ]);
  }

  return (
    <div>
      {/*HEADINF*/}
      {/*<Typography*/}
      {/*    variant={'h4'}*/}
      {/*>*/}
      {/*    Offices*/}
      {/*</Typography>*/}
      {/*<Typography*/}
      {/*    variant={'body1'}*/}
      {/*>*/}
      {/*    Manage offices and their users and privileges*/}
      {/*</Typography>*/}
      {/*<Space vertical space={3}/>*/}
      {/*<Line horizontal/>*/}
      {/*<Space vertical space={3}/>*/}

      {/*<MuiThemeProvider theme={getMuiTheme()}>*/}
      <MUIDataTable
        title={
          <>
            <Typography variant={"h4"}>Offices</Typography>
            <Typography variant={"body1"}>
              Manage offices and their users and privileges
            </Typography>
            <Space vertical space={3} />
          </>
        }
        data={data}
        columns={columns}
        options={options}
      />
      {/*</MuiThemeProvider>*/}

      {props.class.props.adminViewOffice ? <OfficeUsers /> : null}
    </div>
  );
};

class Offices extends Component {
  componentDidMount() {
    GET_DEPARTMENTS();
  }

  render() {
    return <Body class={this} />;
  }
}

const mapStateToProps = (state) => ({
  adminViewOffice: state.CONTEXT.toggles.adminViewOffice,
  departments: state.ADMIN.departments,
});

export default connect(mapStateToProps, {})(Offices);
