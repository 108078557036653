import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import { SendOutlined } from "@material-ui/icons";
import Space from "../panel/Space";
import Line from "../decoration/Line";
import {
  RiClipboardLine,
  RiInformationLine,
  RiLinksLine,
  RiFolderAddLine,
  RiFileAddLine
} from "react-icons/all";
import { setContext, setForm, setToggle } from "../../../redux/actions/context";
import { IS_DIRECTORY_VIEW_CONTEXT_OPEN } from "../../../utils/context_menu";
import {
  SEND_CONFIRMATION_DIALOG,
  SEND_MESSAGE_DIALOG
} from "../../../redux/actions/dialogs";
import {
  COPY_DIRECTORY,
  CUT_DIRECTORY,
  DELETE_DIRECTORY,
  LINK_DIRECTORY
} from "../../../redux/actions/directory";
import {
  COPY_DOCUMENT,
  CUT_DOCUMENT,
  LINK_DOCUMENT,
  REQUEST_ALL_TO_PUBLICIZE
} from "../../../redux/actions/document";
import HAS_CRUD from "../../../utils/permissions/HAS_CRUD";
// import IS_ROOT_DIRECTORY from "../../../utils/directory/IS_ROOT_DIRECTORY";
import IS_HEAD from "../../../utils/permissions/IS_HEAD";
// import IN_DESCENDANCE from "../../../utils/document/IN_DESCENDANCE";
import IS_PUBLIC_REQUEST from "../../../utils/context/IS_PUBLIC_REQUEST";
import IS_SUPPORT from "../../../utils/permissions/IS_SUPPORT";
import IN_ANCESTRY from "../../../utils/directory/IN_ANCESTRY";
// import IS_MODERATOR_IN_DIRECTORY from "../../../utils/permissions/IS_MODERATOR_IN_DIRECTORY";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {},
  popoverRoot: {
    marginTop: 12
  },
  popoverPaper: {
    boxShadow: theme.shadows[3],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minWidth: 150,
    maxWidth: 300
  },
  popoverItem: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.grey.S4,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
      background: theme.palette.grey.RGB0
    }
  },
  flex: {
    display: "flex"
  }
}));

export default function ContextMenuDirectoryView(props) {
  const classes = useStyles();
  const { context, directory_details } = useSelector(state => ({
    context: {
      active_directory: state.CONTEXT.active_directory,
      clipboard: state.CONTEXT.clipboard,
      clipboard_destination: state.CONTEXT.clipboard_destination
    },
    directory_details: state.DIRECTORY.details
  }));

  const onClick = action => {
    switch (action) {
      case "new-directory":
        setContext("formRequestMode", "CREATE");
        setContext("formType", "Directory");
        setToggle("create_new", true);
        break;
      case "new-document":
        setContext("formRequestMode", "CREATE");
        setContext("formType", "Document");
        setToggle("create_new", true);
        break;
      case "paste-copy":
        if (context.clipboard.type === "DOCUMENT") {
          COPY_DOCUMENT(context.active_directory, context.clipboard.id);
        }
        break;
      case "paste-link":
        if (context.clipboard.type === "DOCUMENT") {
          LINK_DOCUMENT(context.active_directory, context.clipboard.id);
        }
        break;
      case "paste-cut":
        if (context.clipboard.type === "DOCUMENT") {
          CUT_DOCUMENT(context.active_directory, context.clipboard.id);
        }
        break;
      case "paste-directory":
        if (context.clipboard.action === "copy") {
          COPY_DIRECTORY(context.active_directory, context.clipboard.id);
        } else if (context.clipboard.action === "cut") {
          CUT_DIRECTORY(context.active_directory, context.clipboard.id);
        }
        break;
      case "paste-directory-link":
        if (context.clipboard.action === "copy") {
          LINK_DIRECTORY(context.clipboard_destination, context.clipboard.id);
        }
        break;
      case "edit":
        setForm("directory", "name", directory_details.name);
        setForm("directory", "description", directory_details.description);
        setForm("directory", "group", directory_details.group.name);
        setForm("directory", "moderators", directory_details.moderators);
        setForm("directory", "is_public", directory_details.is_public);
        setContext("formRequestMode", "UPDATE");
        setContext("formType", "Directory");
        setToggle("create_new", true);
        break;
      case "delete":
        SEND_CONFIRMATION_DIALOG(
          "error",
          "danger",
          "Delete Confirmation",
          "Upon confirmation, this directory will be removed in your directory tree. Once done, " +
          "there is no way to undo it.",
          () => {
            DELETE_DIRECTORY();
          }
        );
        break;
      case "publicize-request-all":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Request Confirmation",
          "Upon confirmation, all unpublicized documents in this directory will be requested for " +
          "public posting and will be subject for approval.",
          () => {
            REQUEST_ALL_TO_PUBLICIZE();
          }
        );
        break;
      case "publicize-request-cancel-all":
        break;
      case "unpublicize":
        break;
      case "info":
        SEND_MESSAGE_DIALOG(
          "info",
          "",
          "Coming Soon",
          "This feature is under development and will be available in the next updates.",
          () => { }
        );
        break;
      default:
        break;
    }

    props.onClose();
  };

  return (
    <div className={classes.root}>
      {IS_PUBLIC_REQUEST() ? (
        IS_DIRECTORY_VIEW_CONTEXT_OPEN() ? (
          props.onClose()
        ) : null
      ) : (
        <Popover
          id={props.id}
          open={props.open}
          onClose={props.onClose}
          anchorPosition={{ left: props.X, top: props.Y - 10 }}
          anchorReference={"anchorPosition"}
          classes={{
            root: classes.popoverRoot,
            paper: classes.popoverPaper
          }}
        >
          {HAS_CRUD() ? (
            <Fragment>
              <div
                className={classes.popoverItem}
                onClick={() => onClick("new-directory")}
              >
                <div className={classes.flex}>
                  <RiFolderAddLine size={24} />
                  <Space space={2} />
                  <Typography variant={"body1"}>New Directory</Typography>
                </div>
              </div>
              <div
                className={classes.popoverItem}
                onClick={() => onClick("new-document")}
              >
                <div className={classes.flex}>
                  <RiFileAddLine size={24} />
                  <Space space={2} />
                  <Typography variant={"body1"}>New Document</Typography>
                </div>
              </div>
              <Space vertical space={1} />
              <Line horizontal width={"100%"} />
              <Space vertical space={1} />

              {context.clipboard.type === "DOCUMENT" ? (
                <Fragment>
                  {context.clipboard.action === "copy" ? (
                    <Fragment>
                      <div
                        className={classes.popoverItem}
                        onClick={() => onClick("paste-copy")}
                      >
                        <div className={classes.flex}>
                          <RiClipboardLine size={24} />
                          <Space space={2} />
                          <Typography variant={"body1"}>
                            Paste as Copy
                          </Typography>
                        </div>
                      </div>
                      <div
                        className={classes.popoverItem}
                        onClick={() => onClick("paste-link")}
                      >
                        <div className={classes.flex}>
                          <RiLinksLine size={24} />
                          <Space space={2} />
                          <Typography variant={"body1"}>
                            Paste as Link
                          </Typography>
                        </div>
                      </div>
                    </Fragment>
                  ) : context.clipboard.action === "cut" ? (
                    <Fragment>
                      <div
                        className={classes.popoverItem}
                        onClick={() => onClick("paste-cut")}
                      >
                        <div className={classes.flex}>
                          <RiClipboardLine size={24} />
                          <Space space={2} />
                          <Typography variant={"body1"}>Paste</Typography>
                        </div>
                      </div>
                    </Fragment>
                  ) : null}

                  <Space vertical space={1} />
                  <Line horizontal width={"100%"} />
                  <Space vertical space={1} />
                </Fragment>
              ) : context.clipboard.type === "DIRECTORY" ? (
                IN_ANCESTRY(context.clipboard.id) ? null : (
                  <Fragment>
                    <div
                      className={classes.popoverItem}
                      onClick={() => onClick("paste-directory")}
                    >
                      <div className={classes.flex}>
                        <RiClipboardLine size={24} />
                        <Space space={2} />
                        <Typography variant={"body1"}>Paste</Typography>
                      </div>
                    </div>
                    {context.clipboard.action === "copy" && (
                      <div
                        className={classes.popoverItem}
                        onClick={() => onClick("paste-directory-link")}
                      >
                        <div className={classes.flex}>
                          <RiLinksLine size={24} />
                          <Space space={2} />
                          <Typography variant={"body1"}>
                            Paste as Link
                          </Typography>
                        </div>
                      </div>
                    )}
                    <Space vertical space={1} />
                    <Line horizontal width={"100%"} />
                    <Space vertical space={1} />
                  </Fragment>
                )
              ) : null}

              {/* {IS_HEAD() && !IS_SUPPORT() ? (
                <Fragment>
                  <div
                    className={classes.popoverItem}
                    onClick={() => onClick("publicize-request-all")}
                  >
                    <div className={classes.flex}>
                      <SendOutlined />
                      <Space space={2} />
                      <Typography variant={"body1"}>
                        Request All to Publicize
                      </Typography>
                    </div>
                  </div>
                  <Space vertical space={1} />
                  <Line horizontal width={"100%"} />
                  <Space vertical space={1} />
                </Fragment>
              ) : null} */}
            </Fragment>
          ) : null}
          <div className={classes.popoverItem} onClick={() => onClick("info")}>
            <div className={classes.flex}>
              <RiInformationLine size={24} />
              <Space space={2} />
              <Typography variant={"body1"}>Information</Typography>
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
}
