import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { ClearOutlined } from "@material-ui/icons";
import { TransitionSlideUp } from "../components/core/transition/TransitionSlideLeft";
import Space from "../components/core/panel/Space";
import Typography from "@material-ui/core/Typography";
import LightToolTip from "../components/core/tooltip/LightToolTip";
import Button from "../components/core/button/Button";
import FormDocument from "../components/forms/FormDocument";
import FormDirectory from "../components/forms/FormDirectory";
import {
  clearForm,
  setContext,
  setForm,
  setToggle,
} from "../redux/actions/context";
import clsx from "clsx";
import { GET_SUGGESTION } from "../redux/actions/suggestion";
import { ADD_DOCUMENT, UPDATE_DOCUMENT } from "../redux/actions/document";
import { ADD_DIRECTORY, UPDATE_DIRECTORY } from "../redux/actions/directory";
import FormDepartment from "../components/forms/FormDepartment";
import Footer from "../components/layout/Footer";
import { ADD_DEPARTMENT, UPDATE_DEPARTMENT } from "../redux/actions/department";
import STATES from "../redux/states";
import IS_SUPPORT from "../utils/permissions/IS_SUPPORT";
import IS_HEAD from "../utils/permissions/IS_HEAD";
import {
  SEND_CONFIRMATION_DIALOG,
  SEND_MESSAGE_DIALOG,
} from "../redux/actions/dialogs";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100vh - 18px)",
    minWidth: 1280,
  },
  innerContainer: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(6),
    marginRight: 3,
    height: "calc(100vh - 48px)",
  },
  icon: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey.S4,
  },
  body: {
    paddingLeft: theme.spacing(8),
  },
  scrollbar: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 8,
      height: 8,
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      opacity: 1,
      backgroundColor: theme.palette.grey.S2,
    },
  },
  flex: {
    display: "flex",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  progressBarContainer: {
    width: 300,
    margin: "auto",
  },
  LinearProgressRoot: {
    height: 16,
    borderRadius: 100,
  },
  LinearProgressBar: {
    borderRadius: 100,
  },
}));

const Body = (props) => {
  const CLASSES = useStyles();
  const CLASS_PROPS = props.class.props;
  const mode = CLASS_PROPS.CONTEXT.formRequestMode;
  const document = CLASS_PROPS.CONTEXT.forms.document;
  const directory = CLASS_PROPS.CONTEXT.forms.directory;
  const department = CLASS_PROPS.CONTEXT.forms.department;

  const [CreateNewType, setCreateNewType] = React.useState(
    CLASS_PROPS.CONTEXT.formType
  );
  const [progress, setProgress] = useState(0);
  const onUploadProgress = (totalItems = 1, item = 1, progress = 0) => {
    setProgress(
      (((item - 1) * 100 + (progress.loaded / progress.total) * 100) /
        (totalItems * 100)) *
      100
    );
  };

  const onClick = (type) => {
    switch (type) {
      case "view-document-close":
        setToggle("create_new", false);
        clearForm("directory");
        clearForm("document");
        clearForm("department");
        if (CLASS_PROPS.CONTEXT.toggles.from_dropzone) {
          setToggle("from_dropzone", false);
        }
        break;

      case "form-submit":
        switch (mode) {
          case "CREATE":
            switch (CreateNewType) {
              case "Document":
                if (isValid("document")) {
                  ADD_DOCUMENT({ onUploadProgress });
                }
                break;
              case "Directory":
                if (isValid("directory")) {
                  ADD_DIRECTORY();
                }
                break;
              case "Department":
                if (isValid("department")) {
                  ADD_DEPARTMENT();
                }
                break;
              default:
                break;
            }
            break;

          case "UPDATE":
            switch (CreateNewType) {
              case "Document":
                if (isValid("document")) {
                  UPDATE_DOCUMENT({ onUploadProgress });
                }
                break;
              case "Directory":
                if (isValid("directory")) {
                  UPDATE_DIRECTORY();
                }
                break;
              case "Department":
                if (isValid("department")) {
                  UPDATE_DEPARTMENT();
                }
                break;
              default:
                break;
            }
            break;

          default:
            break;
        }
        break;

      case "submit-confirmation":
        if (mode === "UPDATE") {
          const caption =
            "Upon confirmation, the changes you have made in this " +
            CreateNewType.toLowerCase() +
            " will be saved and cannot be undone. Please confirm to continue.";

          SEND_CONFIRMATION_DIALOG('warning', 'danger', 'Update Confirmation', caption,
            () => onClick('form-submit'))

          SEND_CONFIRMATION_DIALOG(
            "warning",
            "danger",
            "Update Confirmation",
            caption,
            () => onClick("form-submit")
          );
        } else {
          // SUBMIT FORM DIRECTLY IF THE MODE IS CREATE
          onClick("form-submit");
        }
        break;
      default:
        break;
    }
  };

  const isValid = (type) => {
    let valid_flag = true;

    switch (type) {
      case "document":
        if (document.title === "") {
          setForm("document", "error_title", "This field is required.");
          valid_flag = false;
        } else setForm("document", "error_title", false);

        if (document.type === "") {
          setForm("document", "error_type", "This field is required.");
          valid_flag = false;
        } else setForm("document", "error_type", false);

        if (document.attachments.length === 0 && mode === "CREATE") {
          setForm("document", "error_attachment", "This field is required.");
          valid_flag = false;
        } else setForm("document", "error_attachment", false);

        break;
      case "directory":
        if (directory.name === "") {
          setForm("directory", "error_name", "This field is required.");
          valid_flag = false;
        } else setForm("directory", "error_name", false);

        break;
      case "department":
        if (department.name === "") {
          setForm("department", "error_name", "This field is required.");
          valid_flag = false;
        } else setForm("department", "error_name", false);

        if (department.head.id === 0) {
          setForm("department", "error_head", "This field is required.");
          valid_flag = false;
        } else setForm("department", "error_head", false);

        break;
      default:
        break;
    }

    if (!valid_flag) {
      SEND_MESSAGE_DIALOG(
        "error",
        "danger",
        "Empty Required Fields",
        "Some required fields were left unfilled. Please make sure to fill in them all to continue."
      );
    }

    return valid_flag;
  };

  return (
    <Dialog
      fullScreen
      open
      onClose={() => onClick("view-document-close")}
      TransitionComponent={TransitionSlideUp}
    >
      <div className={clsx(CLASSES.container)}>
        <div className={clsx(CLASSES.innerContainer, CLASSES.scrollbar)}>
          <div className={CLASSES.heading}>
            <LightToolTip title="Close" placement="bottom">
              <ClearOutlined
                className={CLASSES.icon}
                onClick={() => onClick("view-document-close")}
              />
            </LightToolTip>
            <Space space={5} />
            <Typography variant={"h5"}>
              {mode === "CREATE"
                ? "CREATE NEW"
                : mode === "UPDATE"
                  ? "UPDATE " + CreateNewType.toUpperCase()
                  : mode === "CHANGE PASSWORD"
                    ? "CHANGE PASSWORD"
                    : null}
            </Typography>
          </div>
          <Space vertical space={8} />
          <div className={CLASSES.body}>
            {mode === "CREATE" ? (
              <div>
                <Typography variant={"body1"}>
                  What do you want to create?
                </Typography>
                <Space vertical space={2} />
                <div className={CLASSES.flex}>
                  <Button
                    label={"Document"}
                    size={"small"}
                    variant={"outlined"}
                    active={CreateNewType === "Document"}
                    onClick={() => {
                      setCreateNewType("Document");
                      setContext("formType", "Document");
                    }}
                  />
                  <Space space={2} />
                  <Button
                    label={"Directory"}
                    size={"small"}
                    variant={"outlined"}
                    active={CreateNewType === "Directory"}
                    onClick={() => {
                      setCreateNewType("Directory");
                      setContext("formType", "Directory");
                    }}
                  />
                  <Space space={2} />
                  {IS_SUPPORT() && IS_HEAD() ? (
                    <Button
                      label={"Department"}
                      size={"small"}
                      variant={"outlined"}
                      active={CreateNewType === "Department"}
                      onClick={() => {
                        setCreateNewType("Department");
                        setContext("formType", "Department");
                      }}
                    />
                  ) : null}
                </div>
                <Space vertical space={8} />
              </div>
            ) : null}

            {CreateNewType === "Department" ? (
              <FormDepartment class={props.class} />
            ) : null}
            {CreateNewType === "Directory" ? (
              <FormDirectory class={props.class} />
            ) : null}
            {CreateNewType === "Document" ? (
              <FormDocument
                class={props.class}
                supportHead={IS_SUPPORT() && IS_HEAD()}
                mode={mode}
              />
            ) : null}

            <Space vertical space={8} />
            <div className={CLASSES.flex}>
              <Button
                label={mode}
                variant={"outlined"}
                active
                loading={
                  CLASS_PROPS.CONTEXT.toggles.create_submit_loader ||
                  CLASS_PROPS.CONTEXT.toggles.update_submit_loader
                }
                onClick={() => onClick("submit-confirmation")}
              />
              <Space space={2} />
              <Button
                label={"CANCEL"}
                onClick={() => onClick("view-document-close")}
              />
            </div>
            <Space vertical space={8} />
          </div>
        </div>
      </div>
      <Footer />
      <Dialog
        fullScreen
        open={
          CLASS_PROPS.CONTEXT.formType === "Document" &&
          (CLASS_PROPS.CONTEXT.toggles.create_submit_loader ||
            CLASS_PROPS.CONTEXT.toggles.update_submit_loader)
        }
      // TransitionComponent={TransitionSlideUp}
      >
        <div className={CLASSES.progressBarContainer}>
          <Typography variant={"h6"} align={"center"}>
            {CLASS_PROPS.CONTEXT.formRequestMode === "CREATE"
              ? "Creating document..."
              : "Updating document..."}
          </Typography>
          <Space vertical space={5} />
          <Typography variant={"h3"} align={"center"}>
            {parseInt(progress)} %
          </Typography>
          <Space vertical space={5} />
          <LinearProgress
            variant="determinate"
            value={progress}
            classes={{
              root: CLASSES.LinearProgressRoot,
              bar: CLASSES.LinearProgressBar,
            }}
          />
        </div>
      </Dialog>
    </Dialog>
  );
};

class Forms extends Component {
  async componentDidMount() {
    // CLEAR FORMS IF ACTION IS TO CREATE NEW
    if (this.props.CONTEXT.formRequestMode === "CREATE" && !this.props.CONTEXT.toggles.from_dropzone) {
      await clearForm("directory");
      await clearForm("document");
      await clearForm("department");
    }

    // GET SUGGESTIONS
    await GET_SUGGESTION("attribute_key");
    await GET_SUGGESTION("attribute_value");
    await GET_SUGGESTION("keyword");
    await GET_SUGGESTION("type");
    await GET_SUGGESTION("directory_group");
    await GET_SUGGESTION("department_user");
    await GET_SUGGESTION("user");
  }

  /*
        ADD ATTRIBUTE TO THE LIST WHEN CLICKED
     */
  onAttributesAdd = async () => {
    if (
      this.props.CONTEXT.forms.document.attribute_key &&
      this.props.CONTEXT.forms.document.attribute_value
    ) {
      let contains = false;
      for (let attr of this.props.CONTEXT.forms.document.attributes) {
        if (
          attr.key === this.props.CONTEXT.forms.document.attribute_key &&
          attr.value === this.props.CONTEXT.forms.document.attribute_value
        ) {
          contains = true;
        }
      }
      if (!contains) {
        await setForm(
          "document",
          "attributes",
          this.props.CONTEXT.forms.document.attributes.concat([
            {
              key: this.props.CONTEXT.forms.document.attribute_key,
              value: this.props.CONTEXT.forms.document.attribute_value,
            },
          ])
        );
      }
      setForm("document", "attribute_key", "");
      setForm("document", "attribute_value", "");
    }
  };

  /*
        ADD ATTRIBUTE TO THE LIST WHEN ENTER IS PRESSED
     */
  onKeywordsAdd = async () => {
    if (
      !this.props.CONTEXT.forms.document.keywords.includes(
        this.props.CONTEXT.forms.document.keyword
      )
    ) {
      await setForm(
        "document",
        "keywords",
        this.props.CONTEXT.forms.document.keywords.concat([
          this.props.CONTEXT.forms.document.keyword,
        ])
      );
    }
    setForm("document", "keyword", "");
  };

  /*
        ADD FILE ATTACHMENT
     */
  onDropzoneAdd = async (file) => {
    setForm('document', 'attachments', [file])
  }

  render() {
    return <Body class={this} />;
  }
}

export default connect(STATES, null)(Forms);
