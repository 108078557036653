import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { ReportProblemOutlined } from "@material-ui/icons";
import Space from "../panel/Space";
import Typography from "@material-ui/core/Typography";
import Button from "../button/Button";
import clsx from "clsx";
import { TransitionSlideUp } from "../transition/TransitionSlideLeft";

const useStyles = makeStyles((theme) => ({
     root: {
          borderRadius: theme.shape.borderRadius,
     },
     body: {
          padding: theme.spacing(6),
          width: 350,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
     },
     iconContainer: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
     },
     icon: {
          fontSize: 100,
     },
     typography: {
          color: theme.palette.grey.S4,
     },
     link: {
          color: theme.palette.grey.S3,
          "&:hover": {
               cursor: "pointer",
               color: theme.palette.grey.S4,
          },
     },
}));

export default function Main(props) {
     const classes = useStyles();
     const theme = useTheme();

     return (
          <Dialog
               onClose={props.onClose}
               open={props.open}
               className={classes.root}
               TransitionComponent={TransitionSlideUp}
          >
               <div className={classes.body}>
                    <div
                         className={classes.iconContainer}
                         style={{
                              color: theme.palette.primary.main,
                         }}
                    >
                         <ReportProblemOutlined className={classes.icon} />
                    </div>
                    <Space vertical space={4} />
                    <Typography variant={"h5"} className={classes.typography}>
                         {props.title}
                    </Typography>
                    <Space vertical space={2} />
                    <Typography
                         variant={"body1"}
                         align={"center"}
                         className={classes.typography}
                         style={{ color: theme.palette.grey.S3 }}
                    >
                         {props.caption}
                    </Typography>
                    <Space vertical space={6} />
                    <div
                         style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                         }}
                    >
                         <Typography
                              variant={"body1"}
                              className={clsx(classes.typography, classes.link)}
                              onClick={props.onClose}
                         >
                              CLOSE
                         </Typography>
                         <Button
                              label={"REQUEST"}
                              variant={"outlined"}
                              color={"default"}
                              onClick={props.onConfirm}
                         />
                    </div>
               </div>
               <div
                    style={{
                         height: 15,
                         background: theme.palette.primary.gradient,
                    }}
               ></div>
          </Dialog>
     );
}
