import React, {Component, useEffect} from "react";
import {connect, useSelector} from "react-redux";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { ClearOutlined } from "@material-ui/icons";
import { TransitionSlideUp } from "../components/core/transition/TransitionSlideLeft";
import Space from "../components/core/panel/Space";
import Typography from "@material-ui/core/Typography";
import LightToolTip from "../components/core/tooltip/LightToolTip";
import {
    clearForm,
    setForm,
    setToggle,
} from "../redux/actions/context";
import clsx from "clsx";
import Footer from "../components/layout/Footer";
import STATES from "../redux/states";
import InputTextArea from "../components/core/input/InputTextArea";
import InputText from "../components/core/input/InputText";
import StickyHeadTable from "../components/core/table/StickyHeadTable";
import Button from "../components/core/button/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {SEND_CONFIRMATION_DIALOG, SEND_MESSAGE_DIALOG} from "../redux/actions/dialogs";
import {UPDATE_DIRECTORY_LINK, DELETE_DIRECTORY_LINK} from "../redux/actions/directory";
import {MESSAGE} from "../redux/actions/message";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "calc(100vh - 18px)",
        minWidth: 1280,
    },
    innerContainer: {
        paddingLeft: theme.spacing(6),
        paddingTop: theme.spacing(6),
        marginRight: 3,
        height: "calc(100vh - 48px)",
    },
    icon: {
        "&:hover": {
            color: theme.palette.primary.main,
            cursor: "pointer",
        },
    },
    heading: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.grey.S4,
    },
    body: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
    scrollbar: {
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 8,
            height: 8,
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 10,
            opacity: 1,
            backgroundColor: theme.palette.grey.S2,
        },
    },
    flex: {
        display: "flex",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    progressBarContainer: {
        width: 300,
        margin: "auto",
    },
    LinearProgressRoot: {
        height: 16,
        borderRadius: 100,
    },
    LinearProgressBar: {
        borderRadius: 100,
    },
    flexAlignItemsCenter: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    groupControl: {
        // marginRight: theme.spacing(8),
        marginBottom: theme.spacing(6),
        minWidth: 300,
        width: "100%",
    },
    badge: {
        color: theme.palette.error.main,
        "&:hover": {
            cursor: "pointer",
        },
    },
    titleTableContainer: {
        width:"1200px",
        margin: "0 auto",
    },
    btnSubmitContainer: {
        width:"300px",
        margin: "0 auto",
    }
}));

const Body = (props) => {
    const CLASSES = useStyles();
    const CLASS_PROPS = props.class.props;
    const LINK_DESCRIPTION = CLASS_PROPS.CONTEXT.forms.directory.link_description

    const { listView } = useSelector((state) => ({
        listView: state.CONTEXT.toggles.directoryLinkDescriptionView || false,
    }));

    const DATA = CLASS_PROPS.DIRECTORY.linklist

    useEffect(() => {
        if (LINK_DESCRIPTION['selectedTitleId']){
            const copyDetails = DATA.filter((value) => { return (value.id === LINK_DESCRIPTION['selectedTitleId'])})[0].details
            LINK_DESCRIPTION["details"] = JSON.parse(JSON.stringify(copyDetails))
            setForm("directory", "link_description", LINK_DESCRIPTION)
        }
    }, [LINK_DESCRIPTION['selectedTitleId']]);

    const isValid = () => {
        let isValid = true
        if (LINK_DESCRIPTION["description"] === "" || LINK_DESCRIPTION["link"] === "")
            isValid = false

        if (!isValid)
            SEND_MESSAGE_DIALOG(
                "error",
                "danger",
                "Empty Required Fields",
                "Some required fields were left unfilled. Please make sure to fill in them all to continue."
            );

        return isValid
    }

    const onClick = (action) => {
        switch (action) {
            case 'close-link-form':
                setToggle("view_directory_link", false);
                break
            case 'cancel-update':
                LINK_DESCRIPTION["formRequestMode"] = "CREATE"
                LINK_DESCRIPTION["id"] = ""
                LINK_DESCRIPTION["description"] = ""
                LINK_DESCRIPTION["link"] = ""

                setForm("directory", "link_description", LINK_DESCRIPTION)
                break
            case 'add-link':
                if (isValid()){
                    addLink()
                }
                break
            case 'update-link':
                if (isValid()){
                    updateLink()
                }
                break
            case 'show-title-list':
                clearForm("directory")
                setToggle("directoryLinkDescriptionView", false);
                break
            case  'create-new-title':
                clearForm("directory")
                setToggle("directoryLinkDescriptionView", true);
                break
            case 'submit-links':
                if (LINK_DESCRIPTION['details'] && Object.keys(LINK_DESCRIPTION['details']).length > 0){
                    SEND_CONFIRMATION_DIALOG(
                        "warning",
                        "warning",
                        "Confirm Submit",
                        "Are you sure you want to submit this form?",
                        () => {
                            submitLinkForm()
                        }
                    )
                }else{
                    SEND_MESSAGE_DIALOG(
                        "error",
                        "danger",
                        "Empty Links",
                        "Please Add Links"
                    );
                }
                break
            default:
                break
        }
    }

    const COLUMNS = [
        {
            id: 'description',
            label: 'Description',
            minWidth: 170,
            align: 'left',
        },
        {
            id: 'link',
            label: 'Link',
            minWidth: 170,
            align: 'left',
        },
    ];

    const onChangeInput = (event) => {
        LINK_DESCRIPTION[event.target.name] = event.target.value
        setForm("directory", "link_description", LINK_DESCRIPTION)
    }

    const viewLinkDescription = (index) => {
        const datum = DATA[index]
        if(datum && Object.keys(datum).length > 0){
            LINK_DESCRIPTION["selectedTitleId"] = datum.id
            LINK_DESCRIPTION["title"] = datum.title
            LINK_DESCRIPTION["description"] = ""
            LINK_DESCRIPTION["link"] = ""
            setToggle("directoryLinkDescriptionView", true);
        }
    }

    const populateUpdateForm = (index) => {
        const datum = LINK_DESCRIPTION["details"][index]
        if(datum && Object.keys(datum).length > 0){
            LINK_DESCRIPTION["selected_index"] = index
            LINK_DESCRIPTION["formRequestMode"] = "UPDATE"
            LINK_DESCRIPTION["id"] = datum.id
            LINK_DESCRIPTION["description"] = datum.description
            LINK_DESCRIPTION["link"] = datum.link
            setForm("directory", "link_description", LINK_DESCRIPTION)
        }
    }

    const updateLink = () => {
        const selectedDetails = LINK_DESCRIPTION["details"][LINK_DESCRIPTION['selected_index']]
        selectedDetails["description"] = LINK_DESCRIPTION["description"]
        selectedDetails["link"] = LINK_DESCRIPTION["link"]
        LINK_DESCRIPTION["details"][LINK_DESCRIPTION['selected_index']] = selectedDetails
        LINK_DESCRIPTION["description"] = ""
        LINK_DESCRIPTION["link"] = ""
        LINK_DESCRIPTION["formRequestMode"] = "CREATE"
        MESSAGE("Link has been updated.", "enqueue", "success");
    }

    const addLink = () => {
        let linkDetails = LINK_DESCRIPTION['details']
        const datum = {
            description: LINK_DESCRIPTION['description'],
            link: LINK_DESCRIPTION['link'],
        }
        linkDetails.push(datum)
        LINK_DESCRIPTION['details'] = linkDetails
        LINK_DESCRIPTION['description'] = ""
        LINK_DESCRIPTION['link'] = ""
        MESSAGE("Link has been added to list.", "enqueue", "success");
    }

    const deleteLinkDetails = (index) => {
        SEND_CONFIRMATION_DIALOG(
            "warning",
            "warning",
            "Delete Link",
            "Are you sure you want to delete this link?",
            () => {
                LINK_DESCRIPTION["details"].splice(index,1)
            }
        );
    }

    const deleteLinkHead = (index) => {
        SEND_CONFIRMATION_DIALOG(
            "warning",
            "warning",
            "Delete Link",
            "Are you sure you want to delete this link?",
            () => {
                DELETE_DIRECTORY_LINK(CLASS_PROPS.DIRECTORY.linklist[index].id)
                CLASS_PROPS.DIRECTORY.linklist = CLASS_PROPS.DIRECTORY.linklist.filter(function (v,i){ return i !== index})

            }
        );
    }

    const submitLinkForm = () => {
        UPDATE_DIRECTORY_LINK()
    }

    return (
        <Dialog
            fullScreen
            open
            onClose={() => onClick("close-link-form")}
            TransitionComponent={TransitionSlideUp}
        >
            <div className={clsx(CLASSES.container)}>
                <div className={clsx(CLASSES.innerContainer, CLASSES.scrollbar)}>
                    <div className={CLASSES.heading}>
                        { listView ? (

                            <LightToolTip title="Close" placement="bottom">
                                <ArrowBackIcon
                                    className={CLASSES.icon}
                                    onClick={() => onClick("show-title-list")}
                                />
                            </LightToolTip>
                        ) : (
                            <LightToolTip title="Close" placement="bottom">
                                <ClearOutlined
                                    className={CLASSES.icon}
                                    onClick={() => onClick("close-link-form")}
                                />
                            </LightToolTip>
                        )}
                        <Space space={5} />
                        <Typography variant={"h5"}>
                            DIRECTORY LINKS
                        </Typography>
                    </div>
                    <Space vertical space={8} />
                    <div className={CLASSES.body}>

                        {listView ? (
                            <div>

                                <div className={CLASSES.groupControl}>
                                    <div className={CLASSES.flexAlignItemsCenter}>
                                        <Typography variant={"body1"}>Title</Typography>
                                        <Space space={1} />
                                    </div>
                                    <Space vertical space={2} />
                                    <InputText
                                        name={"title"}
                                        placeholder={`Please Enter Title`}
                                        onChange={(event) => onChangeInput(event)}
                                        value={LINK_DESCRIPTION.title}
                                    />
                                </div>
                                <div>
                                    <div className={CLASSES.flexAlignItemsCenter}>
                                        <Typography variant={"body1"}>
                                            Description
                                        </Typography>
                                        <Space space={1} />
                                        <LightToolTip title={"Required field"} placement={"right"}>
                                            <Typography
                                                variant={"body1"}
                                                color={"primary"}
                                                className={CLASSES.badge}
                                            >
                                                *
                                            </Typography>
                                        </LightToolTip>
                                    </div>
                                    <Space vertical space={2} />
                                    <InputTextArea
                                        name={"description"}
                                        rows={5}
                                        placeholder={"Please Enter Description"}
                                        maxlength={200}
                                        onChange={(event) => onChangeInput(event)}
                                        value={LINK_DESCRIPTION.description}
                                    />
                                </div>
                                <Space vertical space={8} />
                                <div className={CLASSES.groupControl}>
                                    <div className={CLASSES.flexAlignItemsCenter}>
                                        <Typography variant={"body1"}>
                                            Link
                                        </Typography>
                                        <Space space={1} />
                                        <LightToolTip title={"Required field"} placement={"right"}>
                                            <Typography
                                                variant={"body1"}
                                                color={"primary"}
                                                className={CLASSES.badge}
                                            >
                                                *
                                            </Typography>
                                        </LightToolTip>
                                    </div>
                                    <Space vertical space={2} />
                                    <InputText
                                        name={"link"}
                                        placeholder={`Please Enter Link`}
                                        onChange={(event) => onChangeInput(event)}
                                        value={LINK_DESCRIPTION.link}
                                    />
                                </div>

                                <Space space={5} />
                                <Button
                                    label={LINK_DESCRIPTION.formRequestMode === 'CREATE' ? 'ADD LINK' : 'UPDATE LINK'}
                                    variant={"outlined"}
                                    active
                                    onClick={() => onClick(LINK_DESCRIPTION.formRequestMode === 'CREATE' ? 'add-link' : 'update-link')}
                                    margin10
                                />

                                {LINK_DESCRIPTION.formRequestMode === 'UPDATE' ? (
                                    <Button
                                        label={'CANCEL'}
                                        variant={"outlined"}
                                        active
                                        onClick={() => onClick("cancel-update")}
                                        margin10
                                    />
                                ) : null}

                                <Space vertical space={6} />
                                <div className={CLASSES.groupControl}>
                                    <StickyHeadTable
                                        rows={LINK_DESCRIPTION['details']}
                                        columns={COLUMNS}
                                        action={['update-link','delete-link-details']}
                                        populateUpdateForm={populateUpdateForm}
                                        deleteLinkDetails={deleteLinkDetails}
                                    />
                                </div>
                                <div className={CLASSES.btnSubmitContainer}>
                                    <Button
                                        label={"SUBMIT"}
                                        variant={"outlined"}
                                        active
                                        onClick={() => onClick('submit-links')}
                                        fullwidth
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className={CLASSES.titleTableContainer}>
                                <Button
                                    label={'CREATE'}
                                    variant={"outlined"}
                                    active
                                    onClick={() => onClick("create-new-title")}
                                    margin10
                                    placement={'right'}
                                />
                                <Space vertical space={8} />
                                <StickyHeadTable
                                    rows={DATA}
                                    columns={[{ id: 'title', label: 'Title', minWidth: 600 }]}
                                    action={['view-link-details','delete-link-head']}
                                    view_link_description={viewLinkDescription}
                                    deleteLinkHead={deleteLinkHead}
                                />
                            </div>
                        )}

                    </div>
                </div>
            </div>
            <Footer />
        </Dialog>
    );
};

class DirectoryLinks extends Component {

    render() {
        return <Body class={this} />;
    }
}

export default connect(STATES, null)(DirectoryLinks);
