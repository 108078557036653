import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconLink from "../../../components/core/button/IconLink";
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoIcon from '@material-ui/icons/Info';
import Typography from "@material-ui/core/Typography";
import Space from "../panel/Space";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,

    },
    icon: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    flexWrapAlignCenter: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
    },
    actionContainer: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
        gap: "10px",
    },
    emptyTitleContainer: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        gap: "5px"
    }
});

export default function StickyHeadTable(PROPS) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const columns = PROPS.columns
    const rows = PROPS.rows
    const action = PROPS.action

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onClick = (type, index) => {
        const i = index
        switch (type) {
            case 'update-link':
                PROPS.populateUpdateForm(i)
                break
            case 'view-link-details':
                PROPS.view_link_description(i)
                break
            case 'delete-link-details':
                PROPS.deleteLinkDetails(i)
                break
            case 'delete-link-head':
                PROPS.deleteLinkHead(i)
                break
            default :
                break
        }
    }

    return (
        <Paper className={classes.root} >
            {rows && Object.keys(rows).length > 0 ? (
                <div>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        align={"center"}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.id === 'title' && value === '' ? (
                                                            <div className={classes.emptyTitleContainer}>
                                                                <Typography variant={"body1"} align={"center"}>
                                                                    No Title Indicated
                                                                </Typography>
                                                                <IconLink
                                                                    tooltipTitle={
                                                                        row.details && Object.keys(row.details).length > 0 ? (
                                                                            <div>
                                                                                <Space vertical space={1} />
                                                                                <h5>
                                                                                    Descriptions
                                                                                </h5>
                                                                                <Space vertical space={1} />
                                                                                <ul style={{listStyleType: "disc"}}>
                                                                                    {row.details.map((detail) => {
                                                                                        return (<li>{detail.description}</li>)
                                                                                    })}
                                                                                </ul>
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                    icon={<InfoIcon size={22} />}
                                                                />
                                                            </div>
                                                        ) : column.format && typeof value === 'number' ? column.format(value) : value }

                                                    </TableCell>
                                                );
                                            })}

                                            <TableCell>
                                                <div className={classes.actionContainer}>
                                                    {action && Object.keys(action).length > 0 ? (
                                                        action.map(function (type){
                                                            switch(type) {
                                                                case 'update-link':
                                                                    return (
                                                                        <IconLink
                                                                            tooltipTitle={"Update"}
                                                                            icon={<EditIcon size={22} />}
                                                                            onClick={() => onClick(type,index)}
                                                                        />


                                                                    )
                                                                case 'delete-link-details':
                                                                    return (
                                                                        <IconLink
                                                                            tooltipTitle={"Delete"}
                                                                            icon={<DeleteIcon size={22} />}
                                                                            onClick={() => onClick(type,index)}
                                                                        />
                                                                    )
                                                                case 'delete-link-head':
                                                                    return (
                                                                        <IconLink
                                                                            tooltipTitle={"Delete"}
                                                                            icon={<DeleteIcon size={22} />}
                                                                            onClick={() => onClick(type,index)}
                                                                        />
                                                                    )
                                                                case 'view-link-details':
                                                                    return (
                                                                        <IconLink
                                                                            tooltipTitle={"View"}
                                                                            icon={<VisibilityIcon size={22} />}
                                                                            onClick={() => onClick(type,index)}
                                                                        />

                                                                    )
                                                                default:
                                                                    return (
                                                                        <></>
                                                                    )
                                                            }
                                                        })

                                                    ) : null}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows ? rows.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            ) : <div>
                    <Typography variant={"body1"} align={"center"}>
                        No Data
                    </Typography>
                </div>
            }
        </Paper>
    );
}
