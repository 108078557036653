import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Link, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import {
  ArrowBack,
  ArrowForward,
  CancelScheduleSendOutlined,
  ClearOutlined,
  FolderOutlined,
  KeyboardArrowRightOutlined,
  LinkOutlined,
  SendOutlined,
  // Publish,
  BusinessRounded
} from "@material-ui/icons";
import TransitionSlideLeft from "../components/core/transition/TransitionSlideLeft";
import Space from "../components/core/panel/Space";
import Typography from "@material-ui/core/Typography";
import LightToolTip from "../components/core/tooltip/LightToolTip";
import { setContext, setForm, setToggle } from "../redux/actions/context";
import clsx from "clsx";
import {
  CANCEL_REQUEST_TO_PUBLICIZE,
  DELETE_DOCUMENT,
  GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS,
  PUBLICIZE,
  REQUEST_TO_PUBLICIZE,
  UNPUBLICIZE,
  PUBLICIZE_GUEST,
  UNPUBLICIZE_GUEST,
  REQUEST_TO_PUBLICIZE_GUEST,
  CANCEL_REQUEST_TO_PUBLICIZE_GUEST,
  PUBLICIZE_GLOBAL_GUEST,
  UNPUBLICIZE_GLOBAL_GUEST,
} from "../redux/actions/document";
import Grid from "@material-ui/core/Grid";
import Line from "../components/core/decoration/Line";
import GET_FILE_ICON from "../utils/GET_FILE_ICON";
import { env } from "../env";
import Footer from "../components/layout/Footer";
import useTheme from "@material-ui/core/styles/useTheme";
import IconLink from "../components/core/button/IconLink";
import IS_PUBLIC_REQUEST from "../utils/context/IS_PUBLIC_REQUEST";
import {
  RiDeleteBin7Line,
  RiDownloadCloud2Line,
  RiEarthLine,
  RiEdit2Line,
  RiFileCopyLine,
  // RiPrinterLine,
  RiScissorsCutLine,
} from "react-icons/all";
import DOWNLOAD_FROM_URL from "../utils/requests/DOWNLOAD_FROM_URL";
import STATES from "../redux/states";
import HAS_CRUD from "../utils/permissions/HAS_CRUD";
import IS_SUPPORT from "../utils/permissions/IS_SUPPORT";
import IS_HEAD from "../utils/permissions/IS_HEAD";
import IS_OFFICEHEAD from "../utils/permissions/IS_OFFICEHEAD";
import IS_AUTHENTICATED from "../utils/permissions/IS_AUTHENTICATED";
import { GET_DIRECTORY_DETAILS } from "../redux/actions/directory";
import { MESSAGE } from "../redux/actions/message";
import { SEND_CONFIRMATION_DIALOG } from "../redux/actions/dialogs";
import IS_ACCREDITOR from "../utils/permissions/IS_ACCREDITOR";
import HAS_DEPARTMENT from "../utils/permissions/HAS_DEPARTMENT";
import BasicPDFViewer from "../components/core/PDFViewer";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    minWidth: 1280,
    display: "flex",
    // background: theme.palette.grey.RGB0
  },
  leftSidebarContainer: {
    height: "100vh",
    // width: 700,
    // minWidth: 700,
    // background: theme.palette.grey.S1,
  },
  leftSidebarBodyContainer: {
    height: "calc(100vh - 18px - " + theme.spacing(6) + "px)",
    width: "calc(100% - 3px)",
    // background: theme.palette.grey.S2,
    display: "flex",
    // justifyContent: 'space-between',
    // paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  leftSidebarBodyHeadingContainer: {
    // height: '100%',
    // width: '100%',
    // background: theme.palette.grey.S3,
    background: "white",
    // paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    // display: 'flex',
    // flexWrap: 'wrap',
    // alignItems: 'center'
  },

  leftSidebarBodyContentContainer: {
    height: "calc(100vh - 190px)",
    // width: '100%',
    // background: theme.palette.grey.S4,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(6),
    // paddingBottom: theme.spacing(6),
  },
  viewerContainer: {
    background: theme.palette.grey.RGB0,
    width: "100%",
    height: "100vh",
    maxHeight: "100vh",
    overflowY: "scroll",
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center'
  },

  badge: {
    // width: 60,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  tooltip: {
    padding: theme.spacing(3),
    maxWidth: 220,
    textAlign: "justify",
  },
  arrow: {
    color: theme.palette.warning.main,
  },
  iconLink: {
    color: theme.palette.grey.S4,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },

  container: {
    // borderRight: '25px solid '+theme.palette.primary.main,
    height: "calc(100vh)",
    minWidth: 1280,
    display: "flex",
    justifyContent: "space-between",
  },
  leftContainer: {
    width: 1280,
    marginTop: theme.spacing(6),
    height: "calc(100vh - " + theme.spacing(6) + "px)",
  },
  leftContainerContent: {
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    width: "100%",
    height: "100%",
  },
  rightContainer: {
    // paddingRight: theme.spacing(6),
    width: "100%",
    height: "100%",
  },
  icon: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey.S4,
  },
  bodyContainer: {
    paddingLeft: theme.spacing(8),
  },
  scrollbar: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 8,
      height: 8,
      backgroundColor: "transparent",
      border: 0,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      opacity: 1,
      backgroundColor: theme.palette.grey.S2,
    },
  },
  flex: {
    display: "flex",
  },
  flexWrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  flexWrapAlignCenter: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  typography: {
    color: theme.palette.grey.S4,
    marginBottom: theme.spacing(1),
  },
  label: {
    // color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
  },
  attribute: {
    marginRight: theme.spacing(3),
  },
  fileIcon: {
    height: 70,
  },
  link: {
    // color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "unset",
      cursor: "pointer",
    },
  },
  attachmentItem: {
    display: "flex",
    alignItems: "center",
    marginLeft: -24,
    width: "calc(100% + 48px)",
    padding: 24,
    borderRadius: 4,
    "&:hover": {
      cursor: "pointer",
    },
  },
  attachmentItemActive: {
    backgroundColor: theme.palette.grey.RGB0,
    fontWeight: "bold",
  },
  attachmentSize: {
    color: theme.palette.primary.main,
  },
}));

const Body = (props) => {
  const CLASSES = useStyles();
  const THEME = useTheme();
  const CLASS_PROPS = props.class.props;
  const DOCUMENT = CLASS_PROPS.DOCUMENT.details;
  const active_directory = CLASS_PROPS.CONTEXT.active_directory.substring(3);
  
  const URL = env.URL_REPOSITORY;
  // const doc_url = DOCUMENT.attachments
  //   ? URL + DOCUMENT.attachments[0].dir + DOCUMENT.attachments[0].name
  //   : null;

  const [showLeftPanel, setShowLeftPanel] = useState(true);
  const [activeAttachmentViewingIndex, setActiveAttachmentViewingIndex] =
    useState(0);

  const onChangeAttachmentClick = (index) => {
    setActiveAttachmentViewingIndex(index);
  };

  const onClick = async (action) => {
    let FILENAME = null;
    let FILE_URL = null;

    if (DOCUMENT.attachments.length > 0) {
      FILENAME = DOCUMENT.attachments[0].name;
      FILE_URL = URL + DOCUMENT.attachments[0].dir + FILENAME;
    }

    switch (action) {
      case "copy":
      case "cut":
        setContext("clipboard", {
          id: DOCUMENT.docdir_id,
          type: "DOCUMENT",
          action,
        });
        if (action === "copy") {
          MESSAGE(`Document cut to clipboard`, "dequeue");
          MESSAGE(`Document copied to clipboard`, "enqueue");
        } else {
          MESSAGE(`Document copied to clipboard`, "dequeue");
          MESSAGE(`Document cut to clipboard`, "enqueue");
        }
        break;
      case "download":
        DOWNLOAD_FROM_URL(FILE_URL);
        MESSAGE(`Downloading attachment`, "enqueue", "success");
        break;
      case "print":
        window.open(FILE_URL, "PRINT", "height=720,width=1280");
        break;
      case "edit":
        await setForm("document", "id", DOCUMENT.id);
        await setForm("document", "title", DOCUMENT.title);
        await setForm("document", "description", DOCUMENT.description);
        await setForm("document", "is_public", DOCUMENT.is_public);
        await setForm("document", "type", DOCUMENT.type);
        await setForm("document", "attributes", DOCUMENT.attributes);
        await setForm("document", "keywords", DOCUMENT.keywords);
        await setForm("document", "attachments", DOCUMENT.attachments);
        await setContext("formRequestMode", "UPDATE");
        await setContext("formType", "Document");
        setToggle("view_document", false);
        setToggle("create_new", true);
        break;
      case "delete":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "warning",
          "Trash Confirmation",
          "This document will be moved to the Office Trash Bin. You can restore it later if necessary.",
          () => {
            DELETE_DOCUMENT(DOCUMENT.docdir_id, () =>
              onClick("close-view-document")
            );
          }
        );
        break;
      case "publicize-request":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Request Confirmation",
          "Upon confirmation, this document will be requested for " +
          "public posting and will be subject for approval.",
          () => {
            REQUEST_TO_PUBLICIZE(DOCUMENT.docdir_id, () => {
              setToggle("view_document", false);
              GET_DIRECTORY_DETAILS();
            });
          }
        );
        break;
      case "publicize-request-cancel":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Cancel Request Confirmation",
          "Upon confirmation, this request to post this document on public will be cancelled.",
          () => {
            CANCEL_REQUEST_TO_PUBLICIZE(DOCUMENT.docdir_id, () => {
              setToggle("view_document", false);
              GET_DIRECTORY_DETAILS();
            });
          }
        );
        break;
      case "publicize-approve":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Approval Confirmation",
          "Upon confirmation, this document will be available to the public.",
          () => {
            PUBLICIZE(DOCUMENT.docdir_id, () => {
              GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS(() => {
                onClick("close-view-document");
              });
            });
          }
        );
        break;
      case "publicize-reject":
        SEND_CONFIRMATION_DIALOG(
          "error",
          "danger",
          "Rejection Confirmation",
          "Upon confirmation, this document will not be available to the public.",
          () => {
            UNPUBLICIZE(DOCUMENT.docdir_id, () => {
              GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS(() => {
                onClick("close-view-document");
              });
            });
          }
        );
        break;
      case "publicize":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Publicize Confirmation",
          "Upon confirmation, this document will only be available to the public.",
          () => {
            PUBLICIZE(DOCUMENT.docdir_id, async () => {
              await GET_DIRECTORY_DETAILS();
              await onClick("close-view-document");
            });
          }
        );
        break;
      case "unpublicize":
        SEND_CONFIRMATION_DIALOG(
          "error",
          "danger",
          "Unpublicize Confirmation",
          "Upon confirmation, this document will be available to your office and not to the public",
          () => {
            UNPUBLICIZE(DOCUMENT.docdir_id, async () => {
              await GET_DIRECTORY_DETAILS();
              await onClick("close-view-document");
            });
          }
        );
        break;
      case "publicize-guest":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Publicize Confirmation",
          "Upon confirmation, this document will only be available to the public guests.",
          () => {
            PUBLICIZE_GUEST(DOCUMENT.docdir_id, async () => {
              await GET_DIRECTORY_DETAILS();
              await onClick("close-view-document");
            });
          }
        );
        break;
      case "unpublicize-guest":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Unpublicize Confirmation",
          "Upon confirmation, this document will be available to your office and not to the public guests.",
          () => {
            UNPUBLICIZE_GUEST(DOCUMENT.docdir_id, async () => {
              await GET_DIRECTORY_DETAILS();
              await onClick("close-view-document");
            });
          }
        );
        break;
      case "publicize-request-guest":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Request Confirmation",
          "Upon confirmation, this document will be requested for " +
          "public posting and will be subject for approval.",
          () => {
            REQUEST_TO_PUBLICIZE_GUEST(DOCUMENT.docdir_id, () => {
              setToggle("view_document", false);
              GET_DIRECTORY_DETAILS();
            });
          }
        );
        break;
      case "publicize-request-cancel-guest":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Cancel Request Confirmation",
          "Upon confirmation, this request to post this document on public will be cancelled.",
          () => {
            CANCEL_REQUEST_TO_PUBLICIZE_GUEST(DOCUMENT.docdir_id, () => {
              setToggle("view_document", false);
              GET_DIRECTORY_DETAILS();
            });
          }
        );
        break;
      case "publicize-approve":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Approval Confirmation",
          "Upon confirmation, this document will be available to the public.",
          () => {
            PUBLICIZE(DOCUMENT.docdir_id, () => {
              GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS(() => {
                onClick("close-view-document");
              });
            });
          }
        );
        break;
      case "publicize-reject":
        SEND_CONFIRMATION_DIALOG(
          "error",
          "danger",
          "Rejection Confirmation",
          "Upon confirmation, this document will be unavailable to the public.",
          () => {
            UNPUBLICIZE(DOCUMENT.docdir_id, () => {
              GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS(() => {
                onClick("close-view-document");
              });
            });
          }
        );
        break;
      case "publicize-approve-guest":
        SEND_CONFIRMATION_DIALOG(
          "warning",
          "danger",
          "Approval Confirmation",
          "Upon confirmation, this document will be available to the public.",
          () => {
            PUBLICIZE_GLOBAL_GUEST(DOCUMENT.docdir_id, () => {
              GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS(() => {
                onClick("close-view-document");
              });
            });
          }
        );
        break;
      case "publicize-reject-guest":
        SEND_CONFIRMATION_DIALOG(
          "error",
          "danger",
          "Rejection Confirmation",
          "Upon confirmation, this document will be unavailable to the public.",
          () => {
            UNPUBLICIZE_GLOBAL_GUEST(DOCUMENT.docdir_id, () => {
              GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS(() => {
                onClick("close-view-document");
              });
            });
          }
        );
        break;
      case "info":
        break;

      case "submit":
        DOCUMENT.is_link
          ? CLASS_PROPS.unlinkDocument(DOCUMENT.docdir_id, async () => { })
          : CLASS_PROPS.deleteDocument(
            CLASS_PROPS.AUTH,
            DOCUMENT.id,
            async () => { }
          );
        break;
      case "close-view-document":
        setToggle("view_document", false);
        break;
      case "hide-left-panel":
        setShowLeftPanel(false);
        break;
      case "show-left-panel":
        setShowLeftPanel(true);
        break;

      default:
        break;
    }
  };

  /* const results = async (event) => {
    switch (event) {
      case "submit-success":
        break;
      default:
        await CLASS_PROPS.getDirectoryTree(CLASS_PROPS.AUTH);
        await CLASS_PROPS.getDirectoryDetails(
          CLASS_PROPS.AUTH,
          CLASS_PROPS.CONTEXT.active_directory,
          CLASS_PROPS.CONTEXT
        );
        break;
    }
  }; */
  
  return (
    <Dialog fullScreen open TransitionComponent={TransitionSlideLeft}>
      <div className={clsx(CLASSES.root)}>
        <div className={clsx(CLASSES.leftSidebarContainer)}>
          <div className={clsx(CLASSES.leftSidebarBodyContainer)}>
            {/* CLOSE BUTTON */}
            <div style={{ width: 50, paddingLeft: 35 }}>
              {showLeftPanel ? (
                <LightToolTip title="Hide Details" placement="bottom">
                  <ArrowBack
                    className={CLASSES.icon}
                    onClick={() => onClick("hide-left-panel")}
                  />
                </LightToolTip>
              ) : (
                <LightToolTip title="Show Details" placement="bottom">
                  <ArrowForward
                    className={CLASSES.icon}
                    onClick={() => onClick("show-left-panel")}
                  />
                </LightToolTip>
              )}
              <Space vertical space={3} />
              <LightToolTip title="Close" placement="bottom">
                <ClearOutlined
                  className={CLASSES.icon}
                  onClick={() => onClick("close-view-document")}
                />
              </LightToolTip>
            </div>

            <Space space={1} />

            {showLeftPanel ? (
              <div style={{ width: 600 }}>
                {IS_ACCREDITOR() ? null : (
                  <>
                    <div
                      className={clsx(CLASSES.leftSidebarBodyHeadingContainer)}
                    >
                      <div className={CLASSES.flexWrapAlignCenter}>
                        {/* BADGE */}
                        {DOCUMENT.is_copy ? (
                          <LightToolTip
                            title="This DOCUMENT is a copy."
                            placement="bottom-start"
                          >
                            <div
                              className={CLASSES.badge}
                              style={{
                                background: THEME.palette.success.main,
                              }}
                            >
                              <Typography
                                variant={"body1"}
                                style={{
                                  color: "white",
                                }}
                              >
                                Copy
                              </Typography>
                            </div>
                          </LightToolTip>
                        ) : DOCUMENT.is_link ? (
                          <LightToolTip
                            title="This DOCUMENT is a link."
                            placement="bottom-start"
                          >
                            <div
                              className={CLASSES.badge}
                              style={{
                                background: THEME.palette.warning.main,
                              }}
                            >
                              <Typography
                                variant={"body1"}
                                style={{
                                  color: "white",
                                }}
                              >
                                Link
                              </Typography>
                            </div>
                          </LightToolTip>
                        ) : (
                          <div
                            className={CLASSES.badge}
                            style={{
                              background: THEME.palette.primary.main,
                            }}
                          >
                            <Typography
                              variant={"body1"}
                              style={{
                                color: "white",
                              }}
                            >
                              Original
                            </Typography>
                          </div>
                        )}

                        <Space space={3} />
                        <Line height={16} />
                        <Space space={1} />

                        {/* CLIPBOARD ACTIONS */}
                        {HAS_DEPARTMENT() && !IS_ACCREDITOR() ? (
                          <div className={CLASSES.flexWrapAlignCenter}>
                            <Space space={2} />
                            <IconLink
                              tooltipTitle={"Copy Document"}
                              icon={<RiFileCopyLine size={22} />}
                              onClick={() => onClick("copy")}
                            />
                          </div>
                        ) : null}

                        {HAS_CRUD() ? (
                          <div className={CLASSES.flexWrapAlignCenter}>
                            <Space space={2} />
                            <IconLink
                              tooltipTitle={"Cut Document"}
                              icon={<RiScissorsCutLine size={22} />}
                              onClick={() => onClick("cut")}
                            />
                          </div>
                        ) : null}

                        <Space space={3} />
                        <Line height={16} />
                        <Space space={1} />

                        {/*{DOCUMENT.attachments &&*/}
                        {/*DOCUMENT.attachments.length > 0 ? (*/}
                        {/*   !HAS_CRUD() && IS_ACCREDITOR() ? null : (*/}
                        {/*      <div*/}
                        {/*         className={*/}
                        {/*            CLASSES.flexWrapAlignCenter*/}
                        {/*         }*/}
                        {/*      >*/}
                        {/*         <Space space={2} />*/}
                        {/*         <IconLink*/}
                        {/*            tooltipTitle={*/}
                        {/*               "Download Attachment"*/}
                        {/*            }*/}
                        {/*            icon={*/}
                        {/*               <RiDownloadCloud2Line*/}
                        {/*                  size={22}*/}
                        {/*               />*/}
                        {/*            }*/}
                        {/*            onClick={() =>*/}
                        {/*               onClick("download")*/}
                        {/*            }*/}
                        {/*         />*/}
                        {/*      </div>*/}
                        {/*   )*/}
                        {/*) : null}*/}
                        {/*{DOCUMENT.attachments &&*/}
                        {/*DOCUMENT.attachments.length > 0 &&*/}
                        {/*DOCUMENT.attachments[0].name*/}
                        {/*   .toLowerCase()*/}
                        {/*   .endsWith(".pdf") ? (*/}
                        {/*   !HAS_CRUD() && IS_ACCREDITOR() ? null : (*/}
                        {/*      <div*/}
                        {/*         className={*/}
                        {/*            CLASSES.flexWrapAlignCenter*/}
                        {/*         }*/}
                        {/*      >*/}
                        {/*         <Space space={2} />*/}
                        {/*         <IconLink*/}
                        {/*            tooltipTitle={*/}
                        {/*               "Print Attachment"*/}
                        {/*            }*/}
                        {/*            icon={*/}
                        {/*               <RiPrinterLine size={22} />*/}
                        {/*            }*/}
                        {/*            onClick={() => onClick("print")}*/}
                        {/*         />*/}
                        {/*      </div>*/}
                        {/*   )*/}
                        {/*) : null}*/}
                        {HAS_CRUD() && !DOCUMENT.is_public ? (
                          <div className={CLASSES.flexWrapAlignCenter}>
                            {!DOCUMENT.is_link ? (
                              <>
                                <Space space={2} />
                                <IconLink
                                  tooltipTitle={"Edit"}
                                  icon={<RiEdit2Line size={22} />}
                                  onClick={() => onClick("edit")}
                                />
                              </>
                            ) : null}
                            <Space space={2} />
                            <IconLink
                              tooltipTitle={"Move to Trash"}
                              icon={<RiDeleteBin7Line size={22} />}
                              onClick={() => onClick("delete")}
                            />
                          </div>
                        ) : null}

                        <Space space={3} />
                        <Line height={16} />
                        <Space space={3} />

                        {/* OFFICE HEADS ACTIONS FOR PUBLIC POSTING */}
                        {!IS_SUPPORT() && IS_HEAD() && HAS_CRUD() ? (
                          DOCUMENT.is_public === false ? (
                            <IconLink
                              tooltipTitle={"Request to Publicize (Uniwide)"}
                              icon={
                                <SendOutlined
                                  style={{
                                    fontSize: 22,
                                  }}
                                />
                              }
                              onClick={() => onClick("publicize-request")}
                            />
                          ) : DOCUMENT.is_public === null && DOCUMENT.is_guest !== null ? (
                            <IconLink
                              tooltipTitle={"Cancel Request to Publicize (Uniwide)"}
                              icon={
                                <CancelScheduleSendOutlined
                                  style={{
                                    fontSize: 22,
                                  }}
                                />
                              }
                              onClick={() =>
                                onClick("publicize-request-cancel")
                              }
                            />
                          ) : DOCUMENT.is_public && !DOCUMENT.is_guest ? (
                            <IconLink
                              tooltipTitle={"Unpublicize (Uniwide)"}
                              icon={<BusinessRounded size={22} />}
                              onClick={async () => {
                                await UNPUBLICIZE(
                                  DOCUMENT.docdir_id,
                                  GET_DIRECTORY_DETAILS
                                );
                                await onClick("close-view-document");
                              }}
                            />
                          ) : null
                        ) : null}

                        {IS_SUPPORT() ? (
                          // APPROVAL OR REJECTION OF REQUESTS FOR SUPPORTS
                          IS_PUBLIC_REQUEST() ? (
                            !DOCUMENT.is_public && DOCUMENT.is_guest !== null ? (
                              <div className={CLASSES.flexWrapAlignCenter}>
                                <IconLink
                                  tooltipTitle={"Approve Request to Publicize (Uniwide)"}
                                  icon={
                                    <SendOutlined
                                      style={{
                                        fontSize: 22,
                                      }}
                                    />
                                  }
                                  onClick={() => onClick("publicize-approve")}
                                />
                                <Space space={2} />
                                <IconLink
                                  tooltipTitle={"Reject Request to Publicize (Uniwide)"}
                                  icon={
                                    <CancelScheduleSendOutlined
                                      style={{
                                        fontSize: 22,
                                      }}
                                    />
                                  }
                                  onClick={() => onClick("publicize-reject")}
                                />
                              </div>
                            ) :
                              !DOCUMENT.is_guest ? (
                                <div className={CLASSES.flexWrapAlignCenter}>
                                  <IconLink
                                    tooltipTitle={"Approve Request to Publicize (Global)"}
                                    icon={
                                      <SendOutlined
                                        style={{
                                          fontSize: 22,
                                        }}
                                      />
                                    }
                                    onClick={() => onClick("publicize-approve-guest")}
                                  />
                                  <Space space={2} />
                                  <IconLink
                                    tooltipTitle={"Reject Request to Publicize (Global)"}
                                    icon={
                                      <CancelScheduleSendOutlined
                                        style={{
                                          fontSize: 22,
                                        }}
                                      />
                                    }
                                    onClick={() => onClick("publicize-reject-guest")}
                                  />
                                </div>
                              ) :
                                DOCUMENT.is_public ? (
                                  <IconLink
                                    tooltipTitle={"Unpublicize (Uniwide)"}
                                    icon={<BusinessRounded size={22} />}
                                    onClick={() => onClick("unpublicize")}
                                  />
                                ) : null
                          ) : !DOCUMENT.is_public && !DOCUMENT.is_guest ? (
                            <>
                              <IconLink
                                tooltipTitle={"Publicize (Uniwide)"}
                                icon={<BusinessRounded size={22} />}
                                onClick={() => onClick("publicize")}
                              />
                              <Space space={2} />
                              <IconLink
                                tooltipTitle={"Publicize (Global)"}
                                icon={<RiEarthLine size={22} />}
                                onClick={() => onClick("publicize-guest")}
                              />

                            </>
                          ) : (
                            (!DOCUMENT.is_guest ? (
                              <>
                                <IconLink
                                  tooltipTitle={"Unpublicize (Uniwide)"}
                                  icon={<BusinessRounded size={22} />}
                                  onClick={() => onClick("unpublicize")}
                                />
                                <Space space={2} />
                                <IconLink
                                  tooltipTitle={"Publicize (Global)"}
                                  icon={<RiEarthLine size={22} />}
                                  onClick={() => onClick("publicize-guest")}
                                />
                              </>
                            ) : <IconLink
                              tooltipTitle={"Unpublicize (Global)"}
                              icon={<BusinessRounded size={22} />}
                              onClick={() => onClick("unpublicize-guest")}
                            />)
                          )
                        ) : DOCUMENT.is_public && IS_OFFICEHEAD(active_directory) && IS_AUTHENTICATED() ? (
                          <IconLink
                            tooltipTitle={"Publicized Document (Uniwide)s"}
                            icon={<BusinessRounded size={22} />}

                          />
                        ) : null}
                        <Space space={2} />
                        {!IS_PUBLIC_REQUEST() && !IS_SUPPORT() && HAS_CRUD() && IS_AUTHENTICATED() ? (
                          DOCUMENT.is_guest === false ? (
                            <IconLink
                              tooltipTitle={"Request to Publicize (Global)"}
                              icon={<RiEarthLine size={22} />}
                              onClick={() => onClick("publicize-request-guest")}
                            />
                          ) : DOCUMENT.is_guest === null ? (
                            <IconLink
                              tooltipTitle={"Cancel Request to Publicize (Global)"}
                              icon={
                                <CancelScheduleSendOutlined
                                  style={{
                                    fontSize: 22,
                                  }}
                                />
                              }
                              onClick={() =>
                                onClick("publicize-request-cancel-guest")
                              }
                            />
                          ) : (
                            <IconLink
                              tooltipTitle={"Unpublicize"}
                              icon={<RiEarthLine size={22} />}
                              onClick={() => onClick("unpublicize-guest")}
                            />
                          )) : ""
                        }
                      </div>
                    </div>
                    <Space vertical space={2} />
                  </>
                )}

                <div
                  className={clsx(
                    CLASSES.leftSidebarBodyContentContainer,
                    CLASSES.scrollbar
                  )}
                >
                  {/* DOCUMENT PATH */}
                  <Typography variant={"body1"} className={CLASSES.label}>
                    Document Path
                  </Typography>
                  <Space vertical space={1} />
                  <div className={CLASSES.flex}>
                    <LightToolTip title="Document Path" placement="bottom">
                      <FolderOutlined
                        className={CLASSES.icon}
                        style={{
                          fontSize: 30,
                        }}
                      />
                    </LightToolTip>
                    <Space space={2} />
                    <div
                      className={CLASSES.flexWrapAlignCenter}
                      style={{
                        flexWrap: "wrap",
                      }}
                    >
                      {DOCUMENT.this_path &&
                        DOCUMENT.this_path.map((dir, index) => (
                          <div
                            key={index}
                            className={CLASSES.flexWrapAlignCenter}
                            style={{
                              flexWrap: "wrap",
                            }}
                          >
                            <Space space={1} />
                            {IS_AUTHENTICATED() || (!IS_AUTHENTICATED() && index == 0) ? (
                              <div>
                                <Typography variant={"body1"}>
                                  {dir.name}
                                </Typography>
                              </div>
                            ) : null}
                            <Space space={1} />
                            {(index !== DOCUMENT.this_path.length - 1) && IS_AUTHENTICATED() ? (
                              <KeyboardArrowRightOutlined fontSize={"small"} />
                            ) : null}
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* DOCUMENT SOURCE PATH */}
                  {DOCUMENT.is_copy || DOCUMENT.is_link ? (
                    <div>
                      <Space vertical space={1.5} />
                      <div className={CLASSES.flex}>
                        <LightToolTip
                          title="Document Source Path"
                          placement="bottom"
                        >
                          <LinkOutlined
                            className={CLASSES.icon}
                            style={{
                              fontSize: 30,
                            }}
                          />
                        </LightToolTip>
                        <Space space={2} />
                        {DOCUMENT.source_path ? (
                          <div
                            className={CLASSES.flexWrapAlignCenter}
                            style={{
                              flexWrap: "wrap",
                            }}
                          >
                            {DOCUMENT.source_path.map((dir, index) => (
                              <div
                                key={index}
                                className={CLASSES.flexWrapAlignCenter}
                              >
                                <Space space={1} />
                                <Typography variant={"body1"}>
                                  {dir.name}
                                </Typography>
                                <Space space={1} />
                                {index !== DOCUMENT.source_path.length - 1 ? (
                                  <KeyboardArrowRightOutlined
                                    fontSize={"small"}
                                  />
                                ) : null}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className={CLASSES.flexWrapAlignCenter}>
                            <Space space={1} />
                            <Typography variant={"body2"}>
                              Source DOCUMENT does not exist anymore!
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}

                  <Space vertical space={6} />
                  <Line horizontal width={"100%"} />
                  <Space vertical space={6} />
                  <Grid container spacing={3}>
                    {DOCUMENT.title ? (
                      <Grid item xs={12}>
                        <Typography variant={"body1"} className={CLASSES.label}>
                          Title
                        </Typography>
                        <Typography
                          variant={"body1"}
                          className={CLASSES.typography}
                        >
                          {DOCUMENT.title}
                        </Typography>
                        <Space vertical space={3} />
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid container spacing={3}>
                    {DOCUMENT.description ? (
                      <Grid item xs={12}>
                        <Typography variant={"body1"} className={CLASSES.label}>
                          Description
                        </Typography>
                        <Typography
                          variant={"body1"}
                          className={CLASSES.typography}
                        >
                          {DOCUMENT.description}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid container spacing={3}>
                    {DOCUMENT.attributes && DOCUMENT.attributes.length > 0 ? (
                      <Grid item xs={6}>
                        <Space vertical space={3} />
                        <Typography variant={"body1"} className={CLASSES.label}>
                          Attributes
                        </Typography>
                        <div className={CLASSES.flexWrap}>
                          {DOCUMENT.attributes.map((attribute, index) => (
                            <div className={CLASSES.attribute} key={index}>
                              <Typography
                                variant={"body1"}
                                className={CLASSES.typography}
                              >
                                {attribute.key}: {attribute.value}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </Grid>
                    ) : null}
                    {DOCUMENT.keywords && DOCUMENT.keywords.length > 0 ? (
                      <Grid item xs={6}>
                        <Space vertical space={3} />
                        <Typography variant={"body1"} className={CLASSES.label}>
                          Keywords
                        </Typography>
                        <div className={CLASSES.flexWrap}>
                          {DOCUMENT.keywords.map((keyword, index) => (
                            <div className={CLASSES.attribute} key={index}>
                              <Typography
                                variant={"body1"}
                                className={CLASSES.typography}
                              >
                                {keyword}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Space vertical space={6} />
                  <Line horizontal width={"100%"} />
                  <Space vertical space={6} />

                  {DOCUMENT.attachments && DOCUMENT.attachments.length > 0 ? (
                    <>
                      <Typography variant={"body1"} className={CLASSES.label}>
                        Attachment
                        {DOCUMENT.attachments.length > 1 && "s"}
                      </Typography>
                      <Space vertical space={2} />
                      <Grid container spacing={0}>
                        {DOCUMENT.attachments.map((file, index) => (
                          <Grid item xs={12}>
                            <div
                              onClick={() => onChangeAttachmentClick(index)}
                              className={clsx(
                                CLASSES.attachmentItem,
                                activeAttachmentViewingIndex === index &&
                                CLASSES.attachmentItemActive
                              )}
                            >
                              <img
                                src={GET_FILE_ICON(file.name)}
                                alt={"File icon"}
                                className={CLASSES.fileIcon}
                              />
                              <Space space={3} />
                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  variant={"body1"}
                                  className={CLASSES.typography}
                                  style={{
                                    fontWeight:
                                      activeAttachmentViewingIndex === index
                                        ? "500"
                                        : null,
                                  }}
                                >
                                  {file.name.split("_").join(" ")}
                                </Typography>
                                {IS_ACCREDITOR() || !IS_AUTHENTICATED() ? null : (
                                  <>
                                    <Space space={1} />
                                    <div
                                      className={CLASSES.flexWrapAlignCenter}
                                    >
                                      <IconLink
                                        tooltipTitle={"Download Attachment"}
                                        icon={
                                          <RiDownloadCloud2Line size={22} />
                                        }
                                        onClick={() => {
                                          DOWNLOAD_FROM_URL(
                                            URL + file.dir + file.name
                                          );
                                          MESSAGE(
                                            `Downloading attachment`,
                                            "enqueue",
                                            "success"
                                          );
                                        }}
                                      />
                                      <Space space={1.5} />
                                      <Typography
                                        className={CLASSES.attachmentSize}
                                      >
                                        {file.size}
                                      </Typography>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>{" "}
                      <Space vertical space={6} />
                      <Line horizontal width={"100%"} />
                      <Space vertical space={6} />
                    </>
                  ) : null}

                  <Grid container spacing={3}>
                    {DOCUMENT.type ? (
                      <Grid item xs={6}>
                        <Typography variant={"body1"} className={CLASSES.label}>
                          Type
                        </Typography>
                        <Typography
                          variant={"body1"}
                          className={CLASSES.typography}
                        >
                          {DOCUMENT.type}
                        </Typography>
                        <Space vertical space={3} />
                      </Grid>
                    ) : null}
                    {DOCUMENT.created_at ? (
                      <Grid item xs={6}>
                        <Typography variant={"body1"} className={CLASSES.label}>
                          Date Created
                        </Typography>
                        <Typography
                          variant={"body1"}
                          className={CLASSES.typography}
                        >
                          {DOCUMENT.created_at}
                        </Typography>
                        <Space vertical space={3} />
                      </Grid>
                    ) : null}
                    {DOCUMENT.updated_at ? (
                      <Grid item xs={6}>
                        <Typography variant={"body1"} className={CLASSES.label}>
                          Date Modified
                        </Typography>
                        <Typography
                          variant={"body1"}
                          className={CLASSES.typography}
                        >
                          {DOCUMENT.updated_at}
                        </Typography>
                        <Space vertical space={3} />
                      </Grid>
                    ) : null}
                    {DOCUMENT.author ? (
                      <Grid item xs={6}>
                        <Typography variant={"body1"} className={CLASSES.label}>
                          Author
                        </Typography>
                        <Typography
                          variant={"body1"}
                          className={CLASSES.typography}
                        >
                          {DOCUMENT.author}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </div>
              </div>
            ) : (
              <Space space={4} />
            )}
          </div>
          <Footer noMinWidth />
        </div>
        <div className={clsx(CLASSES.viewerContainer)}>
          {
            DOCUMENT.attachments && DOCUMENT.attachments.length > 0 ? (
              DOCUMENT.attachments[0].name.toLowerCase().endsWith(".pdf") ? (
                <BasicPDFViewer
                  url={
                    DOCUMENT.attachments
                      ? URL +
                      DOCUMENT.attachments[activeAttachmentViewingIndex].dir +
                      DOCUMENT.attachments[activeAttachmentViewingIndex].name
                      : null
                  }
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    maxHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant={"h3"}>No Preview</Typography>
                  <Space vertical space={1.5} />
                  <Typography>Unsupported Format</Typography>
                </div>
              )
            ) : null

            // <GoogleDocsViewer
            //     width="100%"
            //     height={'calc(100vh)'}
            //     fileUrl={URL + DOCUMENT.attachments[0].dir + DOCUMENT.attachments[0].name}
            //     // fileUrl={'http://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf'}
            // />
          }
        </div>
      </div>
    </Dialog>
  );
};

class CreateNew extends Component {
  render() {
    return <Body class={this} />;
  }
}

export default connect(STATES, null)(CreateNew);
