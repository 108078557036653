import React from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Space from "./panel/Space";

export default function Main(props) {
   return (
      <>
         {(props.PQALink || props.AACCUPLink) && (
            <>
               <Typography variant={"body1"}>
                  Use this link to conduct virtual meeting with your
                  counterpart. Please contact QAD thru Hency C. Encabo, Director
                  at 09177079609 or Marriez T. Aquino, Deputy Director (PACAU)
                  at 09257581219 for proper coordination.
               </Typography>
               {props.PQALink && (
                  <>
                     <Space vertical space={3} />
                     <Typography variant={"body1"}>
                        PQA Link (Internal Accreditors)
                     </Typography>
                     <Link href={props.PQALink} target={"_blank"}>
                        <Typography variant={"h6"}>{props.PQALink}</Typography>
                     </Link>
                  </>
               )}
               {props.AACCUPLink && (
                  <>
                     <Space vertical space={3} />
                     <Typography variant={"body1"}>
                        Program Accreditation Link (AACCUP Accreditors)
                     </Typography>
                     <Link href={props.AACCUPLink} target={"_blank"}>
                        <Typography variant={"h6"}>
                           {props.AACCUPLink}
                        </Typography>
                     </Link>
                  </>
               )}
               <Space vertical space={4} />
            </>
         )}
      </>
   );
}
