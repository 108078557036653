import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import Space from "./core/panel/Space";
import LargeCreateNew from "./core/button/LargeCreateNew";
import { setContext, setToggle } from "../redux/actions/context";
import HAS_CRUD from "../utils/permissions/HAS_CRUD";
import DirectoryTree from "./layout/DirectoryTree";
import clsx from "clsx";

export default function HomeSidebar() {
  const styles = style();
  const { department } = useSelector((state) => ({
    department: state.AUTH.department,
  }));

  const onClickCreateNew = () => {
    setContext("formRequestMode", "CREATE");
    setContext("formType", "Document");
    setToggle("create_new", true);
  };

  return (
    <>
      {department?.length === 0 ? (
        <Space vertical space={4} />
      ) : (
        <LargeCreateNew onClick={onClickCreateNew} disabled={!HAS_CRUD()} />
      )}

      <div
        className={clsx(
          styles.sidebarLeftDirectoryTree,
          styles.scrollbarHidden
        )}
        style={{
          height:
            department && department.length === 0
              ? "calc(100vh - 83px - 2px - 32px)"
              : "calc(100vh - 83px - 100px)",
        }}
      >
        <DirectoryTree />
      </div>
    </>
  );
}

const style = makeStyles((theme) => ({
  sidebarLeftDirectoryTree: {
    height: "calc(100vh - 83px - 100px)",
    overflow: "hidden",
    overflowX: "none",
  },
  scrollbarHidden: {
    "&:hover": {
      overflow: "scroll",
      "&::-webkit-scrollbar": {
        marginRight: "-8px",
        width: 8,
        height: 8,
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 10,
        opacity: 1,
        backgroundColor: theme.palette.grey.S2,
      },
    },
  },
}));
