import { createTheme } from '@material-ui/core/styles'

export const THEME = createTheme({
  palette: {
    default: {
      main: "#303030"
    },
    primary: {
      // main: '#7E0308',
      main: "#D75D5F",
      gradient: "linear-gradient(275.52deg, #973939 0.28%, #DC7171 100%)"
      // pastel:
    },
    secondary: {
      main: "#E59C24",
      light: "#fff6ed"
    },
    error: {
      main: "#F15642"
    },
    warning: {
      main: "#EEB04C"
    },
    success: {
      main: "#84BD5A"
    },
    grey: {
      S0: "#F5F5F5",
      S1: "#EFEFEF",
      S2: "#CFCFCF",
      S3: "#AAAAAA",
      S4: "#707070",
      S5: "rgb(0,0,0,0.87)",
      RGB0: "rgba(239, 239, 239, 0.54)"
    }
  },
  shape: {
    borderRadius: 5
  }

  // overrides: {
  //     MuiPaper: {
  //         root: {
  //             boxShadow: 'none !important'
  //         }
  //     },
  //     MuiToolbar: {
  //         root: {
  //             padding: '0 !important'
  //         }
  //     },
  //     MUIDataTablePagination: {
  //         tableCellContainer: {
  //             padding: '0 !important'
  //         }
  //     },
  // }
});
