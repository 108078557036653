import { store } from "../../redux";

const { getState } = store

export default function Main(directory_id) {
    const DIRECTORY_TREE = getState().DIRECTORY.tree
    if (DIRECTORY_TREE) {
        directory_id = !directory_id ? getState().CONTEXT.active_directory : directory_id
        if (DIRECTORY_TREE.departments && DIRECTORY_TREE.departments.length > 0)
            for (let dir of DIRECTORY_TREE.departments)
                if ('dep' + dir.directory_id === directory_id) return true
        if (DIRECTORY_TREE.public && DIRECTORY_TREE.public.length > 0)
            for (let dir of DIRECTORY_TREE.public)
                if ('pub' + dir.directory_id === directory_id) return true
    }
    return false
}
