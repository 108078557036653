import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Vector from "../static/media/img/Vector.png";
import InputText from "../components/core/input/InputText";
import Space from "../components/core/panel/Space";
import Button from "../components/core/button/Button";
import { LOG_IN } from "../redux/actions/auth";
import QASASBrand from "../static/media/img/Brand.png";
import SDMD from "../static/media/img/SDMD.png";
// import Agreement from "../components/core/dialog/Agreement";
import STATES from "../redux/states";
import { setToggle } from "../redux/actions/context";
import IS_ONLINE from "../utils/context/IS_ONLINE";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
   root: {
      height: "100vh",
      minHeight: "calc(718px + 100px)",
      width: "100%",
      background: theme.palette.grey.RGB0,
   },
   header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      background: "white",
   },
   headerContent: {
      width: 1160,
      margin: "auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
   },
   topHeader: {
      background: theme.palette.primary.gradient,
      height: 25,
      width: "100%",
   },
   content: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
   },
   container: {
      width: 1000,
      margin: "auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      padding: theme.spacing(10),
      borderRadius: theme.shape.borderRadius,
      background: "white",
   },
   vectorContainer: {
      display: "flex",
      alignItems: "flex-end",
      height: "100%",
   },
   vector: {
      width: "100%",
   },
   brand: {
      height: 48,
   },
   inputText: {
      width: 350,
      padding: theme.spacing(2),
      border: "1px solid " + theme.palette.grey.S3,
      borderRadius: theme.shape.borderRadius,
      background: "white",
      outline: 0,
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.grey.S4,
   },
   flex: {
      display: "flex",
   },
   flexAlignItemsCenter: {
      alignItems: "center",
   },
   flexSpaceBetween: {
      justifyContent: "space-between",
   },
}));

const Body = (props) => {
   const classes = useStyles();
   const history = useHistory();
   // const [agreement, setAgreement] = React.useState(true);

   const showPasswordOnChange = () =>
      props.class.setState((prev) => ({
         show_password: !prev.show_password,
      }));
   const inputTextOnChange = (e) =>
      props.class.setState({ [e.target.name]: e.target.value });

   const login = async () => {
      await setToggle("login_loader", true);
      await LOG_IN(
         props.class.state.employee_id,
         props.class.state.password,
         (path) => {
            history.push(path);
         },
         () => { }
      );
   };
   const onKeyDown = (event) => {
      if (event.key === "Enter") login();
   };

   return (
      <div className={classes.root}>
         <div className={classes.topHeader}></div>
         <div className={classes.header}>
            <div className={classes.headerContent}>
               <img
                  src={QASASBrand}
                  alt={"QASAS Brand"}
                  className={classes.brand}
               />
               <img src={SDMD} alt={"SDMD"} className={classes.brand} />
            </div>
         </div>
         <div className={classes.content}>
            <div className={classes.container}>
               <div style={{ width: 500 }}>
                  <Typography variant={"h4"}>Hello there!</Typography>
                  <Typography variant={"h6"}>
                     Please login to get started.
                  </Typography>
                  <Space vertical space={4} />
                  <InputText
                     name={"employee_id"}
                     value={props.class.state.employee_id}
                     onChange={inputTextOnChange}
                     onKeyDown={onKeyDown}
                     placeholder={"Username or PMAPS ID"}
                     className={classes.inputText}
                  />
                  <Space vertical space={2} />
                  <InputText
                     name={"password"}
                     value={props.class.state.password}
                     onChange={inputTextOnChange}
                     onKeyDown={onKeyDown}
                     placeholder={"Password"}
                     type={
                        props.class.state.show_password ? "text" : "password"
                     }
                     className={classes.inputText}
                  />
                  <Space vertical space={2} />
                  <FormControlLabel
                     control={
                        <Checkbox
                           name={"public"}
                           checked={props.class.state.show_password}
                           onChange={showPasswordOnChange}
                           value={props.class.state.show_password}
                           color="primary"
                        />
                     }
                     label="Show Password"
                     labelPlacement="end"
                  />
                  <Space vertical space={7} />
                  <Button
                     label={"LOGIN"}
                     variant={"outlined"}
                     active
                     disabled={!IS_ONLINE()}
                     loading={props.class.props.CONTEXT.toggles.login_loader}
                     class={props.class}
                     onClick={login}
                  />
               </div>
               <Space space={5} />
               <div className={classes.vectorContainer}>
                  <img src={Vector} alt={"Vector"} className={classes.vector} />
               </div>
            </div>
         </div>

         {/* <Agreement
            open={agreement}
            onClose={() => {
               setAgreement(false);
            }}
         /> */}
      </div>
   );
};

class Login extends Component {
   state = {
      employee_id: "",
      password: "",
      show_password: false,
   };

   // componentWillMount() {
   //     if (IS_AUTHENTICATED()) {
   //         if (IS_ADMIN()) {
   //             REDIRECT('/admin')
   //         }
   //     }
   // }

   render() {
      return <Body class={this} />;
   }
}

export default connect(STATES, null)(Login);
