import { DEPARTMENT_GET_PUBLIC_ALL, DEPARTMENT_GET_PUBLIC_GUEST, USER_DEPARTMENTS } from "../actions/types";

const init = {
    public: [],
    office: [],
    guest: [],
    userDepartments: [],
}

export default function Main(state = init, action) {
    switch (action.type) {
        case DEPARTMENT_GET_PUBLIC_ALL:
            return {
                ...state,
                public: action.payload
            };
        case DEPARTMENT_GET_PUBLIC_GUEST:
            return {
                ...state,
                guest: action.payload
            };
        case USER_DEPARTMENTS:
            return {
                ...state,
                userDepartments: action.payload
            };
        default:
            return state
    }
}