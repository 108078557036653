import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import InputText from "../core/input/InputText";
import InputTextSuggestion from "../core/input/InputTextSuggestion";
import Space from "../core/panel/Space";
import InputUserSuggestion from "../core/input/InputUserSuggestion";
import UserChipList from "../core/chip/UserChipList";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import InputTextArea from "../core/input/InputTextArea";
import { setForm } from "../../redux/actions/context";
import { GET_SUGGESTION } from "../../redux/actions/suggestion";
import LightToolTip from "../core/tooltip/LightToolTip";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  groupControl: {
    // marginRight: theme.spacing(8),
    marginBottom: theme.spacing(6),
    minWidth: 300,
    width: "100%",
  },
  flexAlignItemsCenter: {
    display: "flex",
    alignItems: "center",
    // width: '100%'
  },
  flexAlignItemsCenterSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  icon: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  badge: {
    color: theme.palette.error.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function Main(props) {
  const classes = useStyles();
  const form = props.class.props.CONTEXT.forms.directory;
  const suggestion = props.class.props.SUGGESTION;

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <div className={classes.flexColumn} style={{ paddingRight: 24 }}>
            <div className={classes.groupControl}>
              <div className={classes.flexAlignItemsCenter}>
                <Typography variant={"body1"}>
                  What is the name of the directory?
                </Typography>
                <Space space={1} />
                <LightToolTip title={"Required field"} placement={"right"}>
                  <Typography
                    variant={"body1"}
                    color={"primary"}
                    className={classes.badge}
                  >
                    *
                  </Typography>
                </LightToolTip>
              </div>
              <Space vertical space={2} />
              <InputText
                name={"name"}
                value={form.name}
                error={form.error_name}
                placeholder={"Ex. BSCE"}
                onChange={(event) =>
                  setForm("directory", event.target.name, event.target.value)
                }
              />
            </div>
            <div className={clsx(classes.groupControl)}>
              <Typography variant={"body1"}>
                What is the description?
              </Typography>
              <Space vertical space={2} />
              <InputTextArea
                name={"description"}
                rows={5}
                value={form.description}
                placeholder={""}
                onChange={(event) =>
                  setForm("directory", event.target.name, event.target.value)
                }
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={5}>
          <div className={classes.flexColumn} style={{ paddingLeft: 24 }}>
            <div className={classes.groupControl}>
              <Typography variant={"body1"}>
                To which group does this directory belong?
              </Typography>
              <Space vertical space={2} />
              <InputTextSuggestion
                name={"group"}
                items={suggestion.directory_groups}
                value={form.group}
                maxlength={100}
                type={"directory"}
                placeholder={"Ex. Programs"}
                onChange={setForm}
                onSelect={setForm}
                style={{ width: "100%" }}
                onKeyDown={props.onKeyDown}
                callback={() => {
                  GET_SUGGESTION(
                    "directory_group",
                    props.class.props.CONTEXT.forms.directory.group
                  );
                }}
              />
            </div>
            <div className={classes.groupControl}>
              <div className={classes.groupControl}>
                <div className={classes.flexAlignItemsCenterSpaceBetween}>
                  <Typography variant={"body1"}>
                    Who are the moderators of this directory?
                  </Typography>
                </div>
                <Space vertical space={2} />
                <InputUserSuggestion
                  name={"moderator"}
                  items={suggestion.department_users}
                  value={form.moderator.name}
                  maxlength={100}
                  type={"directory"}
                  placeholder={"Ex. John Smith"}
                  multiple
                  onChange={setForm}
                  onSelect={async (id, name) => {
                    let includes = false;
                    for (let item of form.moderators)
                      if (item.id === id) includes = true;
                    if (!includes)
                      form.moderators.push({
                        id,
                        name,
                      });
                    await setForm("directory", "moderators", form.moderators);
                    await setForm("directory", "moderator", {
                      id: 0,
                      name: "",
                    });
                  }}
                  callback={() => {
                    GET_SUGGESTION(
                      "department_user",
                      props.class.props.CONTEXT.forms.directory.moderator.name
                    );
                  }}
                />
                <Space vertical space={2} />
                <UserChipList
                  object
                  list={form.moderators}
                  handleDelete={(item) => {
                    setForm(
                      "directory",
                      "moderators",
                      form.moderators.filter((i) => i !== item)
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
