import React from "react";
import Viewer, {
     defaultLayout,
     LoadError,
     RenderPage,
     RenderPageProps,
     SelectionMode,
     Worker,
} from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

const BasicPDFViewer = ({ url }) => {
     // WATERMARK
     const renderPage: RenderPage = (props: RenderPageProps) => (
          <>
               {props.canvasLayer.children}
               <div
                    style={{
                         alignItems: "center",
                         display: "flex",
                         height: "100%",
                         justifyContent: "center",
                         left: 0,
                         position: "absolute",
                         top: 0,
                         width: "100%",
                    }}
               >
                    <div
                         style={{
                              color: "rgba(0, 0, 0, 0.1)",
                              fontSize: `${6 * props.scale}rem`,
                              fontWeight: "bold",
                              // textTransform: 'uppercase',
                              transform: "rotate(-45deg)",
                              userSelect: "none",
                              textAlign: "center",
                              overflow: "hidden",
                              // opacity: 0.075
                         }}
                    >
                         {/*<img src={USePLogo} alt={'logo'} height={500}/>*/}
                         For USeP <br /> use only
                    </div>
               </div>
               {props.annotationLayer.children}
               {props.textLayer.children}
          </>
     );

     // CUSTIM TOOLBARS
     const renderToolbar = (toolbarSlot) => {
          return (
               <div
                    style={{
                         alignItems: "center",
                         display: "flex",
                         width: "100%",
                    }}
               >
                    <div
                         style={{
                              alignItems: "center",
                              display: "flex",
                         }}
                    >
                         <div style={{ padding: "0 2px" }}>
                              {toolbarSlot.fullScreenButton}
                         </div>
                         {/*<div style={{padding: '0 2px'}}>*/}
                         {/*    {toolbarSlot.searchPopover}*/}
                         {/*</div>*/}
                         {/*<div style={{padding: '0 2px'}}>*/}
                         {/*    {toolbarSlot.previousPageButton}*/}
                         {/*</div>*/}
                         {/*<div style={{padding: '0 2px'}}>*/}
                         {/*    {toolbarSlot.currentPageInput} / {toolbarSlot.numPages}*/}
                         {/*</div>*/}
                         {/*<div style={{padding: '0 2px'}}>*/}
                         {/*    {toolbarSlot.nextPageButton}*/}
                         {/*</div>*/}
                    </div>
                    <div
                         style={{
                              alignItems: "center",
                              display: "flex",
                              flexGrow: 1,
                              flexShrink: 1,
                              justifyContent: "center",
                         }}
                    >
                         {/*<div style={{padding: '0 2px'}}>*/}
                         {/*    {toolbarSlot.searchPopover}*/}
                         {/*</div>*/}
                         <div style={{ padding: "0 2px" }}>
                              {toolbarSlot.previousPageButton}
                         </div>
                         <div style={{ padding: "0 2px" }}>
                              {toolbarSlot.currentPageInput} /{" "}
                              {toolbarSlot.numPages}
                         </div>
                         <div style={{ padding: "0 2px" }}>
                              {toolbarSlot.nextPageButton}
                         </div>
                         <div style={{ padding: "0 2px" }}>
                              {toolbarSlot.zoomOutButton}
                         </div>
                         <div style={{ padding: "0 2px" }}>
                              {toolbarSlot.zoomPopover}
                         </div>
                         <div style={{ padding: "0 2px" }}>
                              {toolbarSlot.zoomInButton}
                         </div>
                    </div>
                    <div
                         style={{
                              alignItems: "center",
                              display: "flex",
                              marginLeft: "auto",
                         }}
                    >
                         <div style={{ padding: "0 2px" }}>
                              {toolbarSlot.moreActionsPopover}
                         </div>
                    </div>
               </div>
          );
     };
     const layout = (isSidebarOpened, container, main, toolbar, sidebar) => {
          return defaultLayout(
               isSidebarOpened,
               container,
               main,
               toolbar(renderToolbar),
               sidebar
          );
     };

     // CUSTOM ERROR
     const renderError = (error: LoadError) => {
          let message = "";
          switch (error.name) {
               case "InvalidPDFException":
                    message = "The document is invalid or corrupted";
                    break;
               case "MissingPDFException":
                    message = "The document is missing";
                    break;
               case "UnexpectedResponseException":
                    message = "Unexpected server response";
                    break;
               default:
                    message = "Cannot load the document";
                    break;
          }

          return (
               <div
                    style={{
                         alignItems: "center",
                         display: "flex",
                         height: "100%",
                         justifyContent: "center",
                    }}
               >
                    <div
                         style={{
                              backgroundColor: "#e53e3e",
                              borderRadius: "0.25rem",
                              color: "#fff",
                              padding: "0.5rem",
                         }}
                    >
                         {message}
                    </div>
               </div>
          );
     };

     return (
          <div
               style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
               }}
          >
               <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                    <div style={{ height: "100%", width: "100%" }}>
                         <Viewer
                              fileUrl={url}
                              renderPage={renderPage}
                              layout={layout}
                              renderError={renderError}
                              selectionMode={SelectionMode.Hand}
                         />
                    </div>
               </Worker>
          </div>
     );
};

export default BasicPDFViewer;
