import {
     ADMIN_DEPARTMENTS_SET,
     ADMIN_RESET,
     ADMIN_STAGING_AUTHENTICATION_SET,
     ADMIN_USERS_SET,
     USERS_USER_DEPARTMENTS_SET,
} from "../actions/types";

const init = {
     staging_authenticated: false,
     users_user_departments: [],
     users: [],
     departments: [],
};

export default function Main(state = init, action) {
     switch (action.type) {
          case ADMIN_STAGING_AUTHENTICATION_SET:
               return {
                    ...state,
                    staging_authenticated: action.staging_authenticated,
               };

          case ADMIN_USERS_SET:
               return {
                    ...state,
                    users: action.payload,
               };
          case USERS_USER_DEPARTMENTS_SET:
               return {
                    ...state,
                    users_user_departments: action.payload,
               };
          case ADMIN_DEPARTMENTS_SET:
               return {
                    ...state,
                    departments: action.payload,
               };

          case ADMIN_RESET:
               return init;

          default:
               return state;
     }
}
