import { env } from "../env";
import print from "../utils/misc/print";
import API, { getAPIConfig } from "./index";
import { isFunction } from "../utils/function";
import { resetOfficeTrash, setOfficeTrash } from "../redux/actions/trash";
import { getState } from "../redux";
import { setToggle } from "../redux/actions/context";
import _ from "lodash";
import { GET_DIRECTORY_DETAILS } from "../redux/actions/directory";

export const apiGetOfficeTrash = async (directory_id, onSuccess, onFail) => {
  setToggle("loading_office_trash", true);

  if (!directory_id)
    directory_id = getState().CONTEXT.active_directory.substring(3);
  const url = `${env.URL_API}trash/office/${directory_id}`;
  const response = await API.get(url, null, getAPIConfig());
  print("GET OFFICE TRASH", response);

  if (response.ok) {
    setOfficeTrash(response.data);
    if (isFunction(onSuccess)) onSuccess(response);
  } else {
    if (isFunction(onFail)) onFail(response);
  }

  setToggle("loading_office_trash", false);
};

export const apiRestoreDirectory = async (trash_id) => {
  const trash = getState().TRASH;
  const url = `${env.URL_API}trash/office/restore/directory/${trash_id}`;
  const response = await API.post(url, null, getAPIConfig());
  print("RESTORE DIRECTORY", response);
  if (response.ok) {
    setOfficeTrash({
      ...trash,
      directory: _.filter(trash.directory, (i) => i.id !== trash_id),
    });
    GET_DIRECTORY_DETAILS();
  }
  return response;
};

export const apiDeleteDirectoryPermanently = async (trash_id) => {
  const trash = getState().TRASH;
  const url = `${env.URL_API}trash/office/delete/directory/${trash_id}`;
  const response = await API.post(url, null, getAPIConfig());
  print("DELETE DIRECTORY", response);
  if (response.ok) {
    setOfficeTrash({
      ...trash,
      directory: _.filter(trash.directory, (i) => i.id !== trash_id),
    });
  }
  return response;
};

export const apiRestoreDocument = async (trash_id) => {
  const trash = getState().TRASH;
  const url = `${env.URL_API}trash/office/restore/document/${trash_id}`;
  const response = await API.post(url, null, getAPIConfig());
  print("RESTORE DOCUMENT", response);
  if (response.ok) {
    setOfficeTrash({
      ...trash,
      document: _.filter(trash.document, (i) => i.id !== trash_id),
    });
    GET_DIRECTORY_DETAILS();
  }
  return response;
};

export const apiDeleteDocumentPermanently = async (trash_id) => {
  const trash = getState().TRASH;
  const url = `${env.URL_API}trash/office/delete/document/${trash_id}`;
  const response = await API.post(url, null, getAPIConfig());
  print("DELETE DOCUMENT", response);
  if (response.ok) {
    setOfficeTrash({
      ...trash,
      document: _.filter(trash.document, (i) => i.id !== trash_id),
    });
  }
  return response;
};

export const apiRestoreAllTrash = async (directory_id) => {
  if (!directory_id)
    directory_id = getState().CONTEXT.active_directory.substring(3);
  const url = `${env.URL_API}trash/office/${directory_id}/restore/all`;
  const response = await API.post(url, null, getAPIConfig());
  print("RESTORE ALL TRASH", response);
  if (response.ok) {
    resetOfficeTrash();
    GET_DIRECTORY_DETAILS();
  }
  return response;
};

export const apiDeleteAllTrash = async (directory_id) => {
  if (!directory_id)
    directory_id = getState().CONTEXT.active_directory.substring(3);
  const url = `${env.URL_API}trash/office/${directory_id}/delete/all`;
  const response = await API.post(url, null, getAPIConfig());
  print("DELETE ALL TRASH", response);
  if (response.ok) {
    resetOfficeTrash();
  }
  return response;
};
