import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Space from "../panel/Space";
import clsx from "clsx";
import { RiAddLine } from "react-icons/all";

const useStyles = makeStyles((theme) => ({
     root: {
          border: "1px solid " + theme.palette.grey.S3,
          borderRadius: theme.shape.borderRadius,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
          width: 220,
          textAlign: "center",
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(3),
          marginLeft: theme.spacing(3),
          display: "flex",
          justifyContent: "center",

          "&:hover": {
               cursor: "pointer",
               border: "1px solid white",
               background: theme.palette.primary.gradient,
               color: "white",
               fontWeight: "bold",
          },
     },
     control: {
          display: "flex",
          alignItems: "center",
     },
     disabled: {
          "&:hover": {
               cursor: "pointer",
               border: "1px solid " + theme.palette.grey.S3,
               // background: theme.palette.grey.S2,
               background: "white",
               color: theme.palette.grey.S4,
               fontWeight: "bold",
          },
     },
}));

export default function Main(props) {
     const classes = useStyles();
     return (
          <div
               className={clsx(
                    classes.root,
                    props.disabled ? classes.disabled : null
               )}
               onClick={props.disabled ? null : props.onClick}
          >
               <div className={classes.control}>
                    <RiAddLine size={22} />
                    <Space space={1} />
                    <Typography variant={"body1"}>Create New</Typography>
               </div>
          </div>
     );
}
