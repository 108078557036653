import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Space from "../core/panel/Space";
import Line from "../core/decoration/Line";
import {
  CheckOutlined,
  FilterListOutlined,
  FormatListBulletedOutlined,
  GridOnOutlined,
  SortOutlined,
} from "@material-ui/icons";
import LightToolTip from "../core/tooltip/LightToolTip";
import clsx from "clsx";
import Popover from "@material-ui/core/Popover";
import IS_PUBLIC_REQUEST from "../../utils/context/IS_PUBLIC_REQUEST";
import IS_GUEST from "../../utils/permissions/IS_GUEST";
import { setToggle } from "../../redux/actions/context";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(3),
  },
  iconAction: {
    color: theme.palette.grey.S4,
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  popoverRoot: {
    marginTop: 20,
  },
  popoverPaper: {
    boxShadow: theme.shadows[3],
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  popoverItemRoot: {
    display: "flex",
    alignItems: "center",
    maxWidth: 250,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.grey.RGB0,
      color: theme.palette.primary.main,
    },
  },
  checkIcon: {
    width: theme.spacing(2),
    height: 20,
  },
}));

const PopoverItem = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.popoverItemRoot} onClick={props.onClick}>
      {props.HTTP_NET_TEST ? (
        <div className={classes.flex}>
          <CheckOutlined className={classes.checkIcon} />
          <Space space={1.5} />
        </div>
      ) : (
        <Space space={3.5} />
      )}
      <Typography noWrap variant={"body2"} className={clsx(classes.typography)}>
        {props.label}
      </Typography>
    </div>
  );
};

export default function Main(props) {
  const classes = useStyles();
  const [sortPopoverAnchorEl, setSortPopoverAnchorEl] = React.useState(null);
  const [sortOpen, setSortOpen] = React.useState(false);

  const { listView } = useSelector((state) => ({
    listView: state.CONTEXT.toggles.directory_list_view || false,
  }));

  const toggleListView = () => {
    setToggle("directory_list_view", !listView);
  };

  const handleClick = async (event) => {
    setSortPopoverAnchorEl(event.currentTarget);
    setSortOpen(true);
  };

  const handleClose = () => {
    setSortPopoverAnchorEl(null);
    setSortOpen(false);
  };

  return (
    <div className={clsx(classes.root, classes.flex)}>
      <Space space={3} />
      <Line height={24} />
      <Space space={3} />
      {/*{ props.class.props.CONTEXT.toggles.directoryListView ? null :*/}
      {!listView ? (
        <div className={classes.flex}>
          <LightToolTip title="Sort Documents" placement="bottom">
            <SortOutlined className={classes.iconAction} onClick={handleClick} />
          </LightToolTip>
          <Space space={2} />
        </div>
      ) : null}

      {!IS_GUEST() ? (
        <div className={classes.flex}>
          <LightToolTip title="Filter Documents" placement="bottom">
            <FilterListOutlined
              className={classes.iconAction}
              onClick={props.filterOnClick}
            />
          </LightToolTip>
          <Space space={2} />
        </div>
      ) : null}

      <div className={classes.flex}>
        {!IS_PUBLIC_REQUEST() ? (
          <LightToolTip title={`Change view`} placement="bottom">
            {listView ? (
              <FormatListBulletedOutlined
                className={classes.iconAction}
                onClick={toggleListView}
              />
            ) : (
              <GridOnOutlined
                className={classes.iconAction}
                onClick={toggleListView}
              />
            )}
          </LightToolTip>
        ) : null}
      </div>
      {/*}*/}

      {/*<Space space={2}/>*/}
      {/*<LightToolTip title="Change View" placement="bottom">*/}
      {/*    { props.class.props.CONTEXT.toggles.directoryListView ?*/}
      {/*        <ListOutlined*/}
      {/*            className={classes.iconAction}*/}
      {/*            onClick={changeView}*/}
      {/*        /> :*/}
      {/*        <ViewModuleOutlined*/}
      {/*            className={classes.iconAction}*/}
      {/*            onClick={changeView}*/}
      {/*        />*/}

      {/*    }*/}
      {/*</LightToolTip>*/}
      {/*<Space space={2}/>*/}
      {/*<LightToolTip title="Change View" placement="bottom">*/}
      {/*    <List className={classes.iconAction}/>*/}
      {/*</LightToolTip>*/}

      <Popover
        id={"popover-more-directory"}
        open={sortOpen}
        anchorEl={sortPopoverAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          root: classes.popoverRoot,
          paper: classes.popoverPaper,
        }}
      >
        <PopoverItem
          label={"Title"}
          HTTP_NET_TEST={props.sortBy === "title"}
          onClick={() => props.sortChangeOnClick("sort_by", "title")}
        />
        <PopoverItem
          label={"Date Created"}
          HTTP_NET_TEST={props.sortBy === "created_at"}
          onClick={() =>
            props.sortChangeOnClick("sort_by", "created_at")
          }
        />
        <PopoverItem
          label={"Date Modified"}
          HTTP_NET_TEST={props.sortBy === "updated_at"}
          onClick={() =>
            props.sortChangeOnClick("sort_by", "updated_at")
          }
        />
        <Space vertical space={0.75} />
        <Line horizontal width={"100%"} />
        <Space vertical space={0.75} />
        <PopoverItem
          label={"Ascending"}
          HTTP_NET_TEST={props.ascending}
          onClick={() => props.sortChangeOnClick("ascending", true)}
        />
        <PopoverItem
          label={"Descending"}
          HTTP_NET_TEST={!props.ascending}
          onClick={() => props.sortChangeOnClick("ascending", false)}
        />
      </Popover>
    </div>
  );
}
