import React, {Component, useState} from 'react';
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core";
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import Space from "../../components/core/panel/Space";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Users from "./Users";
import Offices from "./office/Offices";
import Priviledges from "./Priviledges";
import Monitoring from "./Monitoring";
import Maintenance from "./Maintenance";

const useStyles = makeStyles(theme => ({
    bodyContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 'calc(100vh - 83px)',
        paddingLeft: 24,
        paddingRight: 24,
    },
    menu: {
        paddingTop: 24,
        width: 300,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    activeMenu: {
        color: theme.palette.primary.main
    },
    body: {
        paddingTop: 24,
        width: '100%'
    },
}));

const menu = [
    {
        id: 1,
        label: 'Users'
    },
    {
        id: 2,
        label: 'Offices'
    },
    {
        id: 3,
        label: 'Privileges'
    },
    {
        id: 4,
        label: 'Monitoring'
    },
    {
        id: 5,
        label: 'Maintenance'
    },
]

const menuComponents = {
    1: <Users/>,
    2: <Offices/>,
    3: <Priviledges/>,
    4: <Monitoring/>,
    5: <Maintenance/>,
}

const Body = props => {
    const classes = useStyles()
    const [activeMenuID, setActiveMenuID] = useState(1)
    return (
        <div>
            <Header/>
            <div className={classes.bodyContainer}>
                <div className={classes.menu}>
                    {/*<Typography*/}
                    {/*    variant={'body2'}*/}
                    {/*>*/}
                    {/*    Menu*/}
                    {/*</Typography>*/}
                    {/*<Space vertical space={2}/>*/}
                    {menu.map(item => (
                        <div key={item.id}>
                            <Typography
                                variant={'body1'}
                                className={clsx(
                                    item.id === activeMenuID ? classes.activeMenu : null
                                )}
                                style={{fontWeight: item.id === activeMenuID ? 'bold' : 'normal'}}
                                onClick={() => setActiveMenuID(item.id)}
                            >
                                {item.label}
                            </Typography>
                            <Space vertical space={0.5}/>
                        </div>
                    ))}
                </div>
                <div className={classes.body}>
                    {menuComponents[activeMenuID]}
                </div>
            </div>
            <Footer/>
        </div>
    )
}

class Admin extends Component {
    render() {
        return <Body class={this}/>
    }
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, {})(Admin)