import axios from "axios";
import { env } from "../../env";
import { ADMIN_RESET, ADMIN_STAGING_AUTHENTICATION_SET } from "./types";
import { store } from "../index";
import print from "../../utils/misc/print";
// import {REDIRECT, RELOAD} from "../../utils/history";
const TOKEN_KEY = "Token ";
const url = env.URL_API + "app/";
const { dispatch, getState } = store;

export const STAGING_LOG_IN = (key, onSuccess, onFail) => {
  // PARSE REQUEST PARAMETERS
  const data = new FormData();
  data.set("key", key);

  // SEND REQUEST
  axios
    .post(url + "staging-pass", data)
    .then(async (response) => {
      print("STAGING LOGIN", response);
      dispatch({
        type: ADMIN_STAGING_AUTHENTICATION_SET,
        staging_authenticated: response.data.staging_authenticated,
      });
      if (typeof onSuccess === "function") onSuccess();
    })
    .catch((error) => {
      // PRINT RESULT ERROR
      print("STAGING LOGIN", error);
      if (typeof onFail === "function") onFail();
    });
};

export const STAGING_LOG_OUT = () => {
  dispatch({
    type: ADMIN_RESET,
  });
};

export const POPULATE_HRIS_USER_DATA = () => {
  const AUTH = getState().AUTH;
  const data = new FormData();

  axios.post(url + "populate_hris_users", data, {
    headers: { Authorization: TOKEN_KEY + AUTH.token },
  })
    .then(async (response) => {
      print("POPULATE USERS", response);

    })
    .catch((error) => {
      print("POPULATE USERS", error);
    });
};
