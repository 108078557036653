import { DOCUMENT_DETAILS_GET, DOCUMENT_REQUESTED_TO_PUBLICIZE_GET, DOCUMENT_DIRECTORY_GET, DOCUMENT_ACTIONS_GET, DOCUMENT_MONITORING_GET } from "../actions/types";

const init = {
    details: {},
    requested_to_publicize: {
        documents: [],
        departments: []
    }
}

export default function Main(state = init, action) {
    switch (action.type) {
        case DOCUMENT_DETAILS_GET:
            return {
                ...state,
                details: action.payload
            }
        case DOCUMENT_REQUESTED_TO_PUBLICIZE_GET:
            return {
                ...state,
                requested_to_publicize: action.payload
            }
        case DOCUMENT_DIRECTORY_GET:
            return {
                ...state,
                document_directory: action.payload
            }
        case DOCUMENT_ACTIONS_GET:
            return {
                ...state,
                document_actions: action.payload
            }
        case DOCUMENT_MONITORING_GET:
            return {
                ...state,
                document_monitoring: action.payload
            }
        default:
            return state
    }
}