import axios from "axios";
import { env } from "../../env";
import {
  ADMIN_DEPARTMENTS_SET,
  CONTEXT_FORM_SET_DEPARTMENT,
  DEPARTMENT_GET_PUBLIC_ALL,
  DEPARTMENT_GET_PUBLIC_GUEST,
  USER_DEPARTMENTS,
} from "./types";
import { REQUEST_FUNCTIONS } from "./misc/functions";
import { store } from "../index";
import { setToggle, setActiveDirectory } from "./context";
import print from "../../utils/misc/print";
import { MESSAGE } from "./message";
import { GET_DIRECTORY_DETAILS, GET_DIRECTORY_TREE } from "./directory";
import { REDIRECT, RELOAD } from "../../utils/history";

const URL = env.URL_API + "department/";
const URL_dir = env.URL_API + "directory/";
const TOKEN_KEY = "Token ";
const { dispatch, getState } = store;

export const ADD_DEPARTMENT = (callback) => {
  setToggle("create_submit_loader", true);
  MESSAGE("Creating department", "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const CONTEXT = getState().CONTEXT;
  const DEPARTMENT_FORM = CONTEXT.forms.department;

  const data = new FormData();
  data.set("creator", AUTH.id);
  data.set("name", DEPARTMENT_FORM.name);
  data.set("head", DEPARTMENT_FORM.head.id);

  axios
    .post(URL, data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DEPARTMENT CREATE", response);
      if (REQUEST_FUNCTIONS(response)) {
        if (typeof callback === "function") callback();
        GET_DIRECTORY_TREE();
        setToggle("create_new", false);
        setToggle("create_submit_loader", false);
        MESSAGE("Creating department", "dequeue");
        MESSAGE("Department created successfully", "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DEPARTMENT CREATE", error);
      setToggle("create_submit_loader", false);
      MESSAGE("Creating department", "dequeue");
      MESSAGE("Failed to create department", "enqueue", "error");
    });
};

export const GET_PUBLIC_DEPARTMENTS = callback => {
  const AUTH = getState().AUTH;

  axios
    .get(URL + "public", {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DEPARTMENT GET PUBLIC ALL", response);
      await dispatch({
        type: DEPARTMENT_GET_PUBLIC_ALL,
        payload: response.data.departments,
      });

      await callback()
    })
    .catch((error) => {
      print("DEPARTMENT GET PUBLIC ALL", error);
    });
};
export const getDepartment = (directory_id, callback) => {
  const AUTH = getState().AUTH;
  const CONTEXT = getState().CONTEXT;

  directory_id = !directory_id ? CONTEXT.active_directory : directory_id;

  axios
    .get(URL + directory_id.substring(3) + "/", {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DEPARTMENT GET", response);
      if (REQUEST_FUNCTIONS(response)) {
        await dispatch({
          type: CONTEXT_FORM_SET_DEPARTMENT,
          payload: {
            name: "name",
            value: response.data.name,
          },
        });
        await dispatch({
          type: CONTEXT_FORM_SET_DEPARTMENT,
          payload: {
            name: "head",
            value:
              response.data.heads.length > 0
                ? response.data.heads[0]
                : { id: 0, name: "" },
          },
        });
        if (typeof callback === "function") callback();
      }
    })
    .catch((error) => {
      print("DEPARTMENT GET", error);
    });
};

export const GET_DEPARTMENTS = (callback) => {
  const AUTH = getState().AUTH;

  axios
    .get(URL, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DEPARTMENT GET ALL", response);
      if (REQUEST_FUNCTIONS(response)) {
        dispatch({
          type: ADMIN_DEPARTMENTS_SET,
          payload: response.data.departments,
        });
      }
    })
    .catch((error) => {
      print("DEPARTMENT GET ALL", error);
    });
};

export const UPDATE_DEPARTMENT = (directory_id, callback) => {
  setToggle("update_submit_loader", true);
  MESSAGE("Updating department", "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const CONTEXT = getState().CONTEXT;
  const DEPARTMENT_FORM = CONTEXT.forms.department;

  directory_id = !directory_id ? CONTEXT.active_directory : directory_id;

  const data = new FormData();
  data.set("name", DEPARTMENT_FORM.name);
  data.set("head", DEPARTMENT_FORM.head.id);

  axios
    .put(URL + directory_id.substring(3) + "/", data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DEPARTMENT UPDATE", response);
      if (REQUEST_FUNCTIONS(response)) {
        GET_DIRECTORY_TREE();
        GET_DIRECTORY_DETAILS();
        setToggle("create_new", false);
        setToggle("update_submit_loader", false);
        MESSAGE("Updating department", "dequeue");
        MESSAGE("Department updated successfully", "enqueue", "success");
        if (typeof callback === "function") callback();
      }
    })
    .catch((error) => {
      print("DEPARTMENT UPDATE", error);
      setToggle("update_submit_loader", false);
      MESSAGE("Updating department", "dequeue");
      MESSAGE("Failed to update department", "enqueue", "error");
    });
};


export const GET_GUEST_DEPARTMENTS = async (user_id) => {

  try {
    const response = await axios.get(URL_dir + "guests/" + user_id)
    if (response.status === 200) {
      await dispatch({
        type: DEPARTMENT_GET_PUBLIC_GUEST,
        payload: response.data.public
      })
    }
  } catch (err) {
    console.log(err)
  }
};

export const GET_USER_DEPARTMENTS = (id, defaultId) => {
  let prefix = "pub"
  let directory_id = defaultId
  axios
    .get(URL + "user-departments/" + id)
    .then(async (response) => {
      print("USER-DEPARTMENTS", response);
      if (response.data.departments.length > 0) {
        await dispatch({
          type: USER_DEPARTMENTS,
          payload: response.data.departments,
        });

        prefix = "dep"
        directory_id = response.data.departments[0].root_directory
      }

      await setActiveDirectory(
        prefix + directory_id,
        () => {
          REDIRECT(
            "/dir/" + prefix + directory_id,
            RELOAD
          );
        })
    })
    .catch((error) => {
      print("USER-DEPARTMENTS", error);
    });
}
