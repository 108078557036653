import React from 'react'
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Space from "../panel/Space";

const useStyles = makeStyles(theme => ({
    root: {
        border: '1px solid ' + theme.palette.grey.S3,
        borderRadius: theme.shape.borderRadius,
        background: 'white',
        padding: theme.spacing(1.5),
        width: 'calc(100% - ' + theme.spacing(3) + 'px)',
    },
    input: {
        background: 'transparent',
        border: 0,
        padding: 0,
        outline: 0,
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.grey.S4,
        width: '100%',
        '&::placeholder': {
            color: theme.palette.grey.S3
        }
    },
    control: {
        display: 'flex',
        alignItems: 'center'
    },
    active: {
        border: '1px solid ' + theme.palette.primary.main,
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    small: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    error: {
        border: '1px solid ' + theme.palette.error.main,
    },
    errorMessage: {
        color: theme.palette.error.main
    }
}))

export default function Main(props) {
    const classes = useStyles()
    return (
        <div>
            <div className={clsx(
                props.className ? null : classes.root,
                props.error ? classes.error : null,
            )}>
                <input className={clsx(
                    classes.input, props.active ? classes.active : null,
                    props.size === 'small' ? classes.small : null
                )} {...props} />
            </div>
            {props.error ?
                <div>
                    <Space vertical space={1} />
                    <Typography variant={'caption'} className={classes.errorMessage}>
                        {props.error}
                    </Typography>
                </div> : null
            }
        </div>
    )
}