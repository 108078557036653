import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Space from "../core/panel/Space";
import { AddCircleOutline, ClearOutlined } from "@material-ui/icons";
import InputTextSuggestion from "../core/input/InputTextSuggestion";
import ChipList from "../core/chip/ChipList";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "../core/button/Button";
import InputSelect from "../core/input/InputSelect";

import IS_PUBLIC_REQUEST from "../../utils/context/IS_PUBLIC_REQUEST";
import IS_GUEST from "../../utils/permissions/IS_GUEST";
import { GET_DIRECTORY_DETAILS } from "../../redux/actions/directory";
import { GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS } from "../../redux/actions/document";
import { clearForm, setForm, setToggle } from "../../redux/actions/context";
import { GET_SUGGESTION } from "../../redux/actions/suggestion";
import { useSelector } from "react-redux";

const style = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  groupControl: {
    marginRight: theme.spacing(8),
    width: 288
  },
  flexAlignItemsCenter: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  flexAlignItemsCenterSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  icon: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  }
}));

export default function Main({ onClose }) {
  const styles = style();

  const { form, toggle, suggestion } = useSelector(state => ({
    form: state.CONTEXT.forms.filter,
    toggle: state.CONTEXT.toggles,
    suggestion: state.SUGGESTION
  }));

  const onClick = action => {
    switch (action) {
      case "save":
        setToggle("filter_save_loader", true);
        if (IS_PUBLIC_REQUEST()) GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS();
        else GET_DIRECTORY_DETAILS();
        break;
      case "clear":
        clearForm("filter");
        setToggle("filter_attributes", false);
        setToggle("filter_keywords", false);
        setToggle("filter_type", false);
        setToggle("filter_public", false);
        onClick("save");
        break;
      default:
        break;
    }
  };

  const onAttributesAdd = async () => {
    if (form.attribute_key && form.attribute_value) {
      let contains = false;
      for (let attr of form.attributes) {
        if (
          attr.key === form.attribute_key &&
          attr.value === form.attribute_value
        ) {
          contains = true;
        }
      }
      if (!contains) {
        await setForm(
          "filter",
          "attributes",
          form.attributes.concat([
            {
              key: form.attribute_key,
              value: form.attribute_value
            }
          ])
        );
      }
      setForm("filter", "attribute_key", "");
      setForm("filter", "attribute_value", "");
    }
  };

  const onKeywordAdd = async () => {
    if (form.keyword && !form.keywords.includes(form.keyword)) {
      await setForm("filter", "keywords", form.keywords.concat([form.keyword]));
    }
    setForm("filter", "keyword", "");
  };

  return (
    <div className={styles.root}>
      <div className={styles.flexAlignItemsCenterSpaceBetween}>
        <Typography variant={"subtitle1"}>Filter Documents</Typography>
        <ClearOutlined className={styles.icon} onClick={onClose} />
      </div>
      <Space vertical space={3} />

      <div className={styles.groupControl}>
        <div className={styles.flexAlignItemsCenterSpaceBetween}>
          <FormControlLabel
            control={
              <Checkbox
                checked={toggle.filter_attributes}
                onClick={() =>
                  setToggle("filter_attributes", !toggle.filter_attributes)
                }
                value={toggle.filter_attributes}
                color="primary"
              />
            }
            label={"By Attributes"}
            labelPlacement="end"
          />
          {toggle.filter_attributes ? (
            <AddCircleOutline
              className={styles.icon}
              onClick={onAttributesAdd}
            />
          ) : null}
        </div>
        <Space vertical space={0.5} />
        {toggle.filter_attributes ? (
          <div>
            <div className={styles.flexAlignItemsCenter}>
              <InputTextSuggestion
                name={"attribute_key"}
                items={suggestion.attribute_keys}
                value={form.attribute_key}
                maxlength={100}
                type={"filter"}
                placeholder={"Key"}
                onChange={setForm}
                onSelect={setForm}
                onKeyDown={onAttributesAdd}
                callback={() =>
                  GET_SUGGESTION("attribute_key", form.attribute_key)
                }
              />
              <Space space={2} />
              <InputTextSuggestion
                name={"attribute_value"}
                items={suggestion.attribute_values}
                value={form.attribute_value}
                maxlength={100}
                type={"filter"}
                placeholder={"Value"}
                onChange={setForm}
                onSelect={setForm}
                onKeyDown={onAttributesAdd}
                callback={() =>
                  GET_SUGGESTION("attribute_value", form.attribute_value)
                }
              />
            </div>
            <ChipList
              object
              list={form.attributes}
              handleDelete={item => {
                setForm(
                  "filter",
                  "attributes",
                  form.attributes.filter(i => i !== item)
                );
              }}
            />
            <Space vertical space={2} />
          </div>
        ) : null}
      </div>

      <div className={styles.groupControl}>
        <div className={styles.flexAlignItemsCenterSpaceBetween}>
          <FormControlLabel
            control={
              <Checkbox
                checked={toggle.filter_keywords}
                onClick={() =>
                  setToggle("filter_keywords", !toggle.filter_keywords)
                }
                value={toggle.filter_keywords}
                color="primary"
              />
            }
            label={"By Keywords"}
            labelPlacement="end"
          />
          {toggle.filter_keywords ? (
            <AddCircleOutline className={styles.icon} onClick={onKeywordAdd} />
          ) : null}
        </div>
        <Space vertical space={0.5} />
        {toggle.filter_keywords ? (
          <div>
            <div className={styles.flexAlignItemsCenter}>
              <InputTextSuggestion
                name={"keyword"}
                items={suggestion.keywords}
                value={form.keyword}
                maxlength={100}
                type={"filter"}
                placeholder={""}
                onChange={setForm}
                onSelect={setForm}
                onKeyDown={onKeywordAdd}
                callback={() => GET_SUGGESTION("keyword", form.keyword)}
              />
            </div>
            <ChipList
              list={form.keywords}
              handleDelete={item => {
                setForm(
                  "filter",
                  "keywords",
                  form.keywords.filter(i => i !== item)
                );
              }}
            />
            <Space vertical space={2} />
          </div>
        ) : null}
      </div>
      <div className={styles.groupControl}>
        <div className={styles.flexAlignItemsCenterSpaceBetween}>
          <FormControlLabel
            control={
              <Checkbox
                checked={toggle.filter_type}
                onClick={() => setToggle("filter_type", !toggle.filter_type)}
                value={toggle.filter_type}
                color="primary"
              />
            }
            label={"By Type"}
            labelPlacement="end"
          />
        </div>
        <Space vertical space={0.5} />
        {toggle.filter_type ? (
          <div>
            <div className={styles.flexAlignItemsCenter}>
              <InputSelect
                menuItem={suggestion.types}
                value={form.type}
                onChange={e => {
                  setForm("filter", "type", e.target.value);
                }}
              />
            </div>
            <Space vertical space={2} />
          </div>
        ) : null}
      </div>

      {IS_GUEST() || IS_PUBLIC_REQUEST() ? null : (
        <div className={styles.groupControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={toggle.filter_public}
                onClick={() =>
                  setToggle("filter_public", !toggle.filter_public)
                }
                value={toggle.filter_public}
                color="primary"
              />
            }
            label={"By Publicity"}
            labelPlacement="end"
          />
          {toggle.filter_public ? (
            <div>
              <div className={styles.flexAlignItemsCenter}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"is_public"}
                      checked={form.is_public === true}
                      onClick={() => setForm("filter", "is_public", true)}
                      value={form.is_public === true}
                      color="primary"
                    />
                  }
                  label={"Uniwide"}
                  labelPlacement="end"
                />
                <Space space={2} />
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"is_public"}
                      checked={form.is_public === false}
                      onClick={() => setForm("filter", "is_public", false)}
                      value={form.is_public === false}
                      color="primary"
                    />
                  }
                  label={"Private"}
                  labelPlacement="end"
                />
              </div>
              <Space vertical space={2} />
            </div>
          ) : null}
        </div>
      )}
      <Space vertical space={3} />
      <div className={styles.flexAlignItemsCenter}>
        <Button
          label={"SAVE"}
          variant={"outlined"}
          active
          loading={toggle.filter_save_loader}
          onClick={() => onClick("save")}
        />
        <Button label={"CLEAR"} onClick={() => onClick("clear")} />
      </div>
    </div>
  );
}
