import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import Space from "../panel/Space";
import { RiDeleteBin7Line, RiEdit2Line } from "react-icons/all";
import { setContext } from "../../../redux/actions/context";
import HAS_CRUD from "../../../utils/permissions/HAS_CRUD";
import { SEND_CONFIRMATION_DIALOG_WITH_KEY } from "../../../redux/actions/dialogs";
import { useSelector } from "react-redux";
import { IS_DOCUMENT_TRASH_CONTEXT_OPEN } from "../../../utils/context_menu";
import useTheme from "@material-ui/core/styles/useTheme";
import { MESSAGE } from "../../../redux/actions/message";
import {
  apiDeleteDocumentPermanently,
  apiRestoreDocument,
} from "../../../api/trash";

const useStyles = makeStyles((theme) => ({
  root: {},
  popoverRoot: {
    marginTop: 12,
  },
  popoverPaper: {
    boxShadow: theme.shadows[3],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minWidth: 150,
    maxWidth: 300,
  },
  popoverContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  popoverItem: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.grey.S4,

    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
      background: theme.palette.grey.RGB0,
    },
  },
  flex: {
    display: "flex",
  },
}));

export default function Main() {
  const styles = useStyles();
  const theme = useTheme();

  const { X, Y, trash_id } = useSelector((state) => ({
    X: state.CONTEXT.documentX,
    Y: state.CONTEXT.documentY,
    trash_id: state.CONTEXT.pre_clipboard.id,
  }));

  const onClose = () => setContext("show_document_context_menu_trash", false);
  const onClick = async (action) => {
    onClose();
    switch (action) {
      case "restore":
        MESSAGE("Restoring document...", "enqueue", "info");
        const response = await apiRestoreDocument(trash_id);
        MESSAGE("Restoring document...");
        if (response.ok) MESSAGE("Document restored", "enqueue", "success");
        else MESSAGE("Failed to restore", "enqueue", "error");
        break;
      case "delete":
        SEND_CONFIRMATION_DIALOG_WITH_KEY(
          "error",
          "danger",
          "Delete Confirmation",
          "Upon confirmation, this document will be removed from your directory PERMANENTLY. Once done, " +
          "there is no way to undo it.",
          "DELETE DOCUMENT PERMANENTLY",
          async () => {
            MESSAGE("Deleting document...", "enqueue", "info");
            const response = await apiDeleteDocumentPermanently(trash_id);
            MESSAGE("Deleting document...");
            if (response.ok)
              MESSAGE("Document deleted permanently", "enqueue", "success");
            else MESSAGE("Failed to delete", "enqueue", "error");
          }
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <Popover
        open={IS_DOCUMENT_TRASH_CONTEXT_OPEN()}
        onClose={onClose}
        anchorPosition={{ left: X, top: Y - 10 }}
        anchorReference={"anchorPosition"}
        styles={{
          root: styles.popoverRoot,
          paper: styles.popoverPaper,
        }}
      >
        {HAS_CRUD() && (
          <div className={styles.popoverContainer}>
            <div
              className={styles.popoverItem}
              onClick={() => onClick("restore")}
            >
              <div className={styles.flex}>
                <RiEdit2Line size={24} />
                <Space space={2} />
                <Typography variant={"body1"}>Restore Document</Typography>
              </div>
            </div>
            <div
              className={styles.popoverItem}
              onClick={() => onClick("delete")}
            >
              <div className={styles.flex}>
                <RiDeleteBin7Line size={24} color={theme.palette.error.main} />
                <Space space={2} />
                <Typography variant={"body1"} color={"error"}>
                  Delete Permanently
                </Typography>
              </div>
            </div>
          </div>
        )}
      </Popover>
    </div>
  );
}
