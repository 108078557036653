import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core";
import { GET_DIRECTORY_TREE } from "../../redux/actions/directory";
import DirectoryTreeFolder from "../core/directory/DirectoryTreeFolder";
import clsx from "clsx";
import DirectoryTreeGroup from "../core/directory/DirectoryTreeGroup";
import Space from "../core/panel/Space";
import { GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS } from "../../redux/actions/document";
import STATES from "../../redux/states";
import IS_SUPPORT from "../../utils/permissions/IS_SUPPORT";
import IS_AUTHENTICATED from "../../utils/permissions/IS_AUTHENTICATED";

const useStyles = makeStyles(theme => ({
    container: {
        // width: '100%',
        paddingBottom: theme.spacing(3)
    },
    fullWidth: {
        // width: '100%'
    }
}));

const Tree = (PROPS) => {
    const CLASSES = useStyles()
    const EXPANDED = PROPS.class.props.CONTEXT.expanded_directories

    const id = PROPS.prefix + PROPS.dir.directory_id

    // console.log(PROPS.dir)

    return (
        <div>
            <DirectoryTreeFolder
                id={id}
                level={PROPS.level}
                label={PROPS.dir.name}
                hasChildren={
                    PROPS.dir.has_children || PROPS.dir.children.length > 0
                }
                // hasChildren={PROPS.dir.has_children || false}
                expanded={EXPANDED}
                class={PROPS.class}
            />
            {
                EXPANDED.includes(id) && PROPS.dir.children_groups ?
                    PROPS.dir.children_groups.map((group_name, index) => (
                        <div key={index}>
                            <DirectoryTreeGroup
                                level={PROPS.level + 1}
                                text={group_name}
                            />
                            {
                                PROPS.dir.children.map((child, index) => (
                                    <div key={index} className={CLASSES.fullWidth}>
                                        {
                                            child.group.name === group_name ?
                                                <Tree
                                                    prefix={PROPS.prefix}
                                                    dir={child}
                                                    level={PROPS.level + 1}
                                                    class={PROPS.class}
                                                /> : null
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    )) : null
            }
        </div>
    )
}

const Body = (PROPS) => {
    const CLASSES = useStyles()
    const DIRECTORY_TREE = PROPS.class.props.DIRECTORY.tree

    return (
        <div className={clsx(CLASSES.container)}>

            {/*<DirectoryTreeFolder*/}
            {/*    id={'shared-with-me'}*/}
            {/*    level={1}*/}
            {/*    icon={'shared'}*/}
            {/*    label={'Shared with Me'}*/}
            {/*    hasChildren={false}*/}
            {/*    onClick={() => {*/}
            {/*        // GET SHARED DOCUMENTS*/}
            {/*    }}*/}
            {/*    class={PROPS.class}*/}
            {/*/>*/}
            {IS_SUPPORT() ?
                <DirectoryTreeFolder
                    id={'public-request'}
                    level={1}
                    icon={'special'}
                    label={'Public Requests'}
                    hasChildren={false}
                    onClick={GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS}
                    class={PROPS.class}
                /> : null
            }

            <Space vertical space={1} />

            {/*COLLEGE*/}
            {DIRECTORY_TREE.departments && DIRECTORY_TREE.departments.length > 0 ?
                <div>
                    <DirectoryTreeGroup level={1}
                        text={`MY OFFICE${DIRECTORY_TREE.departments.length > 1 ? 'S' : ''}`} />
                    {DIRECTORY_TREE.departments.map((dir, index) => (
                        <Tree
                            prefix={'dep'}
                            key={index}
                            dir={dir}
                            level={1}
                            class={PROPS.class}
                        />
                    ))}
                </div> : null
            }

            <Space vertical space={1} />

            {/*PUBLIC*/}
            {DIRECTORY_TREE.public && DIRECTORY_TREE.public.length > 0 ?
                <div>
                    <DirectoryTreeGroup level={1} text={'PUBLIC OFFICES'} />

                    {DIRECTORY_TREE.public.map((dir, index) => (
                        <Tree
                            prefix={'pub'}
                            key={index}
                            dir={dir}
                            level={1}
                            class={PROPS.class}
                        />
                    ))}
                </div> : null
            }
            {IS_AUTHENTICATED() ?
                <div>
                    <Space vertical space={2} />
                    <DirectoryTreeFolder
                        id={'monitoring'}
                        level={1}
                        icon={'reports'}
                        label={'Monitoring'}
                        hasChildren={false}
                        class={PROPS.class}
                    />
                </div> : null
            }
        </div>
    )
}


class DirectoryTree extends Component {

    componentDidMount = async () => {
        GET_DIRECTORY_TREE()
    }

    render() {
        return <Body class={this} />
    }
}

export default connect(STATES, null)(DirectoryTree)