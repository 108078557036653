import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { SnackbarProvider, useSnackbar } from "notistack";
import { REMOVE_MESSAGE } from "../../../redux/actions/message";

export const EnqueueSnackBar = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  enqueueSnackbar(props.message, {
    key: props.message,
    variant: props.variant ? props.variant : "info",
    persist: props.persist,
  });
  if (!props.persist) REMOVE_MESSAGE(props.message);

  return null;
};

export const DequeueSnackBar = (props) => {
  const { closeSnackbar } = useSnackbar();

  closeSnackbar(props.message);
  REMOVE_MESSAGE(props.message);

  return null;
};

export default function Main(props) {
  const classes = useStyles();
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    REMOVE_MESSAGE(key);
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      maxSnack={5}
      // dense
      // hideIconVariant
      ref={notistackRef}
      preventDuplicate
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      action={(key) => (
        <Button onClick={onClickDismiss(key)} className={classes.dismissButton}>
          Close
        </Button>
      )}
      classes={{
        root: classes.root,
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
        containerAnchorOriginBottomRight:
          classes.containerAnchorOriginBottomRight,
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      boxShadow: "unset",
    },
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  info: {
    backgroundColor: theme.palette.grey.S4,
  },
  dismissButton: {
    color: "white",
  },
  containerAnchorOriginBottomRight: {
    paddingBottom: 15,
  },
}));
