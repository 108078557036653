import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import Space from "../panel/Space";
import CircularProgress from "@material-ui/core/CircularProgress";
// import {store} from '../../../redux/index'

const useStyles = makeStyles((theme) => ({
     root: {
          border: 0,
          background: "transparent",
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          textAlign: "center",
          outline: 0,
          color: theme.palette.grey.S4,
          minWidth: 100,
          "&:hover": {
               cursor: "pointer",
               fontWeight: "bold",
          },
     },
     control: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
     },
     outlined: {
          border: "1px solid " + theme.palette.grey.S3,
          borderRadius: theme.shape.borderRadius,

          "&:hover": {
               borderColor: "white",
               background: theme.palette.primary.gradient,
               color: "white",
          },
     },
     contained: {
          border: "1px solid " + theme.palette.error.dark,
          backgroundColor: theme.palette.error.dark,
          borderRadius: theme.shape.borderRadius,
          color: "white",
     },
     active: {
          background: theme.palette.primary.gradient,
          borderColor: "white",
          color: "white",
          fontWeight: "bold",

          "&:hover": {
               borderColor: "white",
               color: "white",
               background: theme.palette.primary.gradient,
          },
     },
     small: {
          paddingTop: theme.spacing(0.75),
          paddingBottom: theme.spacing(0.75),
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
          minWidth: "unset",
     },
     default: {
          color: theme.palette.default.main,
          borderColor: theme.palette.default.main,
          "&:hover": {
               borderColor: theme.palette.default.main,
               background: theme.palette.default.main,
               color: "white",
          },
     },
     success: {
          color: theme.palette.success.main,
          borderColor: theme.palette.success.main,
          "&:hover": {
               borderColor: theme.palette.success.main,
               background: theme.palette.success.main,
               color: "white",
          },
     },
     warning: {
          color: theme.palette.warning.main,
          borderColor: theme.palette.warning.main,
          "&:hover": {
               borderColor: theme.palette.warning.main,
               background: theme.palette.warning.main,
               color: "white",
          },
     },
     error: {
          color: theme.palette.error.main,
          borderColor: theme.palette.error.main,
          "&:hover": {
               borderColor: theme.palette.error.main,
               background: theme.palette.error.main,
               color: "white",
          },
     },
     noPadding: {
          padding: "0 !important",
          minWidth: "unset",
     },
     disabled: {
          opacity: 0.5,
     },
     fullWidth: {
          width: "100%",
     },
     margin10: {
          margin: "10px"
     }
}));

export default function Main(props) {
     const classes = useStyles();
     return (
          <button
               className={clsx(
                    classes.root,
                    props.size === "small" ? classes.small : null,
                    props.variant === "outlined" ? classes.outlined : null,
                    props.variant === "contained" ? classes.contained : null,
                    props.color === "default" ? classes.default : null,
                    props.color === "success" ? classes.success : null,
                    props.color === "warning" ? classes.warning : null,
                    props.color === "error" ? classes.error : null,
                    props.active ? classes.active : null,
                    props.noPadding ? classes.noPadding : null,
                    props.fullwidth ? classes.fullWidth : null,
                    props.margin10 ? classes.margin10 : null,
                    props.disabled ? classes.disabled : null
               )}
               onClick={props.disabled || props.loading ? null : props.onClick}
          >
               {props.loading ? (
                    // size={theme.spacing(2)}
                    <CircularProgress color={"white"} size={21} />
               ) : (
                    <div className={classes.control}>
                         {props.icon ? (
                              <div className={classes.control}>
                                   {props.icon}
                                   <Space space={1} />
                              </div>
                         ) : null}
                         <Typography variant={"body1"} align={"center"}>
                              {props.label || props.children}
                         </Typography>
                    </div>
               )}
          </button>
     );
}
