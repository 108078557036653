import React, {Component} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";
import STATES from "../../redux/states";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        minWidth: 1280,
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 3,
        zIndex: 2000
    },
    topHeader: {
        width: '100%',
        height: 15,
        background: theme.palette.primary.gradient
    },
    noMinWidth: {
        minWidth: 'unset',
    }
}));

const Body = (props) => {
    const classes = useStyles();

    return (
        <div className={clsx(
            classes.container,
            props.class.props.noMinWidth ? classes.noMinWidth : null
        )}>
            <div className={classes.topHeader}>

            </div>
        </div>
    )
}


class Footer extends Component {
    render() {
        return <Body class={this}/>
    }
}

export default connect(STATES, null)(Footer)