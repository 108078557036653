import { store } from "../../redux";

const { getState } = store;

export default function Main(directory) {
    const Directorymodules = ["monitoring"];
    directory = !directory ? getState().CONTEXT.active_directory : directory;

    return Directorymodules.includes(directory)
}
