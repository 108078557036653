import { AUTH_LOGIN, AUTH_LOGOUT } from "../actions/types";

const init = {
     id: 0,
};

export default function Main(state = init, action) {
     switch (action.type) {
          case AUTH_LOGIN:
               return action.payload;

          case AUTH_LOGOUT:
               return init;

          default:
               return state;
     }
}
