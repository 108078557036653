import {
    DIRECTORY_DETAILS_GET,
    DIRECTORY_TREE_GET,
    DIRECTORY_TREE_LIST,
    DIRECTORY_LOADING,
    DIRECTORY_LINK_DESCRIPTION
} from "../actions/types";

const init = {
    tree: {},
    treeList: [],
    details: {},
    trash: {},
    loading: false,
}

export default function Main(state = init, action) {
    switch (action.type) {
        case DIRECTORY_TREE_GET:
            return {
                ...state,
                tree: action.payload
            }
        case DIRECTORY_DETAILS_GET:
            return {
                ...state,
                details: action.payload
            }
        case DIRECTORY_TREE_LIST:
            return {
                ...state,
                treeList: action.payload
            }
        case DIRECTORY_LINK_DESCRIPTION:
            return {
                ...state,
                linklist: action.payload
            }
        case DIRECTORY_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state
    }
}