import React from "react";
import { Link, makeStyles } from "@material-ui/core";
import { RiToolsLine } from "react-icons/all";
import Typography from "@material-ui/core/Typography";
import Space from "../core/panel/Space";
import { env } from "../../env";
import QASASBrand from "../../static/media/img/Brand.png";
import KMD from "../../static/media/img/SDMD.png";

export default function Main() {
  const classes = useStyles();
  const mailto =
    "mailto:" +
    env.EMAIL_UDAS.toLowerCase() +
    "?" +
    "cc=" +
    env.EMAIL_KMD.toLowerCase();

  return (
    <div className={classes.root}>
      <div className={classes.topHeader} />
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <img src={QASASBrand} alt={"QASAS Brand"} className={classes.brand} />
          <img src={KMD} alt={"KMD"} className={classes.brand} />
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.dialog}>
          <div className={classes.container}>
            <RiToolsLine size={70} className={classes.icon} />
            <Space vertical space={5} />
            <Typography variant={"h5"}>SYSTEM MAINTENANCE</Typography>
            <Space vertical space={5} />
            <Typography variant={"body1"} align={"center"}>
              Sorry for the inconvenience we have caused. We are doing system
              maintenance at the moment to improve system performance and serve
              you better. Please come back later as it might take some time
              before it's completely done. Thank you for understanding!
            </Typography>
            <Space vertical space={5} />
            <Typography variant={"body1"} align={"center"}>
              If you have concerns, you may reach us through
            </Typography>
            <Link href={mailto} target={"_blank"} rel={"noopener noreferrer"}>
              <Typography variant={"h6"} align={"center"} color={"primary"}>
                {env.EMAIL_UDAS.toLowerCase()}
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: 1280,
    height: "100vh",
  },
  content: {
    width: "100%",
    minWidth: 1280,
    height: "calc(100% - 150px)",
    // background: '#0000004d',
    background: "white",
    zIndex: 200,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dialog: {
    // borderRadius: theme.shape.borderRadius
    width: 525,
  },
  container: {
    borderRadius:
      theme.shape.borderRadius + "px " + theme.shape.borderRadius + "px 0 0",
    background: "white",
    padding: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    color: theme.palette.primary.main,
  },
  footer: {
    height: 15,
    width: "100%",
    background: theme.palette.primary.main,
    borderRadius:
      "0 0 " +
      theme.shape.borderRadius +
      "px " +
      theme.shape.borderRadius +
      "px",
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    background: "white",
  },
  headerContent: {
    width: 1160,
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topHeader: {
    background: theme.palette.primary.gradient,
    height: 30,
    width: "100%",
  },
  brand: {
    height: 48,
  },
}));
