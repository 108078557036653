import { setToggle } from "../redux/actions/context";
import {
   GET_DIRECTORY_DETAILS,
   GET_DIRECTORY_TREE,
} from "../redux/actions/directory";

export function LOAD_DIRECTORY(directory_id = null) {
   if (window.location.pathname.startsWith("/dir/")) {
      // IF DIRECTORY_ID IS NULL, GET URL
      if (!directory_id) directory_id = window.location.pathname.substring(5);

      setToggle("first_directory_details_loader", true);
      setToggle("first_directory_tree_loader", true);
      GET_DIRECTORY_TREE();
      GET_DIRECTORY_DETAILS(directory_id);
   }
}

export const getDirectoryPath = (ancestry) => {
   let path = "";
   ancestry.forEach((item, index) => {
      if (index) path += " > ";
      path += item.name;
   });
   return path;
};
