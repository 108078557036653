import DOC from '../static/media/file_icons/doc.svg'
import XLS from '../static/media/file_icons/xls.svg'
import PPT from '../static/media/file_icons/ppt.svg'
import PDF from '../static/media/file_icons/pdf.svg'
import OTHER from '../static/media/file_icons/other.svg'

export default function Main(file_name) {
    let file_icon = OTHER
    if (typeof (file_name) === 'string') {
        const file_types = {
            doc: ['doc', 'dot', 'wbk', 'docx', 'docm', 'dotx', 'dotm', 'docb'],
            xls: ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xla', 'xlam', 'xll', 'xlw',],
            ppt: ['ppt', 'pot', 'pps', 'pptx', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'ppsm', 'sldx', 'sldm',],
            pdf: ['pdf']
        }
        for (let type in file_types) {
            if (file_types[type].includes(file_name.toLowerCase().split('.').pop())) {
                switch (type) {
                    case 'doc':
                        file_icon = DOC;
                        break
                    case 'xls':
                        file_icon = XLS;
                        break
                    case 'ppt':
                        file_icon = PPT;
                        break
                    case 'pdf':
                        file_icon = PDF;
                        break
                    default:
                        break
                }
                break
            }
        }
    }
    return file_icon
}