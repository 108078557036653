import axios from "axios";
import { store } from "../index";
import { env } from "../../env";
import { REQUEST_FUNCTIONS } from "./misc/functions";
import {
  DIRECTORY_DETAILS_GET,
  DIRECTORY_TREE_GET,
  DIRECTORY_TREE_LIST,
  DIRECTORY_LOADING,
  DIRECTORY_LINK_DESCRIPTION
} from "./types";
import print from "../../utils/misc/print";
import {
  addToExpandedDirectory,
  clearForm,
  setActiveDirectory,
  setContext,
  setToggle,
} from "./context";
import { MESSAGE } from "./message";
import CHANGE_URL from "../../utils/context/CHANGE_URL";
import { REDIRECT } from "../../utils/history";
const URL = env.URL_API + "directory/";
const TOKEN_KEY = "Token ";
const { dispatch, getState } = store;

export const ADD_DIRECTORY = () => {
  setToggle("create_submit_loader", true);
  MESSAGE("Creating directory", "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const CONTEXT = getState().CONTEXT;

  const parent_id = CONTEXT.active_directory;
  const directory_form = CONTEXT.forms.directory;

  const data = new FormData();
  data.set("parent", parent_id.substring(3));
  data.set("name", directory_form.name);
  data.set("description", directory_form.description);
  data.set("group", directory_form.group);
  data.set("is_public", directory_form.is_public);

  for (let i = 0; i < directory_form.moderators.length; i++)
    data.set("m_" + i, directory_form.moderators[i].id);

  axios
    .post(URL, data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DIRECTORY CREATE", response);
      if (REQUEST_FUNCTIONS(response)) {
        GET_DIRECTORY_TREE();
        GET_DIRECTORY_DETAILS();
        setToggle("create_new", false);
        setToggle("create_submit_loader", false);
        MESSAGE("Creating directory", "dequeue");
        MESSAGE("Directory created successfully", "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DIRECTORY CREATE", error);
      setToggle("create_submit_loader", false);
      MESSAGE("Creating directory", "dequeue");
      MESSAGE("Failed to create directory", "enqueue", "error");
    });
};

export const GET_DIRECTORY_DETAILS = (
  directory_id = null,
  onSuccess,
  onFail
) => {
  setToggle("directory_details_loader", true);
  const AUTH = getState().AUTH;
  const CONTEXT = getState().CONTEXT;
  directory_id = !directory_id ? CONTEXT.active_directory : directory_id;

  const data = new FormData();
  data.set("directory_id", directory_id.substring(3));
  data.set("user_id", AUTH.id);

  if (CONTEXT.search_key) {
    data.set("search_key", CONTEXT.search_key);
    data.set("search_scope", CONTEXT.search_scope);
  }

  if (CONTEXT.forms.filter) {
    let filter_form = CONTEXT.forms.filter;
    let toggle = CONTEXT.toggles;
    if (toggle.filter_public && directory_id.substring(0, 3) !== "pub")
      data.set("is_public", filter_form.is_public);

    if (toggle.filter_type) data.set("type", filter_form.type);
    data.set("sort_by", filter_form.sort_by);
    data.set("ascending", filter_form.ascending);

    if (filter_form.attributes && toggle.filter_attributes)
      for (let i = 0; i < filter_form.attributes.length; i++) {
        data.set("ak_" + i, filter_form.attributes[i].key);
        data.set("av_" + i, filter_form.attributes[i].value);
      }
    if (filter_form.keywords && toggle.filter_keywords)
      for (let i = 0; i < filter_form.keywords.length; i++)
        data.set("k_" + i, filter_form.keywords[i]);
  }

  if (AUTH.token) {
    axios
      .post(URL + "details", data, {
        headers: { Authorization: TOKEN_KEY + AUTH.token },
      })
      .then(async (response) => {
        print("DIRECTORY DETAILS", response);
        if (REQUEST_FUNCTIONS(response)) {
          dispatch({
            type: DIRECTORY_DETAILS_GET,
            payload: response.data,
          });
          GET_DIRECTORY_LINK_DESCRIPTION(directory_id.substring(3));
          // ADD ANCESTRY TO DIRECTORY TREE
          // console.log(response.data.ancestry)
          // const tree = getState().DIRECTORY.tree
          // for (let i=0; i<response.data.ancestry.length; i++) {
          //     // has_children: true
          //     // id: 3
          //     // name:
          //
          //     //     response_data['directory_id'] = directory.id
          //     //     response_data['name'] = directory.name
          //     //     response_data['description'] = directory.description
          //     //     response_data['is_public'] = directory.is_public
          //     //     if directory.group:
          //     //     response_data['group'] = {
          //     //         'id': directory.group.id,
          //     //         'name': directory.group.name,
          //     //         'description': directory.group.description
          //     //     }
          //     // else:
          //     //     response_data['group'] = {
          //     //         'id': 0,
          //     //         'name': 'Ungrouped',
          //     //         'description': ''
          //     //     }
          //     //     response_data['children'] = []
          //     //     response_data['children_groups'] = []
          //
          //     const dirInTree = () => {
          //
          //     }
          //
          //     ADD_TO_EXPANDED_DIRECTORY(directory_id.substring(0, 3) + response.data.ancestry[i].id)
          // }
          // dispatch({
          //     type: DIRECTORY_TREE_GET,
          //     payload: tree
          // })
          // #############################
          setActiveDirectory(directory_id);
          addToExpandedDirectory(directory_id);
          if (!CONTEXT.search_key) setContext("search_mode", false);
          REDIRECT("/dir/" + directory_id);

          setToggle("first_directory_details_loader", false);
          setToggle("directory_details_loader", false);
          setToggle("filter_save_loader", false);

          if (typeof onSuccess === "function") onSuccess();
        }
      })
      .catch((error) => {
        print("DIRECTORY DETAILS", error);
        if (error.response && error.response.status === 404) {
          CHANGE_URL("/page-not-found");
        }
        if (typeof onFail === "function") onFail();
      });

  } else {
    axios
      .post(URL + "guest/documents", data)
      .then(async (response) => {
        print("DIRECTORY DETAILS", response);
        dispatch({
          type: DIRECTORY_DETAILS_GET,
          payload: response.data,
        });
        setActiveDirectory(directory_id);
        addToExpandedDirectory(directory_id);
        if (!CONTEXT.search_key) setContext("search_mode", false);
        REDIRECT("/dir/" + directory_id);

        setToggle("first_directory_details_loader", false);
        setToggle("directory_details_loader", false);
        setToggle("filter_save_loader", false);

        if (typeof onSuccess === "function") onSuccess();

      })
      .catch((error) => {
        print("DIRECTORY DETAILS", error);
        if (error.response && error.response.status === 404) {
          CHANGE_URL("/page-not-found");
        }
        if (typeof onFail === "function") onFail();
      });

  }

};

// export const GET_DIRECTORY_TREE = () => {
//
//     SET_TOGGLE('directory_tree_loader', true)
//
//     const AUTH = getState().AUTH
//
//     const data = new FormData()
//     data.set('user_id', AUTH.id)
//
//     axios.post(URL + 'user-tree', data, {
//         headers: {Authorization: TOKEN_KEY + AUTH.token}
//     }).then(async response => {
//         print('USER-TREE', response)
//         if (REQUEST_FUNCTIONS(response)) {
//             dispatch({
//                 type: DIRECTORY_TREE_GET,
//                 payload: response.data
//             })
//             SET_TOGGLE('first_directory_tree_loader', false)
//             SET_TOGGLE('directory_tree_loader', false)
//         }
//     }).catch(error => {
//         print('USER-TREE', error)
//     })
// }

export const GET_DIRECTORY_TREE = () => {
  setToggle("directory_tree_loader", true);
  const AUTH = getState().AUTH;
  if (AUTH.id) {
    const data = new FormData();
    data.set("user_id", AUTH.id);
    axios
      .post(URL + "user-tree", data, {
        headers: { Authorization: TOKEN_KEY + AUTH.token },
      })
      .then(async (response) => {
        print("USER-TREE", response);
        if (REQUEST_FUNCTIONS(response)) {
          dispatch({
            type: DIRECTORY_TREE_GET,
            payload: response.data,
          });
          setToggle("first_directory_tree_loader", false);
          setToggle("directory_tree_loader", false);
        }
      })
      .catch((error) => {
        print("USER-TREE", error);
      });
  } else {
    axios
      .get(URL + "guest/user-tree")
      .then(async (response) => {
        print("USER-TREE-GUEST", response);
        dispatch({
          type: DIRECTORY_TREE_GET,
          payload: response.data,
        });
        setToggle("first_directory_tree_loader", false);
        setToggle("directory_tree_loader", false);

      })
      .catch((error) => {
        print("USER-TREE-GUEST", error);
      });
  }

};

export const GET_DIRECTORY_TREE_LIST = () => {
  dispatch({
    type: DIRECTORY_LOADING,
    payload: true
  });

  const AUTH = getState().AUTH;
  const data = new FormData();
  data.set("user_id", AUTH.id);
  axios
    .post(URL + "parents", data)
    .then(async (response) => {
      dispatch({
        type: DIRECTORY_TREE_LIST,
        payload: response.data.directories,
      });

      dispatch({
        type: DIRECTORY_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      // PRINT RESULT ERROR
      print("DIRECTORY-TREE-LIST", error);
    });
}

export const GET_DIRECTORY_LINK_DESCRIPTION = (directory_id) => {
  dispatch({
    type: DIRECTORY_LOADING,
    payload: true
  });

  axios
      .get(URL + "directory-description/" + directory_id)
      .then(async (response) => {
        dispatch({
          type: DIRECTORY_LINK_DESCRIPTION,
          payload: response.data.linkDescription,
        });

        dispatch({
          type: DIRECTORY_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        // PRINT RESULT ERROR
        print("DIRECTORY-LINK-DESCRIPTION", error);
      });
}

export const GET_CHILD_DIRECTORY_TREE_LIST = (directory, flattenItems, find) => {
  const list = getState().DIRECTORY.treeList
  const parent = find(flattenItems(list.concat(), 'children'), [
    'url',
    directory.url,
  ])

  const data = new FormData();
  data.set("directory_id", directory.id);
  data.set("dept_prefix", directory.deptPrefix);

  axios
    .post(URL + "children", data)
    .then(async (response) => {

      if (response.data.children_directories) {
        Object.assign(parent.children, response.data.children_directories);
      }
      dispatch({
        type: DIRECTORY_TREE_LIST,
        payload: [...list],
      });
    })
    .catch((error) => {
      // PRINT RESULT ERROR
      print("DIRECTORY-TREE-LIST-CHILD", error);
    });
}

export const UPDATE_DIRECTORY = (directory_id = null) => {
  setToggle("update_submit_loader", true);
  MESSAGE("Updating directory", "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const CONTEXT = getState().CONTEXT;

  directory_id = !directory_id ? CONTEXT.active_directory : directory_id;
  const directory_form = CONTEXT.forms.directory;

  const data = new FormData();
  data.set("name", directory_form.name);
  data.set("description", directory_form.description);
  data.set("group", directory_form.group);
  data.set("is_public", directory_form.is_public);

  for (let i = 0; i < directory_form.moderators.length; i++)
    data.set("m_" + i, directory_form.moderators[i].id);

  axios
    .put(URL + directory_id.substring(3) + "/", data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DIRECTORY UPDATE", response);
      if (REQUEST_FUNCTIONS(response)) {
        GET_DIRECTORY_TREE();
        GET_DIRECTORY_DETAILS();
        setToggle("create_new", false);
        setToggle("update_submit_loader", false);
        MESSAGE("Updating directory", "dequeue");
        MESSAGE("Directory updated successfully", "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DIRECTORY UPDATE", error);
      MESSAGE("Updating directory", "dequeue");
      MESSAGE("Failed to update directory", "enqueue", "error");
    });
};

export const DELETE_DIRECTORY = (
  directory_id = null,
  go_upper_directory = true
) => {
  MESSAGE("Deleting directory", "enqueue", "info", true);

  console.log("DELETE");

  const AUTH = getState().AUTH;
  const CONTEXT = getState().CONTEXT;
  const DIRECTORY = getState().DIRECTORY;
  directory_id = !directory_id ? CONTEXT.active_directory : directory_id;

  axios
    .delete(URL + directory_id.substring(3) + "/", {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DIRECTORY DELETE", response);
      if (REQUEST_FUNCTIONS(response)) {
        if (go_upper_directory) {
          await setActiveDirectory(
            "dep" +
            DIRECTORY.details.ancestry[DIRECTORY.details.ancestry.length - 2]
              .id
          );
        }
        await GET_DIRECTORY_DETAILS();
        await GET_DIRECTORY_TREE();
        MESSAGE("Deleting directory", "dequeue");
        MESSAGE("Directory deleted successfully", "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DIRECTORY DELETE", error);
      MESSAGE("Deleting directory", "dequeue");
      MESSAGE("Failed to delete directory", "enqueue", "error");
    });
};

/*
    CLIPBOARD
 */

export const COPY_DIRECTORY = (destination_directory, source_directory) => {
  MESSAGE(`Copying directory`, "enqueue", "info", true);

  const AUTH = getState().AUTH;

  const DATA = new FormData();
  DATA.set("src_directory_id", source_directory.substring(3));
  DATA.set("dst_directory_id", destination_directory.substring(3));
  DATA.set("public", (source_directory.substring(0, 3) === "pub").toString());

  axios
    .post(URL + "copy", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
      timeout: 1800000,
    })
    .then(async (response) => {
      print("DIRECTORY COPY", response);
      if (REQUEST_FUNCTIONS(response)) {
        await GET_DIRECTORY_TREE();
        await GET_DIRECTORY_DETAILS();
        MESSAGE(`Copying directory`, "dequeue");
        MESSAGE(`Directory copied successfully`, "enqueue", "success");
      }
    })
    .catch(async (error) => {
      print("DIRECTORY COPY", error);
      await GET_DIRECTORY_TREE();
      await GET_DIRECTORY_DETAILS();
      MESSAGE(`Copying directory`, "dequeue");
      MESSAGE(`Failed to copy directory`, "enqueue", "error");
    });
};

export const LINK_DIRECTORY = (destination_directory, source_directory) => {
  MESSAGE(`Creating link directory`, "enqueue", "info", true);

  const AUTH = getState().AUTH;

  const DATA = new FormData();
  DATA.set("directory_id", source_directory.substring(3));
  DATA.set("parent_id", destination_directory.substring(3));

  axios
    .post(URL + "link", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
      timeout: 1800000,
    })
    .then(async (response) => {
      print("DIRECTORY LINK", response);
      if (REQUEST_FUNCTIONS(response)) {
        await GET_DIRECTORY_TREE();
        await GET_DIRECTORY_DETAILS();
        MESSAGE(`Creating link directory`, "dequeue");
        MESSAGE(`Directory link created successfully`, "enqueue", "success");
      }
    })
    .catch(async (error) => {
      print("DIRECTORY LINK", error);
      await GET_DIRECTORY_TREE();
      await GET_DIRECTORY_DETAILS();
      MESSAGE(`Creating link directory`, "dequeue");
      MESSAGE(`Failed to create link directory`, "enqueue", "error");
    });
};

export const REMOVE_LINK_DIRECTORY = (
  destination_directory,
  source_directory
) => {
  MESSAGE(`Removing link directory`, "enqueue", "info", true);

  const AUTH = getState().AUTH;

  const DATA = new FormData();
  DATA.set("directory_id", source_directory.substring(3));
  DATA.set("parent_id", destination_directory.substring(3));

  axios
    .post(URL + "link/remove", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
      timeout: 1800000,
    })
    .then(async (response) => {
      print("REMOVE DIRECTORY LINK", response);
      if (REQUEST_FUNCTIONS(response)) {
        await GET_DIRECTORY_TREE();
        await GET_DIRECTORY_DETAILS();
        MESSAGE(`Removing link directory`, "dequeue");
        MESSAGE(`Directory link removed successfully`, "enqueue", "success");
      }
    })
    .catch(async (error) => {
      print("REMOVE DIRECTORY LINK", error);
      await GET_DIRECTORY_TREE();
      await GET_DIRECTORY_DETAILS();
      MESSAGE(`Removing link directory`, "dequeue");
      MESSAGE(`Failed to remove link directory`, "enqueue", "error");
    });
};

export const CUT_DIRECTORY = (destination_directory, source_directory) => {
  MESSAGE(`Moving document`, "enqueue", "info", true);

  const AUTH = getState().AUTH;

  const DATA = new FormData();
  DATA.set("src_directory_id", source_directory.substring(3));
  DATA.set("dst_directory_id", destination_directory.substring(3));

  axios
    .post(URL + "cut", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DIRECTORY CUT", response);
      if (REQUEST_FUNCTIONS(response)) {
        await GET_DIRECTORY_TREE();
        await GET_DIRECTORY_DETAILS();
        MESSAGE(`Moving document`, "dequeue");
        MESSAGE(`Directory moved successfully`, "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DIRECTORY CUT", error);
      MESSAGE(`Moving document`, "dequeue");
      MESSAGE(`Failed to move directory`, "enqueue", "error");
    });
};

export const GET_DESCENDANCE = (directory_id, onSuccess, onFail) => {
  const AUTH = getState().AUTH;

  const DATA = new FormData();
  DATA.set("directory_id", directory_id.substring(3));

  axios
    .post(URL + "descendance", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DIRECTORY DESCENDANCE GET", response);
      if (REQUEST_FUNCTIONS(response)) {
        onSuccess(response.data.descendance);
      }
    })
    .catch((error) => {
      print("DIRECTORY DESCENDANCE GET", error);
      if (typeof onFail === "function") onFail();
    });
};

export const DELETE_DIRECTORY_LINK = (id) => {
  setToggle("update_submit_loader", true);
  const AUTH = getState().AUTH;
  const DATA = new FormData();

  DATA.set("id", id);

  axios
      .post(URL + "delete_directory_link", DATA, {
        headers: { Authorization: TOKEN_KEY + AUTH.token },
      })
      .then(async (response) => {
        print("DIRECTORY LINK DELETE", response);
        if (REQUEST_FUNCTIONS(response)) {
          setToggle("update_submit_loader", false);
          MESSAGE("Directory link has been deleted successfully", "enqueue", "success");
        }
      })
      .catch((error) => {
        print("DIRECTORY LINK DELETE", error);
        MESSAGE("Deleting directory link", "dequeue");
        MESSAGE("Failed to delete directory link", "enqueue", "error");
      });
}

export const UPDATE_DIRECTORY_LINK = () => {

  setToggle("update_submit_loader", true);
  const AUTH = getState().AUTH;
  const CONTEXT = getState().CONTEXT;
  const DIRECTORY = getState().DIRECTORY;

  const directory_id = CONTEXT.active_directory.substring(3);
  const linkDescription = CONTEXT.forms.directory.link_description


  const data = new FormData();
  const id = typeof linkDescription.selectedTitleId !== 'undefined' ? linkDescription.selectedTitleId : null
  const title = linkDescription.title ? linkDescription.title : ''
  data.set("directory_id", directory_id);
  data.set("id", id);
  data.set("title", title);
  data.set("details", JSON.stringify(linkDescription.details));

  axios
      .post(URL + "update_directory_link", data, {
        headers: { Authorization: TOKEN_KEY + AUTH.token },
      })
      .then(async (response) => {

        print("DIRECTORY LINK UPDATE", response);

        if (REQUEST_FUNCTIONS(response)) {

          setToggle("update_submit_loader", false);

          if (Object.keys(DIRECTORY.linklist.filter(function (value){
            return parseInt(value.id) === parseInt(response.data.id)
          })).length > 0){
            DIRECTORY.linklist = DIRECTORY.linklist.map(function (value){
              if (parseInt(value.id) === parseInt(response.data.id)){
                value.title = linkDescription.title
              }
              return value
            })
            MESSAGE("Directory link was updated successfully", "enqueue", "success");
          }else{

            DIRECTORY.linklist.push(
                {
                  directory_id: directory_id,
                  id: response.data.id,
                  title: title,
                  details: linkDescription.details,
                }
            )
            MESSAGE("Directory link was created successfully", "enqueue", "success");
          }

          GET_DIRECTORY_DETAILS(data.directory_id)
          clearForm('directory')
          setToggle("directoryLinkDescriptionView", false)
        }
      })
      .catch((error) => {
        print("DIRECTORY LINK UPDATE", error);
        MESSAGE("Updating directory link", "dequeue");
        MESSAGE("Failed to update directory link", "enqueue", "error");
      });
};