import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { CheckCircleOutlined, ReportProblemOutlined } from "@material-ui/icons";
import Space from "../panel/Space";
import Typography from "@material-ui/core/Typography";
import Button from "../button/Button";
import { TransitionSlideUp } from "../transition/TransitionSlideLeft";
import { RiInformationLine, RiLockLine } from "react-icons/all";

const useStyles = makeStyles((theme) => ({
     root: {
          borderRadius: theme.shape.borderRadius,
     },
     body: {
          padding: theme.spacing(6),
          width: 350,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
     },
     iconContainer: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
     },
     icon: {
          fontSize: 100,
     },
     typography: {
          color: theme.palette.grey.S4,
     },
     link: {
          color: theme.palette.grey.S3,
          "&:hover": {
               cursor: "pointer",
               color: theme.palette.grey.S4,
          },
     },
}));

export default function Main(props) {
     const classes = useStyles();
     const theme = useTheme();

     return (
          <Dialog
               onClose={props.onClose}
               open={props.open}
               className={classes.root}
               TransitionComponent={TransitionSlideUp}
          >
               <div className={classes.body} onKeyDown={props.onKeyDown}>
                    <div
                         className={classes.iconContainer}
                         style={{
                              color:
                                   props.variant === "success"
                                        ? theme.palette.success.main
                                        : props.variant === "warning"
                                             ? theme.palette.warning.main
                                             : props.variant === "info"
                                                  ? theme.palette.primary.main
                                                  : theme.palette.error.main,
                         }}
                    >
                         {props.icon === "lock" ? (
                              <RiLockLine className={classes.icon} />
                         ) : props.variant === "success" ? (
                              <CheckCircleOutlined className={classes.icon} />
                         ) : props.variant === "info" ? (
                              <RiInformationLine className={classes.icon} />
                         ) : (
                              <ReportProblemOutlined className={classes.icon} />
                         )}
                    </div>
                    <Space vertical space={4} />
                    <Typography variant={"h5"} className={classes.typography}>
                         {props.title}
                    </Typography>
                    <Space vertical space={2} />
                    <Typography
                         variant={"body1"}
                         align={"center"}
                         className={classes.typography}
                         style={{ color: theme.palette.grey.S3 }}
                    >
                         {props.body}
                    </Typography>
                    <Space vertical space={6} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                         <Button
                              label={"OKAY"}
                              variant={"outlined"}
                              color={props.variant}
                              onClick={props.onClose}
                         />
                    </div>
               </div>
               <div
                    style={{
                         height: 15,
                         backgroundColor:
                              props.variant === "success"
                                   ? theme.palette.success.main
                                   : props.variant === "warning"
                                        ? theme.palette.warning.main
                                        : props.variant === "info"
                                             ? theme.palette.primary.main
                                             : theme.palette.error.main,
                    }}
               ></div>
          </Dialog>
     );
}
