import {
     SUGGESTION_ATTR_KEY,
     SUGGESTION_ATTR_VALUE,
     SUGGESTION_CLEAR,
     SUGGESTION_DEPARTMENT_USERS,
     SUGGESTION_DIRECTORY_GROUP,
     SUGGESTION_KEYWORD,
     SUGGESTION_TYPE,
     SUGGESTION_USERS,
} from "../actions/types";

const init = {
     attribute_keys: [],
     attribute_values: [],
     keywords: [],
     types: [],
     directory_groups: [],
     users: [],
     department_users: [],
};

export default function Main(state = init, action) {
     switch (action.type) {
          case SUGGESTION_ATTR_KEY:
               return {
                    ...state,
                    attribute_keys: action.payload,
               };

          case SUGGESTION_ATTR_VALUE:
               return {
                    ...state,
                    attribute_values: action.payload,
               };

          case SUGGESTION_KEYWORD:
               return {
                    ...state,
                    keywords: action.payload,
               };

          case SUGGESTION_TYPE:
               return {
                    ...state,
                    types: action.payload,
               };

          case SUGGESTION_DIRECTORY_GROUP:
               return {
                    ...state,
                    directory_groups: action.payload,
               };

          case SUGGESTION_USERS:
               return {
                    ...state,
                    users: action.payload,
               };

          case SUGGESTION_DEPARTMENT_USERS:
               return {
                    ...state,
                    department_users: action.payload,
               };

          case SUGGESTION_CLEAR:
               return {
                    ...state,
                    [action.name]: [],
               };

          default:
               return state;
     }
}
