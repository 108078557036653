import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";
import Space from "../panel/Space";
// import getFileIcon from "../../../utils/GET_FILE_ICON";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { MESSAGE } from "../../../redux/actions/message";

const Dropzone = props => {
   const theme = useTheme();
   const classes = useStyles();

   const baseStyle = {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      // padding: '100px 50px',
      // borderWidth: 1,
      // borderRadius: theme.shape.borderRadius,
      // borderColor: theme.palette.grey.S3,
      // borderStyle: 'solid',
      backgroundColor: "white",
      color: theme.palette.grey.S4,
      outline: "none",
      transition: ".3s ease-in-out",
      height: props.attachments.length > 0 ? 360 : 500,
      width: "100% !important",

      "&:hover": {
         cursor: "pointer"
      }
   };

   const activeStyle = {
      borderColor: theme.palette.success.main
   };

   const acceptStyle = {
      borderColor: theme.palette.success.main
   };

   const rejectStyle = {
      borderColor: theme.palette.error.main
   };

   const onDropAccepted = useCallback(files => {
      props.onDropzoneAdd(files);
      MESSAGE("Filesize limit exceeded", "dequeue");
   }, []);

   const onDropRejected = useCallback(file => {
      const isExceeded = (typeof file[0].size !== "undefined" && file[0].size / 1000000 > props.fileSizeLimit) || (typeof file[0].file.size !== "undefined" && file[0].file.size / 1000000 > props.fileSizeLimit);
      if (isExceeded) {
         MESSAGE("Filesize limit exceeded", "enqueue", "error");
      }

      if ((typeof file[0].name !== "undefined" && !file[0].name.endsWith(".pdf")) || (typeof file[0].file.name !== "undefined" && !file[0].file.name.endsWith(".pdf"))) {
         MESSAGE("Unsupported file format", "enqueue", "error");
      }
   }, []);

   const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      // acceptedFiles
   } = useDropzone({
      onDropAccepted,
      onDropRejected,
      accept: "application/pdf",
      maxSize: props.fileSizeLimit * 1000000,
      multiple: true
   });

   const style = useMemo(
      () => ({
         ...baseStyle,
         ...(isDragActive ? activeStyle : {}),
         ...(isDragAccept ? acceptStyle : {}),
         ...(isDragReject ? rejectStyle : {})
      }),
      [
         isDragActive,
         // isDragAccept,
         isDragReject,
         // baseStyle,
         // activeStyle,
         // acceptStyle,
         // rejectStyle
      ]
   );

   /* const removeFile = file => () => {
      props.onRemoveFile(file);
      acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
   }; */

   // const clear = () => {
   //     for (let file of acceptedFiles)
   //         acceptedFiles.splice(acceptedFiles.indexOf(file), 1)
   // }
   //
   // const setInitFiles = (file_array) => () => {
   //     for (let file of file_array)
   //         acceptedFiles.push(file)
   // }

   /* let isEmpty = true;

   const files = acceptedFiles.map(file => (
      <div
         style={{
            textAlign: "center",
            maxWidth: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
         }}
      >
         {(isEmpty = false)}
         <img
            src={getFileIcon(file.path)}
            alt={"File icon"}
            style={{
               height: 100
            }}
         />
         <Space vertical space={3} />
         <Typography noWrap variant={"body1"}>
            {file.path}
         </Typography>
         <Space vertical space={2} />
         <div
            style={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center"
            }}
         >
            {(file.size / 1000000).toFixed(2)} MB
            <div
               style={{
                  margin: "0 14px"
               }}
            >
               |
            </div>
            <div
               onClick={removeFile(file)}
               style={{
                  color: theme.palette.error.main,
                  cursor: "pointer"
               }}
            >
               Remove
            </div>
         </div>
      </div>
   )); */

   return (
      <div className={classes.root}>
         <div
            style={{ width: "100%" }}
            className={clsx(
               classes.container,
               props.error ? classes.error : null
            )}
         >
            <div {...getRootProps({ style })}>
               <div
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     flexDirection: "column"
                  }}
               >
                  <div style={{ width: "100%" }}>
                     <input {...getInputProps()} />
                     <div
                        style={{
                           width: "100%"
                        }}
                     >
                        {/*{!isEmpty ? (*/}
                        {/*     <div>{files}</div>*/}
                        {/*) : (*/}
                        <div
                           style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center"
                           }}
                        >
                           <Typography variant={"h5"}>
                              Drag PDF files here
                           </Typography>
                           <Space vertical space={2} />
                           <Typography variant={"h5"}>or</Typography>
                           <Space vertical space={2} />
                           <div
                              style={{
                                 backgroundColor: theme.palette.grey.S1,
                                 height: 40,
                                 borderRadius: 5,
                                 width: 120,
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 color: theme.palette.grey.S4
                              }}
                           >
                              <Typography variant={"body1"}>BROWSE</Typography>
                           </div>
                           <Space vertical space={8} />
                           <Typography variant={"body1"}>
                              Filesize must not exceed {props.fileSizeLimit}MB
                           </Typography>
                        </div>
                        {/*)}*/}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {props.error ? (
            <div>
               <Space vertical space={1} />
               <Typography variant={"caption"} className={classes.errorMessage}>
                  {props.error}
               </Typography>
            </div>
         ) : null}
      </div>
   );
};

const useStyles = makeStyles(theme => ({
   root: {
      paddingRight: theme.spacing(8)
   },
   container: {
      border: "1px solid " + theme.palette.grey.S3,
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
         cursor: "pointer"
      }
   },
   error: {
      border: "1px solid " + theme.palette.error.main
   },
   errorMessage: {
      color: theme.palette.error.main
   }
}));

export default Dropzone;
