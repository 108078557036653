import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Space from "../panel/Space";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
     root: {
          paddingRight: theme.spacing(3),
          paddingTop: theme.spacing(1),
          paddingLeft: theme.spacing(1),
          display: "flex",
          alignItems: "center",
          // minWidth: 1280
     },
}));

export default function Main(props) {
     const CLASSES = useStyles();
     let LevelSpace = [];
     for (let i = 0; i < props.level; i++) {
          LevelSpace.push(<Space space={3 - 1} />);
     }
     return (
          <div className={clsx(CLASSES.root)}>
               {LevelSpace.map((space, index) => (
                    <div key={index}>{space}</div>
               ))}

               {props.level > 1 ? <Space space={4} /> : null}

               <Typography noWrap variant={"caption"}>
                    {props.text.toUpperCase()}
               </Typography>
          </div>
     );
}
