import { store } from "../../redux";

const { getState } = store

export default function Main(active_directory) {
    const DIRECTORY_DETAILS = getState().DIRECTORY.details
    if (DIRECTORY_DETAILS.ancestry && DIRECTORY_DETAILS.ancestry.length > 0) {
        const AUTH = getState().AUTH
        for (let item of DIRECTORY_DETAILS.ancestry) {
            if (AUTH.CRUD_dirs &&
                AUTH.CRUD_dirs.includes(item.id)){
                    if(active_directory === item.id){
                        return true
                    }else{
                        return false
                    }
                } 

        }
    }
    return false
}
