import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        background: 'white',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1.5),
        border: '1px solid ' + theme.palette.grey.S3,
        outline: 0,
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.grey.S4,
        width: 'calc(100% - ' + theme.spacing(3) + 'px - 2px)'
    },
    input: {
        outlineWidth: 0,
        border: 0,
        background: 'transparent',
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.grey.S4,
        width: '100%',
        resize: 'none',
        '&::placeholder': {
            color: theme.palette.grey.S3
        }
    },
    icon: {
        marginLeft: theme.spacing(2)
    },
    scrollbar: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            marginRight: '-8px',
            width: 8,
            height: 8,
            backgroundColor: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            opacity: 1,
            backgroundColor: theme.palette.grey.S2,
        }
    },
}));

export default function Main(props) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <textarea {...props} className={clsx(classes.input, classes.scrollbar)} />
        </div>
    )
}