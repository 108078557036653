import axios from "axios";
import { env } from "../../env";
import { ADMIN_USERS_SET, AUTH_LOGIN, AUTH_LOGOUT, USERS_USER_DEPARTMENTS_SET } from "./types";
import { store } from "../index";
import ERROR_HANDLING from "../../utils/requests/ERROR_HANDLING";
import { resetContext, setActiveDirectory, setToggle } from "./context";
import { GET_PUBLIC_DEPARTMENTS } from "./department";
import VALIDATE_LOG_IN from "../../utils/validations/VALIDATE_LOG_IN";
import print from "../../utils/misc/print";
/* import CHANGE_URL from "../../utils/context/CHANGE_URL";
import IS_AUTHENTICATED from "../../utils/permissions/IS_AUTHENTICATED"; */
import { RELOAD, REPLACE } from "../../utils/history";
import { REDIRECT } from "../../utils/history";

const url = env.URL_API + "auth/";
const token_key = "Token ";
const { dispatch, getState } = store;

/**
 * USER LOGIN
 *
 * @param employee_id
 * @param password
 * @constructor
 */
export const LOG_IN = (employee_id, password, onSuccess, onFailed) => {
  // SET LOGIN LOADER TO TRUE
  setToggle("login_loader", true);

  // VALIDATE LOGIN FORM
  if (!VALIDATE_LOG_IN(employee_id, password)) {
    setToggle("login_loader", false);
    return;
  }

  const AUTH = getState().AUTH;

  // PARSE REQUEST PARAMETERS
  const data = new FormData();
  data.set("employee_id", employee_id);
  data.set("password", password);

  // SEND REQUEST
  axios
    .post(url + "login", data)
    .then(async (response) => {
      // PRINT RESULT
      print("LOGIN", response);

      // CHECK FOR ERRORS
      if (response.data.error) ERROR_HANDLING(response);
      // NO ERROR
      else {
        // FIXTURES
        // await setActiveDirectory("dep");

        // SAVE RESPONSE DATA TO REDUX STORE
        await dispatch({
          type: AUTH_LOGIN,
          payload: response.data,
        });

        // IF NOT PREVIOUS USER; CLEAR ALL STATES
        if (AUTH.id !== response.data.id) {
          resetContext();
        }

        // CHECKS IF USER IS A MEMBER TO AT LEAST ONE DEPARTMENT
        if (response.data.department.length > 0) {
          // SET ACTIVE DIRECTORY TO FIRST DEPARTMENT
          await setActiveDirectory(
            "dep" + response.data.department[0].root_directory,
            () => {
              // while (true) {
              //     if (IS_AUTHENTICATED()) {
              //         CHANGE_URL('/dir/dep' + response.data.department[0].root_directory)
              //         break
              //     }
              // }
              // onSuccess('/dir/dep' + response.data.department[0].root_directory)
              // history.push('/dir/dep' + response.data.department[0].root_directory)
              REDIRECT(
                "/dir/dep" + response.data.department[0].root_directory,
                RELOAD
              );
            }
          );

          // FETCH DIRECTORY TREE AND DETAILS
          // await GET_DIRECTORY_TREE()
          // await GET_DIRECTORY_DETAILS('dep' + response.data.department[0].root_directory)
        }

        // NO DEPARTMENT MEMBERSHIP
        else {
          // GET PUBLIC DEPARTMENT ROOT DIRECTORIES
          await GET_PUBLIC_DEPARTMENTS(async () => {
            await setActiveDirectory(
              "pub" + store.getState().DEPARTMENT.public[0].root_directory,
              () => {
                // while (true) {
                //     if (IS_AUTHENTICATED()) {
                //         CHANGE_URL('/dir/pub' + store.getState().DEPARTMENT.public[0].root_directory)
                //         break
                //     }
                // }
                // onSuccess('/dir/pub' + store.getState().DEPARTMENT.public[0].root_directory)
                REDIRECT(
                  "/dir/" + store.getState().CONTEXT.active_directory,
                  RELOAD
                );
              }
            );
            // await GET_DIRECTORY_TREE()
            // await GET_DIRECTORY_DETAILS('pub' + store.getState().DEPARTMENT.public[0].root_directory)
          });
        }
      }

      // SET LOGIN LOADER TO FALSE
      setToggle("login_loader", false);
    })
    .catch((error) => {
      // PRINT RESULT ERROR
      print("LOGIN", error);

      // CHECK ERROR AND DO APPROPRIATE ACTION
      ERROR_HANDLING();

      // SET LOGIN LOADER TO FALSE
      setToggle("login_loader", false);
    });
};

/**
 * USER LOGOUT
 *
 * @constructor
 */
export const LOG_OUT = async () => {
  // SET LOGOUT LOADER TO TRUE
  setToggle("logout_loader", true);

  const AUTH = getState().AUTH;

  // PARSE REQUEST PARAMETERS
  const data = new FormData();
  data.set("user_id", AUTH.id);

  axios
    .post(url + "logout", data, {
      headers: { Authorization: token_key + AUTH.token },
    })
    .then(async (response) => {
      print("LOGOUT", response);
      await dispatch({ type: AUTH_LOGOUT });
      REPLACE("/landing-page", RELOAD);
    })
    .catch((error) => {
      print("LOGOUT", error);
    });

  // CLEAR DATA ON REDUX STORE
  await resetContext();

  // SET LOGOUT LOADER TO TRUE
  setToggle("logout_loader", true);
};

export const GET_USERS = async () => {
  // SET LOGOUT LOADER TO TRUE
  // SET_TOGGLE('logout_loader', true)

  const AUTH = getState().AUTH;

  axios
    .get(url + "users", {
      headers: { Authorization: token_key + AUTH.token },
    })
    .then(async (response) => {
      print("GET ALL USERS", response);
      dispatch({
        type: ADMIN_USERS_SET,
        payload: response.data.users,
      });
    })
    .catch((error) => {
      print("GET ALL USERS", error);
    });

  // SET LOGOUT LOADER TO TRUE
  // SET_TOGGLE('logout_loader', true)
};

export const GET_DEPARTMENT_USERS = async (department_id) => {
  // SET LOGOUT LOADER TO TRUE
  // SET_TOGGLE('logout_loader', true)

  const AUTH = getState().AUTH;

  const data = new FormData();
  data.set("department_id", department_id);

  axios
    .post(url + "department-users", data, {
      headers: { Authorization: token_key + AUTH.token },
    })
    .then(async (response) => {
      print("GET ALL USERS", response);
      dispatch({
        type: ADMIN_USERS_SET,
        payload: response.data.users,
      });
    })
    .catch((error) => {
      print("GET ALL USERS", error);
    });

  // SET LOGOUT LOADER TO TRUE
  // SET_TOGGLE('logout_loader', true)
};
export const GET_ACTIVE_USERS_BY_USER_DEPARTMENTS = (root_directory_id = false) => {
  setToggle("active_users_by_users_department_loader", true);

  const AUTH = getState().AUTH;
  const data = new FormData();
  data.set("root_directory_id", root_directory_id);
  axios.post(url + "users-user-departments", data, {
    headers: { Authorization: token_key + AUTH.token },
  })
    .then(async (response) => {
      print("GET ALL USERS IN USER DEPARTMENTS", response);
      dispatch({
        type: USERS_USER_DEPARTMENTS_SET,
        payload: response.data.users,
      });
      setToggle("active_users_by_users_department_loader", false);
    })
    .catch((error) => {
      print("GET ALL USERS IN USER DEPARTMENTS", error);
    });
};

