import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import InputText from "../core/input/InputText";
import Space from "../core/panel/Space";
import InputUserSuggestion from "../core/input/InputUserSuggestion";
import Grid from "@material-ui/core/Grid";
import { setForm } from "../../redux/actions/context";
import { GET_SUGGESTION } from "../../redux/actions/suggestion";
import LightToolTip from "../core/tooltip/LightToolTip";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  groupControl: {
    marginBottom: theme.spacing(6),
    minWidth: 300,
    width: "100%",
  },
  flexAlignItemsCenter: {
    display: "flex",
    alignItems: "center",
    // width: '100%'
  },
  AlignItemsCenterSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  icon: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  badge: {
    color: theme.palette.error.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function Main(props) {
  const classes = useStyles();
  const form = props.class.props.CONTEXT.forms.department;
  const suggestion = props.class.props.SUGGESTION;

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <div className={classes.flexColumn} style={{ paddingRight: 24 }}>
            <div className={classes.groupControl}>
              <div className={classes.flexAlignItemsCenter}>
                <Typography variant={"body1"}>
                  What is the name of the department?
                </Typography>
                <Space space={1} />
                <LightToolTip title={"Required field"} placement={"right"}>
                  <Typography
                    variant={"body1"}
                    color={"primary"}
                    className={classes.badge}
                  >
                    *
                  </Typography>
                </LightToolTip>
              </div>
              <Space vertical space={2} />
              <InputText
                name={"name"}
                value={form.name}
                error={form.error_name}
                placeholder={"Ex. College of Education"}
                onChange={(event) =>
                  setForm("department", event.target.name, event.target.value)
                }
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={5}>
          <div className={classes.flexColumn} style={{ paddingLeft: 24 }}>
            <div className={classes.groupControl}>
              <div className={classes.flexAlignItemsCenter}>
                <Typography variant={"body1"}>
                  Who is the head of this department?
                </Typography>
                <Space space={1} />
                <LightToolTip title={"Required field"} placement={"right"}>
                  <Typography
                    variant={"body1"}
                    color={"primary"}
                    className={classes.badge}
                  >
                    *
                  </Typography>
                </LightToolTip>
              </div>
              <Space vertical space={2} />
              <InputUserSuggestion
                name={"head"}
                items={suggestion.users}
                value={form.head.name}
                maxlength={100}
                error={form.error_head}
                type={"department"}
                placeholder={"Ex. John Smith"}
                onChange={setForm}
                onSelect={setForm}
                callback={() => {
                  GET_SUGGESTION(
                    "user",
                    props.class.props.CONTEXT.forms.department.head.name
                  );
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
