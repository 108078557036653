import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Line from "../decoration/Line";
import getFileIcon from "../../../utils/GET_FILE_ICON";
import Space from "../panel/Space";
import LightToolTip from "../tooltip/LightToolTip";
import Grow from "@material-ui/core/Grow";

import {
  RiEarthLine,
  RiFileCopyLine,
  RiLinksLine,
  RiLockLine,
} from "react-icons/all";
import {
  BusinessRounded
} from "@material-ui/icons";

import { setContext } from "../../../redux/actions/context";
import { SEND_MESSAGE_DIALOG } from "../../../redux/actions/dialogs";
import IS_ENV_PUB from "../../../utils/context/IS_ENV_PUB";
import IS_ACCREDITOR, {
  isUserAccreditor,
} from "../../../utils/permissions/IS_ACCREDITOR";

import IS_AUTHENTICATED from "../../../utils/permissions/IS_AUTHENTICATED";
import SelectionTable from "../table/SelectionTable";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  documentContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  documentBoxContainer: {
    width: 232,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: "solid 1px " + theme.palette.grey.S2,
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      cursor: "pointer",
    },
  },
  filetypeIconContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(6),
    paddingTop: 0,
  },
  typography: {
    lineHeight: "normal",
    color: theme.palette.grey.S4,
  },
  filetypeIcon: {
    height: 70,
  },
  title: {
    padding: theme.spacing(2),
  },
  icon: {
    color: theme.palette.grey.S3,
  },
  iconLink: {
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  badgeContainer: {
    display: "flex",
    justifyContent: "flex-end",
    // alignItems: 'center',
    padding: theme.spacing(1),
    height: theme.spacing(3),
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },

  folderGroupContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  gridIcon: {
    color: theme.palette.grey.S3,
  },
}));

export default function Main(props) {
  const classes = useStyles();
  const { listView } = useSelector((state) => ({
    listView: state.CONTEXT.toggles.directory_list_view || false,
  }));

  const columns = [
    {
      id: "docdir_id",
      numeric: true,
      disablePadding: true,
      label: "id",
      hidden: true,
    },
    {
      id: "is_public",
      label: "Public",
      hidden: true,
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
      style: {
        width: "50%",
      },
      hidden: false,
    },
    {
      id: "is_guest",
      label: "Guest",
      hidden: true,
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      label: "Date Created",
      wrap: (value) => {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED);
      },
      hidden: false,
    },
    {
      id: "updated_at",
      numeric: false,
      disablePadding: false,
      label: "Date Modified",
      wrap: (value) => {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED);
      },
      hidden: false,
    },
  ];

  const { documents } = props;

  const rows = [];
  documents.forEach((item) => {
    rows.push([
      item.docdir_id,
      item.is_public,
      item.title,
      item.is_guest,
      item.created_at,
      item.updated_at,
    ]);
  });

  /* const toggleListView = () => {
    setToggle("directory_list_view", !listView);
  }; */
  const private_document_on_click = () => {
    SEND_MESSAGE_DIALOG(
      "info",
      "lock",
      "Private Document",
      "This document has not yet publicized by the owner office.",
      () => { }
    );
  };

  const private_document_guest_on_click = () => {
    SEND_MESSAGE_DIALOG(
      "info",
      "lock",
      "Private Document (Uniwide)",
      "This document has not yet publicized by the owner office.",
      () => { }
    );
  };

  const accred_public_document_on_click = () => {
    SEND_MESSAGE_DIALOG(
      "info",
      "lock",
      "No Permission",
      "Sorry! You have no permission to access this document.",
      () => { }
    );
  };

  return (
    <div className={classes.root}>
      {/*{contains ?*/}
      <div className={classes.folderGroupContainer}>
        <Typography
          noWrap
          variant={"body2"}
          className={clsx(classes.typography)}
        >
          DOCUMENTS
        </Typography>
      </div>
      {/*}*/}
      {listView ? (
        <>
          <SelectionTable
            columns={columns}
            rows={rows}
            noHead
            disableSelect
            disablePagination
            disableEmptyRow
            disableDenseToggle
            onClickRow={async (row) => {
              if (isUserAccreditor() && !IS_ACCREDITOR()) {
                accred_public_document_on_click();
              } else {
                if (!row[1] && props.prefix === "pub" && !IS_ACCREDITOR()) {
                  private_document_on_click();
                } else {
                  props.onClick(row[0]);
                }
              }
            }}
          />
        </>
      ) : (
        <Grid container spacing={0}>
          {props.documents.map((doc, index) => (
            <Grid item key={index}>
              <Grow in={true}>
                <LightToolTip title={doc.title} placement={"bottom-start"}>
                  <div
                    className={classes.documentBoxContainer}
                    onClick={() => {
                      if (isUserAccreditor() && !IS_ACCREDITOR()) {
                        accred_public_document_on_click();
                      } else {
                        if (
                          !doc.is_public && !doc.is_guest &&
                          props.prefix === "pub" &&
                          !IS_ACCREDITOR()
                        ) {
                          private_document_on_click();
                        } else if (doc.is_guest && !doc.is_public &&
                          props.prefix === "pub" &&
                          !IS_ACCREDITOR()) {
                           
                          props.onClick(doc.docdir_id);
                        } else if (!doc.is_guest && doc.is_public &&
                          props.prefix === "pub" && !IS_AUTHENTICATED() &&
                          !IS_ACCREDITOR()) {
                          private_document_guest_on_click()
                         
                        } else {
                          props.onClick(doc.docdir_id);
                        }
                      }
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      e.persist();
                      setContext("documentX", e.pageX);
                      setContext("documentY", e.pageY);
                      setContext("show_document_context_menu", true);
                      setContext("pre_clipboard", {
                        id: doc.docdir_id,
                        type: "DOCUMENT",
                        is_public: doc.is_public,
                        is_guest: doc.is_guest,
                      });
                    }}
                  >
                    {IS_ACCREDITOR() ? (
                      <Space vertical space={4} />
                    ) : (
                      <div className={classes.badgeContainer}>
                        {doc.is_link ? (
                          <div>
                            <LightToolTip
                              title="Link Document"
                              placement="bottom-end"
                            >
                              <RiLinksLine size={22} className={classes.icon} />
                            </LightToolTip>
                          </div>
                        ) : doc.is_copy ? (
                          <div>
                            <LightToolTip
                              title="Copy Document"
                              placement="bottom-end"
                            >
                              <RiFileCopyLine
                                size={22}
                                className={classes.icon}
                              />
                            </LightToolTip>
                          </div>
                        ) : (
                          <Space vertical space={3} />
                        )}
                        {doc.is_public && doc.is_guest ?
                          (
                            <div className={classes.flexAlignCenter}>
                              {/* <Space space={1} /> */}
                              {/* <LightToolTip
                                title="Publicized Document (Uniwide)"
                                placement="bottom-end"
                              >
                                <BusinessRounded size={50} className={classes.icon} />
                              </LightToolTip> */}
                              <Space space={1} />
                              <LightToolTip
                                title="Publicized Document"
                                placement="bottom-end"
                              >
                                <RiEarthLine size={22} className={classes.icon} />
                              </LightToolTip>
                            </div>
                          ) : doc.is_guest && !doc.is_public ? (
                            <div className={classes.flexAlignCenter}>
                              <Space space={1} />
                              <LightToolTip
                                title="Publicized Document (Uniwide)"
                                placement="bottom-end"
                              >
                                <RiEarthLine size={22} className={classes.icon} />
                              </LightToolTip>
                            </div>
                          ) 
                          // : doc.is_public && !doc.is_guest ? (
                          //   <div className={classes.flexAlignCenter}>s
                          //     <Space space={1} />
                          //     <LightToolTip
                          //       title="Publicized Document (Uniwide)"
                          //       placement="bottom-end"
                          //     >
                          //       <BusinessRounded size={22} className={classes.icon} />
                          //     </LightToolTip>
                          //   </div>
                          // ) 
                          : IS_ENV_PUB() && !doc.is_public ? (
                            <div className={classes.flexAlignCenter}>
                              <Space space={1} />
                              <LightToolTip
                                title="Private Document"
                                placement="bottom-end"
                              >
                                <RiLockLine size={22} className={classes.icon} />
                              </LightToolTip>
                            </div>
                          ) : null}
                      </div>
                    )}
                    <Space vertical space={3} />
                    <div className={classes.filetypeIconContainer}>
                      <img
                        src={getFileIcon(".pdf")}
                        alt={"Filetype icon"}
                        className={classes.filetypeIcon}
                      />
                    </div>
                    <Line horizontal width={"100%"} />
                    <Typography
                      noWrap
                      variant={"body1"}
                      className={clsx(classes.typography, classes.title)}
                    >
                      {doc.title}
                    </Typography>
                  </div>
                </LightToolTip>
              </Grow>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}
