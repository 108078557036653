// import { store } from "../index";
import { setContext } from "./context";

// const { dispatch, getState } = store;

export const SEND_MESSAGE_DIALOG = (
  variant,
  icon,
  title,
  caption,
  on_close = () => { }
) => {
  setContext("message_dialog", {
    open: true,
    message: {
      title,
      caption,
    },
    variant,
    icon,
    on_close,
  });
};

export const SEND_CONFIRMATION_DIALOG = (
  variant,
  icon,
  title,
  caption,
  on_confirm = () => { }
) => {
  setContext("confirmation_dialog", {
    open: true,
    message: {
      title,
      caption,
    },
    variant,
    icon,
    on_confirm,
  });
};
export const SEND_CONFIRMATION_DIALOG_WITH_KEY = (
  variant,
  icon,
  title,
  caption,
  key,
  on_confirm = () => { }
) => {
  setContext("confirmation_dialog_with_key", {
    open: true,
    message: {
      title,
      caption,
    },
    variant,
    icon,
    key,
    on_confirm,
  });
};
