import React, { Component } from 'react';
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Space from "../../../components/core/panel/Space";
import MUIDataTable from "mui-datatables";
import { TransitionSlideUp } from "../../../components/core/transition/TransitionSlideLeft";
import clsx from "clsx";
import LightToolTip from "../../../components/core/tooltip/LightToolTip";
import { ClearOutlined } from "@material-ui/icons";
import Button from "../../../components/core/button/Button";
import { setToggle } from "../../../redux/actions/context";
import Footer from "../../../components/layout/Footer";
import Dialog from "@material-ui/core/Dialog";
// import { THEME } from "../../../static/styles/theme";

const useStyles = makeStyles((theme) => ({
   container: {
      height: "calc(100vh - 18px)",
      minWidth: 1280,
   },
   innerContainer: {
      paddingLeft: theme.spacing(6),
      paddingTop: theme.spacing(6),
      marginRight: 3,
      height: "calc(100vh - 48px)",
   },
   icon: {
      "&:hover": {
         color: theme.palette.primary.main,
         cursor: "pointer",
      },
   },
   heading: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.grey.S4,
   },
   body: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
   },
   scrollbar: {
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
         width: 8,
         height: 8,
         backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
         borderRadius: 10,
         opacity: 1,
         backgroundColor: theme.palette.grey.S2,
      },
   },
   flex: {
      display: "flex",
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
   },
   action: {
      display: "flex",
   },
}));

/* const getMuiTheme = () =>
   createMuiTheme({
      ...THEME,
      overrides: {
         MuiPaper: {
            root: {
               boxShadow: "none !important",
            },
         },
         MuiToolbar: {
            root: {
               padding: "0 !important",
            },
         },
         MUIDataTablePagination: {
            tableCellContainer: {
               padding: "0 !important",
            },
         },
      },
   }); */

const Body = (props) => {
   const classes = useStyles();
   const CLASSES = useStyles();

   // const [responsive, setResponsive] = useState("standard");
   // const [tableBodyHeight, setTableBodyHeight] = useState("500px");
   // const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");

   const options = {
      filter: true,
      filterType: "multiselect",
      selectableRows: "none",
      tableBodyHeight: 500,
   };

   const columns = [
      "Username",
      "Lastname",
      "Firstname",
      "Role",
      "Date Added",
      {
         name: "Actions",
         options: {
            filter: false,
            sort: false,
            print: false,
            searchable: false,
         },
      },
   ];

   const data = [
      // ["1883", "Lazo", "Creselle John", "Member", "March 12, 2020",
      //     <div className={CLASSES.action}>
      //         <Button size={'small'} color={'warning'}>Edit Privileges</Button>
      //         <Space space={1.5}/>
      //         <Button size={'small'} color={'error'}>Remove User</Button>
      //     </div>
      // ],]
   ];

   for (let user of props.class.props.users) {
      data.push([
         user.username,
         user.lastname,
         user.firstname,
         user.role,
         user.date_added,
         <div className={classes.action}>
            <Button size={"small"} color={"warning"}>
               Edit Privileges
            </Button>
            <Space space={1.5} />
            <Button size={"small"} color={"error"}>
               Remove User
            </Button>
         </div>,
      ]);
   }

   const onClick = (type) => {
      switch (type) {
         case "view-document-close":
            setToggle("adminViewOffice", false);
            break;

         default:
            break;
      }
   };

   return (
      <Dialog
         fullScreen
         open
         onClose={() => onClick("view-document-close")}
         TransitionComponent={TransitionSlideUp}
      >
         <div className={clsx(CLASSES.container)}>
            <div className={clsx(CLASSES.innerContainer, CLASSES.scrollbar)}>
               <div className={CLASSES.heading}>
                  <LightToolTip title="Close" placement="bottom">
                     <ClearOutlined
                        className={CLASSES.icon}
                        onClick={() => onClick("view-document-close")}
                     />
                  </LightToolTip>
                  <Space space={5} />
                  <Typography variant={"h5"}>
                     {props.class.props.adminViewOfficeData[1]}
                  </Typography>
               </div>
               <Space vertical space={8} />
               <div className={CLASSES.body}>
                  {/*<MuiThemeProvider theme={getMuiTheme()}>*/}
                  <MUIDataTable
                     title={
                        <>
                           <Typography variant={"h4"}>Users</Typography>
                           <Typography variant={"body1"}>
                              Manage users in this office and their privileges
                           </Typography>
                           <Space vertical space={3} />
                        </>
                     }
                     data={data}
                     columns={columns}
                     options={options}
                  />
                  {/*</MuiThemeProvider>*/}
               </div>
            </div>
         </div>
         <Footer />
      </Dialog>
   );
};

class OfficeUsers extends Component {
   render() {
      return <Body class={this} />;
   }
}

const mapStateToProps = (state) => ({
   adminViewOfficeData: state.CONTEXT.adminViewOfficeData,
   users: state.ADMIN.users,
});

export default connect(mapStateToProps, {})(OfficeUsers);
