import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./redux";
import { MuiThemeProvider } from "@material-ui/core";
import { THEME } from "./static/styles/theme";
import SnackBarProvider from "./components/core/snackbar/SnackBarProvider";
import history from "./utils/history";

ReactDOM.render(
     <MuiThemeProvider theme={THEME}>
          <Provider store={store}>
               <Router history={history}>
                    <PersistGate
                         loading={<h1>Loading</h1>}
                         persistor={persistor}
                    >
                         <SnackBarProvider>
                              <App />
                         </SnackBarProvider>
                    </PersistGate>
               </Router>
          </Provider>
     </MuiThemeProvider>,
     document.getElementById("root")
);
