import React, { useEffect } from "react";
import { useHistory } from "react-router-dom"
import { Container, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import QASASBrand from "../static/media/img/Brand.png";
import SDMD from "../static/media/img/SDMD.png";
import Space from "../components/core/panel/Space";
/* import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import { Icon, InlineIcon } from "@iconify/react";
import searchLine from "@iconify/icons-ri/search-line"; */
import Button from "../components/core/button/Button";
// import ChipList from "../components/core/chip/ChipList";
// import Grid from "@material-ui/core/Grid";
/* import { setToggle } from "../redux/actions/context";
import { AlternateEmailTwoTone } from "@material-ui/icons"; */
// import Link from "@material-ui/core/Link";

import { GET_GUEST_DEPARTMENTS, GET_USER_DEPARTMENTS } from "../redux/actions/department";
import { useSelector } from "react-redux";
import { store } from "../../src/redux/index";
import Agreement from "../components/core/dialog/Agreement";
import Vector from "../static/media/img/Vector.png";
import ComboBox from "../components/core/combo_box/combo_box";
/* import IS_HEAD from "../utils/permissions/IS_HEAD";
import IS_PUBLIC_REQUEST from "../utils/context/IS_PUBLIC_REQUEST";
import IS_MODERATOR from "../utils/permissions/IS_MODERATOR";
import IS_SUPPORT from "../utils/permissions/IS_SUPPORT";
import CircularProgress from "@material-ui/core/CircularProgress"; */
const { getState } = store;
const style = makeStyles(theme => ({
  root: {
    height: "100vh",
    minHeight: "calc(718px + 100px)",
    width: "100%"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    background: "white"
  },
  headerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  topHeader: {
    background: theme.palette.primary.gradient,
    height: 25,
    width: "100%"
  },
  content: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  },
  titleContainer: {
    width: "100%"
  },
  titleContent: {
    display: "grid",
    gridTemplateRows: "auto auto auto",
    gridTemplateColumns: "45%",
    justifyContent: "center",
    gap: "15px",
    width: "100%",
  },
  container: {
    width: 1000,
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: theme.spacing(10),
    borderRadius: theme.shape.borderRadius,
    background: "white"
  },
  vectorContainer: {
    width: "100%",
  },
  vector: {
    width: "100%",
  },
  brand: {
    height: 48
  },
  brands: {
    height: 48,
    marginLeft: "500px"
  },
  inputText: {
    width: 350,
    padding: theme.spacing(2),
    border: "1px solid " + theme.palette.grey.S3,
    borderRadius: theme.shape.borderRadius,
    background: "white",
    outline: 0,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey.S4
  },
  flex: {
    display: "flex"
  },
  flexAlignItemsCenter: {
    alignItems: "center"
  },
  flexSpaceBetween: {
    justifyContent: "space-between"
  },

  searchBox: {
    border: "2px solid black",
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between"
  },
  input: {
    flex: 1,
    paddingLeft: 12
  },
  iconButton: {
    paddingLeft: 12
  },
  listContainer: {
    width: "100%"
  },
  btnLoginContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  officeContainer: {
    width: "100%",
  }
}));



export default function LandingPage() {
  const styles = style();
  const state = useSelector((state) => ({ ...state }))
  // const dispatch = useDispatch()
  const history = useHistory();
  // const recentSearches = ["Missions", "School", "Official Hymn"];
  const [agreement, setAgreement] = React.useState(true);

  const AUTH = getState().AUTH;

  useEffect(() => {
    GET_GUEST_DEPARTMENTS(AUTH.id)
  }, [AUTH])

  const login = async () => {
    // window.location.href=window.location.origin+"/login";
    history.push("/login");
  };

  const goToDirectory = async () => {
    const defaultDirectoryId = state.DEPARTMENT.guest[0].directory_id
    await GET_USER_DEPARTMENTS(AUTH.id, defaultDirectoryId)
  }
  // const dir = window.location.origin;
  // const directoryListUrl = dir + "/dir/monitoring";
  const homepageSubTitleTxt = "USeP’s official Documents Archiving System is now available for everyone.";
  // const directoryListTxt = "Directory List";
  const officeList = state.DEPARTMENT.guest;
  // console.log(AUTH.employee_id)
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <img src={QASASBrand} alt={"QASAS Brand"} className={styles.brand} />
          <img src={SDMD} alt={"SDMD"} className={styles.brands} />

        </div>
      </div>
      <div className={styles.content}>
        <Container className={styles.listContainer}>

          <div className={styles.titleContent}>
            <div className={styles.titleContainer}>
              <Typography variant={"h2"} color={"primary"}>
                Hello, there!
              </Typography>
              <Typography variant={"body1"}>
                {homepageSubTitleTxt}
              </Typography>
              <Space vertical space={3} />
            </div>
            <div className={styles.vectorContainer}>
              <img src={Vector} alt={"Vector"} className={styles.vector} />
            </div>
            <div className={styles.btnLoginContainer}>
              {
                !AUTH.employee_id ? (
                  <Button
                    label="LOGIN"
                    variant={"outlined"}
                    active
                    //  loading={props.class.props.CONTEXT.toggles.login_loader}
                    //  class={props.class}
                    onClick={login}
                  />) : (<Button
                    label="HOME"
                    variant={"outlined"}
                    active
                    onClick={goToDirectory}
                  />)
              }
            </div>
            <Space vertical space={5} />
          </div>
          <div className={styles.officeContainer}>
            <ComboBox
              label={"Browse by Office"}
              value={officeList}
            />
          </div>
          <Space vertical space={5} />

          {/*state.DEPARTMENT.guest.map(department => (*/}

          {/*));*/}

          {/*<Typography variant={"h4"} align={"center"}>*/}
          {/*  Browse by Office*/}
          {/*</Typography>*/}
          {/*<Space vertical space={3} />*/}
          {/*<Grid container spacing={1}>*/}
          {/*  {state.DEPARTMENT.guest.map(department => (*/}
          {/*    <Grid item key={department.directory_id} md={6}>*/}
          {/*      <Link href={dir + "/dir/" + department.urlPermission + department.directory_id} color="inherit">*/}
          {/*        <Typography>{department.name}</Typography>*/}
          {/*      </Link>*/}
          {/*    </Grid>*/}
          {/*  ))}*/}
          {/*</Grid>*/}

          <Space vertical space={3} />
        </Container>
      </div>
      {(!AUTH.employee_id) ?
        <Agreement
          open={agreement}
          onClose={() => {
            setAgreement(false);
          }}
        /> : ""}

    </div>
  );
}
