import { MESSAGE_ADD, MESSAGE_CLEAR, MESSAGE_REMOVE } from "./types";
import { store } from "../index";

const { dispatch } = store

export const MESSAGE = (message, action = 'dequeue', variant = 'info', persist = false) => {
    dispatch({
        type: MESSAGE_ADD, payload: {
            message, action, variant, persist
        }
    })
}

export const REMOVE_MESSAGE = message => {
    dispatch({ type: MESSAGE_REMOVE, message })
}

export const CLEAR_MESSAGES = () => {
    dispatch({ type: MESSAGE_CLEAR })
}