import { store } from "../../redux";

const { getState } = store;

export default function Main(directory_id) {
     const DIRECTORY_DETAILS = getState().DIRECTORY.details;
     if (DIRECTORY_DETAILS.ancestry && DIRECTORY_DETAILS.ancestry.length > 0) {
          for (let i of DIRECTORY_DETAILS.ancestry) {
               if (i.id === parseInt(directory_id.substring(3))) return true;
          }
     }
     return false;
}
