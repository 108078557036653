import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { setToggle } from "../redux/actions/context";
// import SearchResult from "./core/breadcrumb/SearchResult";
import Typography from "@material-ui/core/Typography";
// import Breadcrumb from "./core/breadcrumb/Breadcrumb";
import IS_PUBLIC_REQUEST from "../utils/context/IS_PUBLIC_REQUEST";
import IS_AUTHENTICATED from "../utils/permissions/IS_AUTHENTICATED";
// import ActionDirectory from "./actions/ActionDirectory";
// import ActionDocuments from "./actions/ActionDocuments";
import { GET_DIRECTORY_DETAILS } from "../redux/actions/directory";
import {
  CLEAR_DOCUMENT_DETAILS,
  GET_DOCUMENT,
  // GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS,
} from "../redux/actions/document";
import clsx from "clsx";
import IS_ROOT_DIRECTORY from "../utils/directory/IS_ROOT_DIRECTORY";
import Space from "./core/panel/Space";
import { env } from "../env";
import CollegeDesc from "./core/CollegeDesc";
import DirectoryLinks from "./core/DirectoryLinks";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import HAS_CRUD from "../utils/permissions/HAS_CRUD";
import { resetOfficeTrash } from "../redux/actions/trash";
import { apiGetOfficeTrash } from "../api/trash";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import RequestedToPublicizeDocumentBox from "./core/document/RequestedToPublicizeDocumentBox";
import EmptyFolder from "../static/media/img/EmptyFolder.svg";
import DirectoryGroupBox from "./core/directory/DirectoryGroupBox";
import DocumentBox from "./core/document/DocumentBox";
import IS_MODULE from "../utils/context/IS_MODULE";
import MonitoringTab from "../components/core/Tab/MonitoringTab";

export default function HomeContentBody() {
  const styles = style();
  const { active_directory, directory_details, auth, document } = useSelector(
    (state) => ({
      active_directory: state.CONTEXT.active_directory,
      directory_details: state.DIRECTORY.details,
      auth: state.AUTH,
      document: {
        requested_to_publicize: state.DOCUMENT.requested_to_publicize,
      },
    })
  );

  return (
    <div className={clsx(styles.contentBody, styles.scrollbar)}>
      {IS_MODULE() && IS_AUTHENTICATED() ? (
        <div>
          <MonitoringTab />
        </div>
      ) : null}
      {/* DIRECTORY DESCRIPTION */}
      {directory_details.description && !IS_PUBLIC_REQUEST() && (
        <div>
          {!IS_ROOT_DIRECTORY() && (
            <>
              <Typography variant={"caption"}>DESCRIPTION</Typography>
              <Space vertical space={0.5} />
            </>
          )}

          {IS_ROOT_DIRECTORY() ? (
            directory_details.description
          ) : (
            <Typography
              variant={"body2"}
              style={{
                fontWeight: "bold",
              }}
            >
              {directory_details.description}
            </Typography>
          )}
          <Space vertical space={4} />
        </div>
      )}

      {/*{active_directory.substring(3) in env.DIRECTORY_DETAILS && (*/}
      {/*  <CollegeDesc*/}
      {/*    PQALink={*/}
      {/*      env.DIRECTORY_DETAILS[active_directory.substring(3)].PQA_link*/}
      {/*    }*/}
      {/*    AACCUPLink={*/}
      {/*      env.DIRECTORY_DETAILS[active_directory.substring(3)].AACCUP_link*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}

      <DirectoryLinks />

      {IS_ROOT_DIRECTORY() && (
        <div className={styles.rootDirectoryContainer}>
          <div>
            {/* <Typography variant={""}> */}
            Hello, <strong>{auth.first_name ? auth.first_name : "Guest"}</strong>!
            {/* </Typography> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              <FolderOutlinedIcon className={styles.iconDirTitle} />
              <Typography variant={"h5"}>{directory_details.name}</Typography>
            </div>
          </div>
          <div>
            {HAS_CRUD() && (
              <div
                className={styles.trashContainer}
                onClick={() => {
                  resetOfficeTrash();
                  setToggle("openTrashBin", true);
                  apiGetOfficeTrash();
                }}
              >
                <DeleteOutlineOutlinedIcon
                  className={styles.icon}
                // onClick={toggleListView}
                />
                <div
                  style={{
                    marginLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  <Typography>Office Trash Bin</Typography>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/*{CLASS_PROPS.DIRECTORY.details.accreditors &&*/}
      {/*CLASS_PROPS.DIRECTORY.details.accreditors.length > 0 ?*/}
      {/*    <DirectoryAccreditor*/}
      {/*        accreditors={CLASS_PROPS.DIRECTORY.details.accreditors}*/}
      {/*    /> : null*/}
      {/*}*/}

      {/* PUBLIC REQUEST VIEW */}
      {IS_PUBLIC_REQUEST() ? (
        <div style={{ height: "100%" }}>
          {/* CHECKS IF THERE IS DOCUMENT */}
          {document.requested_to_publicize.documents.length > 0 ? (
            // THERE IS AT LEAST ONE
            document.requested_to_publicize.departments.map(
              (department, index) => (
                <div key={index}>
                  <RequestedToPublicizeDocumentBox
                    group={department}
                    documents={document.requested_to_publicize.documents}
                  />
                </div>
              )
            )
          ) : (
            // EMPTY
            <div className={styles.emptyDirectoryContainer}>
              <div className={styles.emptyDirectory}>
                <img
                  src={EmptyFolder}
                  alt={"Empty Folder"}
                  className={styles.emptyFolder}
                />
                <Space vertical space={1} />
                <Typography
                  variant={"h6"}
                  style={{
                    color: "#AAA",
                  }}
                >
                  No Pending Request
                </Typography>
              </div>
            </div>
          )}
        </div>
      ) : null}

      {/* DIRECTORY VIEW */}
      {!IS_PUBLIC_REQUEST() ? (
        <div style={{ height: "100%" }}>
          {/* DIRECTORIES */}
          {IS_AUTHENTICATED() ? (
            <div>
              {directory_details.children_groups
                ? directory_details.children_groups.map((group, index) => (
                  <div key={index}>
                    <DirectoryGroupBox
                      prefix={active_directory.substring(0, 3)}
                      groupName={group}
                      children={directory_details.children}
                      childOnClick={GET_DIRECTORY_DETAILS}
                    />
                  </div>
                ))
                : null}

              {directory_details?.links > 0 && (
                <DirectoryGroupBox
                  prefix={"dep"}
                  groupName={"LINKS"}
                  isLink
                  children={directory_details.links}
                  childOnClick={GET_DIRECTORY_DETAILS}
                />
              )}
            </div>
          ) : null}

          {/* DOCUMENTS */}
          {directory_details.documents &&
            directory_details.documents.length > 0 ? (
            <DocumentBox
              prefix={active_directory.substring(0, 3)}
              documents={directory_details.documents}
              onClick={async (docdir_id) => {
                CLEAR_DOCUMENT_DETAILS();
                GET_DOCUMENT(docdir_id);
                setToggle("view_document", true);
              }}
            />
          ) : null}

          {/* EMPTY DOCUMENTS */}
          {directory_details.children_groups &&
            directory_details.children_groups.length < 1 &&
            directory_details.documents &&
            directory_details.documents.length < 1 ? (
            <div className={styles.emptyDirectoryContainer}>
              <div className={styles.emptyDirectory}>
                <img
                  src={EmptyFolder}
                  alt={"Empty Folder"}
                  className={styles.emptyFolder}
                />
                <Space vertical space={1} />
                <Typography
                  variant={"h6"}
                  style={{
                    color: "#AAA",
                  }}
                >
                  Empty Directory
                </Typography>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

const style = makeStyles((theme) => ({
  contentBody: {
    width: "100%",
    minWidth: 300,
    height: "calc(100vh - 57px - 81px - 25px - 3px)",
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: 3,
    marginRight: 3,
  },

  scrollbar: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      marginRight: "-8px",
      width: 8,
      height: 8,
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      opacity: 1,
      backgroundColor: theme.palette.grey.S2,
      // '&:hover': {
      //     backgroundColor: theme.palette.grey.S2,
      // }
    },
  },
  rootDirectoryContainer: {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
    marginBottom: 36,
  },
  icon: {
    color: theme.palette.grey.S3,
  },
  iconDirTitle: {
    fontSize: 36,
    color: theme.palette.primary.main,
    marginRight: 8,
  },
  trashContainer: {
    display: "flex",
    alignItems: "center",
    padding: "12px 12px",
    border: `solid .5px ${theme.palette.grey.S2}`,
    borderRadius: 4,
    "&:hover": {
      cursor: "pointer",
    },
  },
  emptyDirectoryContainer: {
    height: "calc(100% - 80px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // paddingRight: theme.spacing(10),
  },
  emptyDirectory: {
    height: 200,
    width: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(7),
    paddingBottom: theme.spacing(8),
    background: "#FAFAFA",
    borderRadius: 1000,
  },
  emptyFolder: {
    height: 100,
    color: theme.palette.grey.S2,
  },
}));
