import React from "react";
import { makeStyles, Tooltip, withStyles } from "@material-ui/core";

const LightTooltip = withStyles((theme) => ({
   tooltip: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.grey.S4,
      boxShadow: theme.shadows[3],
      fontSize: 14,
   },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
   root: {
      "&:hover": {
         cursor: "pointer",
      },
   },
}));

export default function Main(props) {
   const classes = useStyles();
   return (
      <LightTooltip {...props} className={classes.root}>
         {props.children}
      </LightTooltip>
   );
}
