import React, { Component } from 'react';
import { connect } from "react-redux";
import STATES from "../../redux/states";
import { GET_DIRECTORY_TREE_LIST, GET_CHILD_DIRECTORY_TREE_LIST } from "../../redux/actions/directory";
import { Table, Button } from 'antd'
import 'antd/dist/antd.css';
import find from 'lodash/find'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: "32%",
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '',
    dataIndex: 'url',
    key: 'url',
    width: "15%",
    render: (text) => <Button type="link" onClick={() => window.open(text)}>View Directory</Button>
  },
];

const Body = (PROPS) => {
  const DIRECTORY = PROPS.class.props.DIRECTORY;

  const flattenItems = (items, key) =>
    items.reduce((flattenedItems, item) => {
      flattenedItems.push(item)
      if (Array.isArray(item[key])) {
        return flattenedItems.concat(flattenItems(item[key], key))
      }
      return flattenedItems
    }, [])

  const handleExpand = (expanded, record) => {
    if (expanded === true) {
      GET_CHILD_DIRECTORY_TREE_LIST(record, flattenItems, find)
    }
  }

  return (
    <div className="container">
      <section>
        <div>
          <Table columns={columns} dataSource={DIRECTORY.treeList} onExpand={handleExpand} pagination={false} loading={DIRECTORY.loading} />
        </div>
      </section>
    </div>
  )
}

class TreeList extends Component {

  componentDidMount = async () => {
    GET_DIRECTORY_TREE_LIST()
  }
  render() {
    return <Body class={this} />
  }
}

export default connect(STATES, null)(TreeList)