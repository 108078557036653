import React from "react";
import { Link, makeStyles } from "@material-ui/core";
import { RiCloudOffLine } from "react-icons/all";
import Typography from "@material-ui/core/Typography";
import Space from "./Space";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";
import { env } from "../../../env";

export default function Main(props) {
  const classes = useStyles();
  const mailto =
    "mailto:" +
    env.EMAIL_UDAS.toLowerCase() +
    "?" +
    "cc=" +
    env.EMAIL_KMD.toLowerCase() +
    "&subject=UDAS%20server%20can't%20be%20reached";

  return (
    <Fade in={props.in}>
      <div className={classes.root}>
        <Grow in>
          <div className={classes.dialog}>
            <div className={classes.container}>
              <RiCloudOffLine size={70} className={classes.icon} />
              <Space vertical space={5} />
              <Typography variant={"h5"}>Server can't be reached</Typography>
              <Space vertical space={5} />
              <Typography variant={"body1"} align={"center"}>
                There's an interruption in your network or our server is offline
                at the moment. This will reload automatically when the server
                can be reached. Just make sure you are connected to the
                internet.
              </Typography>
              <Space vertical space={5} />
              <Typography variant={"body1"} align={"center"}>
                If persists, please contact administrator at
              </Typography>
              <Link href={mailto} target={"_blank"} rel={"noopener noreferrer"}>
                <Typography variant={"h6"} align={"center"} color={"primary"}>
                  {env.EMAIL_UDAS.toLowerCase()}
                </Typography>
              </Link>
            </div>
            <div className={classes.footer} />
          </div>
        </Grow>
      </div>
    </Fade>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: 1280,
    height: "100%",
    background: "#0000004d",
    zIndex: 3000,
    position: "absolute",
    top: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dialog: {
    width: 600,
  },
  container: {
    borderRadius:
      theme.shape.borderRadius + "px " + theme.shape.borderRadius + "px 0 0",
    background: "white",
    padding: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    color: theme.palette.primary.main,
  },
  footer: {
    height: 15,
    width: "100%",
    background: theme.palette.primary.main,
    borderRadius:
      "0 0 " +
      theme.shape.borderRadius +
      "px " +
      theme.shape.borderRadius +
      "px",
  },
}));
