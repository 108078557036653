import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import _ from "lodash";
import { isFunction } from "../../../utils/function";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    disableSelect,
  } = props;
  const createSortHandler = (index) => (event) => {
    onRequestSort(event, index);
  };

  return (
    <TableHead>
      <TableRow>
        {!disableSelect && (
          <TableCell padding="checkbox">
            <Checkbox
              color={"primary"}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {columns.map((headCell, index) => {
          if (headCell.hidden) return <></>;
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === index ? order : false}
              style={headCell.style}
            >
              <TableSortLabel
                active={orderBy === index}
                direction={orderBy === index ? order : "asc"}
                onClick={createSortHandler(index)}
              >
                <Typography variant={"h6"}>
                  {headCell.label.toUpperCase()}
                </Typography>
                {orderBy === index ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, title, disableDelete, disableFilter } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 && !disableDelete ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        !disableFilter && (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: "none",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rowSelected:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor:
            lighten(theme.palette.primary.light, 0.85) + " !important",
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
}));

export default function SelectionTable({
  title,
  disableDelete,
  disableFilter,
  rows = [],
  columns = [],
  select = [],
  setSelect = () => {},
  search,
  disableSelect,
  noHead,
  onClickRow,
  disablePagination,
  disableEmptyRow,
  disableDenseToggle,
  paginationRowsPerPage,
  onContextMenuRow,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(0);
  const [selected, setSelected] = React.useState(select);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    paginationRowsPerPage || 25
  );

  // columns = [
  //   {
  //     id: "id",
  //     numeric: true,
  //     disablePadding: true,
  //     label: "Dessert (100g serving)",
  //     hidden: true,
  //   },
  //   {
  //     id: "name",
  //     numeric: false,
  //     disablePadding: true,
  //     label: "Dessert (100g serving)",
  //   },
  //   { id: "calories", numeric: true, disablePadding: false, label: "Calories" },
  //   { id: "fat", numeric: true, disablePadding: false, label: "Fat (g)" },
  //   { id: "carbs", numeric: true, disablePadding: false, label: "Carbs (g)" },
  //   {
  //     id: "protein",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Protein (g)",
  //     align: "right",
  //   },
  // ];
  //
  // rows = [
  //   [0, "Cupcake", 305, 3.7, 67, 4.3],
  //   [1, "Donut", 452, 25.0, 51, 4.9],
  //   [2, "Eclair", 262, 16.0, 24, 6.0],
  //   [3, "Frozen yoghurt", 159, 6.0, 24, 4.0],
  //   [4, "Gingerbread", 356, 16.0, 49, 3.9],
  //   [5, "Honeycomb", 408, 3.2, 87, 6.5],
  //   [6, "Ice cream sandwich", 237, 9.0, 37, 4.3],
  //   [7, "Jelly Bean", 375, 0.0, 94, 0.0],
  //   [8, "KitKat", 518, 26.0, 65, 7.0],
  //   [9, "Lollipop", 392, 0.2, 98, 0.0],
  //   [10, "Marshmallow", 318, 0, 81, 2.0],
  //   [11, "Nougat", 360, 19.0, 9, 37.0],
  //   [12, "Oreo", 437, 18.0, 63, 4.0],
  // ];

  const handleRequestSort = (event, index) => {
    const isAsc = orderBy === index && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(index);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[0]);
      setSelected(newSelecteds);
      setSelect(newSelecteds);
      return;
    }
    setSelected([]);
    setSelect([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setSelect(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  if (search) {
    rows = _.filter(rows, (arr) => {
      let contains = false;
      for (let str of arr) {
        try {
          if (_.includes(str.toString().toLowerCase(), search.toLowerCase())) {
            contains = true;
            break;
          }
        } catch (e) {}
      }
      return contains;
    });
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {!noHead && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            title={title}
            disableDelete={disableDelete}
            disableFilter={disableFilter}
          />
        )}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              columns={columns}
              disableSelect={disableSelect}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(
                  disablePagination ? 0 : page * rowsPerPage,
                  disablePagination
                    ? rows.length
                    : page * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  const isItemSelected = isSelected(row[0]);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      color={"primary"}
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[0]}
                      selected={isItemSelected}
                      classes={{ selected: classes.rowSelected }}
                    >
                      {!disableSelect && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => {
                              handleClick(event, row[0]);
                            }}
                            color={"primary"}
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                      )}
                      {row.map((cell, cellIndex) => {
                        if (columns[cellIndex].hidden) return <></>;
                        return (
                          <TableCell
                            key={cellIndex}
                            onClick={() => {
                              if (typeof onClickRow === "function") {
                                setSelected([]);
                                onClickRow(row);
                              }
                            }}
                            onContextMenu={(e) => {
                              if (isFunction(onContextMenuRow))
                                onContextMenuRow(e, row);
                            }}
                            component="td"
                            id={labelId}
                            scope="row"
                            padding={
                              columns[cellIndex].disablePadding && "none"
                            }
                            align={columns[cellIndex].align}
                            style={columns[cellIndex].style}
                          >
                            {columns[cellIndex].wrap
                              ? columns[cellIndex].wrap(row[cellIndex])
                              : row[cellIndex]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {!disableEmptyRow && emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!disablePagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      {!disableDenseToggle && (
        <FormControlLabel
          control={
            <Switch
              checked={dense}
              onChange={handleChangeDense}
              color={"primary"}
            />
          }
          label="Dense padding"
        />
      )}
    </div>
  );
}
