const STATES = state => ({
    AUTH: state.AUTH,
    DIRECTORY: state.DIRECTORY,
    DOCUMENT: state.DOCUMENT,
    MESSAGE: state.MESSAGE,
    CONTEXT: state.CONTEXT,
    SUGGESTION: state.SUGGESTION,
    ADMIN: state.ADMIN,
})

export default STATES