import {
   CONTEXT_DIRECTORY_ACTIVE,
   CONTEXT_DIRECTORY_EXPANDED_ADD,
   CONTEXT_DIRECTORY_EXPANDED_REMOVE,
   CONTEXT_FORM_RESET_CHANGE_PASSWORD,
   CONTEXT_FORM_RESET_DEPARTMENT,
   CONTEXT_FORM_RESET_DIRECTORY,
   CONTEXT_FORM_RESET_DIRECTORY_GROUP,
   CONTEXT_FORM_RESET_DOCUMENT,
   CONTEXT_FORM_RESET_FILTER,
   CONTEXT_FORM_SET_MONITORING_PARAMETERS,
   CONTEXT_FORM_SET_CHANGE_PASSWORD,
   CONTEXT_FORM_SET_DEPARTMENT,
   CONTEXT_FORM_SET_REPORTS,
   CONTEXT_FORM_SET_DIRECTORY,
   CONTEXT_FORM_SET_DIRECTORY_GROUP,
   CONTEXT_FORM_SET_DOCUMENT,
   CONTEXT_FORM_SET_FILTER,
   CONTEXT_RESET,
   CONTEXT_SET,
   DIRECTORY_TOGGLE_SET
} from "../actions/types";

const init = {
   entered_path: "",
   redirect: null,

   active_directory: "",
   expanded_directories: [],

   selected_directories: [],
   selected_documents: [],

   header_search: "",
   search_key: "",
   search_mode: false,
   search_scope: "directory",

   // Document, Directory, Directory Group
   formType: "Document",

   // CREATE, UPDATE, DELETE
   formRequestMode: "CREATE",

   forms: {
      directory_group: {
         name: "",
         is_public: false
      },
      department: {
         name: "",
         head: {
            id: 0,
            name: ""
         },

         // ERROR FLAGS
         error_name: false,
         error_head: false
      },
      directory: {
         id: "",
         name: "New Folder",
         description: "",
         group: "",
         moderators: [],
         moderator: {
            id: 0,
            name: ""
         },
         is_public: false,

         // ERROR FLAGS
         error_name: false,

         link_description: {formRequestMode: "CREATE", details: []},
      },
      reports: {
         components: {}
      },
      document: {
         title: "",
         description: "",
         is_public: false,
         type: "",
         attributes: [],
         keywords: [],
         attachments: [],
         deletedAttachments: [],

         // TEMPORARY
         attribute_key: "",
         attribute_value: "",
         keyword: "",

         // ERROR FLAGS
         error_title: false,
         error_type: false,
         error_attachment: false
      },
      filter: {
         sort_by: "title",
         ascending: true,

         is_public: true,
         type: "",
         attributes: [],
         keywords: [],
         attachments: [],

         attribute_key: "",
         attribute_value: "",
         keyword: ""
      },
      change_password: {
         old: "",
         new: "",
         confirm: ""
      },

      // CONTEXT MENU
      documentX: 0,
      documentY: 0,
      directoryX: 0,
      directoryY: 0,
      directory_view_X: 0,
      directory_view_Y: 0,
      show_context_menu: false
   },

   toggles: {
      // Panels
      filter_panel: false,

      // Dialogs
      create_new: false,
      view_document: false,
      view_report: false,
      adminViewOffice: false,
      view_directory_link: false,

      // Wrap
      backdrop: false,

      // Filters
      filter_attributes: false,
      filter_keywords: false,
      filter_type: false,
      filter_public: false,

      // Loader
      first_directory_details_loader: true,
      first_directory_tree_loader: true,
      document_directory_loader: true,
      action_loader: true,
      directory_details_loader: false,
      directory_tree_loader: false,
      report_loader: false,
      login_loader: false,
      filter_save_loader: false,
      get_document_loader: false,
      create_submit_loader: false,
      update_submit_loader: false,
      delete_submit_loader: false,
      request_to_publicize_loader: false,
      cancel_request_to_publicize_loader: false,
      approve_request_to_publicize_loader: false,
      copy_loader: false,
      cut_loader: false,
      link_loader: false,
      get_suggestion: false,
      active_users_by_users_department_loader: false,

      // Action
      directoryListView: false,
      directoryLinkDescriptionView: false,

      // Server
      online: true
   },

   message_dialog: {
      open: false,
      variant: "success",
      icon: "",
      message: {
         title: "",
         caption: ""
      },
      on_close: () => { }
   },

   confirmation_dialog: {
      open: false,
      variant: "success",
      icon: "",
      message: {
         title: "",
         caption: ""
      },
      on_confirm: () => { }
   },

   request_dialog: {
      open: false,
      variant: "default",
      icon: "",
      message: {
         title: "",
         caption: ""
      },
      on_confirm: () => { }
   },

   // SESSION
   session_timer: 0,

   // CLIPBOARD
   pre_clipboard: {
      id: 0,
      type: ""
   },
   clipboard: {
      id: 0,
      type: "",
      action: ""
   },
   clipboard_destination: 0,
   cut_directory_descendance: []
};

export default function Main(state = init, action) {
   switch (action.type) {
      case CONTEXT_DIRECTORY_EXPANDED_ADD:
         return {
            ...state,
            expanded_directories: state.expanded_directories.concat([
               action.dir_id
            ])
         };

      case CONTEXT_DIRECTORY_EXPANDED_REMOVE:
         return {
            ...state,
            expanded_directories: state.expanded_directories.filter(
               dir => dir !== action.dir_id
            )
         };

      case CONTEXT_DIRECTORY_ACTIVE:
         return {
            ...state,
            active_directory: action.dir_id
         };

      case CONTEXT_SET:
         return {
            ...state,
            [action.payload.name]: action.payload.value
         };

      case DIRECTORY_TOGGLE_SET:
         return {
            ...state,
            toggles: {
               ...state.toggles,
               [action.payload.name]: action.payload.value
            }
         };

      case CONTEXT_FORM_SET_DIRECTORY:
         return {
            ...state,
            forms: {
               ...state.forms,
               directory: {
                  ...state.forms.directory,
                  [action.payload.name]: action.payload.value
               }
            }
         };

      case CONTEXT_FORM_SET_DIRECTORY_GROUP:
         return {
            ...state,
            forms: {
               ...state.forms,
               directory_group: {
                  ...state.forms.directory,
                  [action.payload.name]: action.payload.value
               }
            }
         };

      case CONTEXT_FORM_SET_DOCUMENT:
         return {
            ...state,
            forms: {
               ...state.forms,
               document: {
                  ...state.forms.document,
                  [action.payload.name]: action.payload.value
               }
            }
         };

      case CONTEXT_FORM_SET_FILTER:
         return {
            ...state,
            forms: {
               ...state.forms,
               filter: {
                  ...state.forms.filter,
                  [action.payload.name]: action.payload.value
               }
            }
         };

      case CONTEXT_FORM_SET_DEPARTMENT:
         return {
            ...state,
            forms: {
               ...state.forms,
               department: {
                  ...state.forms.department,
                  [action.payload.name]: action.payload.value
               }
            }
         };
      case CONTEXT_FORM_SET_REPORTS:
         return {
            ...state,
            forms: {
               ...state.forms,
               reports: {
                  ...state.forms.reports,
                  [action.payload.name]: action.payload.value
               }
            }
         };
      case CONTEXT_FORM_SET_CHANGE_PASSWORD:
         return {
            ...state,
            forms: {
               ...state.forms,
               change_password: {
                  ...state.forms.change_password,
                  [action.payload.name]: action.payload.value
               }
            }
         };

      case CONTEXT_FORM_RESET_DIRECTORY:
         return {
            ...state,
            forms: {
               ...state.forms,
               directory: {
                  id: "",
                  name: "New Folder",
                  description: "",
                  group: "",
                  moderators: [],
                  moderator: {
                     id: 0,
                     name: ""
                  },
                  is_public: false,

                  // ERROR FLAGS
                  error_name: false,

                  link_description: {formRequestMode: "CREATE", details: []},
               },
            }
         };

      case CONTEXT_FORM_RESET_DIRECTORY_GROUP:
         return {
            ...state,
            forms: {
               ...state.forms,
               directory_group: init.forms.directory_group
            }
         };

      case CONTEXT_FORM_RESET_DOCUMENT:
         return {
            ...state,
            forms: {
               ...state.forms,
               document: init.forms.document
            }
         };

      case CONTEXT_FORM_RESET_FILTER:
         return {
            ...state,
            forms: {
               ...state.forms,
               filter: init.forms.filter
            }
         };

      case CONTEXT_FORM_RESET_DEPARTMENT:
         return {
            ...state,
            forms: {
               ...state.forms,
               department: init.forms.department
            }
         };
      case CONTEXT_FORM_SET_MONITORING_PARAMETERS:
         return {
            ...state,
            forms: {
               ...state.forms,
               monitoring: {
                  ...state.forms.monitoring,
                  [action.payload.name]: action.payload.value
               }
            }
         };

      case CONTEXT_FORM_RESET_CHANGE_PASSWORD:
         return {
            ...state,
            forms: {
               ...state.forms,
               change_password: init.forms.change_password
            }
         };

      case CONTEXT_RESET:
         return init;

      default:
         return state;
   }
}
