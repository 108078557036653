import React, { Component } from 'react';
import { connect } from "react-redux";
// import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Space from "../../components/core/panel/Space";
import Line from "../../components/core/decoration/Line";

// const useStyles = makeStyles(theme => ({}));

const Body = props => {
    // const classes = useStyles()
    return (
        <div>

            {/*HEADINF*/}
            <Typography
                variant={'h4'}
            >
                Maintenance
            </Typography>
            <Typography
                variant={'body1'}
            >
                Perform system maintenance for optimal performance
            </Typography>
            <Space vertical space={3} />
            <Line horizontal />
            <Space vertical space={3} />


        </div>
    )
}

class Maintenance extends Component {
    render() {
        return <Body class={this} />
    }
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, {})(Maintenance)