import React from 'react'
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    textOverflow: {
        maxWidth: 400,
        minWidth: 50,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    searchKey: {
        color: theme.palette.primary.main
    },
}))

export default function Main(props) {
    const classes = useStyles()

    return (
        <div className={classes.flexContainer}>
            <Typography noWrap variant={'h6'} className={clsx(classes.textOverflow)}>
                Search Result for '<span className={classes.searchKey}>
                    {props.search_key ? props.search_key : ' '}
                </span>'
            </Typography>
        </div>
    )
}