import { create } from "apisauce";
import { store } from "../redux/index";
import apiConfig from "../config/api.config";

const { getState } = store;

export const API = create({
     baseURL: apiConfig.baseURL
});

export const getAPIConfig = () => {
     let token = getState().AUTH.token;
     return {
          headers: {
               Authorization: `Token ${token}`,
               'Content-Type': 'application/json',
          }
     };
};

export const getOldAPIConfig = () => {
     let token = getState().AUTH.token;
     return {
          headers: {
               Authorization: `Token ${token}`,
               'Content-Type': 'application/json',
          }
     };
};

export default API;
