import axios from "axios";
import { env } from "../../env";
import {
  CONTEXT_DIRECTORY_ACTIVE,
  CONTEXT_SET, DOCUMENT_DIRECTORY_GET,
  DOCUMENT_DETAILS_GET,
  DOCUMENT_REQUESTED_TO_PUBLICIZE_GET,
  DOCUMENT_ACTIONS_GET,
  DOCUMENT_MONITORING_GET,
} from "./types";
import { REQUEST_FUNCTIONS } from "./misc/functions";
import { store } from "../index";
import { clearForm, setToggle } from "./context";
import print from "../../utils/misc/print";
import { GET_DIRECTORY_DETAILS } from "./directory";
import { MESSAGE } from "./message";
import { REDIRECT } from "../../utils/history";
import USePLogo from "../../../src/static/media/img/USePLogo.png";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import {red} from "@material-ui/core/colors";
import { Old_English_Text_MT } from "../../static/fonts/base64/oldenglish";
import { ComicSans } from "../../static/fonts/base64/comicsans";
import { Arial } from "../../static/fonts/base64/arial";
const URL = env.URL_API + "document/";
const TOKEN_KEY = "Token ";
const { dispatch, getState } = store;

/*
    DOCUMENTS
 */
export const ADD_DOCUMENT = ({ callback, onUploadProgress }) => {
  setToggle("create_submit_loader", true);
  // MESSAGE("Creating document", "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const CONTEXT = getState().CONTEXT;
  const DOCUMENT_FORM = CONTEXT.forms.document;

  const DATA = new FormData();
  DATA.set("user_id", AUTH.id);
  DATA.set("directory_id", CONTEXT.active_directory.substring(3));
  DATA.set("title", DOCUMENT_FORM.title);
  DATA.set("description", DOCUMENT_FORM.description);
  DATA.set("is_public", DOCUMENT_FORM.is_public);
  DATA.set("type", DOCUMENT_FORM.type);

  for (let i = 0; i < DOCUMENT_FORM.attributes.length; i++) {
    DATA.set("ak_" + i, DOCUMENT_FORM.attributes[i].key);
    DATA.set("av_" + i, DOCUMENT_FORM.attributes[i].value);
  }
  for (let i = 0; i < DOCUMENT_FORM.keywords.length; i++)
    DATA.set("k_" + i, DOCUMENT_FORM.keywords[i]);
  // for (let i = 0; i < DOCUMENT_FORM.attachments.length; i++)
  //      DATA.set("a_" + i, DOCUMENT_FORM.attachments[i]);
  // DOCUMENT_FORM.attachments.forEach(file => {
  //    DATA.append("attachments[]", file);
  // });

  let uploadItems = DOCUMENT_FORM.attachments.length + 1;
  let uploadItem = 1;

  axios
    .post(URL, DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
      onUploadProgress: (progress) =>
        onUploadProgress(uploadItems, uploadItem, progress),
    })
    .then(async (response) => {
      print("DOCUMENT CREATE", response);
      if (REQUEST_FUNCTIONS(response)) {
        if (typeof callback === "function") callback();
        GET_DIRECTORY_DETAILS();

        const responses = [];
        const errors = [];
        const document_id = response.data.document_id;
        if (document_id) {
          for (const file of DOCUMENT_FORM.attachments) {
            let form = new FormData();
            form.set("document_id", document_id);
            form.set("attachment", file);
            uploadItem += 1;
            const request = {
              url: URL + "attachment/upload/",
              form,
              config: {
                headers: { Authorization: TOKEN_KEY + AUTH.token },
                onUploadProgress: (progress) =>
                  onUploadProgress(uploadItems, uploadItem, progress),
              },
            };
            await axios
              .post(request.url, request.form, request.config)
              .then((response) => {
                if (response.statusText === "OK") responses.push(response);
                else errors.push(request);
                print(response);
              })
              .catch((error) => {
                print(error);
                errors.push(request);
              });
          }
        }
        print({ responses, errors });

        setToggle("create_new", false);
        setToggle("create_submit_loader", false);
        clearForm("document");
        // MESSAGE("Creating document", "dequeue");

        if (errors.length > 0)
          MESSAGE(
            "Document created successfully but some attachments have failed to upload.",
            "enqueue",
            "error",
            true
          );
        else MESSAGE("Document created successfully", "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DOCUMENT CREATE", error);
      setToggle("create_submit_loader", false);
      MESSAGE("Failed to create document", "enqueue", "error");
    });
};

export const GET_DOCUMENT = (docdir_id, onSuccess) => {
  setToggle("get_document_loader", true);

  const AUTH = getState().AUTH;

  const data = new FormData();
  data.set("docdir_id", docdir_id);

  if (AUTH.id) {
    axios
      .post(URL + "details", data, {
        headers: { Authorization: TOKEN_KEY + AUTH.token },
      })
      .then(async (response) => {
        print("DOCUMENT GET", response);
        if (REQUEST_FUNCTIONS(response)) {
          dispatch({
            type: DOCUMENT_DETAILS_GET,
            payload: response.data,
          });
          if (typeof onSuccess === "function") onSuccess();
        }
      })
      .catch((error) => {
        print("DOCUMENT GET", error);
      });

  } else {
    axios
      .post(URL + "guests-details", data, {
        headers: { Authorization: TOKEN_KEY + AUTH.token },
      })
      .then(async (response) => {
        print("DOCUMENT GET", response);
        if (REQUEST_FUNCTIONS(response)) {
          dispatch({
            type: DOCUMENT_DETAILS_GET,
            payload: response.data,
          });
          if (typeof onSuccess === "function") onSuccess();
        }
      })
      .catch((error) => {
        print("DOCUMENT GET", error);
      });

  }


  setToggle("get_document_loader", true);
};

export const UPDATE_DOCUMENT = ({ callback, onUploadProgress }) => {
  setToggle("update_submit_loader", true);
  // MESSAGE("Updating document", "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const CONTEXT = getState().CONTEXT;
  const DOCUMENT_ID = getState().DOCUMENT.details.id;
  const DOCUMENT_FORM = CONTEXT.forms.document;

  const DATA = new FormData();
  DATA.set("title", DOCUMENT_FORM.title);
  DATA.set("description", DOCUMENT_FORM.description);
  DATA.set("is_public", DOCUMENT_FORM.is_public);
  DATA.set("type", DOCUMENT_FORM.type);

  for (let i = 0; i < DOCUMENT_FORM.attributes.length; i++) {
    DATA.set("ak_" + i, DOCUMENT_FORM.attributes[i].key);
    DATA.set("av_" + i, DOCUMENT_FORM.attributes[i].value);
  }
  for (let i = 0; i < DOCUMENT_FORM.keywords.length; i++)
    DATA.set("k_" + i, DOCUMENT_FORM.keywords[i]);
  // for (let i = 0; i < DOCUMENT_FORM.attachments.length; i++)
  //      DATA.set("a_" + i, DOCUMENT_FORM.attachments[i]);
  // DOCUMENT_FORM.attachments.forEach(file => {
  //    if (!file.id) DATA.append("attachments[]", file);
  // });
  DOCUMENT_FORM.deletedAttachments.forEach((id) => {
    DATA.append("deletedAttachments[]", id);
  });

  let uploadItems = 1;
  DOCUMENT_FORM.attachments.forEach((item) => {
    if (!item.id) uploadItems += 1;
  });
  let uploadItem = 1;



  // if(AUTH.id){
  axios
    .put(URL + DOCUMENT_ID + "/", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
      onUploadProgress: (progress) =>
        onUploadProgress(uploadItems, uploadItem, progress),
    })
    .then(async (response) => {
      print("DOCUMENT UPDATE", response);
      if (REQUEST_FUNCTIONS(response)) {
        if (typeof callback === "function") callback();
        GET_DIRECTORY_DETAILS();

        const responses = [];
        const errors = [];

        for (const file of DOCUMENT_FORM.attachments) {
          if (!file.id) {
            let form = new FormData();
            form.set("document_id", DOCUMENT_ID);
            form.set("attachment", file);
            uploadItem += 1;
            const request = {
              url: URL + "attachment/upload/",
              form,
              config: {
                headers: { Authorization: TOKEN_KEY + AUTH.token },
                onUploadProgress: (progress) =>
                  onUploadProgress(uploadItems, uploadItem, progress),
              },
            };
            await axios
              .post(request.url, request.form, request.config)
              .then((response) => {
                if (response.statusText === "OK") responses.push(response);
                else errors.push(request);
                print(response);
              })
              .catch((error) => {
                print(error);
                errors.push(request);
              });
          }
        }

        print({ responses, errors });

        setToggle("create_new", false);
        setToggle("update_submit_loader", false);
        clearForm("document");
        // MESSAGE("Updating document", "dequeue");
        if (errors.length > 0)
          MESSAGE(
            "Document updated successfully but some attachments have failed to upload.",
            "enqueue",
            "error",
            true
          );
        else MESSAGE("Document updated successfully", "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DOCUMENT UPDATE", error);
      setToggle("update_submit_loader", false);
      MESSAGE("Failed to update document", "enqueue", "error");
    });
  // }else{

  // }

};

export const DELETE_DOCUMENT = (docdir_id, callback) => {
  MESSAGE("Deleting document", "enqueue", "info", true);

  const AUTH = getState().AUTH;
  axios
    .delete(URL + docdir_id + "/", {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT DELETE", response);
      MESSAGE("Deleting document", "dequeue");
      if (REQUEST_FUNCTIONS(response)) {
        if (typeof callback === "function") callback();
        GET_DIRECTORY_DETAILS();
        MESSAGE("Deleting document", "dequeue");
        MESSAGE("Document deleted successfully", "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DOCUMENT DELETE", error);
      MESSAGE("Deleting document", "dequeue");
      MESSAGE("Failed to delete document", "enqueue", "error");
    });
};

/*
    PUBLIC POSTING
 */

export const GET_REQUESTED_TO_PUBLICIZE_DOCUMENTS = (callback) => {
  setToggle("get_document_loader", true);

  const AUTH = getState().AUTH;
  const CONTEXT = getState().CONTEXT;

  const data = new FormData();
  if (CONTEXT.search_key) data.set("search_key", CONTEXT.search_key);
  if (CONTEXT.forms.filter) {
    let filter_form = CONTEXT.forms.filter;
    let toggle = CONTEXT.toggles;
    if (toggle.filter_type) data.set("type", filter_form.type);
    data.set("sort_by", filter_form.sort_by);
    data.set("ascending", filter_form.ascending);

    if (filter_form.attributes && toggle.filter_attributes)
      for (let i = 0; i < filter_form.attributes.length; i++) {
        data.set("ak_" + i, filter_form.attributes[i].key);
        data.set("av_" + i, filter_form.attributes[i].value);
      }
    if (filter_form.keywords && toggle.filter_keywords)
      for (let i = 0; i < filter_form.keywords.length; i++)
        data.set("k_" + i, filter_form.keywords[i]);
  }

  axios
    .post(URL + "publicize/requested", data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT GET REQUESTED TO PUBLICIZE", response);
      if (REQUEST_FUNCTIONS(response)) {
        dispatch({
          type: DOCUMENT_REQUESTED_TO_PUBLICIZE_GET,
          payload: response.data,
        });
        dispatch({
          type: CONTEXT_DIRECTORY_ACTIVE,
          dir_id: "public-request",
        });
        if (!CONTEXT.search_key)
          dispatch({
            type: CONTEXT_SET,
            payload: {
              name: "search_mode",
              value: false,
            },
          });
        REDIRECT("public-request");
        if (typeof callback === "function") callback();
      }
    })
    .catch((error) => {
      print("DOCUMENT GET REQUESTED TO PUBLICIZE", error);
    });
};

export const REQUEST_TO_PUBLICIZE = (docdir_id, callback) => {
  MESSAGE(`Requesting document to publicize`, "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const data = new FormData();
  data.set("docdir_id", docdir_id);

  axios
    .post(URL + "publicize/request", data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT REQUEST TO PUBLIC", response);
      if (REQUEST_FUNCTIONS(response)) {
        MESSAGE(`Requesting document to publicize`, "dequeue");
        MESSAGE(`Requested successfully`, "enqueue", "success");
        setToggle("view_document", false);
        GET_DIRECTORY_DETAILS();
      }
    })
    .catch((error) => {
      print("DOCUMENT REQUEST TO PUBLIC", error);
      MESSAGE(`Requesting document to publicize`, "dequeue");
      MESSAGE(`Request failed`, "enqueue", "error");
    });
};

export const REQUEST_ALL_TO_PUBLICIZE = (callback) => {
  MESSAGE(`Requesting documents to publicize`, "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const DOCUMENTS = getState().DIRECTORY.details.documents;
  const DATA = new FormData();
  for (let i = 0; i < DOCUMENTS.length; i++) {
    DATA.set("docdir_id_" + i, DOCUMENTS[i].docdir_id);
  }
  axios
    .post(URL + "publicize/request-all", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT REQUEST ALL TO PUBLIC", response);
      if (REQUEST_FUNCTIONS(response)) {
        if (typeof callback === "function") callback();
        MESSAGE(`Requesting documents to publicize`, "dequeue");
        MESSAGE(`Requested successfully`, "enqueue", "success");
        GET_DIRECTORY_DETAILS();
      }
    })
    .catch((error) => {
      print("DOCUMENT REQUEST ALL TO PUBLIC", error);
      MESSAGE(`Requesting documents to publicize`, "dequeue");
      MESSAGE(`Request failed`, "enqueue", "error");
    });
};

export const CANCEL_REQUEST_TO_PUBLICIZE = (docdir_id, callback) => {
  MESSAGE(`Cancelling request to publicize`, "enqueue", "info", true);

  const AUTH = getState().AUTH;

  const DATA = new FormData();
  DATA.set("docdir_id", docdir_id);

  axios
    .post(URL + "publicize/request-cancel", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT CANCEL REQUEST TO PUBLIC", response);
      if (REQUEST_FUNCTIONS(response)) {
        MESSAGE(`Cancelling request to publicize`, "dequeue");
        MESSAGE(`Request cancelled successfully`, "enqueue", "success");
        setToggle("view_document", false);
        GET_DIRECTORY_DETAILS();
      }
    })
    .catch((error) => {
      print("DOCUMENT CANCEL REQUEST TO PUBLIC", error);
      MESSAGE(`Cancelling request to publicize`, "dequeue");
      MESSAGE(`Cancel request failed`, "enqueue", "error");
    });
};

export const PUBLICIZE = (docdir_id, callback) => {
  MESSAGE(`Publicizing document`, "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const data = new FormData();
  data.set("docdir_id", docdir_id);

  axios
    .post(URL + "publicize/approve", data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT APPROVE REQUEST TO PUBLIC", response);
      GET_DIRECTORY_DETAILS();
      if (REQUEST_FUNCTIONS(response)) {
        if (typeof callback === "function") callback();
        MESSAGE(`Publicizing document`, "dequeue");
        MESSAGE(`Document publicized successfully`, "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DOCUMENT APPROVE REQUEST TO PUBLIC", error);
      MESSAGE(`Publicizing document`, "dequeue");
      MESSAGE(`Failed to publicize document`, "enqueue", "error");
    });
};

export const UNPUBLICIZE = (docdir_id, callback) => {
  MESSAGE(`Unpublicizing document`, "enqueue", "info", true);

  const AUTH = getState().AUTH;

  const DATA = new FormData();
  DATA.set("docdir_id", docdir_id);

  axios
    .post(URL + "publicize/reject", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT REJECT REQUEST TO PUBLIC", response);
      if (REQUEST_FUNCTIONS(response)) {
        if (typeof callback === "function") callback();
        MESSAGE(`Unpublicizing document`, "dequeue");
        MESSAGE(`Document unpublicized successfully`, "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DOCUMENT REJECT REQUEST TO PUBLIC", error);
      MESSAGE(`Unpublicizing document`, "dequeue");
      MESSAGE(`Failed to unpublicize document`, "enqueue", "error");
    });
};

export const PUBLICIZE_GUEST = (docdir_id, callback) => {
  MESSAGE(`Publicizing document for guest.`, "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const DIRECTORY = getState().DIRECTORY;
  const data = new FormData();
  data.set("docdir_id", docdir_id);
  data.set("root_directory_id", DIRECTORY.details.ancestry[0].id);

  axios
    .post(URL + "publicize/request-guest", data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT APPROVE REQUEST TO PUBLIC", response);
      GET_DIRECTORY_DETAILS();
      if (REQUEST_FUNCTIONS(response)) {
        if (typeof callback === "function") callback();
        MESSAGE(`Publicizing document`, "dequeue");
        MESSAGE(`Document publicized successfully`, "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DOCUMENT APPROVE REQUEST TO PUBLIC", error);
      MESSAGE(`Publicizing document`, "dequeue");
      MESSAGE(`Failed to publicize document`, "enqueue", "error");
    });
};

export const UNPUBLICIZE_GUEST = (docdir_id, callback) => {
  MESSAGE(`Unpublicizing document for guest.`, "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const DIRECTORY = getState().DIRECTORY;
  const data = new FormData();
  data.set("docdir_id", docdir_id);
  data.set("root_directory_id", DIRECTORY.details.ancestry[0].id);

  axios
    .post(URL + "publicize/request-cancel-guest", data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT CANCEL REQUEST TO PUBLIC GUESTS", response);
      if (REQUEST_FUNCTIONS(response)) {
        MESSAGE(`Cancelling request to publicize`, "dequeue");
        MESSAGE(`Request cancelled successfully`, "enqueue", "success");
        setToggle("view_document", false);
        GET_DIRECTORY_DETAILS();
      }
    })
    .catch((error) => {
      print("DOCUMENT CANCEL REQUEST TO PUBLIC GUEST", error);
      MESSAGE(`Cancelling request to publicize`, "dequeue");
      MESSAGE(`Cancel request failed`, "enqueue", "error");
    });
};
/*
    CLIPBOARD
 */

export const COPY_DOCUMENT = (destination_directory, source_docdir) => {
  MESSAGE(`Copying document`, "enqueue", "info", true);

  const AUTH = getState().AUTH;
  // const CONTEXT = getState().CONTEXT;

  const DATA = new FormData();
  DATA.set("docdir_id", source_docdir);
  DATA.set("directory_id", destination_directory.substring(3));

  axios
    .post(URL + "copy", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT COPY", response);
      if (REQUEST_FUNCTIONS(response)) {
        await GET_DIRECTORY_DETAILS();
        MESSAGE(`Copying document`, "dequeue");
        MESSAGE(`Document copied successfully`, "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DOCUMENT COPY", error);
      MESSAGE(`Copying document`, "dequeue");
      MESSAGE(`Failed to copy document`, "enqueue", "error");
    });
};

export const LINK_DOCUMENT = (destination_directory, source_docdir) => {
  MESSAGE(`Linking document`, "enqueue", "info", true);

  const AUTH = getState().AUTH;
  // const CONTEXT = getState().CONTEXT;

  const data = new FormData();
  data.set("docdir_id", source_docdir);
  data.set("directory_id", destination_directory.substring(3));

  axios
    .post(URL + "link", data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT LINK", response);
      if (REQUEST_FUNCTIONS(response)) {
        GET_DIRECTORY_DETAILS();
        MESSAGE(`Linking document`, "dequeue");
        MESSAGE(`Linked document created successfully`, "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DOCUMENT LINK", error);
      MESSAGE(`Linking document`, "dequeue");
      MESSAGE(`Failed to create linked document`, "enqueue", "error");
    });
};

export const CUT_DOCUMENT = (destination_directory, source_docdir) => {
  MESSAGE(`Moving document`, "enqueue", "info", true);

  const AUTH = getState().AUTH;
  // const CONTEXT = getState().CONTEXT;

  const DATA = new FormData();
  DATA.set("docdir_id", source_docdir);
  DATA.set("directory_id", destination_directory.substring(3));

  axios
    .post(URL + "cut", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT CUT", response);
      if (REQUEST_FUNCTIONS(response)) {
        await GET_DIRECTORY_DETAILS();
        MESSAGE(`Moving document`, "dequeue");
        MESSAGE(`Document moved successfully`, "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DOCUMENT CUT", error);
      MESSAGE(`Moving document`, "dequeue");
      MESSAGE(`Failed to move document`, "enqueue", "error");
    });
};

export const CLEAR_DOCUMENT_DETAILS = () => {
  dispatch({
    type: DOCUMENT_DETAILS_GET,
    payload: {},
  });
};
export const GET_DOCUMENT_DIRECTORY = () => {
  setToggle("document_directory_loader", true);

  const AUTH = getState().AUTH;

  const data = new FormData();
  data.set("user_id", AUTH.id);

  axios.post(URL + "document_directory", data, {
    headers: { Authorization: TOKEN_KEY + AUTH.token },
  })
    .then(async (response) => {
      print("DOCUMENT DIRECORY", response);
      if (REQUEST_FUNCTIONS(response)) {
        dispatch({
          type: DOCUMENT_DIRECTORY_GET,
          payload: response.data,
        });
        setToggle("document_directory_loader", false);
      }
    })
    .catch((error) => {
      print("DOCUMENT DIRECORY", error);
    });
};

export const GET_ACTIONS = () => {
  setToggle("action_loader", true);

  const AUTH = getState().AUTH;
  const data = new FormData();

  axios.post(URL + "actions", data, {
    headers: { Authorization: TOKEN_KEY + AUTH.token },
  })
    .then(async (response) => {
      print("ACTIONS", response);
      if (REQUEST_FUNCTIONS(response)) {
        dispatch({
          type: DOCUMENT_ACTIONS_GET,
          payload: response.data,
        });
        setToggle("action_loader", false);
      }
    })
    .catch((error) => {
      print("ACTIONS", error);
    });
};

export const VIEW_MONITORING = () => {
  setToggle("report_loader", true);
  setToggle("view_report", false);

  const CONTEXT = getState().CONTEXT;
  const AUTH = getState().AUTH;
  const data = new FormData();

  data.set("user_id", AUTH.id);
  data.set("actions", CONTEXT.forms.monitoring.actions);
  data.set("offices", CONTEXT.forms.monitoring.offices);
  data.set("users", CONTEXT.forms.monitoring.users);
  data.set("date_from", CONTEXT.forms.monitoring.date_from);
  data.set("date_to", CONTEXT.forms.monitoring.date_to);

  axios.post(URL + "monitoring", data, {
    headers: { Authorization: TOKEN_KEY + AUTH.token },
  })
    .then(async (response) => {
      print("MONITORING", response);
      if (REQUEST_FUNCTIONS(response)) {
        dispatch({
          type: DOCUMENT_MONITORING_GET,
          payload: response.data,
        });

        setToggle("report_loader", false);
        setToggle("view_report", true);
      }
    })
    .catch((error) => {
      print("MONITORING", error);
    });
};


export const MONITORING_PRINT_PDF = (office, action, user) => {
  const CONTEXT = getState().CONTEXT;
  const DOCUMENT = getState().DOCUMENT;
  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";
  const date_from = CONTEXT.forms.monitoring.date_from;
  const date_to = CONTEXT.forms.monitoring.date_to;
  const formattedDateFrom = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date(date_from));
  const formattedDateTo = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date(date_to));
  const date_range = formattedDateFrom + " - " + formattedDateTo;

  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(4);
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.height;
  const headers = [["NO.", "ACTION", "DOCUMENT", "DESCRIPTION", "USER", "DATE"]];

  const base64ImgF = USePLogo;

  let ROWS = [{}];

  if (CONTEXT.toggles.report_loader === false && typeof DOCUMENT.document_monitoring.log !== 'undefined') {
    ROWS = DOCUMENT.document_monitoring.log.map(function (data, index) {

      const formattedDate = new Date(data.date).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'Asia/Manila' });
      return [index + 1, data.action, data.document, data.description, data.user, formattedDate.toString()]
    });
  }

  let content = {
    didDrawPage: function (data) {
      // INIT FONT STYLES
      doc.addFileToVFS("MyFont.ttf", Old_English_Text_MT());
      doc.addFileToVFS('ComicSansMS3-normal.ttf', ComicSans());
      doc.addFont("MyFont.ttf", "MyFont", "normal");
      doc.addFont('ComicSansMS3-normal.ttf', 'ComicSansMS3', 'normal');
      doc.addFileToVFS('Arial-normal.ttf', Arial());
      doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

      // Header Image
      if (base64ImgF) {
        doc.addImage(base64ImgF, "JPEG", (pageWidth / 2 - 25), 5, 50, 50);
      }
      // Header
      let mainHeaderFontSize = 16;
      let headerFontSize = 14;
      let mainHeaderText = "University of Southeastern Philippines";
      let headerText = "UDAS Document Activity Log";
      doc.setFontSize(mainHeaderFontSize);

      doc.setFont("MyFont");
      doc.text(mainHeaderText, pageWidth / 2, 70, { align: 'center' });

      doc.setFont("Arial");
      doc.setFontSize(headerFontSize);

      doc.text(headerText, pageWidth / 2, 90, { align: 'center' });

      let headerParamsFontSize = 10;
      doc.setFontSize(headerParamsFontSize);

      Date.prototype.getCurrentDateTime = function () {
        let hours = this.getHours() % 12;
        return this.getFullYear()+"-"+(this.getMonth()+1).toString().padStart(2, '0')+"-"+this.getDate().toString().padStart(2, '0')+" "+ (hours ? hours.toString().padStart(2, '0') : "12") + ":" + this.getMinutes().toString().padStart(2, '0') + " " + (this.getHours() >= 12 ? 'PM' : 'AM');
      }

      const formattedDateNow = new Date().getCurrentDateTime();
      const dateGenerateTxt = "Date Generated: "+ formattedDateNow.toString();
      doc.text(office, pageWidth / 2, 105, { align: 'center' });
      doc.text(action, pageWidth / 2, 120, { align: 'center' });
      doc.text(user, pageWidth / 2, 135, { align: 'center' });
      doc.text(date_range, pageWidth / 2, 150, { align: 'center' });
      doc.setTextColor(255,0,0);

      let dateGeneratedFontSize = 8
      doc.setFontSize(dateGeneratedFontSize);
      doc.text(dateGenerateTxt, pageWidth-42, 165, { align: 'right' });

      // Footer Line
      doc.setLineWidth(1.5);
      doc.setDrawColor(0, 0, 0)
      doc.line(45, pageHeight - 60, pageWidth - 45, pageHeight - 60);
      // Footer Text
      doc.setFontSize(10);
      doc.setTextColor(0,0,0);
      doc.text("WE BUILD DREAM WITHOUT LIMITS", 70, pageHeight - 50);
      doc.addFont("ComicSansMS", "Comic Sans", "normal");
      doc.setFont("Comic Sans");
      doc.setFontSize(8);
      doc.setFont("times");
      doc.text("Address: University of Southeastern Philippines", 70, pageHeight - 40);
      doc.text("Telephone: (082) 227-8192 local 271", pageWidth - 200, pageHeight - 40);
      doc.text("Iñigo St., Bo Obrero, Davao City", 70, pageHeight - 31);
      doc.text("Website: www.dts.usep.edu.ph", pageWidth - 200, pageHeight - 31);
      doc.text("Philippines 8000", 70, pageHeight - 22);
      doc.text("Email:sdmd@usep.edu.ph", pageWidth - 200, pageHeight - 22);
    },
    head: headers,
    body: ROWS,
    columnStyles: {
      0: { cellWidth: 45 },
      2: { cellWidth: 'auto' },
      3: { cellWidth: 'auto' },
      4: { cellWidth: 'auto' },
      5: { cellWidth: 'auto' },
    },
    margin: {
      top: 170,
      bottom: 50
    },
    bodyStyles: {
      fontSize: 7,
    },
    headStyles: {
      fillColor: "#D75D5F",
      fontSize: 9,
    }
  };

  doc.autoTable(content);
  doc.save("monitoring_"+date_from+"_"+date_to+"_"+user+".pdf");
};

export const REQUEST_TO_PUBLICIZE_GUEST = (docdir_id, callback) => {
  MESSAGE(`Requesting document to publicize`, "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const data = new FormData();
  data.set("docdir_id", docdir_id);

  axios
    .post(URL + "publicize/request-approved-guest", data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT REQUEST TO PUBLIC", response);
      if (REQUEST_FUNCTIONS(response)) {
        MESSAGE(`Requesting document to publicize (Global)`, "dequeue");
        MESSAGE(`Requested successfully`, "enqueue", "success");
        setToggle("view_document", false);
        GET_DIRECTORY_DETAILS();
      }
    })
    .catch((error) => {
      print("DOCUMENT REQUEST TO PUBLIC", error);
      MESSAGE(`Requesting document to publicize (Global)`, "dequeue");
      MESSAGE(`Request failed`, "enqueue", "error");
    });
};

export const CANCEL_REQUEST_TO_PUBLICIZE_GUEST = (docdir_id, callback) => {
  MESSAGE(`Cancelling request to publicize (Global)`, "enqueue", "info", true);

  const AUTH = getState().AUTH;

  const DATA = new FormData();
  DATA.set("docdir_id", docdir_id);

  axios
    .post(URL + "publicize/request-cancel-publicize-guests", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT CANCEL REQUEST TO PUBLIC", response);
      if (REQUEST_FUNCTIONS(response)) {
        MESSAGE(`Cancelling request to publicize (Global)`, "dequeue");
        MESSAGE(`Request cancelled successfully (Global)`, "enqueue", "success");
        setToggle("view_document", false);
        GET_DIRECTORY_DETAILS();
      }
    })
    .catch((error) => {
      print("DOCUMENT CANCEL REQUEST TO PUBLIC", error);
      MESSAGE(`Cancelling request to publicize`, "dequeue");
      MESSAGE(`Cancel request failed`, "enqueue", "error");
    });
};

export const PUBLICIZE_GLOBAL_GUEST = (docdir_id, callback) => {
  MESSAGE(`Publicizing document for guest.`, "enqueue", "info", true);

  const AUTH = getState().AUTH;
  const DOCUMENT = getState().DOCUMENT;
  const data = new FormData();
  data.set("docdir_id", docdir_id);
  data.set('root_directory_id', DOCUMENT.details.this_path[0].id)
  axios
    .post(URL + "publicize/request-global-guest", data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT APPROVE REQUEST TO PUBLIC", response);
      GET_DIRECTORY_DETAILS();
      if (REQUEST_FUNCTIONS(response)) {
        if (typeof callback === "function") callback();
        MESSAGE(`Publicizing document`, "dequeue");
        MESSAGE(`Document publicized successfully (Global)`, "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DOCUMENT APPROVE REQUEST TO PUBLIC", error);
      MESSAGE(`Publicizing document`, "dequeue");
      MESSAGE(`Failed to publicize document`, "enqueue", "error");
    });
};

export const UNPUBLICIZE_GLOBAL_GUEST = (docdir_id, callback) => {
  MESSAGE(`Unpublicizing document`, "enqueue", "info", true);

  const AUTH = getState().AUTH;

  const DATA = new FormData();
  DATA.set("docdir_id", docdir_id);

  axios
    .post(URL + "publicize/request-cancel-global-guest", DATA, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (response) => {
      print("DOCUMENT REJECT REQUEST TO PUBLIC", response);
      if (REQUEST_FUNCTIONS(response)) {
        if (typeof callback === "function") callback();
        MESSAGE(`Unpublicizing document`, "dequeue");
        MESSAGE(`Document unpublicized successfully (Global)`, "enqueue", "success");
      }
    })
    .catch((error) => {
      print("DOCUMENT REJECT REQUEST TO PUBLIC", error);
      MESSAGE(`Unpublicizing document`, "dequeue");
      MESSAGE(`Failed to unpublicize document`, "enqueue", "error");
    });
};
