import { RELOAD } from "./history";
import IS_AUTHENTICATED from "./permissions/IS_AUTHENTICATED";
import { LOAD_DIRECTORY } from "./directory";
import IS_ADMIN from "./permissions/IS_ADMIN";
import { setToggle } from "../redux/actions/context";
import { getState } from "../redux";
import MONITOR_INTERNET_CONNECTION from "./global/MONITOR_INTERNET_CONNECTION";
import {
  GET_DIRECTORY_DETAILS,
  GET_DIRECTORY_TREE
} from "../redux/actions/directory";

export default function Main() {
  window.onpopstate = () => {
    if (IS_AUTHENTICATED()) {
      const isOpenCraeteNew = getState.CONTEXT?.toggles.create_new;
      if (isOpenCraeteNew) setToggle("create_new", false);

      if (
        window.location.pathname === "/login" ||
        window.location.pathname === "/"
      ) {
        RELOAD();
      }
      if (!IS_ADMIN()) {
        LOAD_DIRECTORY();
      }
    }
  };

  MONITOR_INTERNET_CONNECTION(() => {
    if (IS_AUTHENTICATED()) {
      GET_DIRECTORY_TREE();
      GET_DIRECTORY_DETAILS();
    }
  });
}
