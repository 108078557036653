import axios from "axios";
import {
  SUGGESTION_ATTR_KEY,
  SUGGESTION_ATTR_VALUE,
  SUGGESTION_CLEAR,
  SUGGESTION_DEPARTMENT_USERS,
  SUGGESTION_DIRECTORY_GROUP,
  SUGGESTION_KEYWORD,
  SUGGESTION_TYPE,
  SUGGESTION_USERS,
} from "./types";
import print from "../../utils/misc/print";

import { store } from "../index";
import { env } from "../../env";
import { REQUEST_FUNCTIONS } from "./misc/functions";
import { setToggle } from "./context";

const TOKEN_KEY = "Token ";
const { dispatch, getState } = store;

export const GET_SUGGESTION = (type, key = "") => {
  setToggle("get_suggestion", true);

  const AUTH = getState().AUTH;
  const CONTEXT = getState().CONTEXT;
  const directory_id = CONTEXT.active_directory.substring(3);
  let URL = env.URL_API;

  let data = new FormData();
  data.set("key", key);

  let action;
  switch (type) {
    case "attribute_key":
      URL += "document/suggestion/attribute/key";
      action = SUGGESTION_ATTR_KEY;
      break;
    case "attribute_value":
      URL += "document/suggestion/attribute/value";
      action = SUGGESTION_ATTR_VALUE;
      break;
    case "keyword":
      URL += "document/suggestion/keyword";
      action = SUGGESTION_KEYWORD;
      break;
    case "type":
      URL += "document/suggestion/type";
      action = SUGGESTION_TYPE;
      break;
    case "directory_group":
      data.set("directory_id", directory_id);
      URL = env.URL_API + "directory/suggestion/group";
      action = SUGGESTION_DIRECTORY_GROUP;
      break;
    case "user":
      console.log(key);
      URL = env.URL_API + "department/suggestion/all-users";
      action = SUGGESTION_USERS;
      break;
    case "department_user":
      data.set("directory_id", directory_id);
      data.set("user_id", AUTH.id);
      URL = env.URL_API + "department/suggestion/users";
      action = SUGGESTION_DEPARTMENT_USERS;
      break;
    default:
      break;
  }

  axios
    .post(URL, data, {
      headers: { Authorization: TOKEN_KEY + AUTH.token },
    })
    .then(async (res) => {
      print(action, res);
      if (REQUEST_FUNCTIONS(res, dispatch)) {
        dispatch({
          type: action,
          payload: res.data.suggestions,
        });
      }
    })
    .catch((err) => {
      print(action, err);
    });

  setToggle("get_suggestion", false);
};

export const CLEAR_SUGGESTION = (type) => {
  dispatch({ type: SUGGESTION_CLEAR, name: type });
};
