import 'date-fns';
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from "@material-ui/core";
import { setForm, setToggle } from "../../../redux/actions/context";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
}));

export default function MaterialUIPickers(props) {
    const classes = useStyles();
    const PROPS = props;
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(null);

    useEffect(() => {
        setForm(PROPS.type, PROPS.name, getFormattedDate(selectedDate));
    }, [selectedDate]);

    const getFormattedDate = function (date) {
        let formattedDate = "";
        if (date) {
            const _date = new Date(date);
            const _year = _date.getFullYear();
            const _month = (1 + _date.getMonth()).toString();
            const _day = _date.getDate();
            formattedDate = _year + "-" + _month.toString().padStart(2, '0') + "-" + _day.toString().padStart(2, '0');
        }
        return formattedDate;
    }

    const handleDateChange = (date) => {
        setToggle("view_report", false);
        setSelectedDate(date);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid className={classes.root}>
                <KeyboardDatePicker
                    margin="normal"
                    // id="date-picker-dialog"
                    label={PROPS.title}
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    maxDate={PROPS.currentMaxDate ? new Date() : null}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}
